import ReactModal from 'react-modal';

import styles from './Drawer.module.scss';

import Translate from '../translate';

interface DrawerProps{
    children: any,
    disableOverlayClick?: boolean,
    fullScreen?: boolean,
    isOpen: boolean,
    onClick?: () => void,
    onClose: () => void,
    showButton?: boolean,
    showHeader?: boolean,
    style?: Record<string, unknown>,
    subheader?: any,
}

const Drawer = ({
    children = null,
    disableOverlayClick = false,
    fullScreen = false,
    isOpen = true,
    onClick = () => {},
    onClose = () => {},
    showHeader = false,
    showButton = false,
    style = {},
    subheader = null,
}: DrawerProps) => {
    if (!isOpen) {
        return null;
    }
    return (
        <ReactModal
            className={`${styles.animate} ${styles.container} ${fullScreen ? styles.fullscreen : ''}`}
            isOpen={isOpen}
            onRequestClose={onClose}
            overlayClassName={styles.overlay}
            shouldCloseOnOverlayClick={!disableOverlayClick}
        >
            {showHeader && (
                <div className={styles.header}>
                    <button
                        className={styles.closeButton}
                        onClick={onClose}
                        type="button"
                    >
                        &#10005;
                    </button>
                </div>
            )}
            {subheader && (
                <div className={styles.subheader}>
                    <Translate>{subheader}</Translate>
                </div>
            )}
            <div className={styles.content} style={style}>
                {children}
            </div>
            {showButton && (
                <div className={styles.buttonContainer}>
                    <button
                        className={styles.applyButton}
                        onClick={onClick}
                        type="button"
                    >
                        <Translate>APPLY</Translate>
                    </button>
                </div>
            )}
        </ReactModal>

    );
};

export default Drawer;
