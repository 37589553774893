import { useEffect, useRef, useState } from 'react';

import styles from './FtpModal.module.scss';

import { iconPowerZones } from '../../../../../../assets';
import Button from '../../../../../../components/button';
import Modal from '../../../../../../components/modal';
import Spinner from '../../../../../../components/spinner';
import Translate from '../../../../../../components/translate';
import { AXS_TEXT_GREY, makeCancellable, RequestType } from '../../../../../../constants';
import { useNexus } from '../../../../../../providers';
import ErrorModal from '../../../../../../views/errorModal';

function renderHeader() {
    return (
        <>
            <Translate>FTP_MODAL_TITLE</Translate>
            <div className={styles.subHeader}>
                <Translate>USER_UPDATE_WARNING</Translate>
            </div>
        </>
    );
}

interface FtpModalProps {
    onCancel: () => void,
    onSave: () => void,
    open: boolean
}

function FtpModal({ onCancel = () => {}, onSave = () => {}, open = false }: FtpModalProps) {
    const [error, setError] = useState<Error | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [profileToUpdate, setProfileToUpdate] = useState<any>({});
    const nexus = useNexus();

    const updateNexusUserProfileRequests = useRef(new Set<RequestType>());

    useEffect(() => (() => {
        updateNexusUserProfileRequests.current.forEach((request) => request.cancel());
        updateNexusUserProfileRequests.current.clear();
    }), []);

    function getCurrentValue(key: string) {
        if (profileToUpdate[key] != null) {
            return profileToUpdate[key];
        }

        if (nexus.nexusUserProfile && nexus.nexusUserProfile[key] != null) {
            return nexus.nexusUserProfile[key];
        }

        return '';
    }

    function stageProfileUpdate(key: string, value: number) {
        if (!key) {
            return;
        }

        const newProfileToUpdate = { ...profileToUpdate };

        if (nexus.nexusUserProfile[key] === value) {
            delete newProfileToUpdate[key];
        } else {
            newProfileToUpdate[key] = value;
        }

        setProfileToUpdate(newProfileToUpdate);
    }

    function stageNumber(key: string, value: number) {
        let newNumber = Number(value);

        if (Number.isNaN(newNumber)) {
            newNumber = 0;
        }

        stageProfileUpdate(key, newNumber);
    }

    async function submitProfileUpdate() {
        // Only update the nexus profile if there has been changes
        if (Object.keys(profileToUpdate).length) {
            // updateNexusUserProfile catches all errors
            setIsFetching(true);

            const fetchRequest = makeCancellable(
                nexus.updateNexusUserProfile(profileToUpdate),
            );
            updateNexusUserProfileRequests.current.add(fetchRequest);

            try {
                const updatedActivity = await fetchRequest.promise;
                updateNexusUserProfileRequests.current.delete(fetchRequest);

                if (!updateNexusUserProfileRequests.current.size) {
                    setIsFetching(false);
                    setProfileToUpdate({});
                }

                if (updatedActivity) {
                    onSave();
                } else {
                    setError(new Error('Error Updating FTP'));
                }
            } catch (err: any) {
                if (!err.isCancelled) {
                    updateNexusUserProfileRequests.current.delete(fetchRequest);

                    setError(new Error('Error Updating FTP'));

                    if (!updateNexusUserProfileRequests.current.size) {
                        setIsFetching(false);
                        setProfileToUpdate({});
                    }
                }
            }
        } else {
            onCancel();
        }
    }

    if (error) {
        return (
            <ErrorModal
                error={error}
                onClose={() => setError(null)}
                onOverlayClick={() => {
                    setError(null);
                    onCancel();
                }}
            />
        );
    }

    return (
        <Modal
            contentClassName={styles.container}
            contentLabel="This will apply to all ride activities"
            dialog
            header={renderHeader()}
            hideCloseButton
            // Overiding styles needs inline to avoid using !important
            imageStyle={{ height: '1.75rem', marginBottom: 0, marginTop: 'auto' }}
            imageSrc={iconPowerZones}
            isOpen={open}
            onClose={() => {
                setProfileToUpdate({});
                onCancel();
            }}
        >
            <Spinner loading={isFetching} />
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    submitProfileUpdate();
                }}
                style={{ margin: 'auto', width: '100%' }}
            >

                <div className={styles.row}>
                    <div className={styles.rowLabel}>
                            FTP
                    </div>
                    <input
                        className={styles.inputField}
                        id="ftp"
                        min={1}
                        onChange={(event) => stageNumber('ftp', Number(event.target.value))}
                        placeholder="---"
                        type="number"
                        value={getCurrentValue('ftp').toString()}
                    />
                </div>
            </form>
            <div className={styles.buttonsContainer}>
                <Button
                    className={styles.button}
                    color={AXS_TEXT_GREY}
                    inverse
                    onClick={onCancel}
                >
                    <Translate>CANCEL</Translate>
                </Button>
                <Button
                    className={styles.button}
                    onClick={() => submitProfileUpdate()}
                    type="button"
                >
                    <Translate>SAVE</Translate>
                </Button>
            </div>
        </Modal>
    );
}

export default FtpModal;
