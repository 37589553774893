import prettyBytes from 'pretty-bytes';
import React from 'react';

import styles from './UploadRow.module.scss';

import { iconDelete, iconTrash } from '../../../assets';

interface FileProps {
    failed: boolean;
    name: string;
    progress: number;
    size: number;
}

interface UploadRowProps {
    file: FileProps;
    isUploading: boolean;
    onDelete: () => void;
}

const UploadRow = ({
    file: {
        failed,
        name,
        progress,
        size,
    },
    isUploading,
    onDelete,
}: UploadRowProps) => {
    const showDeleteButton = (
        failed
        || (!isUploading && progress === 0)
    );

    return (
        <div className={styles.container}>
            <div className={styles.nameContainer}>
                {name}
            </div>
            <div style={{ justifySelf: 'center', whiteSpace: 'nowrap' }}>
                {prettyBytes(size, { locale: true })}
            </div>
            <div className={styles.progressBar}>
                <div className={styles.percentageContainer}>
                    <div
                        className={styles.percentageBar}
                        style={{
                            backgroundColor: `${(progress >= 100) ? '#34A853' : '#E51937'}`,
                            width: `${failed ? 0 : progress}%`,
                        }}
                    />
                </div>
            </div>
            {showDeleteButton && (
                <button
                    className={styles.deleteIconButton}
                    onClick={onDelete}
                    type="button"
                >
                    <img
                        alt=""
                        className={failed ? styles.errorIcon : styles.deleteIcon}
                        src={failed ? iconDelete : iconTrash}
                    />
                </button>
            )}
        </div>
    );
};

export default UploadRow;
