const ZONES = {
    HEART_ZONES: [
        { max: 0.80, min: 0 },
        { max: 0.89, min: 0.81 },
        { max: 0.93, min: 0.90 },
        { max: 0.99, min: 0.94 },
        { max: -1, min: 1.00 },
    ],
    POWER_TRAINING_ZONES: [
        { max: 0.55, min: 0 },
        { max: 0.75, min: 0.55 },
        { max: 0.9, min: 0.75 },
        { max: 1.05, min: 0.9 },
        { max: 1.20, min: 1.05 },
        { max: 1.50, min: 1.20 },
        { max: -1, min: 1.50 },
    ],
};

export default ZONES;
