import React from 'react';
import { Link } from 'react-router-dom';

import styles from './BikeFeedCard.module.scss';

import BikeCard from '../../../views/bikeCard';

import { Bike } from '../../../providers';

interface BikeFeedCardProps {
    bike: Bike;
    notificationSet: any;
}

const BikeFeedCard = ({ bike, notificationSet }: BikeFeedCardProps) => (
    <div
        className={styles.container}
        style={(bike.data && bike.data.retired) ? { filter: 'grayscale(80%)' } : { filter: 'none' }}
    >
        <Link className={styles.mobileLink} to={`${bike.uuid}`}>
            <BikeCard bike={bike} showAlertBadge={!!notificationSet?.length} notificationSet={notificationSet} />
        </Link>
    </div>
);

export default BikeFeedCard;
