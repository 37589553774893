import { useEffect, useState } from 'react';

import styles from './GearingForm.module.scss';

import Button from '../../../components/button';
import Translate from '../../../components/translate';
import { AXS_TEXT_GREY } from '../../../constants';
import DrivetrainInput from '../../../views/bikeForm/drivetrainInput';

interface GearingFormProps {
    bike: any,
    disabled: boolean,
    gearComponent?: any,
    savingBike: boolean,
    updateBike: (bikeUpdates: unknown) => void,
}

const GearingForm = ({
    bike,
    disabled,
    gearComponent,
    savingBike,
    updateBike,
}: GearingFormProps) => {
    if (!bike || !bike.id) return null;

    const [bikeUpdates, setBikeUpdates] = useState<any>({});

    const onDataChange = (key: string, value: unknown) => {
        if (!key) return;

        setBikeUpdates({
            ...bikeUpdates,
            [key]: value,
        });
    };

    const getDataValue = (key: string) => {
        if (bikeUpdates[key]) return bikeUpdates[key];

        if (bike && bike[key]) return bike[key];

        return '';
    };

    const renderButtons = () => {
        if (!Object.keys(bikeUpdates).length) return null;

        return (
            <div className={styles.buttonsContainer}>
                <Button
                    color={AXS_TEXT_GREY}
                    inverse
                    onClick={() => setBikeUpdates({})}
                >
                    <Translate>CANCEL</Translate>
                </Button>
                <Button
                    className={styles.saveButton}
                    type="submit"
                >
                    <Translate>SAVE</Translate>
                </Button>
            </div>
        );
    };

    useEffect(() => {
        // reset bikeUpdates once bike is updated
        if (!savingBike) {
            setBikeUpdates({});
        }
    }, [savingBike]);

    return (
        <form
            onSubmit={(event) => {
                event.preventDefault();
                updateBike(bikeUpdates);
            }}
            style={{ margin: 'auto', width: '100%' }}
        >
            <DrivetrainInput
                disabled={disabled}
                getValue={(key: string) => getDataValue(key)}
                numberOfChainrings={gearComponent && gearComponent.data.num_chainrings}
                numberOfCogs={gearComponent && gearComponent.data.num_cogs}
                onChange={(key: string, value: unknown) => onDataChange(key, value)}
            />
            {renderButtons()}
        </form>
    );
};

export default GearingForm;
