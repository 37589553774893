import styles from './AdvancedUnitsToggleSelectors.module.scss';

import Row from '../Row';

import ToggleSelector from '../../../../components/toggleSelector';
import { SRAM_700 } from '../../../../constants';
import { UNIT_LABELS } from '../../../../providers/units/constants';
import { useUnits } from '../../../../providers/units/UnitsContext';

interface AdvancedUnitsToggleSelectorsProps { advancedUnits: any, onChange: (name: string, newValue: any) => void }

const UNIT_CATEGORY_LABELS: Record<string, string> = {
    altitude: 'ALTITUDE',
    distance: 'DISTANCE',
    energy: 'ENERGY',
    height: 'PROFILE_HEIGHT',
    mass: 'PROFILE_WEIGHT',
    pressure_suspension: 'SUSPENSION_PRESSURE',
    pressure_tire: 'TIRE_PRESSURE',
    speed: 'SPEED',
    temperature: 'TEMPERATURE',
};

const AdvancedUnitsToggleSelectors = ({
    advancedUnits = {},
    onChange = () => {},
}: AdvancedUnitsToggleSelectorsProps): any => {
    const { units } = useUnits();

    return units.map((unit: any) => {
        if (unit.name === 'time_format' || unit.name === 'work') return null;

        return (
            <Row
                className={styles.row}
                contentStyle={{ maxWidth: 'initial' }}
                key={unit.name}
                label={UNIT_CATEGORY_LABELS[unit.name] || unit.name}
            >
                <ToggleSelector
                    activeButton={advancedUnits[unit.name]}
                    buttons={unit.conversions.map((conversion: any) => ({
                        label: UNIT_LABELS[conversion.label].shorthand,
                        value: conversion.label,
                    }))}
                    className={styles.buttonGroup}
                    color={SRAM_700}
                    onToggle={(newValue) => onChange(unit.name, newValue)}
                />
            </Row>
        );
    });
};

export default AdvancedUnitsToggleSelectors;
