import { ReactNode, useEffect } from 'react';
import { Cookies } from 'react-cookie-consent';
import { useLocation } from 'react-router-dom';

import FirebaseAnalytics from '../firebaseAnalytics';
import { useAuth } from '../../providers/auth/AuthContext';

interface AnalyticsProps { children: ReactNode }
const ALLOWED_COOKIE_PREFERENCES = '"preferences":true';

export const checkCookieConsent = (cookieValue: string) => {
    const decodedCookieConsent = decodeURI(cookieValue);
    if (decodedCookieConsent.includes(ALLOWED_COOKIE_PREFERENCES)) {
        return true;
    }

    if (decodedCookieConsent === 'true') {
        return true;
    }

    return false;
};

declare global {
    interface Window {
        woopra: any;
    }
}

const Analytics = ({ children = null }: AnalyticsProps) => {
    const { isAuthenticated, userProfile } = useAuth();

    if (userProfile?.sub && window.woopra) {
        window.woopra.identify({
            // eslint-disable-next-line camelcase
            auth0_sub: userProfile?.sub,
        });
    }

    let consent = false;
    const cookieConsent = Cookies.get('CookieConsent');
    const location = useLocation();
    if (cookieConsent) {
        consent = checkCookieConsent(cookieConsent);
    }
    const sendPageView = () => {
        const properties = {
            /* eslint-disable camelcase */
            page_path: location.pathname,
            page_title: location.pathname,
            userId: userProfile ? userProfile.sub : null,
            /* eslint-enable camelcase */
        };

        FirebaseAnalytics('page_view', properties);
    };

    useEffect(() => {
        if (consent || isAuthenticated()) sendPageView();
    }, [location.pathname]);

    return children;
};

export default Analytics;
