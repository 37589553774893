import React from 'react';

import styles from './FilterHeader.module.scss';

import Translate from '../../../../components/translate';

import { iconRightArrow } from '../../../../assets';

interface FilterHeaderProps {
    heading: string,
    isOpen: boolean
}

const FilterHeader = ({ heading, isOpen } : FilterHeaderProps) => (
    <div className={styles.container}>
        <div className={styles.labelContainer}>
            <div className={styles.labelHeaderContainer}>
                <Translate>{heading}</Translate>
            </div>
            <img
                alt=""
                className={`${styles.indicator} ${!isOpen ? styles.rotateIcon : ''}`}
                src={iconRightArrow}
            />
        </div>
    </div>
);

export default FilterHeader;
