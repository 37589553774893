import { scaleLog } from 'd3';
import {
    Area,
    Grid,
    Line,
    lineBisector,
    onMouseMove,
    onTouchMove,
    Scale,
    VerticalLine,
} from 'd3-charts-react';
import React, { useCallback, useMemo, useState } from 'react';
import { Axis, axisPropsFromTickScale, LEFT } from 'react-d3-axis';
import { useResizeDetector } from 'react-resize-detector';

import styles from './PowerCurveChart.module.scss';
import WeightModal from './weightModal';

import { FtpDisplay } from '../thresholdDisplay';

import {
    categoriseData,
    CURVE_CHART_DURATION_TICK_VALUES,
    formatPower,
    mutateScaleNice,
} from '../../chartHelpers';
import BikeComponentLink from '../../bikeComponentLink';

import ToggleSelector from '../../../../components/toggleSelector';
import Translate from '../../../../components/translate';
import {
    SRAM_200,
    SRAM_700,
    toLocaleString,
    ZONE_COLORS_POWER,
} from '../../../../constants';
import { ComponentSummary as powerComponentProp } from '../../../../providers';

import { useNexus } from '../../../../providers/nexus/NexusContext';
import { useUnits } from '../../../../providers/units/UnitsContext';

import ExpandChart from '../../../../views/expandChart';

const DURATION_EXTRACTOR = ([duration]: number[]) => duration;
const MEAN_MAX_EXTRACTOR = ([,,,, meanMax]: number []) => meanMax;
const NORMALIZED_POWER_EXTRACTOR = ([, , np]: number[]) => np;

interface PowerCurveChartProps {
    disableEdit?: boolean;
    disableOwnerFeatures?: boolean;
    powerComponent: powerComponentProp;
    powerZones: {min: number, max: number}[];
}

const PowerCurveChart = ({
    disableEdit = false,
    disableOwnerFeatures = false,
    powerComponent,
    powerZones,
}: PowerCurveChartProps) => {
    const { nexusUserProfile } = useNexus();
    const { formatDuration } = useUnits();
    const [duration, setDuration] = useState(0);
    const [power, setPower] = useState(0);
    const [showNormalised, setShowNormalised] = useState(true);
    const [showWeighted, setShowWeighted] = useState(false);
    const [showWeightModal, setShowWeightModal] = useState(false);

    const [normalizedCategorisedData, meanMaxCategorisedData] = useMemo(
        () => ([
            // Normalized Categorised Data
            powerComponent
                ? categoriseData(
                    powerComponent.data.mean_maximal_power_curves,
                    powerZones,
                    NORMALIZED_POWER_EXTRACTOR,
                )
                : [],
            // Mean Max Categorised Data
            powerComponent
                ? categoriseData(
                    powerComponent.data.mean_maximal_power_curves,
                    powerZones,
                    MEAN_MAX_EXTRACTOR,
                )
                : [],
        ]),
        [powerComponent, powerZones],
    );

    const powerExtractor = useCallback(
        (...params: any) => {
            const values = lineBisector(...params);

            setPower(values ? values[1] : 0);
        },
        [setPower],
    );

    const formatValue = useCallback(
        (inPower: number) => (showWeighted
            ? toLocaleString(
                Math.round(
                    (inPower / nexusUserProfile.weight)
                    * 100,
                )
                / 100,
            )
            : formatPower(inPower)
        ),
        [showWeighted, nexusUserProfile],
    );

    const yExtractor = showNormalised ? NORMALIZED_POWER_EXTRACTOR : MEAN_MAX_EXTRACTOR;

    const [xMax, yMax, yMin, hasCurveData] = useMemo(
        () => {
            if (!powerComponent) {
                return [null, null, null, null];
            }

            const newHasCurveData = (
                powerComponent.data.mean_maximal_power_curves
                && powerComponent.data.mean_maximal_power_curves.length
            );

            if (!newHasCurveData) {
                return [
                    Math.max(powerComponent.data.time.length, 1),
                    powerComponent.data.max_power,
                    0,
                    newHasCurveData,
                ];
            }

            const maxX = DURATION_EXTRACTOR(
                powerComponent.data.mean_maximal_power_curves[
                    powerComponent.data.mean_maximal_power_curves.length - 1
                ],
            );
            const maxY = yExtractor(powerComponent.data.mean_maximal_power_curves[0]);
            const minY = powerComponent.data.mean_maximal_power_curves.reduce(
                (currentMin: number, dataPoint: number[]) => (Math.min(currentMin, yExtractor(dataPoint))),
                maxY,
            );

            return [maxX, maxY, minY, newHasCurveData];
        },
        [powerComponent, yExtractor],
    );

    if (!powerComponent) {
        return null;
    }

    if (!powerComponent.data.mean_maximal_power_curves?.length) {
        return (
            <div className={styles.noDataNote}>
                <Translate>NO_DATA_AVAILABLE_NOTES</Translate>
            </div>
        );
    }

    const categorisedData = showNormalised
        ? normalizedCategorisedData
        : meanMaxCategorisedData;

    const { width, height, ref } = useResizeDetector();

    return (
        <ExpandChart title="POWER_CURVE">
            <div className={styles.container}>
                <WeightModal
                    id="data-test-weight-modal"
                    onCancel={() => setShowWeightModal(false)}
                    onSave={(flag) => {
                        setShowWeightModal(false);
                        setShowWeighted(flag);
                    }}
                    open={showWeightModal}
                />

                <div className={styles.statisticsContainer}>
                    <div className={styles.statistic}>
                        {formatValue(power)}
                        &nbsp;
                        <Translate>
                            {showWeighted ? 'UNITS_WATTS_PER_KG_SHORT' : 'UNITS_WATTS_SHORT'}
                        </Translate>
                    </div>
                    <div className={styles.statistic}>
                        {formatDuration(duration.toString()) || 0}
                    </div>
                </div>
                <div className={styles.yAxisLabel}>
                    <Translate>
                        {showWeighted ? 'UNITS_WATTS_PER_KG' : 'UNITS_WATTS'}
                    </Translate>
                </div>
                <div ref={ref}>
                    <Scale
                        height={height}
                        width={width}
                        xMax={xMax}
                        xMin={1}
                        xScale={scaleLog}
                        yMax={yMax * 1.05}
                        yMin={Math.max((yMin * 0.95), 0)}
                        yMutator={mutateScaleNice}
                    >
                        {(scale: {
                                x: (arg0: number) => string | number | undefined;
                                y: (arg0: number) => string | number | undefined;
                            }) => {
                            const xAxisProps = axisPropsFromTickScale(scale.x, 3);
                            const yAxisProps = axisPropsFromTickScale(scale.y, 3);

                            return (
                                <div className={styles.chartContainer}>
                                    <svg
                                        className={styles.chart}
                                        height={height}
                                        onMouseMove={(event) => {
                                            const hoveredPower = onMouseMove(event, scale);

                                            if (hoveredPower === null) {
                                                setDuration(0);
                                                setPower(0);
                                                return;
                                            }

                                            setDuration(hoveredPower);
                                        }}
                                        onMouseLeave={() => {
                                            setDuration(0);
                                            setPower(0);
                                        }}
                                        onTouchMove={(event) => {
                                            const hoveredPower = onTouchMove(event, scale);

                                            if (hoveredPower === null) {
                                                setDuration(0);
                                                setPower(0);
                                                return;
                                            }

                                            setDuration(hoveredPower);
                                        }}
                                        width={width}
                                    >
                                        <Grid
                                            hideXTicks
                                            scale={scale}
                                            yLineStyle={{ stroke: SRAM_200 }}
                                            yTicks={yAxisProps.values}
                                        />
                                        {disableOwnerFeatures && (
                                            <Area
                                                data={powerComponent.data.mean_maximal_power_curves}
                                                scale={scale}
                                                style={{ fill: SRAM_200 }}
                                                xExtractor={DURATION_EXTRACTOR}
                                                yExtractor={yExtractor}
                                            />
                                        )}
                                        <Line
                                            data={powerComponent.data.mean_maximal_power_curves}
                                            className={disableOwnerFeatures ? styles.line : styles.hiddenLine}
                                            scale={scale}
                                            sync={duration}
                                            valueExtractor={powerExtractor}
                                            xExtractor={DURATION_EXTRACTOR}
                                            yExtractor={yExtractor}
                                        />
                                        {!disableOwnerFeatures
                                            && categorisedData.map(({ data, zoneIndex }, index) => (
                                                <Area
                                                    data={data}
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    key={`${showNormalised}-${index}`}
                                                    scale={scale}
                                                    style={{
                                                        fill: ZONE_COLORS_POWER[zoneIndex],
                                                        stroke: ZONE_COLORS_POWER[zoneIndex],
                                                    }}
                                                    xExtractor={DURATION_EXTRACTOR}
                                                    yExtractor={yExtractor}
                                                />
                                            ))}
                                        <VerticalLine scale={scale} sync={duration} />
                                        {duration && (
                                            <circle
                                                className={styles.indicator}
                                                cx={scale.x(duration)}
                                                cy={scale.y(power)}
                                                r={7}
                                            />
                                        )}
                                        <g className={styles.axis}>
                                            <Axis
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...yAxisProps}
                                                format={formatValue}
                                                style={{ orient: LEFT }}
                                            />
                                        </g>
                                        <g
                                            className={styles.axis}
                                            style={{ transform: `translateY(${height}px)` }}
                                        >
                                            <Axis
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...xAxisProps}
                                                format={formatDuration}
                                                values={(hasCurveData
                                                    ? CURVE_CHART_DURATION_TICK_VALUES
                                                    : xAxisProps.values
                                                )}
                                            />
                                        </g>
                                    </svg>
                                </div>
                            );
                        }}
                    </Scale>
                </div>
                <div className={styles.xAxisLabel}>
                    <Translate>DURATION</Translate>
                </div>
                {!disableEdit && (
                    <div className={styles.footer}>
                        <FtpDisplay />
                        <BikeComponentLink componentSummary={powerComponent} />
                    </div>
                )}
                <div className={styles.buttonGroup}>
                    <ToggleSelector
                        activeButton={showNormalised}
                        buttonClassName={styles.button}
                        buttons={[
                            { key: 'NP®', label: 'NP', value: true },
                            { key: 'Mean', label: 'MEAN', value: false },
                        ]}
                        color={SRAM_700}
                        onToggle={setShowNormalised}
                    />
                    {(!disableOwnerFeatures && !disableEdit) && (
                        <ToggleSelector
                            activeButton={showWeighted}
                            buttonClassName={styles.button}
                            buttons={[
                                { label: 'UNITS_WATTS', value: false },
                                { label: 'UNITS_WATTS_PER_KG', value: true },
                            ]}
                            color={SRAM_700}
                            id="data-test-weight-toggle"
                            onToggle={((nexusUserProfile && nexusUserProfile.weight)
                                ? setShowWeighted
                                : () => setShowWeightModal(true)
                            )}
                        />
                    )}
                </div>
            </div>
        </ExpandChart>
    );
};

export default PowerCurveChart;
