import React from 'react';
import styles from './RiderSettings.module.scss';
import Translate from '../../../components/translate';
import { useUnits } from '../../../providers/units/UnitsContext';
import { UNITS_IMPERIAL } from '../../../providers/units/constants';
import ToggleSelector from '../../../components/toggleSelector';
import {
    SRAM_RED,
} from '../../../constants/colours';

interface RiderSettingInputsProps {
    onChange: (value: {[key: string]: string | number}) => void,
    riderWeight: number,
    selectedMassUnits: string,
    bikeWeight: number,
    onToggleMass: (value: any) => void,
}

const RiderSettings = ({
    onChange,
    riderWeight,
    selectedMassUnits,
    bikeWeight,
    onToggleMass,

}: RiderSettingInputsProps) => {
    const { getLabelWeight } = useUnits();
    const units = useUnits();
    const [riderWeightError, setRiderWeightError] = React.useState(false);
    const [bikeWeightError, setBikeWeightError] = React.useState(false);
    const isImperial = selectedMassUnits === UNITS_IMPERIAL.mass;
    const maxWeight = isImperial ? 500 : 225;

    const handleBikeWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(e.target.value) > maxWeight || Number(e.target.value) < 1) {
            setBikeWeightError(true);
            return onChange({ bikeWeight: e.target.value });
        }
        setBikeWeightError(false);
        return onChange({ bikeWeight: e.target.value });
    };

    const handleRiderWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(e.target.value) > maxWeight || Number(e.target.value) < 1) {
            setRiderWeightError(true);
            return onChange({ riderWeight: e.target.value });
        }
        setRiderWeightError(false);
        return onChange({ riderWeight: e.target.value });
    };

    const renderBikeWeightInput = () => (
        <div className={styles.section}>
            <div className={styles.header}>
                <Translate>BIKE_WEIGHT</Translate>
            </div>
            <div className={styles.inputContainer}>
                <input
                    key="bikeWeight"
                    className={styles.inputWithUnits}
                    id="data-test-bike-weight"
                    max={maxWeight}
                    min={1}
                    onChange={(e) => handleBikeWeightChange(e)}
                    placeholder="---"
                    required
                    step={0.01}
                    type="number"
                    value={bikeWeight}
                    style={bikeWeightError ? { border: '1px solid red', color: 'red' } : {}}
                />
                <div className={styles.units}>
                    <Translate>{selectedMassUnits}</Translate>
                </div>
            </div>
        </div>
    );

    const renderRiderWeightInput = () => {
        const step = isImperial ? 1 : 0.5;

        return (
            <div className={styles.section}>
                <div className={styles.header}>
                    <Translate>RIDER_WEIGHT</Translate>
                </div>

                <div className={styles.inputContainer}>
                    <input
                        className={styles.inputWithUnits}
                        id="data-test-rider-weight-input"
                        max={maxWeight}
                        min={1}
                        onChange={(e) => handleRiderWeightChange(e)}
                        placeholder="---"
                        required
                        step={step}
                        type="number"
                        value={riderWeight}
                        style={riderWeightError ? { border: '1px solid red', color: 'red' } : {}}
                    />
                    <div className={styles.units}>
                        <Translate>{getLabelWeight(selectedMassUnits).shorthand}</Translate>
                    </div>
                </div>
            </div>
        );
    };

    const renderMassUnitsSelections = () => {
        const massUnits = units.units.find(({ name }) => name === 'mass');
        const massConversions = massUnits
            ? massUnits.conversions
            : [];

        return (
            <div className={styles.section}>
                <Translate>TIRE_PRESSURE_GUIDE_UNITS_MASS</Translate>
                <div className={styles.inputContainer}>
                    <ToggleSelector
                        activeButton={selectedMassUnits}
                        buttonClassName={styles.button}
                        buttons={massConversions.map(({ label }) => ({
                            label: units.getLabelPressure(label).shorthand,
                            value: label,
                        }))}
                        className={styles.toggleSelector}
                        color={SRAM_RED}
                        onToggle={onToggleMass}
                    />
                </div>
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <Translate>WEIGHT</Translate>
            {renderMassUnitsSelections()}
            {renderRiderWeightInput()}
            {renderBikeWeightInput()}
        </div>
    );
};

export default RiderSettings;
