import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

interface TranslateProps {
    id?: string;
  children: string | ReactNode;
  params?: any;
}

const Translate = ({ children, params, id }: TranslateProps) => {
    const intl = useIntl();

    if (!children) {
        return null;
    }

    if (typeof children !== 'string') {
        return <>{children}</>;
    }

    const translation = intl.formatMessage(
        { id: children || id },
        params,
    );

    return <>{translation}</>;
};

function useTranslation() {
    const intl = useIntl();

    return (key: string, params?: Record<string, ReactNode>) => {
        if (!key) return '';

        const translation = intl.formatMessage(
            { defaultMessage: key, id: key },
            params,
        );

        return translation as string;
    };
}

export default Translate;
export { useTranslation };
