import { createContext, useContext } from 'react';
import { BikesProviderProps } from './types';

const BikesContext = createContext<BikesProviderProps>({
    bikes: {
        // State
        bikes: [],
        hasMore: true,
        isFetching: false,
        page: 1,

        // Functions
        // eslint-disable-next-line sort-keys
        addBike: () => Promise.resolve({} as any),
        clear: () => {},
        deleteBike: () => Promise.resolve(false),
        deleteComponentFromBike: () => {},
        fetch: () => Promise.resolve([]),
        fetchBike: () => Promise.resolve({} as any),
        fetchBikeComponentSet: () => {},
        fetchBikeModelBrands: () => {},
        fetchBikeNotifications: () => {},
        fetchBikesByBrandId: () => {},
        fetchCassettes: () => {},
        fetchChainLengths: () => {},
        fetchChainLengthsByFrame: () => {},
        fetchChainrings: () => {},
        fetchOEMFrameCategories: () => {},
        fetchUdhByChainStayLength: () => {},
        fetchUdhByFrame: () => {},
        list: [],
        notificationSet: {},
        updateBike: () => Promise.resolve({} as any),
    },
});

export function useBikes() {
    const { bikes } = useContext(BikesContext);

    return bikes;
}

export default BikesContext;
