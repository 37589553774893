import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './BikeComponentItem.module.scss';

import Translate from '../../../components/translate';
import { makeCancellable, RequestType } from '../../../constants';
import Logger from '../../../Logger';
import { BikeComponent, ProductDetailsProps, useBikeComponents } from '../../../providers';

const EXAMPLE = 'example';

interface BikeComponentItemProps {
    bikeComponent: BikeComponent
}

const BikeComponentItem = ({ bikeComponent }: BikeComponentItemProps) => {
    const [productDetails, setProductDetails] = useState<ProductDetailsProps | null>(null);
    const bikeComponents = useBikeComponents();
    const fetchProductDetailsRequest = useRef<RequestType | null>(null);

    const fetchProductDetails = async () => {
        if (bikeComponent?.bike?.toString() === EXAMPLE) return null;

        fetchProductDetailsRequest.current = makeCancellable(
            bikeComponents.fetchProductDetails(bikeComponent.model_code),
        );

        try {
            const data = await fetchProductDetailsRequest.current.promise;
            fetchProductDetailsRequest.current = null;

            setProductDetails(data);

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn(error);
                fetchProductDetailsRequest.current = null;
            }

            return null;
        }
    };

    useEffect(() => {
        fetchProductDetails();

        return () => {
            if (fetchProductDetailsRequest.current) {
                fetchProductDetailsRequest.current.cancel();
                fetchProductDetailsRequest.current = null;
            }
        };
    }, []);

    return (
        <Link
            className={styles.container}
            to={`/components/${bikeComponent.id}`}
        >
            <img
                alt=""
                className={styles.icon}
                src={(productDetails && productDetails.image) || bikeComponent.mobile_display_icon_url}
            />
            <div className={styles.content}>
                {bikeComponent.is_bridge_device && (
                    <div className={styles.master}>
                        <Translate>BIKE_COMPONENT_MASTER</Translate>
                    </div>
                )}
                <div className={styles.text}>
                    {bikeComponent.mobile_display_name_key || (productDetails && productDetails.name)}
                </div>
                <div className={styles.subText}>
                    {bikeComponent.serial}
                </div>
            </div>
            <div>
                <div className={styles.arrow} />
            </div>
        </Link>
    );
};

export default BikeComponentItem;
