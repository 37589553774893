import {
    arrayOf,
    func,
    object,
    shape,
} from 'prop-types';

const FUNCTIONS = {
    handleServiceLinked: func,
    unlinkNexusService: func,
    updateNexusUserProfile: func,
};

const STATE = {
    nexusLinkedIds: arrayOf(object),
    nexusUserProfile: shape({
        // TODO update shape
    }),
};

const NEXUS_PROP_TYPES = {
    nexus: shape({
        ...STATE,
        ...FUNCTIONS,
    }),
};

export default NEXUS_PROP_TYPES;
