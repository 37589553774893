import { toLocaleString } from '../../constants';

export const CURVE_CHART_DURATION_TICK_VALUES = [10, 60, 300, 1200, 3600];

export function findZoneIndex(dataPoint, zones) {
    if (!dataPoint) {
        return -1;
    }

    const roundedDataPoint = Math.round(dataPoint);

    return zones.findIndex(({ min, max }) => (
        roundedDataPoint >= min
        && (roundedDataPoint <= max || max < 0)
    ));
}

export function formatHeartRate(heartRate) {
    return toLocaleString(Math.round(heartRate));
}

export function formatPower(inValue) {
    return toLocaleString(Math.round(inValue));
}

export function extractAccumulate(value) {
    return value ? 1 : 0;
}

export function extractTime(componentSummary, _value, index) {
    return componentSummary.data.adjustedTime[index] * 1000;
}

export function extractValue(value) {
    return value;
}

export function mutateScaleNice(scale) {
    return scale.nice();
}

// Use a three second moving average to zone the data
export function categoriseData(data, zones, extractor = extractValue) {
    const categorisedData = [];

    if (!data || data.length < 3) {
        return categorisedData;
    }

    let currentAverage = (extractor(data[0]) + extractor(data[1]) + extractor(data[2])) / 3;
    let currentZonedData = {
        data: [],
        startIndex: 0,
        zoneIndex: Math.max(findZoneIndex(currentAverage, zones), 0),
    };

    let startIndex = 0;
    for (let i = 3; (i + 3) < data.length; i += 3) {
        currentAverage = (
            extractor(data[i])
            + extractor(data[i + 1])
            + extractor(data[i + 2])
        ) / 3;

        const currentZoneIndex = Math.max(findZoneIndex(currentAverage, zones), 0);

        if (currentZoneIndex !== currentZonedData.zoneIndex) {
            currentZonedData.data = data.slice(startIndex, i + 1);

            if (currentZonedData.zoneIndex !== -1) {
                categorisedData.push(currentZonedData);
            }

            currentZonedData = {
                data: [],
                startIndex: i,
                zoneIndex: currentZoneIndex,
            };
            startIndex = i;
        }
    }

    currentZonedData.data = data.slice(startIndex);
    categorisedData.push(currentZonedData);

    return categorisedData;
}


export function sanitizeAltitude(altitudes) {
    if(!altitudes.includes(null)) return altitudes;
    
    let lastValidAltitude = altitudes.find((a) => a !== null);
     
      return altitudes.map((alt) => {
        if (alt === null) {
            return lastValidAltitude;
        }
        lastValidAltitude = alt;
        return alt;
    });
}
