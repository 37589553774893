import moment from 'moment';
import { useEffect, useState } from 'react';

import Zone from './zone';
import styles from './ZoneDistribution.module.scss';

import { extractValue } from '../../chartHelpers';

import { ZONE_COLORS_POWER } from '../../../../constants';
import Translate, { useTranslation } from '../../../../components/translate';

const ZONE_TITLES = [
    'ACTIVE_RECOVERY',
    'ENDURANCE',
    'TEMPO',
    'THRESHOLD',
    'VO2MAX',
    'ANAEROBIC',
    'NEUROMUSCULAR',
];

interface ZoneDistributionProps {
    data: number[],
    units: string,
    zoneColors: string[],
    zones: { min: number, max: number }[],
    zoneTitles: string[]
}

function ZoneDistribution(props: any) {
    const {
        data = [],
        units = 'UNITS_WATTS_SHORT',
        zoneColors = ZONE_COLORS_POWER,
        zones = [],
        zoneTitles = ZONE_TITLES,
    }: ZoneDistributionProps = props;
    const [durationInZones, setDurationInZones] = useState<any>([]);
    const [maxZone, setMaxZone] = useState(0);
    const [prevProps, setPrevProps] = useState<any>({});
    const [total, setTotal] = useState(0);
    const translate = useTranslation();
    function setDerivedStates() {
        if (props.data !== prevProps.data
            || props.zones !== prevProps.zones
        ) {
            if (!props.data || !props.data.length || !props.zones || !props.zones.length) {
            // Reset the durationInZones if the length is 0
                setDurationInZones([]);
                setPrevProps(props);
            }

            const newDurationInZones = props.data.reduce(
                (accum: any, dataPoint: any) => {
                // Zones are expected to be an array of 7 zones with max and mins
                    const matchingZoneIndex = props.zones.findIndex(({ min, max }: { min: number, max: number }) => {
                        const power = Math.round(extractValue(dataPoint));
                        // Zones are expected to be an array of 7 zones with max and mins
                        return (
                            power >= min
                            && (power <= max || max < 0)
                        );
                    });
                    if (matchingZoneIndex !== -1) {
                        accum[matchingZoneIndex] += 1;
                    }

                    return accum;
                },
                new Array(props.zones.length).fill(0),
            );

            setDurationInZones(newDurationInZones);
            setMaxZone(newDurationInZones.reduce((currentMax: any, zone: any) => (
                (currentMax > zone) ? currentMax : zone), 0));
            setPrevProps(props);
            setTotal(newDurationInZones.reduce((currentTotal: any, zone: any) => (currentTotal + zone), 0));
        }
    }

    useEffect(() => {
        setDerivedStates();
    }, [props]);

    if (!zones.length) {
        return null;
    }

    if (!data.length) {
        return (
            <div className={styles.noDataNote}>
                <Translate>NO_DATA_AVAILABLE_NOTES</Translate>
            </div>
        );
    }

    return (
        <div>
            <div className={styles.container}>
                <div className={styles.labelContainer}>
                    <div className={styles.zone}>
                        <Translate>ZONE</Translate>
                    </div>
                </div>
                <div className={styles.duration}>
                    <Translate>TIME</Translate>
                </div>
            </div>
            {durationInZones.map((duration: number, index: number) => {
                let range = zones[index].min.toString();

                if (zones[index].max < 0) {
                    range = `${range}+ ${translate(units)}`;
                } else {
                    range = `${range} - ${zones[index].max} ${translate(units)}`;
                }

                const percentage = Math.round((duration / total) * 100);

                // Set the width of the percentage bar to be
                // a ratio of this zone's duration and the max zone duration
                const percentageBarWidth = Math.round((duration / maxZone) * 100);

                let formattedDuration = moment
                    .duration(duration, 'seconds')
                    .format('h[:]mm[:]ss', { stopTrim: 'm' });

                if (duration === 0) {
                    formattedDuration = '--';
                }

                return (
                    <Zone
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        color={zoneColors[index]}
                        duration={formattedDuration}
                        percentage={percentage}
                        range={range}
                        percentageBarWidth={percentageBarWidth}
                        zone={zoneTitles[index]}
                    />
                );
            })}
        </div>
    );
}

export default ZoneDistribution;
