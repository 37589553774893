import 'cropperjs/dist/cropper.css';
import Cropper from 'react-cropper';
import React, { useRef } from 'react';

import styles from './CropImageModal.module.scss';

import Button from '../../components/button';
import Modal from '../../components/modal';
import Translate from '../../components/translate';
import { AXS_TEXT_GREY } from '../../constants/colours';

interface CropImageModalProps {
    aspectRatio?: number;
    guides?: boolean;
    imageCanvas?: HTMLCanvasElement | null;
    minCropBoxWidth?: number;
    modal?: boolean;
    onCancel: () => void;
    onCrop: (value: any) => any;
    open?: boolean;
    restore?: boolean;
    toggleDragModeOnDblclick?: boolean;
    zoomOnWheel?: boolean;
}

const CropImageModal = ({
    aspectRatio = 16 / 7,
    guides = false,
    imageCanvas = null,
    minCropBoxWidth = 100,
    modal = false,
    onCancel = () => {},
    onCrop = () => {},
    open = false,
    restore = false,
    toggleDragModeOnDblclick = false,
    zoomOnWheel = false,
}: CropImageModalProps) => {
    const cropper = useRef<any>(null);

    if (!imageCanvas) {
        return null;
    }

    const maxWidth = (imageCanvas.height > 400)
        ? Math.floor((imageCanvas.width / imageCanvas.height) * 400)
        : imageCanvas.width;

    return (
        <Modal
            containerStyle={{ content: { minHeight: 0 } }}
            contentLabel="Crop Image"
            disableOverlayClick
            header="CROP_IMAGE"
            hideCloseButton
            hideImage
            isOpen={open}
            onClose={onCancel}
        >
            <Cropper
                aspectRatio={aspectRatio}
                className={styles.cropperContainer}
                dragMode="crop"
                guides={guides}
                minCropBoxWidth={minCropBoxWidth}
                modal={modal}
                ref={cropper}
                restore={restore}
                src={imageCanvas.toDataURL('image/jpg')}
                style={{ maxHeight: 400, maxWidth }}
                toggleDragModeOnDblclick={toggleDragModeOnDblclick}
                viewMode={1}
                zoomOnWheel={zoomOnWheel}
            />
            <div className={styles.buttonContainer}>
                <Button color={AXS_TEXT_GREY} inverse onClick={onCancel}>
                    <Translate>CANCEL</Translate>
                </Button>
                <Button onClick={() => onCrop(cropper.current.cropper.getCroppedCanvas())}>
                    <Translate>SAVE</Translate>
                </Button>
            </div>
        </Modal>
    );
};

export default CropImageModal;
