import React from 'react';

import styles from './Dropdown.module.scss';

interface DropdownProps {
    woopraData?: string;
    buttonClassName?: string;
    buttonContent?: React.ReactNode;
    buttonStyle?: Record<string, unknown>;
    children: React.ReactNode;
    containerClassName?: string;
    containerStyle?: Record<string, unknown>;
    dropdownClassName?: string;
    dropdownStyle?: Record<string, unknown>;
    id?: string;
    onAuxClick?: (e: any) => void;
    onClick?: (e: any) => void;
    open?: boolean;
}

const Dropdown = ({
    woopraData,
    buttonClassName,
    buttonContent,
    buttonStyle,
    children,
    containerClassName,
    containerStyle,
    dropdownClassName,
    dropdownStyle,
    id,
    onAuxClick,
    onClick,
    open,
}: DropdownProps) => (
    <div className={`${styles.container} ${containerClassName}`} id={id} style={containerStyle}>
        <button
            className={`${styles.button} ${buttonClassName}`}
            id="data-test-dropdown-button"
            style={buttonStyle}
            type="button"
            onAuxClick={onAuxClick}
            onClick={onClick}
            data-woopra-track={woopraData}
        >
            {buttonContent}
        </button>
        {open && (
            <>
                <div aria-hidden className={styles.backDrop} onClick={onClick} />
                <div className={`${styles.dropdownContainer} ${dropdownClassName}`} style={dropdownStyle}>
                    {children}
                </div>
            </>
        )}
    </div>
);

export default Dropdown;
