import { useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { AutoSizer } from 'react-virtualized';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FadeLoader } from 'react-spinners';
import { EAGLE_TRANSMISSION_SUPPORT_VIDEOS, XPLR_SUPPORT_VIDEOS } from '../../constants';
import ContentContainer from '../../components/contentContainer';
import MobileContainer from '../../components/mobileContainer';
import Translate, { useTranslation } from '../../components/translate';
import styles from './bikeSetup.module.scss';
import ChainLengthInfo from './modals/ChainLengthInfo';
import SetupCogInfo from './modals/SetupCogInfo';
import ChainRingInfo from './modals/ChainRingInfo';
import SetupKeyInfo from './modals/SetupKeyInfo';
import Spinner from '../../components/spinner';

interface videoType {
    name: string,
    url: string,
}

const VideoItem = ({ index, style, data }: ListChildComponentProps<videoType[]>) => {
    const item = data[index];
    const id = item.url?.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)?.pop();

    return (
        <a
            style={{
                ...style, color: 'white', height: '128px', width: '224px',
            }}
            href={item.url}
            key={item.url}
            rel="noreferrer"
            target="_blank"
        >
            <div className={styles.videoImageContainer}>
                <img
                    alt=""
                    className={styles.videoImage}
                    src={`https://img.youtube.com/vi/${id}/0.jpg`}
                />
            </div>
            <div className={styles.videoTitle}>
                <Translate>{item.name}</Translate>
            </div>
        </a>
    );
};

interface BikeSetUpStateTypes {
    chainLength: number | null,
    chainStayLength: number | null,
    setUpCogPosition: string | null,
    supportedChainrings: Array<string>,
    driveTrainType: string | null,
    selectedFrameType: string | null,
    setupCogPositionText: string | null,
    setupKey: string | null,
    setupKeyImage: string | null,
}

const BikeSetUp = () => {
    const { search } = useLocation();
    const [state, setState] = useState<BikeSetUpStateTypes>({
        chainLength: null,
        chainStayLength: null,
        driveTrainType: '',
        selectedFrameType: null,
        setUpCogPosition: '',
        setupCogPositionText: '',
        setupKey: null,
        setupKeyImage: '',
        supportedChainrings: [],
    });
    const [modalState, setModalState] = useState('');

    const {
        chainLength,
        chainStayLength,
        driveTrainType,
        selectedFrameType,
        setUpCogPosition,
        setupCogPositionText,
        setupKey,
        setupKeyImage,
        supportedChainrings,
    } = state;

    const checkURLParams = () => {
        const urlParams = new URLSearchParams(search);
        const chainLengthParam = urlParams.get('chainLength');
        const chainStayLengthParam = urlParams.get('chainStayLength');
        const driveTrainTypeParam = urlParams.get('driveTrainType');
        const setUpCogPositionParam = urlParams.get('setupCogPosition');
        const setupCogPositionTextParam = urlParams.get('setupCogPositionText');
        const setupKeyParam = urlParams.get('setupKey');
        const setupKeyImageParam = urlParams.get('setupKeyImage');
        const supportedChainringsParam = urlParams.get('supportedChainRings')?.split(',');
        const selectedFrameTypeParam = urlParams.get('frameType');

        if (!chainLengthParam
            || !chainStayLengthParam
            || !driveTrainTypeParam
            || !setUpCogPositionParam
            || !setupCogPositionTextParam
            || !supportedChainringsParam) return;

        setState({
            chainLength: Number(chainLengthParam),
            chainStayLength: Number(chainStayLengthParam),
            driveTrainType: driveTrainTypeParam,
            selectedFrameType: selectedFrameTypeParam || null,
            setUpCogPosition: setUpCogPositionParam,
            setupCogPositionText: setupCogPositionTextParam,
            setupKey: setupKeyParam,
            setupKeyImage: setupKeyImageParam,
            supportedChainrings: supportedChainringsParam,

        });
    };

    useEffect(() => {
        if (search) checkURLParams();
    }, [search]);

    const isXPLR = driveTrainType === 'XPLR';
    const chainRingSizes = supportedChainrings.length > 1
        ? `${supportedChainrings.slice(0, -1).join(', ')} & ${supportedChainrings.slice(-1)}`
        : supportedChainrings[0];

    const setUpCogData = setupCogPositionText === 'E' ? '21T' : `${setupCogPositionText} (${setUpCogPosition})`;

    const renderHeader = () => (
        <>
            <MobileContainer className={styles.mobileContainer}>
                <div className={styles.description}>
                    <Translate>{isXPLR ? 'XPLR_SETUP_DESC' : 'MOBILE_EAGLE_SETUP_DESC'}</Translate>
                </div>
            </MobileContainer>
        </>
    );

    const renderInfoCard = (category: string, data: string | number | null) => (
        <div
            className={styles.infoCard}
            onClick={() => setModalState(category)}
            role="button"
            id={`${category}Card`}
            tabIndex={0}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    setModalState(category);
                }
            }}
        >
            {!data && <Spinner Component={FadeLoader} margin={-12} height={6} width={2} />}
            <div className={styles.infoCardTitle}>
                <Translate>{category}</Translate>
            </div>
            <button
                className={styles.infoButton}
                id="infoButton"
                type="button"
                onClick={() => setModalState(category)}
            >
                <InfoOutlinedIcon />
            </button>
            <div className={styles.infoCardText}>
                {data}
            </div>
        </div>
    );

    return (
        <>
            <Helmet>
                <title>Bike Setup</title>
                <meta
                    name="description"
                    content="Calculate the perfect chain length for your
                    Eagle Transmission with the Eagle AXS™ Transmission Chain and Setup Key Guide."
                />
            </Helmet>
            <div className={styles.modalContainer}>
                <ChainLengthInfo
                    open={modalState === 'CHAIN_LENGTH_CAPS'}
                    onClose={() => setModalState('')}
                    isXPLR={isXPLR}
                />
                <SetupCogInfo
                    open={modalState === 'SETUP_COG_CAPS'}
                    onClose={() => setModalState('')}
                    isXPLR={isXPLR}
                />
                <ChainRingInfo
                    open={modalState === 'CHAIN_RING'}
                    onClose={() => setModalState('')}
                />
                {setupKeyImage && (
                    <SetupKeyInfo
                        open={modalState === 'SETUP_KEY'}
                        onClose={() => setModalState('')}
                        image={setupKeyImage}
                    />
                )}
                <ContentContainer className={styles.container}>
                    {renderHeader()}
                    <div className={styles.infoContainer}>
                        {!isXPLR && setupKey && renderInfoCard('SETUP_KEY', setupKey)}
                        {renderInfoCard('CHAIN_LENGTH_CAPS', chainLength)}
                    </div>
                    <div className={styles.infoContainer}>
                        {renderInfoCard('SETUP_COG_CAPS', setUpCogData)}
                        {renderInfoCard('CHAIN_RING', chainRingSizes)}
                    </div>
                    <div className={styles.warningWrapper}>
                        {
                            selectedFrameType === 'FULL_SUSPENSION' && (
                                <div className={styles.warningBold}>
                                    <Translate>WARNING_INFO_FULL_SAG</Translate>
                                </div>
                            )
                        }
                        <div
                            className={styles.warning}
                            style={{
                                paddingLeft: selectedFrameType === 'FULL_SUSPENSION' ? '2px' : '0',
                            }}
                        >
                            <Translate>WARNING_INFO</Translate>
                        </div>
                    </div>
                    <div className={styles.videoContainerLabel}>
                        <Translate>SUPPORT_VIDEOS_CAPS</Translate>
                    </div>
                    <AutoSizer disableHeight style={{ paddingLeft: '1rem', width: '100%' }}>
                        {({ width }) => (
                            <FixedSizeList
                                height={170}
                                width={width}
                                itemCount={isXPLR ? XPLR_SUPPORT_VIDEOS.length : EAGLE_TRANSMISSION_SUPPORT_VIDEOS.length}
                                itemData={isXPLR ? XPLR_SUPPORT_VIDEOS : EAGLE_TRANSMISSION_SUPPORT_VIDEOS}
                                itemSize={250}
                                layout="horizontal"
                                style={{ borderRadius: '10px' }}
                            >
                                {VideoItem}
                            </FixedSizeList>
                        )}
                    </AutoSizer>

                </ContentContainer>
            </div>
        </>
    );
};

export default BikeSetUp;
