import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import style from './BikeComponentLink.module.scss';

import { makeCancellable, RequestType } from '../../../constants';
import { ComponentSummary, useBikeComponents } from '../../../providers';

interface BikeComponentLinkProps {
    componentSummary: ComponentSummary | undefined;
}

const BikeComponentLink = ({ componentSummary }: BikeComponentLinkProps) => {
    const [bikeComponent, setBikeComponent] = useState<any | null>(null);
    const [productDetails, setProductDetails] = useState<any | null>(null);
    const bikeComponents = useBikeComponents();

    const fetchBikeComponentRequest = useRef<RequestType | null>(null);
    const fetchProductDetailsRequest = useRef<RequestType | null>(null);

    const fetchProductDetails = async (component: string) => {
        fetchProductDetailsRequest.current = makeCancellable(
            bikeComponents.fetchProductDetails(component),
        );

        try {
            const data = await fetchProductDetailsRequest.current.promise;
            fetchProductDetailsRequest.current = null;

            if (!data) return;

            setProductDetails(data);
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchProductDetailsRequest.current = null;
            }
        }
    };

    const fetchBikeComponent = async () => {
        if (!componentSummary || !componentSummary.serial) return;

        fetchBikeComponentRequest.current = makeCancellable(
            bikeComponents.fetch(componentSummary.serial.toString(), true),
        );

        try {
            const data = await fetchBikeComponentRequest.current.promise;
            fetchBikeComponentRequest.current = null;

            if (!data) return;

            if (!data.name && !data.mobile_display_name_key) {
                fetchProductDetails(data);
            }

            setBikeComponent(data);
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchBikeComponentRequest.current = null;

                setBikeComponent(null);
            }
        }
    };

    useEffect(() => {
        fetchBikeComponent();

        return () => {
            if (fetchBikeComponentRequest.current) {
                fetchBikeComponentRequest.current.cancel();
                fetchBikeComponentRequest.current = null;
            }

            if (fetchProductDetailsRequest.current) {
                fetchProductDetailsRequest.current.cancel();
                fetchProductDetailsRequest.current = null;
            }
        };
    }, []);

    if (!bikeComponent) return null;

    const label = bikeComponent.name
    || bikeComponent.mobile_display_name_key
    || (productDetails && productDetails.name);

    return (
        <Link
            className={style.container}
            to={`/component/${bikeComponent.id}`}
            style={(!label ? { textTransform: 'capitalize' } : {})}
        >
            {label}
        </Link>
    );
};

export default BikeComponentLink;
