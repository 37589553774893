import React from 'react';

import styles from './GearRow.module.scss';

import Translate from '../../../../../components/translate';
import { SRAM_RED } from '../../../../../constants';

interface GearRowProps {
    backgroundColor?: string;
    backgroundPercentage?: number;
    color?: string;
    durationClassName?: string;
    label?: string | number;
    labelClassName?: string;
    percentage?: number;
    value?: string | number | null;
}

const GearRow = ({
    backgroundColor = '',
    backgroundPercentage = 0,
    color = SRAM_RED,
    value = null,
    label = '',
    labelClassName = '',
    durationClassName = '',
    percentage = 0,
}: GearRowProps) => (
    <div className={styles.container} key={label}>
        <div className={`${styles.labelContainer} ${labelClassName}`}>
            <Translate>{label}</Translate>
        </div>
        <div className={styles.barContainer}>
            <div
                className={`${styles.percentageBar} ${styles.bar}`}
                style={{ backgroundColor: color, width: `${percentage}%` }}
            />
            <div
                className={`${styles.backgroundPercentageBar}`}
                style={{ backgroundColor, width: `${backgroundPercentage}%` }}
            />
        </div>
        <div className={`${styles.duration} ${durationClassName}`}>
            {value || '--'}
        </div>
    </div>
);

export default GearRow;
