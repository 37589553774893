import { LoadScript } from '@react-google-maps/api';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import ErrorBoundary from './pages/errorBoundary';
import Spinner from './components/spinner';
import Translations from './components/translations';
import { GOOGLE_MAPS_KEY, GOOGLE_MAPS_LIBRARIES } from './constants';
import Logger from './Logger';
import Providers from './providers';
import Routes from './routes';

// Wrap Routes component with providers so child components can subscribe to needed data
const App = () => (
    <BrowserRouter>
        <Providers>
            <Translations>
                <ErrorBoundary>
                    <LoadScript
                        googleMapsApiKey={GOOGLE_MAPS_KEY}
                        loadingElement={<Spinner />}
                        libraries={GOOGLE_MAPS_LIBRARIES}
                        onError={Logger.error}
                    >
                        <Routes />
                    </LoadScript>
                </ErrorBoundary>
            </Translations>
        </Providers>
    </BrowserRouter>
);

export default App;
