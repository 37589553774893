import React from 'react';

import styles from './BikeTutorial.module.scss';

import { ridingBanner } from '../../../assets';
import PairingFinePrint from '../../../views/pairingFinePrint';
import Translate from '../../../components/translate';
import TutorialLinkedApps from '../../../views/tutorialLinkedApps';
import TutorialPairing from '../../../views/tutorialPairing';

const BikeTutorial = () => (
    <div className={styles.container}>
        <img alt="" style={{ height: 'auto', width: '100%' }} src={ridingBanner} />
        <div className={styles.content}>
            <div className={styles.header}>
                <Translate>TUTORIAL_AXS_DATA</Translate>
            </div>
            <div className={styles.text}>
                <Translate>TUTORIAL_PAIR_INFO_ONE</Translate>
            </div>

            <TutorialPairing className={styles.guideContent} showAXS />
            <TutorialLinkedApps className={styles.guideContent} />
            <PairingFinePrint className={styles.finePrint} />
        </div>
    </div>
);

export default BikeTutorial;
