import { ReactNode } from 'react';

import { AccessGroup } from './accessGroups';
import { Activities } from './activities';
import { Auth } from './auth';
import { Bikes } from './bikes';
import { BikeComponents } from './bikeComponents';
import { ComponentSummariesProvider } from './componentSummaries';
import { DeviceModelsProvider } from './deviceModels';
import { Nexus } from './nexus';
import { Notifications } from './notifications';
import { Units } from './units';

interface ProvidersProps { children: ReactNode }

const Providers = ({ children }: ProvidersProps) => (
    <Auth>
        <AccessGroup>
            <Nexus>
                <Units>
                    <Bikes>
                        <BikeComponents>
                            <Notifications>
                                <Activities>
                                    <ComponentSummariesProvider>
                                        <DeviceModelsProvider>
                                            {children}
                                        </DeviceModelsProvider>
                                    </ComponentSummariesProvider>
                                </Activities>
                            </Notifications>
                        </BikeComponents>
                    </Bikes>
                </Units>
            </Nexus>
        </AccessGroup>
    </Auth>
);

export default Providers;
