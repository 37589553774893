import React, {
    ReactElement,
    useEffect,
    useState,
} from 'react';

import styles from './Carousel.module.scss';
import CarouselItem, { CarouselItemProps } from './CarouselItem';

import Fade from '../fade';
import Translate from '../translate';

import { SRAM_RED } from '../../constants';

interface CarouselProps {
    children: ReactElement<CarouselItemProps> | ReactElement<CarouselItemProps>[];
    hideTitle?: boolean;
    label?: string;
    onChange?: (title: string) => void;
}

const Carousel = ({
    children,
    hideTitle = false,
    label = '',
    onChange = () => {},
}: CarouselProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const getVisibleChildren = () => {
        // this ensures the children array does not contain undefined values
        const childrenArray = React.Children.toArray(children) as ReactElement<CarouselItemProps>[];

        return childrenArray.filter((child) => child.props.show);
    };

    const visibleChildrenArray = getVisibleChildren();

    useEffect(() => {
        if (visibleChildrenArray.length) {
            onChange(visibleChildrenArray[currentIndex].props.title);
        }
    }, [currentIndex]);

    if (!visibleChildrenArray.length) return null;

    const { title } = visibleChildrenArray[currentIndex].props;

    const navigateBackward = () => {
        if (visibleChildrenArray.length <= 1) return;

        const newIndex = (currentIndex > 0) ? currentIndex - 1 : visibleChildrenArray.length - 1;

        setCurrentIndex(newIndex);
    };

    const navigateForward = () => {
        if (visibleChildrenArray.length <= 1) return;

        const newIndex = (currentIndex < visibleChildrenArray.length - 1) ? currentIndex + 1 : 0;

        setCurrentIndex(newIndex);
    };

    const renderDots = () => (
        <div className={styles.dotsContainer}>
            <ul className={styles.dots}>
                {visibleChildrenArray.map((child, index) => (
                    <li
                        className={styles.dot}
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        style={{ color: currentIndex === index ? SRAM_RED : '#bbbcbc' }}
                    >
                            &bull;
                    </li>
                ))}
            </ul>
        </div>
    );

    const renderContent = () => (
        <div className={styles.contentContainer}>
            <Fade CSSTransitionKey={currentIndex}>
                <div className={styles.content}>
                    {visibleChildrenArray[currentIndex]}
                </div>
            </Fade>
        </div>
    );

    return (
        <div className={styles.container} style={hideTitle ? { paddingTop: 0 } : {}}>
            <button
                className={`${styles.button} ${styles.buttonLeft}`}
                onClick={() => navigateBackward()}
                style={hideTitle ? { top: 0 } : {}}
                type="button"
            >
                <div className={styles.triangleLeft} />
            </button>
            <div className={styles.title}>
                {!hideTitle && (
                    <div className={styles.headerText}>
                        <Translate>{title || label}</Translate>
                    </div>
                )}
                {renderDots()}
            </div>
            <button
                className={`${styles.button} ${styles.buttonRight}`}
                onClick={() => navigateForward()}
                style={hideTitle ? { top: 0 } : {}}
                type="button"
            >
                <div className={styles.triangleRight} />
            </button>
            {renderContent()}
        </div>
    );
};

Carousel.Item = CarouselItem;

export default Carousel;
