import { useEffect, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { FadeLoader } from 'react-spinners';
import ContentContainer from '../../components/contentContainer';
import styles from './mobileCommunications.module.scss';
import MobileContainer from '../../components/mobileContainer';
import Translate from '../../components/translate';
import { useSetState } from '../../hooks';
import {
    useAccessGroups, useNotifications,
} from '../../providers';
import Button from '../../components/button';
import {
    makeCancellable, RequestType, SRAM_RED,
} from '../../constants';
import Logger from '../../Logger';
import CheckBox from '../../components/checkbox';
import WaffleBlock from '../../components/waffleBlock';
import ConfirmationModal from '../mobileUpdateAccount/modals/ConfirmationModal';
import Spinner from '../../components/spinner';

const CHANNEL_APP = { label: 'COMMUNICATIONS_APP', value: 'app' };
const CHANNEL_EMAIL = { label: 'EMAIL', value: 'email' };
const CHANNEL_ACCESS_GROUPS = { label: 'ACCESS_GROUP', value: 'accessGroup' };

function getChannel(title: string) {
    switch (title) {
        case 'MOBILE_APP_NOTIFICATIONS':
            return CHANNEL_APP;
        case 'MOBILE_NOTIFICATIONS_EMAIL':
            return CHANNEL_EMAIL;
        case 'MOBILE_NOTIFICATIONS_ACCESS_GROUPS':
            return CHANNEL_ACCESS_GROUPS;
        default:
            return CHANNEL_ACCESS_GROUPS;
    }
}

interface RowProps {
    title: string,
    desc?: string,
    enabled: boolean,
    communicationName: string,
    channel: { label: string, value: string },
    onSelect: (arg: string) => void,
}

const DEFAULT_STATE = {
    isFetching: false,
    isUpdating: false,
    notificationPreferences: null,
    updatingError: null,
    userAccessGroups: null,
};

const MobileCommunications = () => {
    const [state, setState] = useSetState(DEFAULT_STATE);
    const notifications = useNotifications();
    const accessGroups = useAccessGroups();
    const {
        isFetching,
        isUpdating,
        notificationPreferences,
        initialNotificationPreferences,
        initialUserAccessGroups,
        updatingError,
        userAccessGroups,
        unsavedChanges,
    } = state;
    const fetchNotificationPreferencesRequest = useRef<RequestType | null>(null);
    const fetchUserAccessGroupsRequest = useRef<RequestType | null>(null);
    const generateNotificationRequest = useRef<RequestType | null>(null);
    const updateNotificationPreferenceRequests = useRef(new Set<RequestType>());
    const updateAccessGroupsRequests = useRef(new Set<RequestType>());

    // Generate a copy of original prefernces
    useEffect(() => {
        if (notificationPreferences && !initialNotificationPreferences) {
            setState({ initialNotificationPreferences: notificationPreferences });
        }
        if (userAccessGroups && !initialUserAccessGroups) {
            setState({ initialUserAccessGroups: userAccessGroups });
        }
    }, [notificationPreferences, userAccessGroups]);

    async function updateNotificationPreferences() {
        setState({ isUpdating: true });

        const updateRequest = makeCancellable(
            notifications.updateNotificationPreferences(notificationPreferences),
        );

        updateNotificationPreferenceRequests.current.add(updateRequest);

        try {
            const updatedNotifications = await updateRequest.promise;
            updateNotificationPreferenceRequests.current.delete(updateRequest);

            // Set updated notifications object
            if (updatedNotifications) {
                setState({ notificationPreferences: updatedNotifications });
            } else {
                setState({
                    isUpdating: false,
                    updatingError: new Error('COMMUNICATIONS_UPDATE_ERROR'),
                });
            }

            if (!updateNotificationPreferenceRequests.current.size) {
                setState({ isUpdating: false });
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.error(error);
                updateNotificationPreferenceRequests.current.delete(updateRequest);

                if (!updateNotificationPreferenceRequests.current.size) {
                    setState({ isUpdating: false, updatingError: error });
                }
            }
        }
    }

    async function updateAccessGroups() {
        if (!userAccessGroups || !userAccessGroups.length) return;

        setState({ isUpdating: true });

        const updateRequest = makeCancellable(
            accessGroups.updateAccessGroups(userAccessGroups),
        );

        updateAccessGroupsRequests.current.add(updateRequest);

        try {
            const updatedAccessGroups = await updateRequest.promise;
            updateAccessGroupsRequests.current.delete(updateRequest);

            if (updatedAccessGroups) {
                setState({ userAccessGroups: updatedAccessGroups });
            } else {
                setState({
                    isUpdating: false,
                    updatingError: new Error('ACCESS_GROUPS_UPDATE_ERROR'),
                });
            }

            if (!updateAccessGroupsRequests.current.size) {
                setState({ isUpdating: false });
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.error(error);
                updateAccessGroupsRequests.current.delete(updateRequest);

                if (!updateAccessGroupsRequests.current.size) {
                    setState({ isUpdating: false, updatingError: error });
                }
            }
        }
    }

    function submitNotificationsUpdate() {
        updateNotificationPreferences();
        updateAccessGroups();
    }

    async function fetchNotificationPreferences() {
        setState({ isFetching: true });

        const fetchRequest = makeCancellable(notifications.fetchNotificationPreferences());
        fetchNotificationPreferencesRequest.current = fetchRequest;
        try {
            const fetchedNotificationPreferences = await fetchRequest.promise;

            fetchNotificationPreferencesRequest.current = null;

            setState({ isFetching: false, notificationPreferences: fetchedNotificationPreferences });
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchNotificationPreferencesRequest.current = null;
            }

            setState({ isFetching: false });
        }
    }

    async function fetchUserAccessGroups() {
        setState({ isFetching: true });

        const fetchRequest = makeCancellable(accessGroups.fetch());

        fetchUserAccessGroupsRequest.current = fetchRequest;
        try {
            const fetchedUserGroups = await fetchRequest.promise;

            fetchUserAccessGroupsRequest.current = null;

            setState({ isFetching: false, userAccessGroups: fetchedUserGroups });
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchUserAccessGroupsRequest.current = null;
            }

            setState({ isFetching: false });
        }
    }

    function toggleNotification(notificationName: string) {
        const newNotificationPreferences = {
            ...notificationPreferences,
            communications: notificationPreferences.communications.map((communication: any) => {
                const { name, enabled } = communication;
                if (name === notificationName) {
                    return {
                        ...communication,
                        enabled: !enabled,
                    };
                }
                return communication;
            }),
        };

        setState({ notificationPreferences: newNotificationPreferences });

        if (JSON.stringify(newNotificationPreferences) !== JSON.stringify(initialNotificationPreferences)) {
            setState({ unsavedChanges: true });
        } else {
            setState({ unsavedChanges: false });
        }
    }

    function toggleAccessGroupNotification(notificationName: string) {
        const newUserAccessGroups = userAccessGroups.map((accessGroup: any) => {
            const { name, member } = accessGroup;
            if (name === notificationName) {
                return { ...accessGroup, member: !member };
            }
            return accessGroup;
        });

        setState({ userAccessGroups: newUserAccessGroups });

        if (JSON.stringify(newUserAccessGroups) !== JSON.stringify(initialUserAccessGroups)) {
            setState({ unsavedChanges: true });
        } else {
            setState({ unsavedChanges: false });
        }
    }

    const RenderRow = ({
        title,
        desc,
        enabled,
        communicationName,
        channel,
        onSelect,
    }:RowProps) => (
        <>
            <div className={styles.row}>
                <div className={styles.rowTitleContainer}>
                    <div className={styles.rowTitle}>
                        <Translate>{title}</Translate>
                    </div>
                    {desc && (
                        <div className={styles.rowDesc}>
                            <Translate>{desc}</Translate>
                        </div>
                    )}
                </div>
                <div className={styles.rowValue}>
                    <CheckBox
                        id={`${communicationName}-${channel.label}`}
                        checked={enabled}
                        onChange={() => onSelect(communicationName)}
                        style={{ display: 'inline-block' }}
                    />
                </div>
            </div>
        </>

    );

    const NotificationSection = ({
        title,
        notificationPrefs,
        onSelect,
        userAccessGroup,
    }: {
        title:string,
        notificationPrefs?: any,
        onSelect: (communicationName: string) => void,
        userAccessGroup?: any
    }) => {
        const channel = getChannel(title);
        const filteredChannels = useMemo(
            () => {
                if (!notificationPrefs) return [];
                const { communications } = notificationPrefs;

                if (!communications) return [];
                return communications.filter(({ medium }: { medium: string }) => medium === channel.value);
            },
            [channel, notificationPrefs],
        );
        return (
            <MobileContainer className={styles.section}>
                <div className={styles.sectionTitle}>
                    <Translate>{title}</Translate>
                </div>
                {filteredChannels && filteredChannels.map(({ name: communicationName, enabled }: any) => (
                    <RenderRow
                        title={communicationName}
                        enabled={enabled}
                        communicationName={communicationName}
                        channel={channel}
                        onSelect={() => onSelect(communicationName)}
                    />
                ))}
                {userAccessGroup && userAccessGroup.map(({ name, member }: any) => (
                    <RenderRow
                        title={name}
                        enabled={member}
                        communicationName={name}
                        channel={channel}
                        onSelect={() => onSelect(name)}
                    />
                ))}

            </MobileContainer>
        );
    };

    const renderErrorModal = () => (
        <ConfirmationModal
            open={!!updatingError}
            header="ERROR"
            msg={updatingError}
            option1={{
                onClick: () => {
                    setState({ updatingError: null });
                },
                text: 'OK',
            }}
            subheader=""
        />
    );

    useEffect(() => {
        fetchNotificationPreferences();
        fetchUserAccessGroups();

        return () => {
            if (generateNotificationRequest.current) {
                generateNotificationRequest.current.cancel();
            }

            if (fetchNotificationPreferencesRequest.current) {
                fetchNotificationPreferencesRequest.current.cancel();
            }

            updateNotificationPreferenceRequests.current.forEach((request) => request.cancel());
            updateNotificationPreferenceRequests.current.clear();
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>Rider Portal - Communications</title>
                <meta name="description" content="Rider Portal" />
            </Helmet>
            <Spinner loading={isFetching || isUpdating} Component={FadeLoader} margin={-12} height={6} width={2} />
            <ContentContainer className={styles.container}>
                <MobileContainer className={styles.header}>
                    <div className={styles.title}>
                        <Translate>MOBILE_COMMUNICATIONS</Translate>
                    </div>
                </MobileContainer>
                <NotificationSection
                    title="MOBILE_APP_NOTIFICATIONS"
                    notificationPrefs={notificationPreferences}
                    onSelect={(args: string) => toggleNotification(args)}
                />
                <NotificationSection
                    title="MOBILE_NOTIFICATIONS_EMAIL"
                    notificationPrefs={notificationPreferences}
                    onSelect={(args: string) => toggleNotification(args)}
                />
                <WaffleBlock flag="public_beta" showIndicator>
                    <NotificationSection
                        title="MOBILE_NOTIFICATIONS_ACCESS_GROUPS"
                        userAccessGroup={userAccessGroups}
                        onSelect={(args: string) => toggleAccessGroupNotification(args)}
                    />
                </WaffleBlock>
                <div className={styles.saveBtnContainer}>
                    <Button
                        className={styles.button}
                        color={SRAM_RED}
                        onClick={() => submitNotificationsUpdate()}
                        disabled={!unsavedChanges}
                    >
                        <Translate>SAVE</Translate>
                    </Button>
                </div>
            </ContentContainer>
            {renderErrorModal()}
        </>
    );
};

export default MobileCommunications;
