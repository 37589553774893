import axios from 'axios';
import { ReactNode, useEffect, useRef } from 'react';

import BikeComponentsContext from './BikeComponentsContext';
import parseBikeComponent from './parseBikeComponent';
import { BikeComponent } from './types';

import { useAuth } from '../auth';
import { useNexus } from '../nexus';

import {
    makeCancellable,
    RequestType,
    URL_API,
    URL_FIRMWARE_API,
} from '../../constants';
import { useSetState } from '../../hooks';
import Logger from '../../Logger';
import { Bike } from '../bikes/types';

const DEFAULT_STATE = {
    availableFirmwareVersion: {},
    bikeComponents: [],
    bikeComponentsBleServices: {},
    bikeComponentsCount: 0,
    bikeComponentsNotifications: {},
    bikeComponentsServices: {},
    bikeComponentsTags: {},
    hasMore: true,
    isFetching: false,
    page: 1,
    productDetails: {},
};
const EXAMPLE = 'example';
const URL_BIKE_COMPONENTS = `${URL_API}components/`;

function BikeComponentsProvider({ children = null }: { children: ReactNode }) {
    const [state, setState] = useSetState(DEFAULT_STATE);
    const {
        availableFirmwareVersion,
        bikeComponents,
        bikeComponentsBleServices,
        bikeComponentsCount,
        bikeComponentsNotifications,
        bikeComponentsServices,
        bikeComponentsTags,
        hasMore,
        page,
        productDetails,
    } = state;
    const auth = useAuth();
    const nexus = useNexus();

    const availableFirmwareVersionRequests = useRef(new Map<string, RequestType>());
    const bikeComponentDeleteRequests = useRef(new Map<string, RequestType>());
    const bikeComponentFetchRequests = useRef(new Map<string, RequestType>());
    const bikeComponentFetchBySerialRequests = useRef(new Map<string, RequestType>());
    const bikeComponentRegisterRequests = useRef(new Map<string, RequestType>());
    const bikeComponentsRequest = useRef< RequestType | null>(null);
    const componentBleServicesFetchRequests = useRef(new Map<string, RequestType>());
    const createNexusComponentRequests = useRef(new Map<string, RequestType>());
    const fetchExampleBikeComponentArrayRequest = useRef< RequestType | null>(null);
    const initialComponentsTotal = useRef(0);
    const productDetailRequests = useRef(new Map<string, RequestType>());
    const bikeComponentNotificationsFetchRequests = useRef(new Map<string, RequestType>());
    const bikeComponentsServicesFetchRequests = useRef(new Map<string, RequestType>());
    const bikeComponentsTagsFetchRequests = useRef(new Map<string, RequestType>());

    let tempAvailableFirmwareVersion = availableFirmwareVersion;
    let tempBikeComponentsNotifications = bikeComponentsNotifications;
    let tempProductDetails = productDetails;

    function clear() {
        availableFirmwareVersionRequests.current.forEach((request) => request.cancel());
        availableFirmwareVersionRequests.current.clear();

        bikeComponentFetchRequests.current.forEach((request) => request.cancel());
        bikeComponentFetchRequests.current.clear();

        bikeComponentDeleteRequests.current.forEach((request) => request.cancel());
        bikeComponentDeleteRequests.current.clear();

        bikeComponentRegisterRequests.current.forEach((request) => request.cancel());
        bikeComponentRegisterRequests.current.clear();

        createNexusComponentRequests.current.forEach((request) => request.cancel());
        createNexusComponentRequests.current.clear();

        componentBleServicesFetchRequests.current.forEach((request) => request.cancel());
        componentBleServicesFetchRequests.current.clear();

        productDetailRequests.current.forEach((request) => request.cancel());
        productDetailRequests.current.clear();

        // Cancel requests when the component unmounts
        if (bikeComponentsRequest.current) {
            bikeComponentsRequest.current.cancel();
            bikeComponentsRequest.current = null;
        }

        initialComponentsTotal.current = 0;

        setState({ ...DEFAULT_STATE });
    }

    function getBikeComponent(id: string) {
        return bikeComponents.find((bikeComponent: any) => bikeComponent.id === id);
    }

    async function fetchBikeComponentBySerial(serial: string) {
        if (!serial) {
            return null;
        }

        Logger.log('Fetching bike component by serial number', serial);

        const currentRequest = bikeComponentFetchBySerialRequests.current.get(serial);

        if (currentRequest) {
            try {
                const { data } = await currentRequest.promise;

                return data.map((item: any) => parseBikeComponent(item));
            } catch (error) {
                return null;
            }
        }

        const fetchRequest = makeCancellable(axios.get(URL_BIKE_COMPONENTS, { params: { serial } }));

        bikeComponentFetchBySerialRequests.current.set(serial, fetchRequest);

        try {
            const { data } = await fetchRequest.promise;

            bikeComponentFetchBySerialRequests.current.delete(serial);

            const parsedBikeComponents = data.map((item: any) => parseBikeComponent(item));

            Logger.log('Successfully fetched bike component by serial number', serial);

            return parsedBikeComponents;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching bike component by serial number', serial, error);

                bikeComponentFetchBySerialRequests.current.delete(serial);
            }

            return null;
        }
    }

    async function createNexusComponent(serial: string, bike: Bike) {
        const currentRequest = createNexusComponentRequests.current.get(serial);

        if (currentRequest) {
            try {
                const { data } = await currentRequest.promise;

                return data;
            } catch (error) {
                return null;
            }
        }

        const payload = { bike, manufacturer: 268, serial };

        if (serial.startsWith('A')) {
            payload.manufacturer = 8;
        }

        const createRequest = makeCancellable(axios.post(`${URL_API}components/`, payload));
        createNexusComponentRequests.current.set(serial, createRequest);

        try {
            const { data } = await createRequest.promise;
            const newComponent = await fetchBikeComponentBySerial(serial);

            if (newComponent) {
                setState({
                    bikeComponents: [
                        ...bikeComponents.filter((component: any) => newComponent[0].id !== component.id),
                        ...newComponent,
                    ],
                });
            }

            createNexusComponentRequests.current.delete(serial);

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                createNexusComponentRequests.current.delete(serial);
                Logger.error('Error creating nexus component', serial, bike, error);
            }
        }

        return null;
    }

    async function deleteBikeComponent(id: string) {
        const currentRequest = bikeComponentDeleteRequests.current.get(id);

        if (currentRequest) {
            try {
                await currentRequest.promise;
                return true;
            } catch (error) {
                return false;
            }
        }

        const fetchRequest = makeCancellable(axios.delete(`${URL_BIKE_COMPONENTS}${id}/`));
        bikeComponentDeleteRequests.current.set(id, fetchRequest);

        try {
            await fetchRequest.promise;
            bikeComponentDeleteRequests.current.delete(id);

            setState({
                // eslint-disable-next-line eqeqeq
                bikeComponents: bikeComponents.filter((bikeComponent: any) => bikeComponent.id != id),
            });

            return true;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error deleting bike', id, error);
                bikeComponentDeleteRequests.current.delete(id);
            }

            return false;
        }
    }

    async function fetchExampleBikeComponentArray() {
        if (fetchExampleBikeComponentArrayRequest.current) {
            try {
                const data = await fetchExampleBikeComponentArrayRequest.current.promise;

                return data.default;
            } catch (error) {
                return null;
            }
        }

        fetchExampleBikeComponentArrayRequest.current = makeCancellable(
            import('../../assets/data/examples/bikeComponents'),
        );

        try {
            const data = await fetchExampleBikeComponentArrayRequest.current.promise;

            fetchExampleBikeComponentArrayRequest.current = null;

            return data.default;
        } catch (error) {
            fetchExampleBikeComponentArrayRequest.current = null;

            return null;
        }
    }

    async function fetchBikeComponent(id: string, useExisting = false) {
        if (!id) {
            return null;
        }

        const splitId = id.toString().split('_');

        if (splitId[0] === EXAMPLE) {
            const exampleBikeComponentArray = await fetchExampleBikeComponentArray();

            if (!exampleBikeComponentArray) {
                return null;
            }

            return exampleBikeComponentArray.find((component: any) => component.id === id);
        }

        if (useExisting) {
            const bikeComponent = getBikeComponent(id);
            if (bikeComponent) {
                return bikeComponent;
            }
        }

        // Check if bike component is already being fetched
        const currentRequest = bikeComponentFetchRequests.current.get(id);
        if (currentRequest) {
            try {
                const { data } = await currentRequest.promise;

                return parseBikeComponent(data);
            } catch (error) {
                return null;
            }
        }

        const fetchRequest = makeCancellable(axios.get(`${URL_BIKE_COMPONENTS}${id}/`));
        bikeComponentFetchRequests.current.set(id, fetchRequest);

        try {
            const { data } = await fetchRequest.promise;

            // Remove the bike component request from the list
            bikeComponentFetchRequests.current.delete(id);

            const parsedBikeComponent = parseBikeComponent(data);

            const newBikeComponents = [
                // eslint-disable-next-line eqeqeq
                ...bikeComponents.filter((component: any) => component.id != id),
                parsedBikeComponent,
            ];

            setState({ bikeComponents: newBikeComponents.sort((a, b) => (b.last_update_ts - a.last_update_ts)) });

            return parsedBikeComponent;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching bike component', id, error);

                // Remove the bike component request from the list
                bikeComponentFetchRequests.current.delete(id);
            }

            return null;
        }
    }

    async function fetchComponentBleServices(id: string) {
        if (!id) {
            return null;
        }

        if (bikeComponentsBleServices[id]) {
            return bikeComponentsBleServices[id];
        }

        // Check if bike component is already being fetched
        const currentRequest = componentBleServicesFetchRequests.current.get(id);
        if (currentRequest) {
            try {
                const { data } = await currentRequest.promise;

                return data;
            } catch (error) {
                return null;
            }
        }

        const headers = { 'QBABEL-VERSION': '3.1' };

        const fetchRequest = makeCancellable(axios.get(`${URL_BIKE_COMPONENTS}${id}/bleservices/`, { headers }));
        componentBleServicesFetchRequests.current.set(id, fetchRequest);

        try {
            const { data } = await fetchRequest.promise;

            componentBleServicesFetchRequests.current.delete(id);

            setState({
                bikeComponentsBleServices: {
                    ...bikeComponentsBleServices,
                    [id]: data,
                },
            });

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching bike component ble-services', id, error);

                componentBleServicesFetchRequests.current.delete(id);
            }

            return null;
        }
    }

    async function fetchComponentNotifications(serial: string) {
        if (bikeComponentsNotifications[serial]) {
            return bikeComponentsNotifications[serial];
        }

        let fetchRequest = bikeComponentNotificationsFetchRequests.current.get(serial);

        if (fetchRequest) {
            try {
                const { data } = await fetchRequest.promise;
                return data;
            } catch (err) {
                return null;
            }
        }

        fetchRequest = makeCancellable(axios.get(`${URL_BIKE_COMPONENTS}${serial}/notifications/`));
        bikeComponentNotificationsFetchRequests.current.set(serial, fetchRequest);

        try {
            const { data } = await fetchRequest.promise;

            // Temporarily store BikeComponentsNotifications untill state is set.
            tempBikeComponentsNotifications = { ...tempBikeComponentsNotifications, [serial]: data };

            // Use stable state to prevent multiple fetches during lifecycle from corrupting state
            setState({
                bikeComponentsNotifications: tempBikeComponentsNotifications,
            });

            bikeComponentNotificationsFetchRequests.current.delete(serial);

            return data;
        } catch (err: any) {
            if (!err.isCancelled) {
                bikeComponentNotificationsFetchRequests.current.delete(serial);
            }

            return null;
        }
    }

    async function fetchComponentServices(serial: string) {
        if (bikeComponentsServices[serial]) {
            return bikeComponentsServices[serial];
        }

        let fetchRequest = bikeComponentsServicesFetchRequests.current.get(serial);

        if (fetchRequest) {
            try {
                const { data } = await fetchRequest.promise;

                return data;
            } catch (err) {
                return [];
            }
        }

        fetchRequest = makeCancellable(axios.get(`${URL_BIKE_COMPONENTS}${serial}/services/`));
        bikeComponentsServicesFetchRequests.current.set(serial, fetchRequest);

        try {
            const { data } = await fetchRequest.promise;
            bikeComponentsServicesFetchRequests.current.delete(serial);
            setState({
                bikeComponentsServices: {
                    ...bikeComponentsServices,
                    [serial]: data,
                },
            });

            return data;
        } catch (err: any) {
            if (!err.isCancelled) {
                bikeComponentsServicesFetchRequests.current.delete(serial);
            }

            return [];
        }
    }

    async function fetchComponentTags(serial: string) {
        if (bikeComponentsTags[serial]) {
            return bikeComponentsTags[serial];
        }

        let fetchRequest = bikeComponentsTagsFetchRequests.current.get(serial);
        if (fetchRequest) {
            try {
                const { data } = await fetchRequest.promise;

                return data;
            } catch (err) {
                return [];
            }
        }

        fetchRequest = makeCancellable(axios.get(`${URL_BIKE_COMPONENTS}${serial}/tags/`));
        bikeComponentsTagsFetchRequests.current.set(serial, fetchRequest);

        try {
            const { data } = await fetchRequest.promise;
            bikeComponentsTagsFetchRequests.current.delete(serial);
            setState({
                bikeComponentsTags: {
                    ...bikeComponentsTags,
                    [serial]: data,
                },
            });

            return data;
        } catch (err: any) {
            if (!err.isCancelled) {
                bikeComponentsTagsFetchRequests.current.delete(serial);
            }
            return [];
        }
    }

    async function fetchBikeComponents() {
        // Don't refetch is fetching
        if (bikeComponentsRequest.current) {
            try {
                const { data } = await bikeComponentsRequest.current.promise;

                return data;
            } catch (error) {
                return null;
            }
        }

        if (!hasMore) {
            return null;
        }

        setState({ isFetching: true });

        bikeComponentsRequest.current = makeCancellable(axios.get(URL_BIKE_COMPONENTS, { params: { page } }));

        try {
            const { data, headers } = await bikeComponentsRequest.current.promise;

            bikeComponentsRequest.current = null;

            if (page === 1) {
                initialComponentsTotal.current = Number.parseInt(headers['total-count']);
            }

            const newComponents = [
                ...bikeComponents.filter(
                    ({ id }: { id: string }) => !data.find((component: BikeComponent) => component.id === id),
                ),
                ...data,
            ];

            setState({
                bikeComponents: newComponents.sort((a, b) => (b.last_update_ts - a.last_update_ts)),
                bikeComponentsCount: bikeComponentsCount + data.length,
                hasMore: bikeComponentsCount + data.length < initialComponentsTotal.current,
                isFetching: false,
                page: page + 1,
            });

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching components', page, error);
                bikeComponentsRequest.current = null;
                setState({ isFetching: false });
            }

            return null;
        }
    }

    async function fetchAvailableFirmwareVersion(bikeComponent: BikeComponent) {
        if (!bikeComponent || !bikeComponent.firmware_type) {
            return null;
        }

        if (availableFirmwareVersion[bikeComponent.model]) {
            return availableFirmwareVersion[bikeComponent.model];
        }

        const currentRequest = availableFirmwareVersionRequests.current.get(bikeComponent.id);

        if (currentRequest) {
            try {
                const { data } = await currentRequest.promise;

                return data[0];
            } catch (error) {
                return null;
            }
        }

        const fetchRequest = makeCancellable(axios.get(`${URL_FIRMWARE_API}v2/firmware/${bikeComponent.model}`));

        availableFirmwareVersionRequests.current.set(bikeComponent.id, fetchRequest);

        try {
            const { data } = await fetchRequest.promise;
            availableFirmwareVersionRequests.current.delete(bikeComponent.id);

            if (data.length) {
                // Temporarily store fetched availableFirmwareVersions untill state is set.
                tempAvailableFirmwareVersion = { ...tempAvailableFirmwareVersion, [bikeComponent.model]: data[0] };

                setState({ availableFirmwareVersion: tempAvailableFirmwareVersion });
                return data[0];
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching available firmware version', bikeComponent.id, error);
                availableFirmwareVersionRequests.current.delete(bikeComponent.id);
            }
            return null;
        }
        return null;
    }

    async function fetchProductDetails(model_code: string) {
        if (!model_code) {
            return null;
        }

        if (productDetails[model_code]) {
            return productDetails[model_code];
        }

        // Await preexisting request
        const currentRequest = productDetailRequests.current.get(model_code);
        if (currentRequest) {
            try {
                const { data } = await currentRequest.promise;

                return data;
            } catch (error) {
                return null;
            }
        }

        const productDetailsRequest = makeCancellable(axios.get(`${URL_API}productdetails/${model_code}/`));

        productDetailRequests.current.set(model_code, productDetailsRequest);
        try {
            const { data } = await productDetailsRequest.promise;
            // Success
            productDetailRequests.current.delete(model_code);

            // Temporarily store productDetails untill state is set.
            tempProductDetails = { ...tempProductDetails, [model_code]: data };

            // Use stable state to prevent multiple fetches during lifecycle from corrupting state
            setState({ productDetails: tempProductDetails });

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.error('Error fetching product details from ', model_code, error);

                productDetailRequests.current.delete(model_code);
            }

            return null;
        }
    }

    async function registerComponent(serialNumber: string) {
        const currentRequest = bikeComponentRegisterRequests.current.get(serialNumber);

        if (currentRequest) {
            try {
                const { data } = await currentRequest.promise;

                return data;
            } catch (error) {
                return null;
            }
        }

        const fetchRequest = makeCancellable(axios.post(
            `${URL_API}componentregistrations/`,
            { email: nexus.nexusUserProfile.email, serial: serialNumber },
        ));

        bikeComponentRegisterRequests.current.set(serialNumber, fetchRequest);

        try {
            const { data } = await fetchRequest.promise;

            const newComponent = await fetchBikeComponentBySerial(serialNumber);

            if (newComponent) {
                setState({
                    bikeComponents: [
                        ...bikeComponents.filter((component: any) => newComponent[0].id !== component.id),
                        ...newComponent,
                    ],
                });
            }

            bikeComponentRegisterRequests.current.delete(serialNumber);

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                bikeComponentRegisterRequests.current.delete(serialNumber);
                Logger.error('Error Registering Component', serialNumber, error);
            }

            return null;
        }
    }

    function onAuthenticationChange() {
        if (!auth.accessToken) {
            clear();
            return;
        }

        fetchBikeComponents();
    }

    useEffect(() => {
        onAuthenticationChange();
    }, [auth.accessToken]);

    useEffect(() => () => {
        clear();
    }, []);

    return (
        <BikeComponentsContext.Provider
            value={{
                bikeComponents: {
                    ...state,
                    clear: () => clear(),
                    createNexusComponent: (serial: string, bike: any) => createNexusComponent(serial, bike),
                    deleteBikeComponent: (id: string) => deleteBikeComponent(id),
                    fetch: (id: string, useExisting?: boolean) => fetchBikeComponent(id, useExisting),
                    fetchAvailableFirmwareVersion: (bikeComponent: any) => fetchAvailableFirmwareVersion(bikeComponent),
                    fetchBikeComponentBySerial: (serial: string) => fetchBikeComponentBySerial(serial),
                    fetchBikeComponents: () => fetchBikeComponents(),
                    fetchComponentBleServices: (id: string) => fetchComponentBleServices(id),
                    fetchComponentNotifications: (serial: string) => fetchComponentNotifications(serial),
                    fetchComponentServices: (serial: string) => fetchComponentServices(serial),
                    fetchComponentTags: (serial: string) => fetchComponentTags(serial),
                    fetchProductDetails: (serial: string) => fetchProductDetails(serial),
                    list: bikeComponents,
                    registerComponent: (serial: string) => registerComponent(serial),
                },
            }}
        >
            {children}
        </BikeComponentsContext.Provider>
    );
}

export default BikeComponentsProvider;
