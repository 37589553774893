import TyreWiz from './tyreWiz';
import styles from './TyreWizPane.module.scss';

import {
    Bike, ComponentSummary,
} from '../../../../providers';

interface TyreWizPaneProps {
    bike?: Bike;
    disableEdit?: boolean;
    tyreComponents: ComponentSummary[];
}

const TyreWizPane = ({ bike, disableEdit = false, tyreComponents }: TyreWizPaneProps) => {
    const front = tyreComponents.find(({ data: { position } }) => position === 1);
    const rear = tyreComponents.find(({ data: { position } }) => position === 2);
    const other = tyreComponents.find(({ data: { position } }) => (
        (position !== 1 && position !== 2)
    ));

    return (
        <div className={styles.container}>
            <TyreWiz className={styles.tyrewiz} bike={bike} disableEdit={disableEdit} tyreWiz={front} />
            <TyreWiz className={styles.tyrewiz} bike={bike} disableEdit={disableEdit} tyreWiz={rear} />
            <TyreWiz className={styles.tyrewiz} disableEdit={disableEdit} tyreWiz={other} />
        </div>
    );
};

export default TyreWizPane;
