import React from 'react';
import styles from './modals.module.scss';
import { imageHowToMeasure } from '../../../assets';
import Modal from '../../../components/modal';
import Translate from '../../../components/translate';

const HowToMeasureInfoModal = ({
    open, onClose,
}:
    {open: boolean, onClose: () => void, }) => (
    <Modal
        className={styles.modalContainerInfo}
        contentClassName={styles.infoContent}
        headerStyle={{ color: 'white', fontSize: '1.5rem' }}
        contentLabel="InfoModal"
        isOpen={open}
        header="HOW_TO_MEASURE"
        hideImage
        onClose={() => onClose()}
        containerStyle={{ padding: 'none' }}
        onOverlayClick={() => onClose()}
        darkMode
    >

        <div className={styles.imageContainer}>
            <img
                alt="How to Measure"
                className={styles.image}
                src={imageHowToMeasure}
                style={{ width: '100%' }}
            />
        </div>
        <div className={styles.infoText}>
            <Translate>HOW_TO_MEASURE_CHAINSTAY_LENGTH_TITLE</Translate>
        </div>
        <div className={styles.infoText}>
            <Translate>HOW_TO_MEASURE_CHAINSTAY_LENGTH</Translate>
        </div>
        <div className={styles.infoText}>
            <Translate>HOW_TO_MEASURE_CHAINRING_SIZE_TITLE</Translate>
        </div>
        <div className={styles.infoText}>
            <Translate>HOW_TO_MEASURE_CHAINRING_SIZE</Translate>
        </div>
    </Modal>
);

export default HowToMeasureInfoModal;
