import React from 'react';
import { Link } from 'react-router-dom';

import styles from './DefaultBikeCard.module.scss';

import { AXS_TEXT_DARK } from '../../../constants';
import Button from '../../../components/button';
import Translate from '../../../components/translate';
import BikeCard from '../../../views/bikeCard';

const DefaultBikeCard = () => (
    <div className={styles.container}>
        <BikeCard bike={{ name: <Translate>BIKE_NEW_PROFILE</Translate> }}>
            <div className={styles.footerContainer}>
                <div>
                    <div className={styles.headingContainer}>
                        <Translate>BIKE_GET_STARTED</Translate>
                    </div>
                    <div className={styles.subText}>
                        <Translate>BIKE_GET_STARTED_SUBHEADER</Translate>
                    </div>
                </div>
                <Button
                    className={styles.button}
                    color={AXS_TEXT_DARK}
                    Component={Link}
                    inverse
                    to="/buildbike"
                >
                    <Translate>BIKE_BUILD_MY</Translate>
                </Button>
            </div>
        </BikeCard>
    </div>
);

export default DefaultBikeCard;
