import { useEffect, useRef } from 'react';
import Moment from 'react-moment';
import { Link, useLocation } from 'react-router-dom';

import EmailSentModal from './emailSentModal';
import styles from './ManageAccount.module.scss';

import { iconDelete, logoSramWhite } from '../../../assets';
import Button from '../../../components/button';
import ContentHeaderContainer from '../../../components/contentHeaderContainer';
import Modal from '../../../components/modal';
import Spinner from '../../../components/spinner';
import Translate, { useTranslation } from '../../../components/translate';
import { AXS_TEXT_GREY, makeCancellable, RequestType } from '../../../constants';
import Logger from '../../../Logger';
import { useAuth, useNexus } from '../../../providers';
import { useSetState } from '../../../hooks';
import ErrorModal from '../../../views/errorModal';

const DEFAULT_STATE = {
    isOpenChangeEmailModal: false,
    isOpenChangePasswordModal: false,
    isOpenDeleteAccountEmailSentModal: false,
    isOpenDeleteAccountModal: false,
    isRequestingEmailChange: false,
    isRequestingPasswordChange: false,
    newEmail: '',
    requestError: null,
};

function ManageAccount() {
    const auth = useAuth();
    const nexus = useNexus();
    const location = useLocation();
    const translate = useTranslation();
    const [state, setState] = useSetState({
        ...DEFAULT_STATE,
        showEmailMissingModal: (
            location.hash === '#account_email_missing'
        || !nexus.nexusUserProfile.email
        ),
    });

    const {
        isOpenChangeEmailModal,
        isOpenChangePasswordModal,
        isOpenDeleteAccountEmailSentModal,
        isOpenDeleteAccountModal,
        isRequestingDeleteAccount,
        isRequestingEmailChange,
        isRequestingPasswordChange,
        newEmail,
        requestError,
        showEmailMissingModal,
    } = state;
    const requestAccountDeleteRequest = useRef<RequestType | null>(null);
    const requestEmailChangeRequest = useRef<RequestType | null>(null);
    const requestPasswordChangeRequest = useRef<RequestType | null>(null);

    useEffect(() => (() => {
        if (requestAccountDeleteRequest.current) {
            requestAccountDeleteRequest.current.cancel();
        }

        if (requestEmailChangeRequest.current) {
            requestEmailChangeRequest.current.cancel();
        }

        if (requestPasswordChangeRequest.current) {
            requestPasswordChangeRequest.current.cancel();
        }
    }), []);

    async function requestAccountDelete() {
        if (requestAccountDeleteRequest.current) {
            try {
                await requestAccountDeleteRequest.current.promise;
                return;
            } catch (error) {
                return;
            }
        }

        setState({ isRequestingDeleteAccount: true });

        requestAccountDeleteRequest.current = makeCancellable(auth.requestAccountDelete());

        try {
            await requestAccountDeleteRequest.current.promise;
            requestAccountDeleteRequest.current = null;

            setState({
                isOpenDeleteAccountEmailSentModal: true,
                isOpenDeleteAccountModal: false,
                isRequestingDeleteAccount: false,
            });
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error Requesting Delete Account', error);
                requestAccountDeleteRequest.current = null;

                setState({ isRequestingDeleteAccount: false, requestError: error });
            }
        }
    }

    async function requestEmailChange() {
        if (requestEmailChangeRequest.current) {
            try {
                await requestEmailChangeRequest.current.promise;
                return;
            } catch (error) {
                return;
            }
        }

        setState({ isRequestingEmailChange: true });

        requestEmailChangeRequest.current = makeCancellable(auth.requestEmailChange(newEmail));
        try {
            await requestEmailChangeRequest.current.promise;
            requestEmailChangeRequest.current = null;

            setState({ isOpenChangeEmailModal: true, isRequestingEmailChange: false });
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error Requesting Email Change', error);
                requestEmailChangeRequest.current = null;
                setState({ isRequestingEmailChange: false, requestError: error });
            }
        }
    }

    async function requestPasswordChange() {
        if (requestPasswordChangeRequest.current) {
            try {
                await requestPasswordChangeRequest.current.promise;
                return;
            } catch (error) {
                return;
            }
        }

        setState({ isRequestingPasswordChange: true });
        requestPasswordChangeRequest.current = makeCancellable(auth.requestPasswordChange());

        try {
            await requestPasswordChangeRequest.current.promise;
            requestPasswordChangeRequest.current = null;

            setState({ isOpenChangePasswordModal: true, isRequestingPasswordChange: false });
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error Requesting Password Change', error);
                requestPasswordChangeRequest.current = null;

                setState({ isRequestingPasswordChange: false, requestError: error });
            }
        }
    }

    function renderChangeEmail() {
        return (
            <>
                <EmailSentModal
                    email={newEmail}
                    message="EMAIL_SENT_VERIFY"
                    open={isOpenChangeEmailModal}
                    onClose={() => setState({
                        isOpenChangeEmailModal: false,
                        newEmail: '',
                    })}
                />
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        requestEmailChange();
                    }}
                    style={{ borderBottom: '1px solid lightgray', marginTop: '2rem', paddingBottom: '2rem' }}
                >
                    <div className={styles.title}>
                        <Translate>EMAIL_CHANGE_TITLE</Translate>
                    </div>
                    <div className={styles.subTitle}>
                        <Translate>EMAIL_CHANGE_SUBTITLE</Translate>
                    </div>
                    <input
                        className={styles.inputField}
                        onChange={(event) => setState({ newEmail: event.target.value })}
                        placeholder={translate('ENTER_EMAIL')}
                        required
                        style={{ display: 'block', margin: '2rem 0' }}
                        type="email"
                        value={newEmail}
                    />
                    <Button
                        className={styles.button}
                        inverse
                        type="submit"
                    >
                        <Translate>CONTINUE</Translate>
                    </Button>
                </form>
            </>
        );
    }

    function renderChangeEmailModal() {
        return (
            <Modal
                contentClassName={styles.modalContent}
                contentLabel="Account Update Required"
                header="ACCOUNT_EMAIL_MISSING_UPDATE"
                imageSrc={logoSramWhite}
                imageClassName={styles.image}
                isOpen={showEmailMissingModal}
                onClose={() => setState({ showEmailMissingModal: !showEmailMissingModal })}
            >
                <p>
                    <Translate>ACCOUNT_EMAIL_MISSING_TITLE</Translate>
                </p>
                <p>
                    <Translate>ACCOUNT_EMAIL_MISSING_PRIVACY</Translate>
                </p>
                <p>
                    <Translate>ACCOUNT_EMAIL_MISSING_FACEBOOK</Translate>
                </p>
                <p className={styles.bulletPoint}>
                    <Translate>ACCOUNT_EMAIL_MISSING_STEP_ONE</Translate>
                </p>
                <p className={styles.bulletPoint}>
                    <Translate>ACCOUNT_EMAIL_MISSING_STEP_TWO</Translate>
                </p>
                <p className={styles.bulletPoint}>
                    <Translate>ACCOUNT_EMAIL_MISSING_STEP_THREE</Translate>
                </p>
                <p>
                    <Translate>ACCOUNT_EMAIL_MISSING_DATA</Translate>
                </p>
                <p>
                    <Translate>ACCOUNT_EMAIL_MISSING_CONTACT</Translate>
                    <a href="mailto:app@sram.com"> apps@sram.com</a>
                </p>
            </Modal>
        );
    }

    function renderChangePassword() {
        return (
            <div style={{ borderBottom: '1px solid lightgray', marginTop: '2rem', paddingBottom: '2rem' }}>
                <EmailSentModal
                    email={nexus.nexusUserProfile.email}
                    message="RIDER_PROFILE_PASSWORD_RESET_SUCCESS"
                    open={isOpenChangePasswordModal}
                    onClose={() => setState({ isOpenChangePasswordModal: false })}
                />

                <div style={{ marginBottom: '2rem' }}>
                    <div className={styles.title}>
                        <Translate>CHANGE_PASSWORD</Translate>
                    </div>
                    <div className={styles.subTitle}>
                        <Translate>CHANGE_PASSWORD_SUBTITLE</Translate>
                    </div>
                </div>

                <Button
                    className={styles.button}
                    inverse
                    onClick={() => requestPasswordChange()}
                    style={{ marginTop: '2rem' }}
                    type="button"
                >
                    <Translate>CHANGE_PASSWORD</Translate>
                </Button>
            </div>
        );
    }

    function renderDeleteAccount() {
        return (
            <div style={{ borderBottom: '1px solid lightgray', marginTop: '2rem', paddingBottom: '2rem' }}>
                <Modal
                    contentClassName={styles.deleteAccountContainer}
                    contentLabel="Delete Account"
                    dialog
                    header="ARE_YOU_SURE"
                    hideCloseButton
                    // Overriding styles needs inline to avoid using !important
                    imageStyle={{ height: '5rem', marginBottom: 0, marginTop: 'auto' }}
                    imageSrc={iconDelete}
                    isOpen={isOpenDeleteAccountModal}
                >
                    <Translate>RIDER_PROFILE_ACCOUNT_DELETION_CONFIRM</Translate>
                    <div className={styles.buttonContainer}>
                        <Button
                            color={AXS_TEXT_GREY}
                            inverse
                            onClick={() => setState({ isOpenDeleteAccountModal: false })}
                        >
                            <Translate>CANCEL</Translate>
                        </Button>
                        <Button onClick={() => requestAccountDelete()}>
                            <Translate>DELETE</Translate>
                        </Button>
                    </div>
                </Modal>

                <EmailSentModal
                    email={nexus.nexusUserProfile.email}
                    message="RIDER_PROFILE_ACCOUNT_DELETION_EMAIL_SENT"
                    open={isOpenDeleteAccountEmailSentModal}
                    onClose={() => setState({ isOpenDeleteAccountEmailSentModal: false })}
                />

                <div style={{ marginBottom: '2rem' }}>
                    <div className={styles.title}>
                        <Translate>DELETE_ACCOUNT</Translate>
                    </div>
                    <div className={styles.subTitle}>
                        <Translate>RIDER_PROFILE_ACCOUNT_DELETION_INFO</Translate>
                    </div>
                </div>

                <Button
                    className={styles.button}
                    onClick={() => setState({ isOpenDeleteAccountModal: true })}
                    style={{ marginTop: '2rem' }}
                    type="button"
                >
                    <Translate>DELETE_ACCOUNT</Translate>
                </Button>
            </div>
        );
    }

    function renderErrorModal() {
        return (
            <ErrorModal
                error={requestError}
                onClose={() => setState({ requestError: null })}
            />
        );
    }

    function renderJoinedDate() {
        return (
            <div
                className="flex"
                style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '2rem',
                }}
            >
                <div className={styles.title}>
                    <Translate>JOINED_SRAM_AXS</Translate>
                </div>
                <div className={styles.subTitle}>
                    <Moment format="MMMM Do, YYYY" unix>
                        {nexus.nexusUserProfile.create_ts}
                    </Moment>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Spinner
                loading={isRequestingDeleteAccount || isRequestingEmailChange || isRequestingPasswordChange}
            />
            <ContentHeaderContainer className={styles.accountButtonContainer}>
                <Link
                    className={styles.link}
                    to="/riderportal"
                >
                        &lt;&nbsp;
                    <Translate>BACK_TO_ACCOUNT</Translate>
                </Link>
            </ContentHeaderContainer>
            <div style={{ marginBottom: '1rem' }}>
                <div className={styles.title}>
                    <Translate>MANAGE_ACCOUNT_TITLE</Translate>
                </div>
                <div className={styles.subTitle}>
                    {nexus.nexusUserProfile.email}
                </div>
            </div>
            {renderChangeEmail()}
            {renderChangePassword()}
            {renderDeleteAccount()}
            {renderJoinedDate()}
            {renderErrorModal()}
            {renderChangeEmailModal()}
        </div>
    );
}

export default ManageAccount;
