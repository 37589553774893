import { scaleTime } from 'd3';
import {
    Grid,
    Line,
    lineBisector,
    onMouseMove,
    onTouchMove,
    Scale,
    VerticalLine,
} from 'd3-charts-react';
import { Axis, axisPropsFromTickScale, LEFT } from 'react-d3-axis';
import { useResizeDetector } from 'react-resize-detector';

import styles from './CadenceLineChart.module.scss';

import AltitudeChart from '../../altitudeChart';
import { extractTime, extractValue, mutateScaleNice } from '../../chartHelpers';
import BikeComponentLink from '../../bikeComponentLink';

import Translate from '../../../../components/translate';
import { RED_LIGHT, SRAM_200, toLocaleString } from '../../../../constants';
import { useSetState } from '../../../../hooks';
import { ComponentSummary, useUnits } from '../../../../providers';
import ExpandChart from '../../../../views/expandChart';

interface CadenceLineChartProps {
    disableEdit?: boolean,
    powerComponent: ComponentSummary,
    gpsComponent: ComponentSummary
}

const FORMAT_CADENCE = (cadence: number) => toLocaleString(Math.round(cadence));
const DEFAULT_STATE = { altitude: 0, cadence: 0, timestamp: null };

function CadenceLineChart({ disableEdit = false, powerComponent, gpsComponent }: CadenceLineChartProps) {
    const [state, setState] = useSetState(DEFAULT_STATE);
    const units = useUnits();
    const { altitude, cadence, timestamp } = state;

    const cadenceExtractor = (...params: any) => {
        const values = lineBisector(...params);

        setState({ cadence: values ? values[1] : 0 });
    };

    const renderChart = (scale: any) => {
        const xAxisProps = axisPropsFromTickScale(scale.x, 3);
        const yAxisProps = axisPropsFromTickScale(scale.y, 3);

        const width = scale.x.range()[1];
        const height = scale.y.range()[0];

        return (
            <div className={styles.chartContainer}>
                <svg
                    className={styles.chart}
                    height={height}
                    onMouseLeave={() => setState({
                        altitude: 0,
                        cadence: 0,
                        timestamp: null,
                    })}
                    onMouseMove={(event) => {
                        const hoveredTimeStamp = onMouseMove(event, scale);

                        if (hoveredTimeStamp) {
                            setState({ timestamp: hoveredTimeStamp });
                        }
                    }}
                    onTouchMove={(event) => {
                        const hoveredTimeStamp = onTouchMove(event, scale);

                        if (hoveredTimeStamp) {
                            setState({ timestamp: hoveredTimeStamp });
                        }
                    }}
                    width={width}
                >
                    <AltitudeChart
                        gpsComponent={gpsComponent}
                        height={height}
                        onAltitudeChange={(value) => setState({ altitude: value })}
                        sync={timestamp}
                        width={width}
                    />
                    <Grid
                        hideXTicks
                        scale={scale}
                        yLineStyle={{ stroke: SRAM_200 }}
                        yTicks={yAxisProps.values}
                    />
                    <Line
                        className={styles.hiddenLine}
                        data={powerComponent.data.cadence}
                        scale={scale}
                        style={{ stroke: RED_LIGHT }}
                        sync={timestamp}
                        valueExtractor={(...params: any) => cadenceExtractor(...params)}
                        xExtractor={(...params: any) => extractTime(powerComponent, ...params)}
                        yExtractor={extractValue}
                    />
                    <VerticalLine scale={scale} sync={timestamp} />
                    {timestamp && (
                        <circle
                            className={styles.indicator}
                            cx={scale.x(timestamp)}
                            cy={Math.min(scale.y(cadence), 400)}
                            r={7}
                        />
                    )}
                    <g className={styles.axis}>
                        <Axis
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...yAxisProps}
                            format={FORMAT_CADENCE}
                            style={{ orient: LEFT }}
                        />
                    </g>
                    <g
                        className={styles.axis}
                        style={{ transform: `translateY(${height}px)` }}
                    >
                        <Axis
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...xAxisProps}
                            format={units.formatTime}
                        />
                    </g>
                </svg>
                <div className={styles.mobileTimeScale}>
                    <div className={styles.startTime}>
                        {units.formatTime(powerComponent.adjustedStartTs * 1000)}
                    </div>
                    <div className={styles.endTime}>
                        {units.formatTime(powerComponent.adjustedEndTs * 1000)}
                    </div>
                </div>
            </div>
        );
    };

    const { width, height, ref } = useResizeDetector();

    const renderScale = () => (
        <div ref={ref}>
            <Scale
                height={height}
                width={width}
                xMax={powerComponent.adjustedEndTs * 1000}
                xMin={powerComponent.adjustedStartTs * 1000}
                xScale={scaleTime}
                yMax={powerComponent.data.max_cadence * 1.05}
                yMin={0}
                yMutator={mutateScaleNice}
            >
                {(scale: any) => renderChart(scale)}
            </Scale>
        </div>
    );

    if (!powerComponent) return null;

    if (!powerComponent.data.cadence.length) {
        return (
            <div className={styles.noDataNote}>
                <Translate>NO_DATA_AVAILABLE_NOTES</Translate>
            </div>
        );
    }

    return (
        <ExpandChart title="CADANCE_CHART">
            <div className={styles.container}>
                <div className={styles.statisticsContainer}>
                    <div className={styles.statistic}>
                        {FORMAT_CADENCE(cadence)}
                        &nbsp;
                        <Translate>UNITS_RPM</Translate>
                    </div>
                    <div className={styles.statistic}>
                        {Number.isFinite(altitude) && (
                            <>
                                {toLocaleString(altitude)}
                                &nbsp;
                                <Translate>{units.getLabelAltitude().shorthand}</Translate>
                            </>
                        )}
                    </div>
                </div>
                <div className={styles.yAxisLabel}>
                    <Translate>UNITS_RPM</Translate>
                </div>
                {renderScale()}
                <div className={styles.xAxisLabel}>
                    <Translate>TIME</Translate>
                </div>
                {!disableEdit && <BikeComponentLink componentSummary={powerComponent} />}
            </div>
        </ExpandChart>
    );
}

export default CadenceLineChart;
