import { useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ActivityNavigation from './activityNavigation';
import ActivitySummary from './activitySummary';
import ComponentSummary from './componentSummary';
import RiderPerformance from './riderPerformance';

import ContentContainer from '../../components/contentContainer';
import Spinner from '../../components/spinner';
import { DEVICE_TYPES, makeCancellable, RequestType } from '../../constants';
import ErrorModal from '../../views/errorModal';
import Logger from '../../Logger';
import NotFoundCard from '../../views/notFoundCard';
import {
    ComponentSummary as ComponentSummaryTypes,
    useActivities,
    useAuth,
    useComponentSummaries,
    useNexus,
} from '../../providers';
import { useSetState } from '../../hooks';
import { useBikes } from '../../providers/bikes';
import {
    filterGearComponents,
    filterGpsComponents,
    filterHeartComponents,
    filterPowerComponents,
    filterReverbComponents,
    filterTyreComponents,
    getTimeRange,
} from './helpers';
import { BikeUpdates } from '../../providers/bikes/types';

const EXAMPLE = 'example';
const DEFAULT_FTP = 200;
const DEFAULT_LTHR = 170;
const DEFAULT_STATE = {
    activity: null,
    bike: null,
    gearComponents: [],
    gpsComponents: [],
    heartComponents: [],
    isDeleting: false,
    isDownloadingActivity: false,
    isFetchingActivity: true,
    isFetchingBike: true,
    isFetchingComponentSummaries: true,
    isOwner: false,
    isRegenerating: false,
    isUpdating: false,
    powerComponents: [],
    reverbComponents: [],
    shockWiz: null,
    showDownloadedErrorModal: false,
    tyreComponents: [],
};

function Activity() {
    const [state, setState] = useSetState(DEFAULT_STATE);
    const activities = useActivities();
    const auth = useAuth();
    const bikes = useBikes();
    const componentSummaries = useComponentSummaries();
    const nexus = useNexus();
    const { id } = useParams();

    const deleteActivityRequest = useRef< RequestType | null>(null);
    const downloadActivityRequest = useRef< RequestType | null>(null);
    const fetchActivityRequest = useRef< RequestType | null>(null);
    const fetchBikeRequest = useRef< RequestType | null>(null);
    const fetchComponentSummariesRequest = useRef< RequestType | null>(null);
    const fetchExampleDataRequest = useRef< RequestType | null>(null);
    const regenerateActivityRequest = useRef< RequestType | null>(null);
    const updateActivityRequests = useRef(new Set<RequestType>());
    const updateActivitySummaryRequests = useRef(new Set<RequestType>());
    const updateBikeRequests = useRef(new Set<RequestType>());
    const navigate = useNavigate();

    const {
        activity,
        bike,
        gearComponents,
        gpsComponents,
        heartComponents,
        isDeleting,
        isDownloadingActivity,
        isFetchingActivity,
        isFetchingBike,
        isFetchingComponentSummaries,
        isOwner,
        isRegenerating,
        isUpdating,
        powerComponents,
        reverbComponents,
        shockWiz,
        showDownloadedErrorModal,
        tyreComponents,
    } = state;
    const { nexusUserProfile } = nexus;
    const location = useLocation();
    const timeRange = useMemo(() => getTimeRange(activity, location), [location, activity]);
    const { endTs, startTs } = timeRange;
    const gearComponentsFiltered = filterGearComponents(gearComponents, timeRange);
    const gpsComponentsFiltered = filterGpsComponents(gpsComponents, timeRange);
    const heartComponentsFiltered = filterHeartComponents(heartComponents, timeRange);
    const powerComponentsFiltered = filterPowerComponents(powerComponents, timeRange);
    const reverbComponentsFiltered = filterReverbComponents(reverbComponents, timeRange);
    const tyreComponentsFiltered = filterTyreComponents(tyreComponents, timeRange);

    const generateHeartZonesValues = () => {
        const lthr = nexusUserProfile ? nexusUserProfile.lactate_threshold : DEFAULT_LTHR;

        return activities.heartZones.map((zone: {min: number, max: number}, index: number) => {
            const max = (index + 1 < activities.heartZones.length)
                ? Math.round(activities.heartZones[index + 1].min * lthr)
                : zone.max;

            return { max, min: Math.round(zone.min * lthr) };
        });
    };

    const generatePowerZonesValues = () => {
        const ftp = nexusUserProfile ? nexusUserProfile.ftp : DEFAULT_FTP;

        return activities.powerZones.map((zone: {min: number, max: number}, index: number) => {
            const max = (index + 1 < activities.powerZones.length)
                ? Math.round(activities.powerZones[index + 1].min * ftp)
                : zone.max;

            return { max, min: Math.round(zone.min * ftp) };
        });
    };

    const heartZones = useMemo(() => generateHeartZonesValues(), [nexusUserProfile, activities]);
    const powerZones = useMemo(() => generatePowerZonesValues(), [nexusUserProfile, activities]);

    useEffect(() => () => {
        if (deleteActivityRequest.current) {
            deleteActivityRequest.current.cancel();
        }

        if (downloadActivityRequest.current) {
            downloadActivityRequest.current.cancel();
        }

        if (fetchActivityRequest.current) {
            fetchActivityRequest.current.cancel();
        }

        if (fetchBikeRequest.current) {
            fetchBikeRequest.current.cancel();
        }

        if (fetchComponentSummariesRequest.current) {
            fetchComponentSummariesRequest.current.cancel();
        }

        if (fetchExampleDataRequest.current) {
            fetchExampleDataRequest.current.cancel();
        }

        if (regenerateActivityRequest.current) {
            regenerateActivityRequest.current.cancel();
        }

        updateActivityRequests.current.forEach((request) => request.cancel());
        updateActivityRequests.current.clear();

        updateActivitySummaryRequests.current.forEach((request) => request.cancel());
        updateActivitySummaryRequests.current.clear();

        updateBikeRequests.current.forEach((request) => request.cancel());
        updateBikeRequests.current.clear();
    }, []);

    const isExampleActivity = () => id === EXAMPLE;

    const fetchBike = async () => {
        // Do not fetch the bike is the current user is not the owner
        if (!activity || !isOwner) {
            setState({ isFetchingBike: false });

            return;
        }

        const bikeUUID = activity.id === EXAMPLE ? EXAMPLE : activity.bike_uuid;

        if (!bikeUUID) {
            setState({ bike: null, isFetchingBike: false });

            return;
        }

        setState({ isFetchingBike: true });

        fetchBikeRequest.current = makeCancellable(bikes.fetchBike(bikeUUID, true));

        try {
            const data = await fetchBikeRequest.current.promise;
            fetchBikeRequest.current = null;
            setState({ bike: data, isFetchingBike: false });
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn(error);
                fetchBikeRequest.current = null;
                setState({ isFetchingBike: false });
            }
        }
    };

    const fetchComponentSummaries = async () => {
        setState({ isFetchingComponentSummaries: true });

        fetchComponentSummariesRequest.current = makeCancellable(
            isExampleActivity()
                ? componentSummaries.fetchExample()
                : componentSummaries.fetch(
                    activity.componentsummary_set.map((componentSummary: ComponentSummaryTypes) => componentSummary.id),
                ),
        );

        try {
            let fetchedComponentSummaries = await fetchComponentSummariesRequest.current.promise;
            // Remove summaries that errored while fetching
            fetchedComponentSummaries = fetchedComponentSummaries.filter((componentSummary: ComponentSummaryTypes) => componentSummary);
            fetchComponentSummariesRequest.current = null;

            setState({
                gearComponents: fetchedComponentSummaries.filter(({ device_type }: { device_type: number }) => (
                    device_type === DEVICE_TYPES.gears
                )),
                gpsComponents: fetchedComponentSummaries.filter(({ device_type }: { device_type: number }) => (
                    device_type === DEVICE_TYPES.gps
                )),
                heartComponents: fetchedComponentSummaries.filter(({ device_type }: { device_type: number }) => (
                    device_type === DEVICE_TYPES.heartRate
                )),
                isFetchingComponentSummaries: false,
                powerComponents: fetchedComponentSummaries.filter(({ device_type }: { device_type: number }) => (
                    device_type === DEVICE_TYPES.power
                )),
                reverbComponents: fetchedComponentSummaries.filter(({ device_type }: { device_type: number }) => (
                    device_type === DEVICE_TYPES.reverb
                )),
                tyreComponents: fetchedComponentSummaries.filter(({ device_type }: { device_type: number }) => (
                    device_type === DEVICE_TYPES.tirePressure
                )),
            });
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn(error);
                fetchComponentSummariesRequest.current = null;
                setState({ isFetchingComponentSummaries: false });
            }
        }
    };

    const fetchExampleData = async () => {
        fetchExampleDataRequest.current = makeCancellable(activities.fetchExampleActivity());

        try {
            const exampleData = await fetchExampleDataRequest.current.promise;
            fetchExampleDataRequest.current = null;

            setState({
                activity: exampleData,
                isFetchingActivity: false,
                isOwner: isExampleActivity() || !!nexusUserProfile,
            });

            return exampleData;
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchExampleDataRequest.current = null;
            }

            return null;
        }
    };

    const regenerateActivity = async () => {
        if (regenerateActivityRequest.current) {
            try {
                const regeneratedActivity = await regenerateActivityRequest.current.promise;
                return regeneratedActivity;
            } catch (error) {
                // Error handled below
                return null;
            }
        }

        setState({ isRegenerating: true });
        regenerateActivityRequest.current = makeCancellable(
            activities.regenerateActivity(activity.id),
        );

        try {
            const regeneratedActivity = await regenerateActivityRequest.current.promise;
            regenerateActivityRequest.current = null;

            if (regeneratedActivity) {
                setState({ activity: regeneratedActivity, isRegenerating: false });
            }

            return regeneratedActivity;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn(error);
                regenerateActivityRequest.current = null;

                setState({ isRegenerating: false });
            }

            return null;
        }
    };

    const updateActivity = async (activityUpdates: { [key: string]: any }) => {
        setState({ isUpdating: true });

        const fetchRequest = makeCancellable(
            activities.updateActivity(activity.id, activityUpdates),
        );

        updateActivityRequests.current.add(fetchRequest);

        try {
            const updatedActivity = await fetchRequest.promise;
            updateActivityRequests.current.delete(fetchRequest);

            if (updatedActivity) {
                setState({ activity: updatedActivity });
            }

            if (!updateActivityRequests.current.size) {
                setState({ isUpdating: false });
            }

            return updatedActivity;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn(error);
                updateActivityRequests.current.delete(fetchRequest);

                if (!updateActivityRequests.current.size) {
                    setState({ isUpdating: false });
                }
            }
            return null;
        }
    };

    const unassignBikeFromActivity = () => {
        updateActivity({ bike_uuid: null });
    };

    const updateActivitySummary = async (activitySummary: { [key: string]: any }) => {
        let activitySummarySet = {} as any;

        if (activity.activitysummary_set.length) {
            activitySummarySet = activity.activitysummary_set[0].data;
        }

        const activitySummaryUpdates = {
            ...activitySummary,
            data: { ...activitySummarySet.data, ...activitySummary.data },
        };

        setState({ isUpdating: true });

        const fetchRequest = makeCancellable(activities.updateActivitySummary(
            activity.id,
            activity.activitysummary_set[0].id,
            activitySummaryUpdates,
        ));
        updateActivitySummaryRequests.current.add(fetchRequest);

        try {
            const updatedActivity = await fetchRequest.promise;

            updateActivitySummaryRequests.current.delete(fetchRequest);

            if (updatedActivity) {
                setState({ activity: updatedActivity, bike: null });
            }

            if (!updateActivitySummaryRequests.current.size) {
                setState({ isUpdating: false });
            }

            return updatedActivity;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn(error);
                updateActivitySummaryRequests.current.delete(fetchRequest);
                if (!updateActivitySummaryRequests.current.size) {
                    setState({ isUpdating: false });
                }
            }
            return null;
        }
    };

    const updateBike = async (bikeUpdates: BikeUpdates) => {
        const fetchRequest = makeCancellable(bikes.updateBike(bike.id, bikeUpdates));
        updateBikeRequests.current.add(fetchRequest);

        try {
            const updatedBike = await fetchRequest.promise;
            updateBikeRequests.current.delete(fetchRequest);

            if (updatedBike) {
                setState({ bike: updatedBike });
            }

            return updatedBike;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn(error);
                updateBikeRequests.current.delete(fetchRequest);
            }

            return null;
        }
    };

    const fetchActivity = async () => {
        if (!id) return;

        if (isExampleActivity()) {
            fetchExampleData();
            return;
        }

        if (fetchActivityRequest.current) {
            try {
                await fetchActivityRequest.current.promise;

                return;
            } catch (error) {
                return;
            }
        }

        setState({ isFetchingActivity: true });
        fetchActivityRequest.current = makeCancellable(activities.fetchActivity(id));

        try {
            const data = await fetchActivityRequest.current.promise;

            fetchActivityRequest.current = null;

            if (!data && !auth.isAuthenticated()) {
                navigate('/login');
            }

            setState({ activity: data, isFetchingActivity: false });
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchActivityRequest.current = null;
                setState({ isFetchingActivity: false });
            }
        }
    };

    useEffect(() => {
        fetchActivity();
    }, [id]);

    const initializeIsOwner = () => {
        // Assume not the owner if requisite data are not available
        let owner = false;

        if (nexusUserProfile && activity) {
            owner = nexusUserProfile.id === activity.owner_uuid;
        }

        setState({ isOwner: owner });
    };

    useEffect(() => {
        if (activity) {
            fetchBike();
            // fetch component summaries after setting activity state
            fetchComponentSummaries();
            // this.fetchShockWiz();
            initializeIsOwner();
        }
    }, [activity, isOwner]);

    const deleteActivity = async () => {
        if (!id) return;

        if (deleteActivityRequest.current) {
            try {
                const succeeded = await deleteActivityRequest.current.promise;
                return succeeded;
            } catch (error) {
                return null;
            }
        }

        setState({ isDeleting: true });

        deleteActivityRequest.current = makeCancellable(activities.delete(id));

        try {
            const succeeded = await deleteActivityRequest.current.promise;

            deleteActivityRequest.current = null;

            setState({ isDeleting: false });

            return succeeded;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn(error);
                deleteActivityRequest.current = null;

                setState({ isDeleting: false });
            }
        }

        return null;
    };

    const downloadActivity = async () => {
        if (!id) return;

        if (downloadActivityRequest.current) {
            try {
                await downloadActivityRequest.current.promise;

                return;
            } catch (error) {
                return;
            }
        }

        setState({ isDownloadingActivity: true });

        downloadActivityRequest.current = makeCancellable(activities.download(id));

        try {
            const fileUrl = await downloadActivityRequest.current.promise;

            if (!fileUrl) {
                downloadActivityRequest.current = null;

                setState({ isDownloadingActivity: false, showDownloadedErrorModal: true });

                return;
            }

            const fileLink = document.createElement('a');
            fileLink.href = fileUrl;
            fileLink.setAttribute('download', `${id}.fit`);
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
            window.URL.revokeObjectURL(fileUrl);

            downloadActivityRequest.current = null;
            setState({ isDownloadingActivity: false });
        } catch (error: any) {
            if (!error.isCancelled) {
                downloadActivityRequest.current = null;
                setState({
                    isDownloadingActivity: false,
                    showDownloadedErrorModal: true,
                });
            }
        }
    };

    const renderActivityDownloadErrorModal = () => (
        <ErrorModal
            error={{ message: 'ACTIVITY_DOWNLOAD_ERROR' }}
            isOpen={showDownloadedErrorModal}
            onClose={() => setState({ showDownloadedErrorModal: false })}
        />
    );

    const renderActivitySummary = () => (
        <ActivitySummary
            activity={activity}
            bike={bike}
            deleteActivity={() => deleteActivity()}
            disableEdit={isExampleActivity() || !auth.isAuthenticated()}
            downloadActivity={() => downloadActivity()}
            endTs={endTs}
            gpsComponent={gpsComponents[0]}
            gpsComponentFiltered={gpsComponentsFiltered[0]}
            isFetchingActivity={isFetchingActivity}
            isFetchingBike={isFetchingBike}
            isOwner={isOwner}
            regenerateActivity={() => regenerateActivity()}
            shockWiz={shockWiz}
            startTs={startTs}
            unassignBikeFromActivity={() => unassignBikeFromActivity()}
            updateActivity={(activityUpdates) => updateActivity(activityUpdates)}
            updateActivitySummary={(activitySummary) => updateActivitySummary(activitySummary)}
        />
    );

    const renderRiderPerformance = () => (
        <RiderPerformance
            disableEdit={isExampleActivity() || !auth.isAuthenticated()}
            disableOwnerFeatures={!isOwner}
            gpsComponent={gpsComponentsFiltered[0]}
            heartComponent={heartComponentsFiltered[0]}
            heartZones={heartZones}
            isFetchingActivity={isFetchingActivity}
            isFetchingComponentSummaries={isFetchingComponentSummaries}
            powerComponent={powerComponentsFiltered[0]}
            powerZones={powerZones}
        />
    );

    const renderComponentSummary = () => (
        <ComponentSummary
            activity={activity}
            bike={bike}
            disableEdit={isExampleActivity() || !auth.isAuthenticated()}
            disableOwnerFeatures={!isOwner}
            gearComponents={gearComponentsFiltered}
            gpsComponent={gpsComponentsFiltered[0]}
            isFetchingActivity={isFetchingActivity}
            isFetchingComponentSummaries={isFetchingComponentSummaries}
            powerComponent={powerComponentsFiltered[0]}
            reverbComponent={reverbComponentsFiltered[0]}
            tyreComponents={tyreComponentsFiltered}
            updateActivity={(activityUpdates: { [key: string]: any }) => updateActivity(activityUpdates)}
            updateBike={(bikeUpdates) => updateBike(bikeUpdates)}
        />
    );

    const renderContent = () => {
        if (isFetchingActivity) return null;

        if (!activity) return <NotFoundCard title="ACTIVITY_NOT_FOUND" />;

        return (
            <>
                <ActivityNavigation activity={activity} />
                {renderActivitySummary()}
                {renderComponentSummary()}
                {renderRiderPerformance()}
            </>
        );
    };

    return (
        <ContentContainer style={{ padding: 0 }}>
            {renderActivityDownloadErrorModal()}
            <Spinner
                loading={(
                    isDeleting
                    || isDownloadingActivity
                    || isFetchingActivity
                    || isFetchingComponentSummaries
                    || isRegenerating
                    || isUpdating
                )}
            />
            {renderContent()}
        </ContentContainer>
    );
}

export default Activity;
