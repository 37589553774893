import { useState } from 'react';

import styles from './EditDrivetrainModal.module.scss';

import AssignBikeModal from '../../../../activitySummary/routeSummary/assignBikeModal';

import Button from '../../../../../../components/button';
import Modal from '../../../../../../components/modal';
import Translate from '../../../../../../components/translate';
import { AXS_TEXT_GREY } from '../../../../../../constants';
import { Activity, Bike, ComponentSummary } from '../../../../../../providers';
import { ExtendedBike } from '../../../../../../providers/bikes/types';
import DrivetrainInput from '../../../../../../views/bikeForm/drivetrainInput';

interface EditDrivetrainModalProps {
    activity: Activity;
    bike: Bike;
    gearComponent: ComponentSummary | undefined;
    onCancel: () => void;
    onSave: (bikeUpdates: {
        casette?: {name: string, teeth: number[]}
        chainring?: {name: string, teeth: number[]}
    }) => void;
    open: boolean;
    updateActivity?: (data: {bike_uuid: string}) => void;
}

const EditDrivetrainModal = ({
    activity,
    bike,
    gearComponent,
    onCancel,
    onSave,
    open = false,
    updateActivity,
}: EditDrivetrainModalProps) => {
    const [bikeUpdates, setBikeUpdates] = useState({});

    const onDataChange = (key: string, value: {name: string, teeth: number[]}) => {
        if (!key) return;

        setBikeUpdates({
            ...bikeUpdates,
            [key]: value,
        });
    };

    const onAssign = (assignedBike: ExtendedBike) => {
        if (!assignedBike) return;

        if (assignedBike.chainring.teeth || assignedBike.cassette.teeth) {
            onCancel();
        }
    };

    const getLabelValue = (key: string) => {
        if (bikeUpdates[key as keyof typeof bikeUpdates]) return bikeUpdates[key as keyof typeof bikeUpdates];

        if (bike && bike[key as keyof typeof bike]) return bike[key as keyof typeof bike];

        return '';
    };

    const submitGearingForm = () => {
        if (!Object.keys(bikeUpdates).length) {
            onCancel();
        } else {
            onSave(bikeUpdates);
        }
    };

    const renderGearingForm = () => {
        if (!bike || !bike.id) return null;

        return (
            <>
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        submitGearingForm();
                    }}
                    style={{ margin: 'auto', width: '100%' }}
                >
                    <DrivetrainInput
                        getValue={(...params) => getLabelValue(...params)}
                        numberOfChainrings={gearComponent && gearComponent.data.num_chainrings}
                        numberOfCogs={gearComponent && gearComponent.data.num_cogs}
                        onChange={(...params) => onDataChange(...params)}
                    />
                </form>
                <div className={styles.buttonsContainer}>
                    <Button
                        className={styles.button}
                        color={AXS_TEXT_GREY}
                        inverse
                        onClick={() => {
                            setBikeUpdates({});
                            onCancel();
                        }}
                    >
                        <Translate>CANCEL</Translate>
                    </Button>
                    <Button
                        className={`${styles.button} ${styles.saveButton}`}
                        type="button"
                        onClick={() => submitGearingForm()}
                    >
                        <Translate>SAVE</Translate>
                    </Button>
                </div>
            </>
        );
    };

    const renderHeader = () => (
        <>
            <Translate>DRIVETRAIN_EDIT</Translate>
            <div className={styles.bikeName}>
                {bike && bike.name}
            </div>
        </>
    );

    const renderAssignBike = () => {
        if (bike && bike.id) return null;

        return (
            <AssignBikeModal
                activity={activity}
                onCancel={onCancel}
                onSave={(assignedBike) => onAssign(assignedBike)}
                open
                updateActivity={updateActivity}
            />
        );
    };

    return (
        <Modal
            className={styles.container}
            contentClassName={styles.content}
            contentLabel="This will apply to all ride activities"
            dialog={!bike}
            header={renderHeader()}
            hideCloseButton
            hideImage
            isOpen={open}
            onClose={() => {
                setBikeUpdates({});
                onCancel();
            }}
        >
            {renderGearingForm()}
            {renderAssignBike()}
        </Modal>
    );
};

export default EditDrivetrainModal;
