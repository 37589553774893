import React from 'react';
import { useLocation } from 'react-router-dom';

import styles from './Footer.module.scss';

import {
    logoAxsWhite,
    logoFacebookGray,
    logoInstagram,
    logoTwitter,
    logoYouTube,
} from '../../assets';
import SupportLink from '../../components/support/supportLink';
import Translate from '../../components/translate';
import { COPYRIGHT_YEAR } from '../../constants';

interface FooterProps {
    hideSupportLink?: boolean;
}

const Footer = ({ hideSupportLink }: FooterProps) => {
    const { pathname } = useLocation();
    const excludedPaths = ['/guides/mobile/fullmount/chain/calculator', '/mobile/riderportal'];
    const isExcluded = excludedPaths.some((path) => pathname && pathname.startsWith(path));

    if (isExcluded) return null;

    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <div>
                    <a
                        href="https://axs.sram.com/"
                        rel="noreferrer noopener"
                        target="_blank"
                    >
                        <div className={styles.logoContainer}>
                            <img alt="SRAM AXS" src={logoAxsWhite} />
                        </div>
                    </a>
                    <div className={styles.socialIcons}>
                        <a
                            href="https://www.facebook.com/SRAMUSA/"
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            <img alt="Facebook" src={logoFacebookGray} />
                        </a>
                        <a
                            href="https://www.instagram.com/sramroad/"
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            <img alt="Instagram" src={logoInstagram} />
                        </a>
                        <a
                            href="https://twitter.com/sramroad"
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            <img alt="Twitter" src={logoTwitter} />
                        </a>
                        <a
                            href="https://www.youtube.com/channel/UCwPoOErBToGWC756Rfo2-Og"
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            <img alt="Youtube" src={logoYouTube} />
                        </a>
                    </div>
                </div>
                <div>
                    <ul>
                        <li className={styles.socialMediaTitle}>
                            <Translate>APP_DOWNLOADS</Translate>
                        </li>
                        <li>
                            <a
                                href="https://itunes.apple.com/us/app/sram-axs/id1430049231?ls=1&mt=8"
                                rel="noreferrer noopener"
                                target="_blank"
                            >
                                <Translate>APP_DOWNLOADS_APP_STORE</Translate>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.sram.armyknife"
                                rel="noreferrer noopener"
                                target="_blank"
                            >
                                <Translate>APP_DOWNLOADS_GOOGLE_PLAY</Translate>
                            </a>
                        </li>

                        {!hideSupportLink && (
                            <li>
                                <SupportLink className={styles.socialMediaTitle} />
                            </li>
                        )}
                    </ul>
                </div>
            </div>
            <div className={styles.bottom}>
                <p>
                    <Translate params={{ year: COPYRIGHT_YEAR }}>
                    COPY_RIGHT_NOTICE
                    </Translate>
                </p>
                <a
                    href="https://www.sram.com/privacy-policy"
                    rel="noreferrer noopener"
                    target="_blank"
                >
                    <Translate>POLICIES</Translate>
                </a>
            </div>
        </div>
    );
};

export default Footer;
