import React from 'react';

import { Bike } from '../../../providers';

import styles from './ActivityDropdown.module.scss';

import Dropdown from '../../../views/navbar/dropdown';
import MenuItem from '../../../views/navbar/menuItem';

import { iconMore } from '../../../assets';
import Translate from '../../../components/translate';
import WaffleBlock from '../../../components/waffleBlock';

interface ActivityDropdownProps {
    bike: Bike,
    disableEdit: boolean,
    downloadActivity: () => void,
    dropdownStyle?: Record<string, unknown>,
    id?: string,
    isOwner: boolean,
    onClick: () => void,
    open: boolean,
    regenerateActivity: () => void,
    toggleDeleteModal: () => void,
    toggleEditActivity: () => void,
    toggleShareActivity: () => void,
    unassignBikeFromActivity: () => void,
}

const ActivityDropdown = ({
    bike,
    disableEdit,
    downloadActivity,
    dropdownStyle,
    id,
    isOwner,
    onClick,
    open,
    regenerateActivity,
    toggleDeleteModal,
    toggleEditActivity,
    toggleShareActivity,
    unassignBikeFromActivity,
}: ActivityDropdownProps) => (
    <Dropdown
        buttonContent={(
            <img
                alt="More"
                className={styles.moreIcon}
                src={iconMore}
            />
        )}
        dropdownClassName={styles.dropdown}
        dropdownStyle={dropdownStyle}
        woopraData="activity-dropdown-menu"
        id={id}
        onClick={onClick}
        open={open}
    >
        <MenuItem className="flex">
            <button
                className={styles.actionButton}
                disabled={disableEdit}
                onClick={toggleShareActivity}
                type="button"
                data-woopra-track="activity-share-button"
            >
                <Translate>ACTIVITY_SHARE</Translate>
            </button>
        </MenuItem>
        {isOwner && (
            <>
                <MenuItem className="flex">
                    <button
                        className={styles.actionButton}
                        disabled={disableEdit}
                        onClick={toggleEditActivity}
                        type="button"
                        data-woopra-track="activity-edit-button"
                    >
                        <Translate>ACTIVITY_EDIT</Translate>
                    </button>
                </MenuItem>
                <MenuItem className="flex">
                    <button
                        className={styles.actionButton}
                        disabled={disableEdit}
                        onClick={toggleDeleteModal}
                        type="button"
                        data-woopra-track="activity-delete-button"
                    >
                        <Translate>ACTIVITY_DELETE</Translate>
                    </button>
                </MenuItem>
                {/* <MenuItem className="flex">
                    <button
                        className={styles.actionButton}
                        onClick={() => console.log('Sharing of Activity to be implemented')}
                        type="button"
                    >
                        Share Activity
                    </button>
                </MenuItem> */}
                <WaffleBlock flag="test" showIndicator>
                    <MenuItem className="flex">
                        <button
                            className={styles.actionButton}
                            disabled={disableEdit}
                            onClick={() => {
                                onClick();
                                regenerateActivity();
                            }}
                            type="button"
                            data-woopra-track="activity-regenerate-button"
                        >
                            <Translate>ACTIVITY_REGENERATE</Translate>
                        </button>
                    </MenuItem>
                </WaffleBlock>
                {bike && (
                    <MenuItem className="flex">
                        <button
                            className={styles.actionButton}
                            disabled={disableEdit}
                            onClick={() => {
                                onClick();
                                unassignBikeFromActivity();
                            }}
                            type="button"
                            data-woopra-track="activity-unassign-button"
                        >
                            <Translate>UNASSIGN_BIKE</Translate>
                        </button>
                    </MenuItem>
                )}
            </>
        )}
        <MenuItem className="flex">
            <button
                className={styles.actionButton}
                disabled={disableEdit}
                onClick={() => {
                    onClick();
                    downloadActivity();
                }}
                type="button"
                data-woopra-track="activity-download-button"
            >
                <Translate>ACTIVITY_DOWNLOAD</Translate>
            </button>
        </MenuItem>
    </Dropdown>
);

export default ActivityDropdown;
