import { lazy, Suspense, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';

import AccountLinkingErrorModal from './accountLinkingErrorModal';
import ManageAccount from './manageAccount';
import Notifications from './notifications';
import Profile from './profile';
import styles from './RiderPortal.module.scss';
import Tutorials from './tutorials';

import Fade from '../../components/fade';
import Spinner from '../../components/spinner';
import Translate from '../../components/translate';

const Accounts = lazy(() => import('./accounts'));

const RiderPortal = () => {
    const [linkedAccountErrorMessage, setLinkedAccountErrorMessage] = useState(
        sessionStorage.getItem('linkedServiceAuth0Error'),
    );
    sessionStorage.removeItem('linkedServiceAuth0Error');
    const { tab } = useParams();

    const renderTab = () => {
        switch (tab) {
            case 'communications': return <Notifications />;
            case 'account': return <ManageAccount />;
            case 'profile': return <Profile />;
            case 'tutorials': return <Tutorials />;
            case null:
            case undefined:
                return (
                    <Suspense fallback={<Spinner />}>
                        <Accounts />
                    </Suspense>
                );
            default:
                return <Navigate replace to="/riderportal" />;
        }
    };

    const renderSideMenu = () => (
        <div className={styles.sideMenu}>
            <div className={styles.header} style={{ paddingBottom: '1.5rem' }}>
                <Translate>ACCOUNT_SETTINGS</Translate>
            </div>
            <Link
                className={styles.sideMenuButton}
                id="data-test-connections-tab"
                style={(!tab || tab === 'account') ? {
                    fontWeight: 500,
                } : {}}
                to="/riderportal"
            >
                <Translate>ACCOUNT</Translate>
            </Link>
            <Link
                className={styles.sideMenuButton}
                style={(tab === 'profile') ? {
                    fontWeight: 500,
                } : {}}
                to="/riderportal/profile"
            >
                <Translate>PROFILE_TITLE</Translate>
            </Link>
            <Link
                className={styles.sideMenuButton}
                style={(tab === 'communications') ? {
                    fontWeight: 500,
                } : {}}
                to="/riderportal/communications"
            >
                <Translate>COMMUNICATIONS</Translate>
            </Link>

            <Link
                className={styles.sideMenuButton}
                id="data-test-tutorials-tab"
                style={(tab === 'tutorials') ? {
                    fontWeight: 500,
                } : {}}
                to="/riderportal/tutorials"
            >
                <Translate>TUTORIALS</Translate>
            </Link>
            <Link className={styles.sideMenuButton} to="/releasenotes">
                <Translate>RELEASE_NOTES</Translate>
            </Link>
            <a
                className={styles.sideMenuButton}
                href="https://www.sram.com/en/company/campaigns/sram-rider-support"
                rel="noreferrer noopener"
                target="_blank"
            >
                <Translate>HELP_AND_SUPPORT</Translate>
            </a>
            <a
                className={styles.sideMenuButton}
                href="https://www.sram.com/en/company/legal/global-privacy-policy"
                rel="noreferrer noopener"
                target="_blank"
            >
                <Translate>PRIVACY_POLICY</Translate>
            </a>
        </div>
    );

    return (
        <>
            <AccountLinkingErrorModal
                message={linkedAccountErrorMessage}
                open={!!linkedAccountErrorMessage}
                toggle={() => setLinkedAccountErrorMessage(null)}
            />
            <div className={styles.container}>
                {renderSideMenu()}
                <div className={styles.content}>
                    <Fade CSSTransitionKey={tab || ''}>
                        {renderTab()}
                    </Fade>
                </div>
            </div>
        </>
    );
};

export default RiderPortal;
