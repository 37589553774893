import FullscreenIcon from '@mui/icons-material/Fullscreen';
import React, { useState } from 'react';

import styles from './ExpandChart.module.scss';

import Modal from '../../components/modal';

import { GRID_BREAKPOINTS } from '../../constants';

interface ExpandChartProps {
    children?: React.ReactNode;
    renderModalFooter?: React.ReactNode;
    title: string;
 }

const ExpandChart = ({ children, renderModalFooter, title }: ExpandChartProps) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const body = document.querySelector('body');

    if (body) body.style.overflow = isExpanded ? 'hidden' : '';

    return (
        <div className={styles.content}>
            <div className={styles.expandButtonContainer}>
                <button
                    className={styles.expandButton}
                    onClick={() => setIsExpanded(true)}
                    type="button"
                >
                    <FullscreenIcon />
                </button>
            </div>
            {!isExpanded && children}
            <Modal
                containerStyle={{ content: { maxWidth: GRID_BREAKPOINTS.lg } }}
                contentClassName={styles.modalContent}
                contentLabel={title}
                header={title}
                hideImage
                isOpen={isExpanded}
                onClose={() => setIsExpanded(false)}
            >
                {children}
                {renderModalFooter}
            </Modal>
        </div>
    );
};

export default ExpandChart;
