import { ReactNode } from 'react';

import styles from './Row.module.scss';

import Translate from '../../../components/translate';

interface RowProps {
    children: ReactNode,
    className?: string,
    contentStyle?: any,
    label?: string,
    labelClassName?: string,
    labelStyle?: any,
}

const Row = ({
    children = null,
    className = '',
    contentStyle = {},
    label = '',
    labelClassName = '',
    labelStyle = {},
}: RowProps) => (
    <div className={`${styles.container} ${className}`}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className={`${styles.label} ${labelClassName}`} style={labelStyle}>
            <Translate>{label}</Translate>
        </label>
        <div className={styles.content} style={contentStyle}>
            {children}
        </div>
    </div>
);

export default Row;
