import axios from 'axios';
import { ReactNode, useEffect, useRef } from 'react';
import AccessGroupContext from './AccessGroupContext';
import { useSetState } from '../../hooks';
import Logger from '../../Logger';
import { makeCancellable, RequestType, URL_API } from '../../constants';
import { useAuth } from '../auth';

interface AccessGroupsProviderProps { children: ReactNode }

const URL_GROUPS = `${URL_API}users/self/public_accessgroups/`;

const DEFAULT_STATE = {
    accessGroups: [],
    isFetching: false,
};

function AccessGroupProvider({ children }: AccessGroupsProviderProps) {
    const [state, setState] = useSetState(DEFAULT_STATE);
    const auth = useAuth();

    const { accessGroups } = state;

    const fetchAccessGroupsRequest = useRef<RequestType | null>(null);
    const updateAccessGroupsRequests = useRef(new Set<RequestType | null>());

    const clear = () => {
        if (fetchAccessGroupsRequest.current) {
            fetchAccessGroupsRequest.current.cancel();
        }

        updateAccessGroupsRequests.current.forEach((request) => request?.cancel());
        updateAccessGroupsRequests.current.clear();
    };

    async function fetchAccessGroups() {
        if (fetchAccessGroupsRequest.current) {
            try {
                const { data } = await fetchAccessGroupsRequest.current.promise;
                return data;
            } catch (error) {
                return null;
            }
        }
        setState({ isFetching: true });

        fetchAccessGroupsRequest.current = makeCancellable(axios.get(URL_GROUPS));

        try {
            const { data } = await fetchAccessGroupsRequest.current.promise;
            fetchAccessGroupsRequest.current = null;

            setState({ accessGroups: data, isFetching: false });

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching access groups.');
                fetchAccessGroupsRequest.current = null;
                setState({ isFetching: false });
            }

            return null;
        }
    }

    const updateAccessGroups = async (updates: any) => {
        if (!accessGroups.length) {
            return null;
        }

        const updateRequest = makeCancellable(axios.put(
            `${URL_GROUPS}`,
            updates,
        ));

        updateAccessGroupsRequests.current.add(updateRequest);
        try {
            const { data } = await updateRequest.promise;

            updateAccessGroupsRequests.current.delete(updateRequest);

            setState({ accessGroups: data });

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error updating access groups', error);
                updateAccessGroupsRequests.current.delete(updateRequest);
            }

            return null;
        }
    };

    useEffect(() => {
        if (!auth.accessToken) {
            clear();
            return;
        }

        fetchAccessGroups();
    }, [auth.accessToken]);

    useEffect(() => () => {
        clear();
    }, []);

    return (
        <AccessGroupContext.Provider
            value={{
                accessGroups: {
                    ...state,
                    clear: () => clear(),
                    fetch: () => fetchAccessGroups(),
                    list: accessGroups,
                    updateAccessGroups,
                },
            }}
        >
            {children}
        </AccessGroupContext.Provider>
    );
}

export default AccessGroupProvider;
