import styles from './CheckBox.module.scss';

import Translate from '../translate';

interface CheckBoxProps {
    checked: boolean,
    className?: string,
    disabled?: boolean,
    id?: string,
    label?: string,
    onChange: () => void,
    style?: Record<string, unknown>,
}

const CheckBox = ({
    checked = false,
    className = '',
    disabled = false,
    id = '',
    label = '',
    onChange = () => {},
    style = {},
}: CheckBoxProps) => (
    <label
        className={`${styles.container} ${className}`}
        htmlFor={id || label}
        style={style}
    >
        {!!label && (
            <>
                <Translate>{label}</Translate>
                <div>&nbsp;</div>
            </>
        )}
        <div className={styles.inputContainer}>
            <input
                checked={checked}
                className={styles.defaultInput}
                disabled={disabled}
                id={id || label}
                onChange={onChange}
                type="checkbox"
            />
            <div className={styles.checkbox}>
                <div className={styles.checkmark} />
            </div>
        </div>
    </label>
);

export default CheckBox;
