import axios from 'axios';

import { SERVER, URL_LOGGER } from './constants';

const SEVERITY = {
    DEBUG: 'DEBUG',
    ERROR: 'ERROR',
    INFO: 'INFO',
    WARNING: 'WARNING',
};
class Logger {
    constructor() {
        this.userId = null;
        this.nexusId = null;
    }

    setNexusId(nexusId) {
        this.nexusId = nexusId;
    }

    setUserId(userId) {
        this.userId = userId;
    }

    createData(severity, detail) {
        return {
            detail,
            server: SERVER,
            severity,
            sramid: this.userId,
            ts: new Date().getTime(),
            user: this.nexusId,
        };
    }

    static async postLog(data) {
        try {
            await axios.post(URL_LOGGER, data);
        } catch (error) {
            console.error('Error uploading log', error);
        }
    }

    log(...args) {
        // eslint-disable-next-line no-console
        console.log(...args);

        this.logSilent(...args);
    }

    logSilent(...args) {
        const DATA = this.createData(SEVERITY.INFO, args);
        Logger.postLog(DATA);
    }

    warn(...args) {
        console.warn(...args);

        this.warnSilent(...args);
    }

    warnSilent(...args) {
        const DATA = this.createData(SEVERITY.WARNING, args);
        Logger.postLog(DATA);
    }

    error(...args) {
        console.error(...args);

        this.errorSilent(...args);
    }

    errorSilent(...args) {
        const DATA = this.createData(SEVERITY.ERROR, args);
        Logger.postLog(DATA);
    }
}

const SingletonLogger = new Logger();
export default SingletonLogger;
