import React from 'react';

import styles from './PowerZoneDistribution.module.scss';

import ZoneDistribution from '../zoneDistribution';
import { FtpDisplay } from '../thresholdDisplay';

import BikeComponentLink from '../../bikeComponentLink';

import { ZONE_COLORS_POWER } from '../../../../constants';
import { ComponentSummary } from '../../../../providers';

interface PowerZoneDistributionProps {
    disableEdit?: boolean;
    powerComponent: ComponentSummary;
    powerZones: {min: number, max: number}[] | null;
}

const PowerZoneDistribution = ({
    disableEdit = false, powerComponent, powerZones = null,
}: PowerZoneDistributionProps) => {
    if (!powerComponent || !powerZones) {
        return null;
    }

    return (
        <div className={styles.container}>
            <ZoneDistribution
                data={powerComponent.data.power}
                zoneColors={ZONE_COLORS_POWER}
                zones={powerZones}
            />
            {!disableEdit && (
                <div className={styles.footer}>
                    <FtpDisplay />
                    <BikeComponentLink componentSummary={powerComponent} />
                </div>
            )}
        </div>
    );
};

export default PowerZoneDistribution;
