import { scaleTime } from 'd3';
import { Area, Line, Scale } from 'd3-charts-react';
import React, { useCallback } from 'react';

import { extractTime, extractValue } from '../chartHelpers';

import { SRAM_200, TRANSPARENT } from '../../../constants';
import { ComponentSummary, useUnits } from '../../../providers';

interface TemperatureLineChartProps {
    gpsComponent: ComponentSummary,
    height: number,
    sync: boolean,
    onTemperatureChange: (temperature: number) => void,
    width: number,
}

const TemperatureLineChart = ({
    gpsComponent,
    height,
    sync,
    onTemperatureChange,
    width,
}: TemperatureLineChartProps) => {
    const units = useUnits();
    const valueExtractor = useCallback(
        (
            data: { [x: string]: any; },
            _scale: any,
            _pathRef: any,
            timestamp: { getTime: () => number; },
        ) => {
            if (!timestamp) {
                onTemperatureChange(0);
                return;
            }

            const timestampInSeconds = Math.round(timestamp.getTime() / 1000);

            const dataPointIndex = gpsComponent.data.adjustedTime.findIndex(
                (time: number) => time === timestampInSeconds,
            );
            const temperature = data[dataPointIndex];

            onTemperatureChange(
                temperature
                    ? units.convertTemperatureFromSI(temperature)
                    : 0,
            );
        },
        [onTemperatureChange],
    );

    if (!gpsComponent) {
        return null;
    }

    return (
        <Scale
            height={height}
            width={width}
            xMax={gpsComponent.adjustedEndTs * 1000}
            xMin={gpsComponent.adjustedStartTs * 1000}
            xScale={scaleTime}
            yMax={gpsComponent.data.max_temp + (
                Math.abs(gpsComponent.data.max_temp - gpsComponent.data.min_temp)
                / 0.3
                - Math.abs(gpsComponent.data.max_temp - gpsComponent.data.min_temp)
            )}
            yMin={gpsComponent.data.min_temp - (Math.abs(gpsComponent.data.min_temp) * 0.05)}
        >
            {(scale: any) => (
                <>
                    <Line
                        data={gpsComponent.data.temp}
                        scale={scale}
                        style={{ stroke: TRANSPARENT }}
                        xExtractor={(...params: any) => extractTime(gpsComponent, ...params)}
                        yExtractor={extractValue}
                        valueExtractor={valueExtractor}
                        sync={sync}
                    />
                    <Area
                        data={gpsComponent.data.temp}
                        scale={scale}
                        style={{ fill: SRAM_200, stroke: SRAM_200 }}
                        xExtractor={(...params: any) => extractTime(gpsComponent, ...params)}
                        yExtractor={extractValue}
                    />
                </>
            )}
        </Scale>
    );
};

export default TemperatureLineChart;
