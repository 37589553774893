import React from 'react';

import styles from './RouteTrack.module.scss';

import { ComponentSummary } from '../../../../../providers';

// filter speed array for stationary values
const filterForStationaryValues = (speedArray: number[]) => {
    const stationaryArray = [];
    let tempIndexesArray = [];

    for (let index = 0; index < speedArray.length; index++) {
        // collect index of element where speed value is 0
        if (speedArray[index] === 0) {
            tempIndexesArray.push(index);
        } else if (tempIndexesArray.length > 0) {
            // otherwise check if there are any collected indexes and push to stationary array
            stationaryArray.push(tempIndexesArray);
            // reset temporary array as consecutive stationary value ends
            tempIndexesArray = [];
        }
    }

    return stationaryArray;
};

interface RouteTrackProps {
    gpsComponent: ComponentSummary;
    gpsComponentFiltered: ComponentSummary;
}

const RouteTrack = ({ gpsComponent, gpsComponentFiltered }: RouteTrackProps) => {
    const renderActiveSegment = () => {
        if (
            !gpsComponentFiltered
            || !gpsComponentFiltered.data.filteredPosition
            || !gpsComponentFiltered.data.filteredPosition.length
        ) return null;

        const { ts: gpsStart } = gpsComponentFiltered.data.position[0];
        const { ts: gpsEnd } = gpsComponentFiltered.data.position[gpsComponentFiltered.data.position.length - 1];
        const totalDuration = gpsEnd - gpsStart;

        let start = (gpsComponentFiltered.data.filteredPosition[0].ts - gpsStart);
        start /= totalDuration;
        start *= 100;

        let { ts: end } = gpsComponentFiltered.data.filteredPosition[
            gpsComponentFiltered.data.filteredPosition.length - 1
        ];
        end -= gpsStart;
        end /= totalDuration;
        end *= 100;

        return (
            <div
                className={styles.mapStationaryZone}
                style={{
                    backgroundColor: '#28E523',
                    height: `${end - start}%`,
                    opacity: 0.6,
                    top: `${start}%`,
                }}
            />
        );
    };

    // render on route map where speed is 0
    const renderStationaryZones = () => {
        let stationaryMap: number[][] = [];
        let totalSpeed = 0;
        if (gpsComponent) {
            stationaryMap = filterForStationaryValues(gpsComponent.data.speed);
            totalSpeed = gpsComponent.data.speed.length;
        }

        return stationaryMap.map((zone) => {
            // get starting point and height of stationary zone
            const start = (zone[0] / totalSpeed) * 100;
            const height = (zone.length / totalSpeed) * 100;

            return (
                <div
                    key={start}
                    className={styles.mapStationaryZone}
                    style={{ height: `${height}%`, top: `${start}%` }}
                />
            );
        });
    };

    return (
        <div className={styles.mapContainer}>
            <div className={styles.mapRoute}>
                <div className={styles.mapStartMark} />
                <div className={styles.stationaryMapContainer}>
                    {renderStationaryZones()}
                    {renderActiveSegment()}
                </div>
                <div className={styles.mapFinishMark} />
            </div>
        </div>
    );
};

export default RouteTrack;
