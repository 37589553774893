import {
    arrayOf,
    bool,
    func,
    instanceOf,
    number,
    oneOf,
    oneOfType,
    shape,
    string,
} from 'prop-types';

import { NOTIFICATION_SHAPE } from '../notifications';
import { BIKE_COMPONENT_SHAPE } from '../bikeComponents';

const BIKE_SHAPE = shape({
    archived: bool,
    brand: string,
    component_set: arrayOf(BIKE_COMPONENT_SHAPE),
    data: shape({ retired: string }),
    ebike: bool,
    history_url: string,
    id: string,
    image: string,
    last_history_date: number,
    local_id: string,
    model: string,
    name: string,
    notification_set: arrayOf(NOTIFICATION_SHAPE),
    owner: string,
    owner_id: oneOfType([
        number,
        oneOf(['example']),
    ]),
    owner_url: string,
    purchase_date: instanceOf(Date),
    url: string,
    weight: number,
    year: number,
});

const FUNCTIONS = {
    addBike: func,
    deleteBike: func,
    fetch: func,
    fetchBike: func,
    list: arrayOf(BIKE_SHAPE),
    updateBike: func,
};

const STATE = {
    bikes: arrayOf(BIKE_SHAPE),
    hasMore: bool,
    isFetching: bool,
    page: number,
};

const BIKES_PROP_TYPES = {
    bikes: shape({
        ...STATE,
        ...FUNCTIONS,
    }),
};

export default BIKES_PROP_TYPES;
export { BIKE_SHAPE };
