import React from 'react';

import styles from './EmailSentModal.module.scss';

import Modal from '../../../../components/modal';
import Translate from '../../../../components/translate';

import { iconEmailVerified } from '../../../../assets';

interface EmailSentModalProps {
email: string;
message: string;
open: boolean;
onClose: () => void;
}

const EmailSentModal = ({
    email,
    message,
    open,
    onClose,
}: EmailSentModalProps) => (
    <Modal
        contentClassName={styles.container}
        contentLabel="Email Sent!"
        dialog
        header="EMAIL_SENT"
        imageAlt="Email"
        imageSrc={iconEmailVerified}
        imageStyle={{ margin: 0 }}
        isOpen={!!open}
        onClose={onClose}
    >
        <div className={styles.email}>{email}</div>
        <Translate>{message}</Translate>
    </Modal>
);

export default EmailSentModal;
