import React, { useMemo } from 'react';

import styles from './GearHistogram.module.scss';

import Translate from '../../../../components/translate';
import { ComponentSummary } from '../../../../providers';
import { CUSTOM_GEARING } from '../../../../views/bikeForm/drivetrainInput';
import { ExtendedBike } from '../../../../providers/bikes/types';

interface GearHistogramProps {
    bike: ExtendedBike;
    displayLabel: string | React.ReactNode
    gearComponents: ComponentSummary[];
    renderFDRow: ({
        fdCount, index, label, percentage, value,
    }: {fdCount?: number, index: number, label: string | number, percentage: number, value: number}) => void;
    renderRDRow: ({
        rdCount, index, label, percentage, value,
    }: {rdCount?: number, index: number, label: string | number, percentage: number, value: number}) => void;
}

const GearHistogram = ({
    bike,
    displayLabel,
    gearComponents,
    renderFDRow,
    renderRDRow,
}: GearHistogramProps) => {
    const gearComponent = useMemo(
        () => gearComponents.find(({ data }) => data.ant_component_id === 2),
        [gearComponents],
    );

    // use cog labels instead of index if there is cassette cog values
    let useBikeChainring = false;
    let useBikeCog = false;

    if (bike && gearComponent) {
        useBikeChainring = (
            bike.chainring
            && bike.chainring.teeth
            && bike.chainring.teeth.length >= gearComponent.data.num_chainrings
        );

        useBikeCog = (
            bike.cassette
            && bike.cassette.teeth
            && bike.cassette.teeth.length >= gearComponent.data.rd_histogram.length
        );
    }

    // use either bike gear length or gear component length for histogram
    const fdHistogram = useBikeChainring
        ? bike.chainring.teeth.map((_chainring: number, index: string | number) => (
            gearComponent?.data.fd_histogram[index] || 0
        ))
        : gearComponent?.data.fd_histogram;

    const rdHistogram = useBikeCog
        ? bike.cassette.teeth.map((_cog: number, index: string | number) => (
            gearComponent?.data.rd_histogram[index] || 0
        ))
        : gearComponent?.data.rd_histogram;

    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                <div className={styles.row}>
                    <div>
                        <span className={styles.gearTitle}>
                            <Translate>RING_FRONT</Translate>
                        </span>
                    </div>
                </div>
                <div className={`${styles.row} ${styles.rowLabel}`}>
                    <div className={styles.gearModel}>
                        {((bike && bike.chainring && bike.chainring.name !== CUSTOM_GEARING)
                            ? bike.chainring.name
                            : ''
                        )}
                    </div>
                    <div className={styles.durationLabel}>
                        <Translate>{displayLabel}</Translate>
                    </div>
                </div>
            </div>
            {fdHistogram.map((tally: number, index: number) => {
                const label = useBikeChainring
                    ? `${bike.chainring.teeth[index]}T`
                    : index + 1;

                const percentage = (tally / Math.max(...gearComponent?.data.fd_histogram)) * 100;

                return renderFDRow({
                    fdCount: fdHistogram.length,
                    index,
                    label,
                    percentage,
                    value: tally,
                });
            })}
            <div className={styles.heading}>
                <div className={styles.row}>
                    <div>
                        <span className={styles.gearTitle}>
                            <Translate>COG_REAR</Translate>
                        </span>
                    </div>
                </div>
                <div className={`${styles.row} ${styles.rowLabel}`}>
                    <div className={styles.gearModel}>
                        {((bike && bike.cassette && bike.cassette.name !== CUSTOM_GEARING)
                            ? bike.cassette.name
                            : ''
                        )}
                    </div>
                    <div className={styles.durationLabel}>
                        <Translate>{displayLabel}</Translate>
                    </div>
                </div>
            </div>
            {rdHistogram.map((tally: number, index: number) => {
                const label = useBikeCog
                    ? `${bike.cassette.teeth[index]}T`
                    : index + 1;

                const percentage = (tally / Math.max(...gearComponent?.data.rd_histogram)) * 100;

                return renderRDRow({
                    index,
                    label,
                    percentage,
                    value: tally,
                });
            })}
        </div>
    );
};

export default GearHistogram;
