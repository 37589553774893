import React from 'react';

import styles from './EmailChangedModal.module.scss';

import { iconEmailVerified } from '../../assets';
import Modal from '../../components/modal';
import Translate from '../../components/translate';

interface EmailChangedModalProps {
    onClose: () => void;
    open: boolean;
}

const EmailChangedModal = ({ open, onClose }: EmailChangedModalProps) => (
    <Modal
        contentClassName={styles.container}
        contentLabel="Email Changed!"
        dialog
        header="EMAIL_CHANGED"
        imageSrc={iconEmailVerified}
        imageStyle={{ height: '3rem', margin: 0 }}
        isOpen={!!open}
        onClose={onClose}
    >
        <Translate>EMAIL_CHANGE_SUCCESSFUL</Translate>
    </Modal>
);

export default EmailChangedModal;
