import React from 'react';
import {
    generatePath,
    Navigate,
    Route,
    Routes,
    useLocation,
    useParams,
} from 'react-router-dom';

import WaffleBlock from '../components/waffleBlock';
import Activities from '../pages/activities';
import ActivitiesTable from '../pages/activities/activitiesTable';
import Activity from '../pages/activity';
import ActivityUpload from '../pages/activityUpload';
import Bike from '../pages/bike';
import BikeComponent from '../pages/bikeComponent';
import BikeComponents from '../pages/bikeComponents';
import Bikes from '../pages/bikes';
import NewBike from '../pages/newBike';
import Notifications from '../pages/notifications';
import RiderPortal from '../pages/riderPortal';
import { useNexus } from '../providers/nexus/NexusContext';
import MobileRiderPortal from '../mobilePages/mobileRiderPortal';
import UpdateAccount from '../mobilePages/mobileUpdateAccount/UpdateAccount';
import MobileCommunications from '../mobilePages/mobileCommunications/MobileCommunications';

const AuthenticatedRoutes = () => {
    const { nexusUserProfile } = useNexus();

    return (
        <>
            <Routes location={useLocation()}>
                <Route
                    element={(
                        <WaffleBlock flag="test" BlockedComponent={<Navigate to="/" />}>
                            <ActivitiesTable />
                        </WaffleBlock>
                    )}
                    path="/activities/log"
                />
                <Route element={<ActivityUpload />} path="/activities/upload" />
                <Route element={<Activity />} path="/activities/:id" />
                <Route element={<Activities />} path="/activities/" />

                <Route element={<Bikes />} path="/bikerack" />
                <Route element={<Redirect to="/bikerack/:uuid" />} path="/mobile/bikerack/:uuid" />
                <Route element={<Bike />} path="/bikerack/:uuid" />

                <Route element={<NewBike />} path="/buildbike" />

                <Route element={<Navigate to="/components/:id" />} path="/component/:id" />
                <Route element={<BikeComponent />} path="/components/:id" />
                <Route element={<BikeComponents />} path="/components/" />

                <Route element={<Notifications />} path="/messages" />

                <Route element={<RiderPortal />} path="/riderportal">
                    <Route element={<RiderPortal />} path=":tab" />
                </Route>
                <Route element={<MobileRiderPortal />} path="/mobile/riderportal" />
                <Route element={<UpdateAccount />} path="/mobile/riderportal/updateAccount" />
                <Route element={<MobileCommunications />} path="/mobile/riderportal/communications" />
                <Route
                    element={((nexusUserProfile
                    && nexusUserProfile.user_journey
                    && nexusUserProfile.user_journey.bikeCount)
                        ? <Navigate from="/" to="activities" />
                        : <Navigate from="/" to="bikerack" />
                    )}
                    path="*"
                />
            </Routes>
        </>
    );
};

const Redirect = ({ to }) => {
    const params = useParams();
    const generated = generatePath(to, params);

    return <Navigate to={generated} replace />;
};

export default AuthenticatedRoutes;
