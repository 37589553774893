import React, { useEffect, useState } from 'react';

import styles from './Slider.module.scss';

import StyledSlider from '../../../components/slider';

interface SliderProps {
    className: string,
    max: number,
    min: number,
    onChange: (args: number) => void,
    value: number | string,
    step?: number,
    valueLabelDisplay?: string,
    dataId?: string,
}

const Slider = ({
    className,
    max,
    min,
    onChange,
    value,
    step,
    valueLabelDisplay,
    dataId,
}: SliderProps) => {
    const [sliderValue, setSliderValue] = useState(value);

    useEffect(
        () => {
            let newValue = Number(value);

            if (newValue) {
                if (newValue > max) {
                    newValue = max;
                } else if (newValue < min) {
                    newValue = min;
                }

                onChange(newValue);
            }

            setSliderValue(newValue);
        },
        [max, min, value],
    );

    return (
        <div className={`${styles.container} ${className}`} data-testid={dataId}>
            <StyledSlider
                /* eslint-disable react/jsx-props-no-spreading */
                step={step}
                valueLabelDisplay={valueLabelDisplay}
                max={max}
                min={min}
                onChange={(_event: any, inValue: number) => setSliderValue(inValue)}
                onChangeCommitted={(_event: any, inValue: number) => onChange(inValue)}
                value={sliderValue}
            />
        </div>
    );
};

export default Slider;
