import {
    arrayOf,
    bool,
    func,
    number,
    oneOf,
    oneOfType,
    shape,
    string,
} from 'prop-types';

import { NOTIFICATION_SHAPE } from '../notifications';

const BIKE_COMPONENT_SHAPE = shape({
    ant_id: number,
    bike: string,
    bike_url: string,
    // componentservice_set: []
    data: shape({
        activity_end_ts: number,
        activity_id: number,
        actuations_accumulator: number,
        actuationsAccumulator: number,
        battery_status: number,
        battery_volts: number,
        bootloader: number,
        chainrings: number,
        cogs: number,
        firmware_version: oneOfType([number, string]),
        front_actuations_accumulator: number,
        front_actuations_activity: number,
        hardware: number,
        last_post_ts: number,
        meters_accumulator: number,
        rear_actuations_accumulator: number,
        rear_actuations_activity: number,
        seconds_accumulator: number,
        secondsAccumulator: number,
        softdeviceNum: number,
        softdeviceVer: number,
        updateTimeMs: number,
        versionsTVersion: number,
    }),
    description: string,
    device_group_type: number,
    device_type: number,
    firmware_part_number: string,
    firmware_type: string,
    history_url: string,
    id: oneOfType([number, string]),
    is_bridge_device: bool,
    local_id: string,
    manufacturer: number,
    mobile_display_icon: string,
    mobile_display_icon_mod: string,
    mobile_display_icon_mod_url: string,
    mobile_display_icon_url: string,
    mobile_display_marker: string,
    mobile_display_marker_url: string,
    mobile_display_name_key: string,
    model: number,
    model_code: string,
    model_manufacturer: number,
    name: string,
    network_description: string,
    network_display_icon: string,
    network_display_icon_url: string,
    notification_set: arrayOf(NOTIFICATION_SHAPE),
    owner: oneOfType([number, string]),
    owner_id: oneOfType([
        number,
        oneOf(['example']),
    ]),
    owner_url: string,
    part_description: string,
    published: bool,
    serial: string,
    srambond_image: string,
    srambond_image_url: string,
    url: string,
});

const BIKE_COMPONENTS_PROPS_TYPES = {
    bikeComponents: shape({
        delete: func,
        fetch: func,
        list: arrayOf(BIKE_COMPONENT_SHAPE),
        // registerComponent: func,
        update: func,
    }),
};

const PRODUCT_DETAILS_SHAPE = shape({
    description: string,
    image: string,
    manuals: arrayOf(shape({
        name: string,
        url: string,
    })),
    name: string,
    videos: arrayOf(shape({
        name: string,
        url: string,
    })),
});

export default BIKE_COMPONENTS_PROPS_TYPES;
export { BIKE_COMPONENT_SHAPE, PRODUCT_DETAILS_SHAPE };
