import { useState } from 'react';

import styles from './DeleteActivityModal.module.scss';

import { iconDelete } from '../../../assets';
import Button from '../../../components/button';
import Modal from '../../../components/modal';
import Translate from '../../../components/translate';
import { AXS_TEXT_GREY } from '../../../constants';
import ErrorModal from '../../../views/errorModal';

interface DeleteActivityModalProps {
    deleteActivity: () => Promise<boolean>;
    onCancel: () => void;
    onDelete: () => void;
    open: boolean;
}

const DeleteActivityModal = ({
    deleteActivity,
    onCancel,
    onDelete,
    open,
}: DeleteActivityModalProps) => {
    const [error, setError] = useState<any>(null);

    const triggerDeleteActivity = async () => {
        const succeeded = await deleteActivity();

        if (succeeded) {
            onDelete();
        } else {
            setError(new Error('ACTIVITY_DELETE_ERROR'));
        }
    };

    if (error) {
        return (
            <ErrorModal
                error={error}
                onClose={() => setError(null)}
                onOverlayClick={() => {
                    setError(null);
                    onCancel();
                }}
            />
        );
    }

    return (
        <Modal
            contentClassName={styles.container}
            contentLabel="Delete Activity"
            dialog
            header="ARE_YOU_SURE"
            hideCloseButton
            // Overriding styles needs inline to avoid using !important
            imageStyle={{ height: '5rem', marginBottom: 0, marginTop: 'auto' }}
            imageSrc={iconDelete}
            isOpen={open}
            onOverlayClick={onCancel}
        >
            <Translate>ACTIVITY_DELETE_WARNING</Translate>
            <div className={styles.buttonContainer}>
                <Button color={AXS_TEXT_GREY} inverse onClick={onCancel} woopraData="activity-delete-cancel">
                    <Translate>CANCEL</Translate>
                </Button>
                <Button onClick={() => triggerDeleteActivity()} woopraData="activity-delete-confirm">
                    <Translate>DELETE</Translate>
                </Button>
            </div>
        </Modal>
    );
};

export default DeleteActivityModal;
