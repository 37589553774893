import React, { useMemo, useState } from 'react';

import styles from './GearUsage.module.scss';

import BikeComponentLink from '../../bikeComponentLink';
import GearHistogram, {
    EditDrivetrain,
    filterCassetteArrayByChainring,
    gearMapStatistic,
    GearRow,
    mapGearArrayToHistogram,
    tallyStatisticsByCassetteIndexes,
} from '../gearHistogram';

import ToggleSelector from '../../../../components/toggleSelector';
import Translate from '../../../../components/translate';
import { SRAM_300, SRAM_700, SRAM_RED } from '../../../../constants';
import { useUnits } from '../../../../providers/units/UnitsContext';
import { Activity, Bike, ComponentSummary } from '../../../../providers';
import { BikeUpdates } from '../../../../providers/bikes/types';

interface GearUsageProps {
    activity: Activity,
    bike: Bike,
    disableEdit?: boolean,
    gearComponents: ComponentSummary[],
    gpsComponent: ComponentSummary,
    updateActivity?: (activity: Activity) => void,
    updateBike?: (bikeUpdates: BikeUpdates) => void,
}

const GearUsage = ({
    activity,
    bike,
    disableEdit,
    gearComponents,
    gpsComponent,
    updateActivity = () => {},
    updateBike = () => {},
}: GearUsageProps) => {
    const units = useUnits();
    const [showTime, setShowTime] = useState(true);
    const [showToggle, setShowToggle] = useState(false);
    const [fdFilterIndex, setFdFilterIndex] = useState(null);

    const gearComponent = useMemo(
        () => gearComponents.find(({ data }) => data.ant_component_id === 2),
        [gearComponents],
    );

    const distanceHistogram = useMemo(
        () => {
            let fdDistanceTemp = [];
            let rdDistanceTemp = [];

            if (gearComponent && gpsComponent) {
                fdDistanceTemp = gearMapStatistic(
                    gearComponent.data.fd_gear,
                    gpsComponent.data.speed,
                    (_value, index) => gearComponent.data.adjustedTime[index],
                    (_value, index) => gpsComponent.data.adjustedTime[index],
                ).map(({ tally }: {tally: number}) => tally);

                rdDistanceTemp = gearMapStatistic(
                    gearComponent.data.rd_gear,
                    gpsComponent.data.speed,
                    (_value, index) => gearComponent.data.adjustedTime[index],
                    (_value, index) => gpsComponent.data.adjustedTime[index],
                ).map(({ tally }: {tally: number}) => tally);

                setShowToggle(true);
            }

            return { fd_histogram: fdDistanceTemp, rd_histogram: rdDistanceTemp };
        },
        [gearComponent, gpsComponent],
    );

    const timeHistogram = useMemo(
        () => {
            let fdTimeTemp = [];
            let rdTimeTemp = [];

            if (gearComponent && gpsComponent) {
                fdTimeTemp = gearMapStatistic(
                    gearComponent.data.fd_gear,
                    gpsComponent.data.speed,
                    (_value, index) => gearComponent.data.adjustedTime[index],
                    (_value, index) => gpsComponent.data.adjustedTime[index],
                    (speed) => (speed ? 1 : 0),
                ).map(({ tally }: {tally: number}) => tally);

                rdTimeTemp = gearMapStatistic(
                    gearComponent.data.rd_gear,
                    gpsComponent.data.speed,
                    (_value, index) => gearComponent.data.adjustedTime[index],
                    (_value, index) => gpsComponent.data.adjustedTime[index],
                    (speed) => (speed ? 1 : 0),
                ).map(({ tally }: {tally: number}) => tally);
            }

            return { fd_histogram: fdTimeTemp, rd_histogram: rdTimeTemp };
        },
        [gearComponent, gpsComponent],
    );

    const activeRDHistogram = useMemo(
        () => {
            let tempRdHistogram: any = [];

            if (!gearComponent) {
                return tempRdHistogram;
            }

            if (!showTime) {
                tempRdHistogram = distanceHistogram.rd_histogram;

                if (fdFilterIndex !== null && gpsComponent) {
                    const filteredArrayIndexes = filterCassetteArrayByChainring(
                        gearComponent.data.rd_gear,
                        fdFilterIndex,
                        gearComponent.data.fd_gear,
                        true,
                    );

                    const tallies: { [key: string]: any } = tallyStatisticsByCassetteIndexes(
                        filteredArrayIndexes,
                        gearComponent.data.rd_gear,
                        tempRdHistogram,
                        gpsComponent.data.speed,
                    );

                    tempRdHistogram = Object.keys(tallies).map((tallyKey) => tallies[tallyKey].tally);
                }
            } else {
                tempRdHistogram = gpsComponent ? timeHistogram.rd_histogram : gearComponent.data.rd_histogram;

                if (fdFilterIndex !== null) {
                    const filteredArrayIndexes = filterCassetteArrayByChainring(
                        gearComponent.data.rd_gear,
                        fdFilterIndex,
                        gearComponent.data.fd_gear,
                        true,
                    );

                    // tally by 1 for positives in extractor function
                    tempRdHistogram = mapGearArrayToHistogram(
                        gearComponent.data.rd_gear,
                        filteredArrayIndexes,
                        tempRdHistogram,
                        gpsComponent ? gpsComponent.data.speed : [],
                    );
                }
            }
            return tempRdHistogram;
        },
        [
            distanceHistogram,
            fdFilterIndex,
            gearComponent,
            gpsComponent,
            showTime,
            timeHistogram,
        ],
    );

    const filterIsActive = (fdFilterIndex !== null);

    return (
        <>
            <GearHistogram
                bike={bike}
                displayLabel={(showTime
                    ? 'TIME_GEAR'
                    : (
                        <>
                            <Translate>DISTANCE</Translate>
                            &nbsp;(
                            <Translate>{units.getLabelDistance().shorthand}</Translate>
                            )
                        </>
                    )
                )}
                gearComponents={gearComponents}
                renderFDRow={({
                    fdCount,
                    index,
                    label,
                    percentage,
                }: any) => {
                    let displayValue = null;
                    let displayPercentage = percentage;

                    if (showTime) {
                        displayValue = units.formatDuration(timeHistogram.fd_histogram[index]);
                    } else if (distanceHistogram.fd_histogram[index]) {
                        displayValue = distanceHistogram.fd_histogram[index];
                        displayPercentage = (displayValue / Math.max(...distanceHistogram.fd_histogram)) * 100;
                        displayValue = units.formatDistance(units.convertDistanceFromSI(displayValue));
                    }

                    const isActiveIndex = (fdFilterIndex === index);
                    const isInactiveIndex = (filterIsActive && !isActiveIndex);
                    const textStyle = isInactiveIndex ? styles.inactive : null;
                    const color = isInactiveIndex ? SRAM_300 : null;

                    return (
                        <button
                            className={styles.clickableRow}
                            disabled={(fdCount < 2)}
                            key={index}
                            onClick={() => setFdFilterIndex(isActiveIndex ? null : index)}
                            type="button"
                        >
                            <GearRow
                                color={color || ''}
                                durationClassName={textStyle || ''}
                                key={index}
                                label={label}
                                labelClassName={textStyle || ''}
                                percentage={displayPercentage}
                                value={displayValue}
                            />
                        </button>
                    );
                }}
                // eslint-disable-next-line react/no-unused-prop-types
                renderRDRow={({ index, label }: {index: number, label: string | number}) => {
                    let displayValue = activeRDHistogram[index];
                    let displayPercentage = ((displayValue / Math.max(...activeRDHistogram)) * 100);

                    displayValue = showTime
                        ? units.formatDuration(displayValue)
                        : units.formatDistance(units.convertDistanceFromSI(displayValue));

                    if (!Number.isFinite(displayPercentage)) {
                        displayPercentage = 0;
                    }

                    return (
                        <GearRow
                            color={SRAM_RED}
                            key={index}
                            label={label}
                            percentage={displayPercentage}
                            value={displayValue}
                        />
                    );
                }}
            />

            <div className={styles.footer}>
                {showToggle && (
                    <div className={styles.toggle}>
                        <ToggleSelector
                            activeButton={showTime}
                            buttonClassName={styles.button}
                            buttons={[
                                { key: 'Time', label: 'TIME', value: true },
                                { key: 'Distance', label: 'DISTANCE', value: false },
                            ]}
                            color={SRAM_700}
                            id="data-test-gear-usage-toggle"
                            onToggle={setShowTime}
                        />
                    </div>
                )}
                {!disableEdit && (
                    <div className={styles.editDrivetrain}>
                        <EditDrivetrain
                            activity={activity}
                            bike={bike}
                            disableEdit={disableEdit}
                            gearComponent={gearComponent}
                            updateActivity={updateActivity as (data: {bike_uuid: string}) => void}
                            updateBike={updateBike}
                        />
                    </div>
                )}
                {!disableEdit && <BikeComponentLink componentSummary={gearComponent} />}
            </div>
        </>
    );
};

export default GearUsage;
