import React from 'react';
import styles from './MobileContainer.module.scss';

interface MobileContainerProps{ className?: string, children: React.ReactNode, id?: string }

const MobileContainer = ({ className = '', id, ...otherProps }: MobileContainerProps) => (
    <div
        id={id}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
        className={`${styles.container} ${className}`}
    />
);

export default MobileContainer;
