import {
    GoogleMap,
    Marker,
    MarkerClusterer,
    Polyline,
} from '@react-google-maps/api';
import React, { memo, useState } from 'react';

import styles from './Map.module.scss';

import {
    iconMapPinStart,
    iconMapPinStop,
    iconMarkerBobbing,
    iconMarkerBottoming,
    iconMarkerBounce,
    iconMarkerJump,
    iconMarkerLargeBump,
    iconMarkerPacking,
} from '../../../../assets';
import { DEVICE_TYPES, RED } from '../../../../constants';
import { Activity, ComponentSummary } from '../../../../providers';

interface MapProps {
    activity: Activity,
    gpsComponent: ComponentSummary,
    shockWiz: any,
}

const getGpsPoint = (timeStamp: number, gpsComponent: ComponentSummary) => {
    const gpsIndex = Math.min(
        Math.round(timeStamp / 1000) - gpsComponent.data.time[0],
        gpsComponent.data.time.length - 1,
    );

    return gpsComponent.data.position[gpsIndex];
};

// [TODO] - Add Map types to replace any is Maps https://www.npmjs.com/package/@types/google.maps
// [TODO] - Remove ShockWiz implementation if it is not in use
const Map = memo(({ activity, gpsComponent, shockWiz }: MapProps) => {
    const [map, setMap] = useState<any>(null);

    if (activity.componentsummary_set.every(({ device_type }) => (device_type !== DEVICE_TYPES.gps))) {
        return null;
    }

    if (!gpsComponent || !gpsComponent.data.time.length) {
        return <div className={styles.container} />;
    }

    const center = {
        lat: gpsComponent.data.lat.reduce((total: number, inLat: number) => (
            total + inLat
        )) / gpsComponent.data.lat.length,
        lng: gpsComponent.data.long.reduce((total: number, inLong: number) => (
            total + inLong
        )) / gpsComponent.data.long.length,
    };

    const bobbingMarkerIcon = {
        anchor: new window.google.maps.Point(0, 50),
        origin: new window.google.maps.Point(0, 0),
        size: new window.google.maps.Size(50, 50),
        url: iconMarkerBobbing,
    };

    const bottomingMarkerIcon = {
        anchor: new window.google.maps.Point(0, 50),
        origin: new window.google.maps.Point(0, 0),
        size: new window.google.maps.Size(50, 50),
        url: iconMarkerBottoming,
    };

    const bounceMarkerIcon = {
        anchor: new window.google.maps.Point(0, 50),
        origin: new window.google.maps.Point(0, 0),
        size: new window.google.maps.Size(50, 50),
        url: iconMarkerBounce,
    };

    const jumpMarkerIcon = {
        anchor: new window.google.maps.Point(0, 50),
        origin: new window.google.maps.Point(0, 0),
        size: new window.google.maps.Size(50, 50),
        url: iconMarkerJump,
    };

    const largeBumpMarkerIcon = {
        anchor: new window.google.maps.Point(0, 50),
        origin: new window.google.maps.Point(0, 0),
        size: new window.google.maps.Size(50, 50),
        url: iconMarkerLargeBump,
    };

    const packingMarkerIcon = {
        anchor: new window.google.maps.Point(0, 50),
        origin: new window.google.maps.Point(0, 0),
        size: new window.google.maps.Size(50, 50),
        url: iconMarkerPacking,
    };

    return (
        <GoogleMap
            center={center}
            mapContainerClassName={styles.container}
            options={{ streetViewControl: false }}
            onLoad={(inMap: any) => {
                const bounds = new window.google.maps.LatLngBounds();
                gpsComponent.data.position.forEach((position: any) => {
                    bounds.extend(position);
                });

                inMap.fitBounds(bounds);
                setMap(inMap);
            }}
            zoom={15}
        >
            <MarkerClusterer maxZoom={20}>
                {(clusterer) => shockWiz && (
                    <>
                        {shockWiz.sw_event_bobbing.map((point: any) => (
                            <Marker
                                clusterer={clusterer}
                                icon={bobbingMarkerIcon}
                                key={point.ts}
                                position={getGpsPoint(point.ts, gpsComponent)}
                            />
                        ))}
                        {shockWiz.sw_event_bottoming.map((point: any) => (
                            <Marker
                                clusterer={clusterer}
                                icon={bottomingMarkerIcon}
                                key={point.ts}
                                position={getGpsPoint(point.ts, gpsComponent)}
                            />
                        ))}
                        {shockWiz.sw_event_bounce.map((point: any) => (
                            <Marker
                                clusterer={clusterer}
                                icon={bounceMarkerIcon}
                                key={point.ts}
                                position={getGpsPoint(point.ts, gpsComponent)}
                            />
                        ))}
                        {shockWiz.sw_event_jump.map((point: any) => (
                            <Marker
                                clusterer={clusterer}
                                icon={jumpMarkerIcon}
                                key={point.ts}
                                position={getGpsPoint(point.ts, gpsComponent)}
                            />
                        ))}
                        {shockWiz.sw_event_large_bump.map((point: any) => (
                            <Marker
                                clusterer={clusterer}
                                icon={largeBumpMarkerIcon}
                                key={point.ts}
                                position={getGpsPoint(point.ts, gpsComponent)}
                            />
                        ))}
                        {shockWiz.sw_event_packing.map((point: any) => (
                            <Marker
                                clusterer={clusterer}
                                icon={packingMarkerIcon}
                                key={point.ts}
                                position={getGpsPoint(point.ts, gpsComponent)}
                            />
                        ))}
                    </>
                )}
            </MarkerClusterer>
            {gpsComponent.data.position.length && (
                <Marker icon={iconMapPinStart} position={gpsComponent.data.position[0]} />
            )}
            {gpsComponent.data.position.length && (
                <Marker
                    icon={iconMapPinStop}
                    position={gpsComponent.data.position[gpsComponent.data.position.length - 1]}
                />
            )}
            <Polyline
                onClick={() => {
                    const bounds = new window.google.maps.LatLngBounds();
                    gpsComponent.data.position.forEach((position: any) => {
                        bounds.extend(position);
                    });

                    map.fitBounds(bounds);
                }}
                path={gpsComponent.data.position}
                options={{ strokeColor: RED }}
            />
            {gpsComponent.data.filteredPosition && (
                <Polyline
                    key={`${gpsComponent.adjustedStartTs}-${gpsComponent.adjustedEndTs}`}
                    onClick={() => {
                        const bounds = new window.google.maps.LatLngBounds();
                        gpsComponent.data.filteredPosition.forEach((position: any) => {
                            bounds.extend(position);
                        });

                        map.fitBounds(bounds);
                    }}
                    onLoad={() => {
                        const bounds = new window.google.maps.LatLngBounds();
                        gpsComponent.data.filteredPosition.forEach((position: any) => {
                            bounds.extend(position);
                        });

                        map.fitBounds(bounds);
                    }}
                    onUnmount={() => {
                        const bounds = new window.google.maps.LatLngBounds();

                        gpsComponent.data.position.forEach((position: any) => {
                            bounds.extend(position);
                        });

                        map.fitBounds(bounds);
                    }}
                    path={gpsComponent.data.filteredPosition}
                    options={{ strokeColor: '#00FF00', zIndex: 1 }}
                />
            )}
        </GoogleMap>
    );
});

export default Map;
