import React from 'react';
import { Link } from 'react-router-dom';
import { Moment } from 'moment';
import styles from './ActivitiesFilterModal.module.scss';

import FilterHeader from './filterHeader';

import CheckboxForm from '../../../views/checkboxForm';
import CollapsablePanel from '../../../components/collapsablePanel';
import DateRangePicker from '../../../components/dateRangePicker';
import Drawer from '../../../components/drawer';
import RangeSlider from '../../../components/rangeSlider';
import Translate from '../../../components/translate';
import { useSetState } from '../../../hooks';
import { useBikes, useUnits } from '../../../providers';

const DEFAULT_STATE = {
    activityDistanceOpen: false,
    activityDurationOpen: false,
    activityTypeOpen: false,
    bikesOpen: false,
    dateRangeOpen: false,
    stagedFilters: {
        bikeFilters: null,
        newDistanceFilters: [0, 200],
        newDurationFilters: [0, 24],
        newFromDate: undefined,
        newToDate: undefined,
        typeFilters: null,
        unAssigned: undefined,
    },
};

interface ActivitiesFilterModalProps {
    activityTypes: {label: string, value: string}[],
    applyFilters: (value: {[key:string]: number[] | string}) => void,
    distanceFilters: number[],
    durationFilters: number[],
    fromDate: Date | Moment | null,
    isOpen: boolean,
    onCancel: () => void,
    selectedBikesFilter: string[] | number[],
    selectedTypeFilter: string[],
    toDate: Date | Moment | null,
}

function getFilterkey(by: string) {
    switch (by) {
        case 'bike':
            return 'bikeFilters';
        case 'distance':
            return 'newDistanceFilters';
        case 'duration':
            return 'newDurationFilters';
        case 'from':
            return 'newFromDate';
        case 'to':
            return 'newToDate';
        case 'type':
            return 'typeFilters';
        default:
            return by;
    }
}

const ActivitiesFilterModal = ({
    applyFilters,
    onCancel,
    activityTypes,
    selectedTypeFilter,
    selectedBikesFilter,
    fromDate,
    toDate,
    distanceFilters,
    durationFilters,
    isOpen,
}: ActivitiesFilterModalProps) => {
    const [state, setState] = useSetState({
        ...DEFAULT_STATE,
        stagedFilters: {
            bikeFilters: selectedBikesFilter,
            newDistanceFilters: distanceFilters,
            newDurationFilters: durationFilters,
            newFromDate: fromDate,
            newToDate: toDate,
            typeFilters: selectedTypeFilter,
        },
    });
    const bikes = useBikes();
    const { getLabelDistance } = useUnits();

    const {
        activityDistanceOpen,
        activityDurationOpen,
        activityTypeOpen,
        bikesOpen,
        dateRangeOpen,
        stagedFilters,
    } = state;

    function applySelectedFilters() {
        const {
            bikeFilters,
            newDistanceFilters,
            newDurationFilters,
            newFromDate,
            newToDate,
            typeFilters,
        } = stagedFilters;

        const stagedFiltersObject = {
            bikeFilters,
            distanceFilters: newDistanceFilters,
            durationFilters: newDurationFilters,
            fromDate: newFromDate,
            toDate: newToDate,
            typeFilters,
        };

        applyFilters(stagedFiltersObject);

        onCancel();
    }

    function stageClearAll() {
        setState({
            stagedFilters: {
                bikeFilters: [],
                newDistanceFilters: [0, 200],
                newDurationFilters: [0, 24],
                newFromDate: null,
                newToDate: null,
                typeFilters: [],
            },
        });
    }

    function stageFilters(by: string, filters: (string | number)[] | Moment | string) {
        const filterKey = getFilterkey(by);
        setState({
            stagedFilters: {
                ...stagedFilters,
                [filterKey]: filters,
            },
        });
    }

    function renderActivityTypesFilter() {
        return (
            <CollapsablePanel
                containerClassName={styles.collapsableContainer}
                label={(<FilterHeader heading="ACTIVITY_TYPE" isOpen={activityTypeOpen} />)}
                onToggle={(newOpen) => setState({ activityTypeOpen: newOpen })}
                openOnMount={activityTypeOpen}
            >
                <div className={styles.content}>
                    <CheckboxForm
                        checked={stagedFilters.typeFilters}
                        onChange={(filter: (string | number)[]) => stageFilters('type', filter)}
                        options={activityTypes}
                    />
                </div>
            </CollapsablePanel>
        );
    }

    function renderBikesFilter() {
        const bikesList = bikes.list.map((bike) => ({ label: bike.name, value: bike.id }));

        return (
            <CollapsablePanel
                containerClassName={styles.collapsableContainer}
                label={(<FilterHeader heading="BIKES" isOpen={bikesOpen} />)}
                onToggle={(newOpen) => setState({ bikesOpen: newOpen })}
                openOnMount={bikesOpen}
            >
                <div className={styles.content}>
                    <CheckboxForm
                        checked={stagedFilters.bikeFilters}
                        onChange={(filter: (string | number)[]) => stageFilters('bike', filter)}
                        options={bikesList}
                    />
                    {!bikesList.length && (
                        <div className={styles.noBikesContainer}>
                            <div className={styles.noBikesRow}>
                                <Translate>NO_BIKES</Translate>
                            </div>
                            <div className={styles.noBikesRow}>
                                <Link className={styles.createBike} to="/buildbike">
                                    <Translate>BIKE_ADD_NEW</Translate>
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </CollapsablePanel>
        );
    }

    function renderDatesFilter() {
        return (
            <CollapsablePanel
                containerClassName={styles.collapsableContainer}
                label={(<FilterHeader heading="DATE_RANGE" isOpen={dateRangeOpen} />)}
                onToggle={(newOpen) => setState({ dateRangeOpen: newOpen })}
                openOnMount={dateRangeOpen}
            >
                <div className={styles.content}>
                    <DateRangePicker
                        end={stagedFilters.newToDate}
                        hideClear
                        // onClear={() => stageClearDates()}
                        onSave={((filters) => {
                            Object.keys(filters).forEach((filter) => {
                                stageFilters(filter, filters[filter] as Moment);
                            });
                        })}
                        start={stagedFilters.newFromDate}
                    />
                </div>
            </CollapsablePanel>
        );
    }

    function renderDistanceFilter() {
        return (
            <CollapsablePanel
                containerClassName={styles.collapsableContainer}
                label={(<FilterHeader heading="DISTANCE" isOpen={activityDistanceOpen} />)}
                onToggle={(newOpen) => setState({ activityDistanceOpen: newOpen })}
                openOnMount={activityDistanceOpen}
            >
                <div className={`${styles.content} ${styles.slider}`}>
                    <RangeSlider
                        max={200}
                        min={0}
                        onChange={(values) => stageFilters('distance', values)}
                        rangeValues={stagedFilters.newDistanceFilters}
                        units={getLabelDistance().shorthand}
                    />
                </div>
            </CollapsablePanel>
        );
    }

    function renderDurationFilter() {
        return (
            <CollapsablePanel
                containerClassName={styles.collapsableContainer}
                label={(<FilterHeader heading="DURATION" isOpen={activityDurationOpen} />)}
                onToggle={(newOpen) => setState({ activityDurationOpen: newOpen })}
                openOnMount={activityDurationOpen}
            >
                <div className={`${styles.content} ${styles.slider}`}>
                    <RangeSlider
                        max={24}
                        min={0}
                        onChange={(values) => stageFilters('duration', values)}
                        rangeValues={stagedFilters.newDurationFilters}
                        units="HOURS"
                    />
                </div>
            </CollapsablePanel>
        );
    }

    return (
        <Drawer
            fullScreen
            isOpen={isOpen}
            onClick={() => applySelectedFilters()}
            onClose={onCancel}
            showButton
            showHeader
        >
            <div className={styles.subheaderContainer}>
                <div className={styles.subheaderLabel}>
                    <Translate>FILTERS</Translate>
                </div>
                <button
                    className={styles.clearButton}
                    onClick={() => stageClearAll()}
                    type="button"
                >
                    <Translate>CLEAR</Translate>
                </button>
            </div>
            {renderActivityTypesFilter()}
            {renderBikesFilter()}
            {renderDistanceFilter()}
            {renderDurationFilter()}
            {renderDatesFilter()}
        </Drawer>
    );
};

export default (ActivitiesFilterModal);
