import React from 'react';

import styles from './InlineStatistic.module.scss';

import Translate from '../../../../components/translate';

interface InlineStatisticProps {
    disableBorder?: boolean;
    label?: string;
    labelParams?: Record<string, unknown>;
    value: string | number | React.ReactNode;
}

const InlineStatistic = ({
    disableBorder,
    label,
    labelParams,
    value,
}: InlineStatisticProps) => (
    <div className={`${styles.row} ${disableBorder ? styles.border : ''}`}>
        <div className={styles.label}>
            <Translate params={labelParams}>
                {label}
            </Translate>
        </div>
        <div className={styles.value}>
            {value}
        </div>
    </div>
);

export default InlineStatistic;
