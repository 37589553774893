import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ContentContainer from '../../components/contentContainer';
import styles from './fullMountChainLengthCalculator.module.scss';
import Translate from '../../components/translate';
import DesktopContainer from '../../components/desktopContainer';
import { BLACK } from '../../constants';
import CustomChain from './customChain';
import MTBFlow from './MTBFlow';
import { bikeBrandType } from './MTBFlow/types';

interface StateTypes {
    bikeType: string;
    driveTrainType: string;
    openDataUnavailableModal: boolean;
}

function FullMountChainLengthCalculator() {
    const [state, setState] = useState<StateTypes>({
        bikeType: '',
        driveTrainType: '',
        openDataUnavailableModal: false,
    });
    const [bikeBrands, setBikeBrands] = useState<bikeBrandType[] | null>(null);

    useEffect(() => {
        setState({
            bikeType: state.bikeType,
            driveTrainType: '',
            openDataUnavailableModal: false,
        });
    }, [state.bikeType]);

    function renderOptions(sectionHeader: string, btn1Text: string, btn2Text: string, key: string) {
        return (
            <ContentContainer className={styles.optionsContainer}>
                <div className={styles.row}>
                    <div className={styles.selector}>
                        <div className={styles.label}>
                            <Translate>{sectionHeader}</Translate>
                        </div>
                        <div className={styles.selectionButtonContainer}>
                            <button
                                className={styles.selectionButton}
                                onClick={() => setState({ ...state, [key]: btn1Text })}
                                type="button"
                                style={{
                                    backgroundColor:
                                    state[key as keyof typeof state] === btn1Text ? BLACK : 'transparent',
                                    color: state[key as keyof typeof state] === btn1Text ? 'white' : BLACK,
                                }}
                            >
                                <Translate>{btn1Text}</Translate>
                            </button>
                            <button
                                className={styles.selectionButton}
                                onClick={() => {
                                    setState({ ...state, [key]: btn2Text });
                                }}
                                type="button"
                                style={{
                                    backgroundColor:
                                    state[key as keyof typeof state] === btn2Text ? BLACK : 'transparent',
                                    color: state[key as keyof typeof state] === btn2Text ? 'white' : BLACK,
                                }}
                            >
                                <Translate>{btn2Text}</Translate>
                            </button>
                        </div>
                    </div>
                </div>
            </ContentContainer>
        );
    }

    return (
        <>
            <Helmet>
                <title>Full Mount Chain and Setup Key Guide</title>
                <meta
                    name="description"
                    content="Calculate the perfect chain length for your
                    Eagle Transmission with the Eagle AXS™ Transmission Chain and Setup Key Guide."
                />
            </Helmet>
            <ContentContainer className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.headerDescription}>
                        <div className={styles.title}>
                            <Translate>FULL_MOUNT_CHAIN_LENGTH_GUIDE</Translate>
                        </div>
                        <DesktopContainer className={styles.description}>
                            <Translate>FULL_MOUNT_CHAIN_CALC_DESC</Translate>
                        </DesktopContainer>

                    </div>
                </div>
                {renderOptions('BIKE_TYPE', 'DROP_BAR', 'MTB/E-MTB', 'bikeType')}
                {state.bikeType === 'DROP_BAR' && (
                    renderOptions('DRIVETRAIN_TYPE', 'XPLR', 'EAGLE_TRANSMISSION', 'driveTrainType'))}
                {state.bikeType === 'MTB/E-MTB' && (
                    <MTBFlow bikeModelBrands={bikeBrands} setBikeBrands={setBikeBrands} />)}
                {state.driveTrainType !== '' && <CustomChain driveTrainType={state.driveTrainType} />}
            </ContentContainer>
        </>
    );
}

export default FullMountChainLengthCalculator;
