import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import Spinner from '../spinner';

import { useAuth } from '../../providers/auth/AuthContext';

const Login = () => {
    const auth = useAuth();
    const isAuthenticated = auth.isAuthenticated();
    const location = useLocation();

    useEffect(
        () => {
            if (auth.isLoggingIn || isAuthenticated) return;

            const callAsyncInEffect = async () => {
                const accessToken = await auth.fetchAccessToken();
                if (accessToken) return;

                switch (location.pathname) {
                    case '/':
                    case '/login':
                    case '/logout':
                        localStorage.removeItem('callbackRedirectPathname');
                        break;
                    default:
                        localStorage.setItem('callbackRedirectPathname', location.pathname);
                        break;
                }

                auth.login();
            };

            callAsyncInEffect();
        },
        [auth.isLoggingIn, isAuthenticated],
    );

    if (!isAuthenticated) return <Spinner />;

    return <Navigate replace to="/" />;
};

export default Login;
