import { BikeComponent } from './types';

export default function parseBikeComponent(bikeComponent: BikeComponent) {
    if (bikeComponent.data) {
        return bikeComponent;
    }

    return {
        ...bikeComponent,
        data: {},
    };
}
