import React from 'react';
import Modal from '../../../components/modal';
import styles from './modals.module.scss';
import Translate from '../../../components/translate';

interface DataUnavailableModalProps {
    open: boolean,
    onClose: () => void,
    onConfirm: () => void,
    header: string,
    infoText: string,
    options: boolean
}

const DataUnavailableModal = ({
    open, onClose, onConfirm, header, infoText, options,
}: DataUnavailableModalProps) => (
    <Modal
        className={styles.unavailableModalContainer}
        contentClassName={styles.infoContent}
        headerStyle={{ color: 'white', fontSize: '1.5rem' }}
        contentLabel="InfoModal"
        isOpen={open}
        header={header}
        hideImage
        onClose={() => onClose()}
        containerStyle={{ padding: 'none' }}
        hideCloseButton
    >

        <div className={styles.infoText}>
            <Translate>{infoText}</Translate>
        </div>
        <button
            className={`${styles.button}`}
            onClick={() => onConfirm()}
            type="button"
        >
            <Translate>YES</Translate>
        </button>
        {options && (
            <button
                className={`${styles.button}`}
                onClick={() => onClose()}
                type="button"
            >
                <Translate>No</Translate>
            </button>
        )}
    </Modal>
);

export default DataUnavailableModal;
