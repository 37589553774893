import { node } from 'prop-types';
import React from 'react';

import LoginError from '../../components/loginError';
import { useAuth } from '../../providers/auth/AuthContext';

const ForceLogoutUserWrapper = ({ children }) => {
    const { userSessionError, logout } = useAuth();
    return (
        (userSessionError
            ? <LoginError error={userSessionError} id="data-test-loginError-page" onClick={logout} />
            : children
        )
    );
};

ForceLogoutUserWrapper.propTypes = { children: node };

export default ForceLogoutUserWrapper;
