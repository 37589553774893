import React from 'react';

import style from './NoActivitiesText.module.scss';

import Translate from '../../../components/translate';

const NoActivitiesText = () => (
    <div className={style.noActivitiesContainer}>
        <Translate>FEED_NO_ACTIVITIES</Translate>
    </div>
);

export default NoActivitiesText;
