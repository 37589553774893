import GearPlaceholder from './gearPlaceholder';
import GearPower from './gearPower';
import GearRatioChart from './gearRatioChart';
import GearUsage from './gearUsage';
import TyrePlaceholder from './tyrePlaceholder';
import TyreWizLineCharts from './tyreWizLineCharts';
import TyreWizPane from './tyreWizPane';

import Card from '../card';
import Row from '../row';
import Statistic from '../statistic';

import { logoAxsLogo } from '../../../assets';
import Carousel from '../../../components/carousel';
import Translate, { useTranslation } from '../../../components/translate';
import { toLocaleString } from '../../../constants';
import {
    Activity,
    Bike,
    useUnits,
} from '../../../providers';
import { calculateUnitsPerDistance } from '../../../providers/units/constants';
import { BikeUpdates } from '../../../providers/bikes/types';
import { ComponentSummary as ComponentSummaryType } from '../../../providers/componentSummaries/types';

const GEAR = { FD: 'FRONT', RD: 'REAR' };

interface ComponentSummaryProps {
    activity: Activity;
    bike: Bike;
    disableEdit: boolean;
    disableOwnerFeatures: boolean;
    gearComponents: ComponentSummaryType[];
    gpsComponent: ComponentSummaryType;
    isFetchingActivity: boolean;
    isFetchingComponentSummaries: boolean;
    powerComponent: ComponentSummaryType;
    reverbComponent: ComponentSummaryType;
    tyreComponents: ComponentSummaryType[];
    updateActivity: (activity: Activity) => void;
    updateBike: (bikeUpdates: BikeUpdates) => void;
}

const ComponentSummary = ({
    activity,
    bike,
    disableEdit,
    disableOwnerFeatures,
    gearComponents,
    gpsComponent,
    isFetchingActivity = false,
    isFetchingComponentSummaries = false,
    powerComponent,
    reverbComponent,
    tyreComponents,
    updateActivity,
    updateBike,
}: ComponentSummaryProps) => {
    const units = useUnits();
    const translate = useTranslation();
    const renderGearShiftRow = (shiftCount: number, derailleur: string) => {
        let distance = null;
        let shiftsPerDistance = null;

        const { shorthand } = units.getLabelDistance();

        if (activity.activitysummary_set.length && activity.activitysummary_set[0].data.distance) {
            distance = units.convertDistanceFromSI(activity.activitysummary_set[0].data.distance);

            shiftsPerDistance = toLocaleString(
                calculateUnitsPerDistance(distance, shiftCount),
            );
        }

        return (
            <Row>
                <Statistic
                    id={`data-test-component-summary-${derailleur.toLowerCase()}-shifts`}
                    label={`DERAILLEUR_${derailleur}_SHIFTS`}
                >
                    {toLocaleString(shiftCount)}
                </Statistic>
                <Statistic
                    id={`data-test-component-summary-${derailleur.toLowerCase()}-shifts-distance`}
                    label={`DERAILLEUR_${derailleur}_SHIFTS_PER_DISTANCE`}
                    labelParams={{ unit: <Translate>{shorthand}</Translate> }}
                >
                    {distance && `${shiftsPerDistance}/${translate(shorthand)}`}
                </Statistic>
            </Row>
        );
    };

    const renderGearShift = () => {
        let rdShifts = null;
        let fdShifts = null;

        const gearComponent = gearComponents.find(({ data }) => data.ant_component_id === 2);

        if (!gearComponent || !gearComponent.data.num_chainrings) return null;

        rdShifts = gearComponent.data.rd_shift_count;
        fdShifts = gearComponent.data.fd_shift_count;

        return (
            <>
                {(gearComponent.data.num_chainrings > 1) && renderGearShiftRow(fdShifts, GEAR.FD)}
                {renderGearShiftRow(rdShifts, GEAR.RD)}
            </>
        );
    };

    const renderSeatpostActuations = () => {
        if (!reverbComponent) return null;

        const { shorthand } = units.getLabelDistance();

        let distance = null;
        let seatActuationsPerDistance = 0;

        if (activity.activitysummary_set.length && activity.activitysummary_set[0].data.distance) {
            distance = units.convertDistanceFromSI(activity.activitysummary_set[0].data.distance);

            seatActuationsPerDistance = toLocaleString(calculateUnitsPerDistance(
                distance,
                reverbComponent.data.total_actuations,
            ));
        }

        return (
            <Row key="seatpostActuations">
                <Statistic
                    id="data-test-component-summary-seat-actuations"
                    label="SEATPOST_ACTUATIONS"
                >
                    {(reverbComponent.data
                        ? toLocaleString(reverbComponent.data.total_actuations)
                        : null
                    )}
                </Statistic>
                <Statistic
                    id="data-test-component-summary-seat-actuations-distance"
                    label="SEATPOST_ACTUATIONS_PER_DISTANCE"
                    labelParams={{ unit: <Translate>{shorthand}</Translate> }}
                >
                    {seatActuationsPerDistance && `${seatActuationsPerDistance}/${translate(shorthand)}`}
                </Statistic>
            </Row>
        );
    };

    const renderTyrePressure = () => {
        const frontTyre = tyreComponents.find(({ data }) => (data.position === 1));
        const rearTyre = tyreComponents.find(({ data }) => (data.position === 2));

        if (!frontTyre && !rearTyre) return null;

        const { shorthand } = units.getLabelPressure();

        let frontTyrePressure = null;
        if (frontTyre) {
            const pressure = toLocaleString(
                units.formatPressure(
                    units.convertPressureFromSI(frontTyre.data.last_pressure),
                ),
            );

            frontTyrePressure = `${pressure} ${translate(shorthand)}`;
        }

        let rearTyrePressure = null;
        if (rearTyre) {
            const pressure = toLocaleString(
                units.formatPressure(
                    units.convertPressureFromSI(rearTyre.data.last_pressure),
                ),
            );

            rearTyrePressure = `${pressure} ${translate(shorthand)}`;
        }

        return (
            <Row>
                <Statistic id="data-test-component-summary-tyre-pressure-front" label="TYREWIZ_FINAL_FRONT">
                    {frontTyrePressure}
                </Statistic>
                <Statistic id="data-test-component-summary-tyre-pressure-rear" label="TYREWIZ_FINAL_REAR">
                    {rearTyrePressure}
                </Statistic>
            </Row>
        );
    };

    const renderCarousel = () => {
        const gearComponent = gearComponents.find(({ data }) => data.ant_component_id === 2);
        const hasTyreComponents = tyreComponents && !!tyreComponents.length;
        const isFetchingData = isFetchingActivity || isFetchingComponentSummaries;

        return (
            <Carousel>
                <Carousel.Item show={!!gearComponent} title="GEAR_USAGE">
                    <GearUsage
                        activity={activity}
                        bike={bike}
                        disableEdit={disableEdit || disableOwnerFeatures}
                        gearComponents={gearComponents}
                        gpsComponent={gpsComponent}
                        updateActivity={updateActivity}
                        updateBike={updateBike}
                    />
                </Carousel.Item>
                <Carousel.Item show={!!gearComponent && !!powerComponent} title="GEAR_POWER">
                    <GearPower
                        activity={activity}
                        bike={bike}
                        disableEdit={disableEdit || disableOwnerFeatures}
                        gearComponents={gearComponents}
                        powerComponent={powerComponent}
                        updateActivity={updateActivity}
                        updateBike={updateBike}
                    />
                </Carousel.Item>
                <Carousel.Item show={!!gearComponent && !disableOwnerFeatures} title="GEAR_RATIO">
                    <GearRatioChart
                        activity={activity}
                        bike={bike}
                        disableEdit={disableEdit || disableOwnerFeatures}
                        gpsComponent={gpsComponent}
                        gearComponent={gearComponent as ComponentSummaryType}
                        updateActivity={updateActivity}
                        updateBike={updateBike}
                    />
                </Carousel.Item>
                <Carousel.Item show={hasTyreComponents} title="TIRE_PRESSURE">
                    <TyreWizPane bike={bike} disableEdit={disableEdit} tyreComponents={tyreComponents} />
                </Carousel.Item>
                <Carousel.Item show={hasTyreComponents} title="TYREWIZ">
                    <TyreWizLineCharts
                        disableEdit={disableEdit}
                        gpsComponent={gpsComponent}
                        tyreComponents={tyreComponents}
                    />
                </Carousel.Item>
                <Carousel.Item show={!gearComponent && !isFetchingData} title="GEAR_ANALYSIS">
                    <GearPlaceholder />
                </Carousel.Item>
                <Carousel.Item show={!hasTyreComponents && !isFetchingData} title="TIRE_PRESSURE">
                    <TyrePlaceholder />
                </Carousel.Item>
            </Carousel>
        );
    };

    return (
        <Card label="COMPONENT_SUMMARY" logo={logoAxsLogo}>
            <div>
                {renderGearShift()}
                {renderSeatpostActuations()}
                {renderTyrePressure()}
            </div>
            {renderCarousel()}
        </Card>
    );
};

export default ComponentSummary;
