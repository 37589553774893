import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Moment from 'react-moment';

import styles from './Notification.module.scss';

import Spinner from '../../../components/spinner';
import Translate from '../../../components/translate';
import {
    iconAchievement,
    iconAlert,
    iconWarning,
    logoAxsLogo,
} from '../../../assets';
import { makeCancellable, RequestType } from '../../../constants';
import Logger from '../../../Logger';
import {
    NOTIFICATION_SHAPE,
    useBikes,
    useNotifications,
} from '../../../providers';
import { Bike as BikeType } from '../../../providers/bikes/types';
import { BikeComponent as BikeComponentType } from '../../../providers/bikeComponents/types';
import { Notification as NotificationType } from '../../../providers/notifications/types.d';

function Notification({ notification }: { notification: NotificationType}) {
    const [bike, setBike] = useState<BikeType | null>(null);
    const [component, setComponent] = useState<BikeComponentType | null>(null);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    const bikes = useBikes();
    const navigate = useNavigate();
    const notifications = useNotifications();

    const fetchBikeRequest = useRef<RequestType | null>(null);
    const updateNotificationRequest = useRef<RequestType | null>(null);

    function getActivityId() {
        if (
            notification
            && notification.data
            && notification.data.activity_id
        ) {
            return notification.data.activity_id;
        }

        if (notification && notification.notification_url) {
            const activityURL = notification.notification_url.split('/');
            return (activityURL[activityURL.length - 1]);
        }

        return null;
    }

    async function fetchBike() {
        if (!notification.bike) {
            return;
        }

        fetchBikeRequest.current = makeCancellable(bikes.fetchBike(notification.bike, true));

        try {
            const fetchedBike: BikeType = await fetchBikeRequest.current.promise;
            fetchBikeRequest.current = null;
            // Just incase the bike isn't available
            if (!fetchedBike) {
                return;
            }

            const fetchedBikeComponent = fetchedBike.component_set.find(
                (itrComponent: BikeComponentType) => itrComponent.id === notification.component,
            );

            setBike(fetchedBike);
            setComponent(fetchedBikeComponent || null);
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn(error);
                fetchBikeRequest.current = null;
            }
        }
    }

    function route() {
        const activityId = getActivityId();

        if (activityId) {
            navigate(`/activities/${activityId}`);
        } else if (notification.component) {
            navigate(`/component/${notification.component}`);
        } else if (notification.bike) {
            navigate(`/bikerack/${notification.bike}`);
        }
    }

    async function updateNotification() {
        if (updateNotificationRequest.current) {
            try {
                await updateNotificationRequest.current.promise;
                return;
            } catch (error) {
                return;
            }
        }

        setIsUpdating(true);
        updateNotificationRequest.current = makeCancellable(
            notifications.updateNotification(notification.id, { view_ts: Math.floor(Date.now() / 1000) }),
        );

        try {
            await updateNotificationRequest.current.promise;
            updateNotificationRequest.current = null;

            setIsUpdating(false);

            route();
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn(error);
                updateNotificationRequest.current = null;

                setIsUpdating(false);

                route();
            }
        }
    }

    function renderBike() {
        if (!bike) {
            return null;
        }

        return (
            <h2 className={styles.subTitle} style={{ marginBottom: 0 }}>
                <Translate>BIKE</Translate>
                :&nbsp;
                {bike?.name}
            </h2>
        );
    }

    function renderIcon() {
        switch (notification.notification_type) {
            case 'achievement':
                return <img alt="" className={styles.alertImage} src={iconAchievement} />;
            case 'alarm':
                return <img alt="" className={styles.alertImage} src={iconAlert} />;
            case 'activity':
                return <img alt="" className={styles.alertImage} src={logoAxsLogo} />;
            case 'service':
                return <img alt="" className={styles.alertImage} src={iconWarning} />;
            default:
                return null;
        }
    }

    function renderRouteArrow() {
        if (!notification.component
            && !notification.bike
            && !getActivityId()
        ) {
            return null;
        }

        return <span className={styles.arrow} />;
    }

    useEffect(() => {
        fetchBike();

        return () => {
            if (fetchBikeRequest.current) {
                fetchBikeRequest.current.cancel();
            }

            if (updateNotificationRequest.current) {
                updateNotificationRequest.current.cancel();
            }
        };
    }, []);

    return (
        <button
            className={`${styles.container} ${(notification.view_ts ? styles.viewed : '')}`}
            onClick={() => updateNotification()}
            type="button"
        >
            <Spinner loading={isUpdating} />
            <div className={styles.alert}>
                {renderIcon()}
            </div>
            <div className={styles.content}>
                <h1 className={styles.title}>
                    {notification.title}
                </h1>
                {renderBike()}
                <h2 className={styles.subTitle}>
                    {component && component.mobile_display_name_key}
                </h2>
                <h2 className={`${styles.subTitle} ${styles.text}`}>
                    {notification.body}
                </h2>
            </div>
            <div className={styles.dateContainer}>
                <Moment format="MMM Do, h:mm A" unix>
                    {notification.create_ts}
                </Moment>
                {renderRouteArrow()}
            </div>
        </button>
    );
}

Notification.propTypes = {
    notification: NOTIFICATION_SHAPE.isRequired,
};

export default Notification;
