import styles from './DisplayLabel.module.scss';

import Translate from '../../translate';

interface DisplayLabelProps{
    label: string,
    max: number,
    min: number,
    rangeValues: number[],
    stagedValues: number[],
    units: string,
}

const DisplayLabel = ({
    label = '',
    max = 10,
    min = 0,
    rangeValues = [],
    stagedValues = [],
    units = '',
}: DisplayLabelProps) => {
    if (!rangeValues.length) {
        return null;
    }

    const values = stagedValues || rangeValues;
    const displayValue = ((values[0] === min) && (values[1] === max))
        ? <Translate>ANY</Translate>
        : (
            <>
                {values[0]}
                -
                {values[1]}
                &nbsp;
                <Translate>{units}</Translate>
            </>
        );

    const displayText = (
        <div className={styles.selectedRange}>
            {displayValue}
        </div>
    );

    if (!label) {
        return displayText;
    }

    return (
        <div className={styles.container}>
            <div className={styles.label}>
                <Translate>{label}</Translate>
            </div>
            {displayText}
        </div>
    );
};

export default DisplayLabel;
