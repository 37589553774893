import { createContext, useContext } from 'react';

import { NexusProps } from './types';

const NexusContext = createContext<NexusProps>({
    nexus: {
        // State
        cachedProfileImage: null,
        nexusLinkedIds: [],
        nexusUserProfile: {},
        nickname: '',
        // Functions
        // eslint-disable-next-line sort-keys
        handleServiceLinked: () => {},
        triggerNexusIdsSync: () => {},
        unlinkNexusService: () => {},
        updateNexusUserProfile: () => {},
    },
});

export function useNexus() {
    const { nexus } = useContext(NexusContext);

    return nexus;
}

export default NexusContext;
