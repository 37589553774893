import {
    number,
    oneOf,
    oneOfType,
    shape,
    string,
} from 'prop-types';

const COMPONENT_SUMMARY_SHAPE = shape({
    activity: oneOfType([
        string,
        oneOf(['example']),
    ]),
    adjustedEndTs: number,
    adjustedStartTs: number,
    data: shape({}),
    device_id: number,
    device_type: number,
    end_ts: number,
    id: oneOfType([number, string]),
    manufacturer: number,
    model: number,
    nexus_component_id: oneOfType([
        number,
        oneOf([
            'example',
            'example_4',
            'example_5',
            'example_6',
        ]),
    ]),
    owner: oneOfType([
        number,
        oneOf(['example']),
    ]),
    owner_url: string,
    serial: number,
    source: string,
    source_user: string,
    start_ts: number,
    timezone_offset: number,
    voltage: number,
});

export default COMPONENT_SUMMARY_SHAPE;
