import styles from './ActivitiesTutorial.module.scss';

import {
    axsAppOne,
    axsBanner,
    axsNotificationOne,
    axsNotificationTwo,
    logoAppleStore,
    logoPlaystore,
} from '../../../assets';
import PairingFinePrint from '../../../views/pairingFinePrint';
import Translate from '../../../components/translate';
import TutorialLinkedApps from '../../../views/tutorialLinkedApps';
import TutorialPairing from '../../../views/tutorialPairing';

const ActivitiesTutorial = () => {
    const renderAppScreens = () => (
        <div className={styles.guideContainer}>
            <div className={styles.circle}>1</div>
            <div className={styles.guideContent}>
                <div className={styles.subHeader}>
                    <Translate>TUTORIAL_APP_TITLE</Translate>
                </div>
                <div className={styles.text}>
                    <Translate>TUTORIAL_APP_INFO_ONE</Translate>
                </div>
                <div className={styles.appContainer}>
                    <div className={styles.axsAppImageContainer}>
                        <img
                            alt=""
                            className={styles.axsAppImage}
                            src={axsAppOne}
                        />
                    </div>
                    <div className={styles.appStoreContainer}>
                        <a
                            className={styles.appStoreLink}
                            href="https://itunes.apple.com/us/app/sram-axs/id1430049231?ls=1&mt=8"
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            <img
                                alt="Download on the App Store"
                                className={styles.appStoreImage}
                                src={logoAppleStore}
                            />
                        </a>
                        <a
                            className={styles.appStoreLink}
                            href="https://play.google.com/store/apps/details?id=com.sram.armyknife"
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            <img
                                alt="Get it on Google Play"
                                className={styles.appStoreImage}
                                src={logoPlaystore}
                            />
                        </a>
                    </div>
                </div>
                <div className={styles.text}>
                    <p>
                        <Translate>TUTORIAL_APP_INFO_TWO</Translate>
                    </p>
                </div>
                <div className={styles.imageContainer}>
                    <div className={styles.appNotificationImageContainer}>
                        <img
                            alt=""
                            className={styles.appNotificationImage}
                            src={axsNotificationTwo}
                        />
                    </div>
                    <div className={styles.appNotificationImageContainer}>
                        <img
                            alt=""
                            className={styles.appNotificationImage}
                            src={axsNotificationOne}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className={styles.container}>
            <img alt="" src={axsBanner} style={{ height: 'auto', width: '100%' }} />
            <div className={styles.content}>
                <div className={styles.header}>
                    <Translate>TUTORIAL_TITLE</Translate>
                </div>
                <div className={styles.text}>
                    <Translate>TUTORIAL_SUBTITLE</Translate>
                </div>

                {renderAppScreens()}
                <div className={styles.guideContainer}>
                    <div className={styles.circle}>2</div>
                    <TutorialPairing className={styles.guideContent} />
                </div>
                <div className={styles.guideContainer}>
                    <div className={styles.circle}>3</div>
                    <TutorialLinkedApps className={styles.guideContent} />
                </div>
                <PairingFinePrint className={styles.finePrint} />
            </div>
        </div>
    );
};

export default ActivitiesTutorial;
