import React from 'react';

import styles from './BikeComponentNotification.module.scss';

import { iconAlert } from '../../../assets';

interface BikeComponentNotificationProps {
    icon?: string;
    id?: string;
    notification?: {
        id?: string;
        title: string;
        body: string;
    };
}

const BikeComponentNotification = ({ icon = iconAlert, id =  'data-test-bike-component-notification', notification }: BikeComponentNotificationProps) => {
    if (!notification) {
        return null;
    }

    return (
        <div className={styles.container} id={id} key={notification.id}>
            <img
                alt=""
                className={styles.icon}
                src={icon}
            />
            <div className={styles.content}>
                <div className={styles.title}>{notification.title}</div>
                {notification.body}
            </div>
        </div>
    );
};


export default BikeComponentNotification;
