/* eslint-disable max-len */
export const RIDE_STYLE_CROSS = 'RIDE_STYLE_CROSS';
export const RIDE_STYLE_FAT = 'RIDE_STYLE_FAT';
export const RIDE_STYLE_DOWNHILL_MTB = 'RIDE_STYLE_DOWNHILL_MTB';
export const RIDE_STYLE_ENDURO_MTB = 'RIDE_STYLE_ENDURO_MTB';
export const RIDE_STYLE_GRAVEL = 'RIDE_STYLE_GRAVEL';
export const RIDE_STYLE_ROAD = 'RIDE_STYLE_ROAD';
export const RIDE_STYLE_TRAIL_MTB = 'RIDE_STYLE_TRAIL_MTB';
export const RIDE_STYLE_XCOUNTRY_MTB = 'RIDE_STYLE_XCOUNTRY_MTB';
export const RIM_TYPE_TUBELESS_CROCHET = 'RIM_TYPE_TUBELESS_CROCHET';
export const RIM_TYPE_TUBELESS_STRAIGHT_SIDE = 'RIM_TYPE_TUBELESS_STRAIGHT_SIDE';
export const RIM_TYPE_TUBULAR = 'RIM_TYPE_TUBULAR';
export const RIM_TYPE_TUBES = 'RIM_TYPE_TUBES';
export const RIM_TYPE_303_XPLR = 'RIM_TYPE_303_XPLR';
export const SURFACE_DRY = 'SURFACE_DRY';
export const SURFACE_WET = 'SURFACE_WET';
export const SURFACE_SNOW = 'SURFACE_SNOW';
export const TIRE_CASING_DOUBLE = 'TIRE_CASING_DOUBLE';
export const TIRE_CASING_REINFORCED = 'TIRE_CASING_REINFORCED';
export const TIRE_CASING_STANDARD = 'TIRE_CASING_STANDARD';
export const TIRE_CASING_THIN = 'TIRE_CASING_THIN';
export const WHEEL_FRONT = 'WHEEL_FRONT';
export const WHEEL_REAR = 'WHEEL_REAR';
export const TIRE_CASING_GY_SW = 'TIRE_CASING_GY_SW';
export const TIRE_CASING_GY_NSW = 'TIRE_CASING_GY_NSW';
export const TIRE_CASING_GY_XPLR_SLICKS = 'TIRE_CASING_GY_XPLR_SLICKS';
export const TIRE_CASING_GY_INTER = 'TIRE_CASING_GY_INTER';

const CASING_FUDGE = {
    TIRE_CASING_DOUBLE: 0.9,
    TIRE_CASING_GY_INTER: 1,
    TIRE_CASING_GY_NSW: 1.025,
    TIRE_CASING_REINFORCED: 0.95,
    TIRE_CASING_STANDARD: 1,
    TIRE_CASING_THIN: 1.025,
    TIRE_CASING_XPLR_SLICKS: 1,
};

const FR_RATIO = {
    WHEEL_FRONT: 0.94,
    WHEEL_REAR: 1,
};

const RIDE_STYLE_FUDGE = {
    RIDE_STYLE_CROSS: 0.6,
    RIDE_STYLE_DOWNHILL_MTB: 1.1,
    RIDE_STYLE_ENDURO_MTB: 1.05,
    RIDE_STYLE_FAT: 1,
    RIDE_STYLE_GRAVEL: 0.9,
    RIDE_STYLE_ROAD: 1,
    RIDE_STYLE_TRAIL_MTB: 1.05,
    RIDE_STYLE_XCOUNTRY_MTB: 0.9,
};

const RIM_TYPE_FUDGE = {
    // Increase 303 XPLR pressure outputs by 7.5%
    RIM_TYPE_303_XPLR: 0.9675,
    RIM_TYPE_TUBELESS_CROCHET: 1.03,
    RIM_TYPE_TUBELESS_STRAIGHT_SIDE: 0.955,
    RIM_TYPE_TUBES: 1.05,
    RIM_TYPE_TUBULAR: 1.1,
};

const RIM_TYPE_FUDGE_CROSS = {
    RIM_TYPE_TUBELESS_CROCHET: 1.03,
    RIM_TYPE_TUBELESS_STRAIGHT_SIDE: 0.955,
    RIM_TYPE_TUBES: 1.05,
    RIM_TYPE_TUBULAR: 0.9,
};

const WET_FUDGE = {
    SURFACE_DRY: 1,
    SURFACE_SNOW: 0.5,
    SURFACE_WET: 0.9,
};

const COMPATIBLE_TIRE_RIM_WIDTHS = [
    { compatibleRimWidth: 15, max: 22, min: 18 },
    { compatibleRimWidth: 17, max: 25, min: 22 },
    { compatibleRimWidth: 19, max: 29, min: 25 },
    { compatibleRimWidth: 21, max: 35, min: 29 },
    { compatibleRimWidth: 23, max: 47, min: 35 },
    { compatibleRimWidth: 25, max: 58, min: 47 },
    { compatibleRimWidth: 30, max: 66, min: 58 },
    { compatibleRimWidth: 35, max: 72, min: 66 },
    { compatibleRimWidth: 45, max: 84, min: 72 },
    { compatibleRimWidth: 55, max: 96, min: 84 },
    { compatibleRimWidth: 76, max: 113, min: 96 },
    { compatibleRimWidth: 94, max: 133, min: 114 },
];

export const RIDE_STYLE_OPTIONS = [
    RIDE_STYLE_ROAD,
    RIDE_STYLE_CROSS,
    RIDE_STYLE_GRAVEL,
    RIDE_STYLE_XCOUNTRY_MTB,
    RIDE_STYLE_TRAIL_MTB,
    RIDE_STYLE_ENDURO_MTB,
    RIDE_STYLE_DOWNHILL_MTB,
    RIDE_STYLE_FAT,
];

export const RIM_TYPE_OPTIONS = [
    RIM_TYPE_TUBES,
    RIM_TYPE_TUBULAR,
    RIM_TYPE_TUBELESS_CROCHET,
    RIM_TYPE_TUBELESS_STRAIGHT_SIDE,
];

const MASS_UNIT = ['lbs', 'kg'];

const PRESSURE_UNITS = ['psi', 'bar'];

const SURFACE_TYPES = [SURFACE_DRY, SURFACE_WET, SURFACE_SNOW];
const TIRE_CASING_GRAVEL = [
    TIRE_CASING_STANDARD,
    TIRE_CASING_REINFORCED,
    TIRE_CASING_GY_INTER,
    TIRE_CASING_GY_XPLR_SLICKS,
];
const TIRE_CASING_XCROSS = [TIRE_CASING_THIN, TIRE_CASING_STANDARD, TIRE_CASING_REINFORCED];
const TIRE_CASING_ROAD = [
    TIRE_CASING_THIN,
    TIRE_CASING_STANDARD,
    TIRE_CASING_REINFORCED,
    TIRE_CASING_GY_SW,
    TIRE_CASING_GY_NSW,
];
const TIRE_CASING_OTHERS = [TIRE_CASING_STANDARD, TIRE_CASING_REINFORCED, TIRE_CASING_DOUBLE];

export const isGoodYearTire = (tireCasing: string) => [TIRE_CASING_GY_NSW, TIRE_CASING_GY_SW, TIRE_CASING_GY_INTER, TIRE_CASING_GY_XPLR_SLICKS].includes(tireCasing);
export const isXPLRTire = (tireCasing: string) => [TIRE_CASING_GY_INTER, TIRE_CASING_GY_XPLR_SLICKS].includes(tireCasing);

export function getRimTypeOptions(rideStyle: string, readTireCasing: string, frontTireCasing: string) {
    if (isXPLRTire(readTireCasing) && isXPLRTire(frontTireCasing)) {
        return [RIM_TYPE_303_XPLR];
    }

    if (isGoodYearTire(readTireCasing) && isGoodYearTire(frontTireCasing)) {
        return [RIM_TYPE_TUBELESS_STRAIGHT_SIDE];
    }

    switch (rideStyle) {
        case RIDE_STYLE_GRAVEL:
            return [...RIM_TYPE_OPTIONS, RIM_TYPE_303_XPLR];
        case RIDE_STYLE_ROAD:
        case RIDE_STYLE_CROSS:
        case RIDE_STYLE_XCOUNTRY_MTB:
        case RIDE_STYLE_TRAIL_MTB:
        case RIDE_STYLE_ENDURO_MTB:
        case RIDE_STYLE_DOWNHILL_MTB:
        case RIDE_STYLE_FAT:
        default:
            return RIM_TYPE_OPTIONS;
    }
}

export function getCompatibleRimWidth(labelledTireWidth: number, isGoodYearSelection?: boolean) {
    if (isGoodYearSelection) {
        return 25;
    }
    const value = COMPATIBLE_TIRE_RIM_WIDTHS
        .find(({ max, min }) => (labelledTireWidth >= min && labelledTireWidth < max));

    if (!value) {
        return 0;
    }

    return value.compatibleRimWidth;
}

export function compareTireAndRimWidthCompatibility(labelledTireWidth: number, rimWidth: number) {
    // const tireWidth = Number.parseInt(labelledTireWidth);
    // const inputRimWidth = Number.parseInt(rimWidth);
    //
    // if (!Number.isFinite(tireWidth) || !Number.isFinite(inputRimWidth)) {
    //     return false;
    // }
    //
    // const compatibleRimWidth = getCompatibleRimWidth(tireWidth);
    //
    // const tireWidthAdjusted = tireWidth + (0.4 * (inputRimWidth - compatibleRimWidth));
    //
    // // get 110% of inner rim width
    // const rimWidthAdjusted = rimWidth * 1.1;
    //
    // // it is incompatible if calculated tire width is less than 100% of internal rim width
    // return (tireWidthAdjusted < rimWidthAdjusted);

    // for now, turn off incompatibility check
    return false;
}

export const calculateRecommendedPressure = (config: {
    bikeWeight: number;
    tireCasing?: string;
    tireWidth: number;
    wheelPosition?: string;
    frontTireCasing?: string;
    frontTireWidth?: number;
    innerRimWidth: number;
    rearTireCasing?: string;
    rearTireWidth?: number;
    riderWeight: number;
    rideStyle?: string;
    rimType?: string;
    surface?: string;
    wheelDiameter: number;
}) => {
    const rideStyleFudge = RIDE_STYLE_FUDGE[config.rideStyle as keyof typeof RIDE_STYLE_FUDGE] || 0;
    const isGoodYearSelection = isGoodYearTire(config.tireCasing || '') && isGoodYearTire(config.rearTireCasing || '');

    // map wheel position to the appropriate weight distribution ratio
    const frRatio = FR_RATIO[config.wheelPosition as keyof typeof FR_RATIO] || 0.5;

    // map tire type to the added fudge
    let rimTypeFudge = ((config.rideStyle === RIDE_STYLE_CROSS)
        ? RIM_TYPE_FUDGE_CROSS[config.rimType as keyof typeof RIM_TYPE_FUDGE_CROSS]
        : RIM_TYPE_FUDGE[config.rimType as keyof typeof RIM_TYPE_FUDGE]
    );

    if (!rimTypeFudge) {
        rimTypeFudge = 1;
    }

    // maps tire casing extra fudge (0 = thin, 1 = standard, 2 = reinforced, 3 = dh casing)
    const casingFudge = CASING_FUDGE[config.tireCasing as keyof typeof CASING_FUDGE] || 1;

    // fudge if the trail is wet
    const wetFudge = WET_FUDGE[config.surface as keyof typeof WET_FUDGE] || 1;

    const tireWidthAdjusted = config.tireWidth + (0.4 * (
        config.innerRimWidth - getCompatibleRimWidth(config.tireWidth, isGoodYearSelection)));

    // calculate the surface area from diameter and width (simple torus)
    // adjust for rim width by modify the estimated inner width of the tire
    const surfaceArea = (
        4
        * (Math.PI ** 2.0)
        * (config.wheelDiameter / 2.0 + tireWidthAdjusted / 2.0)
        * (tireWidthAdjusted / 2.0)
    );

    // regression from the Surface Area
    const surfaceAreaFit = 10 ** (8.684670773) * surfaceArea ** -1.304556655;

    // regression from the weight
    const weightFit = (
        surfaceAreaFit
        * (1 + (2.2 * (config?.bikeWeight + config.riderWeight) - 180) * 0.0025)) * frRatio;

    // output is in units of psi
    let pressure = weightFit;

    // Apply all the fudge
    pressure *= rimTypeFudge * rideStyleFudge * wetFudge * casingFudge;

    // Convert pressure to mbar
    return pressure * 68.9476;
};

export function getDefaultWheelSettings(rideStyle: string) {
    switch (rideStyle) {
        case RIDE_STYLE_ROAD:
            return {
                innerRimWidth: 23,
                rimType: RIM_TYPE_TUBELESS_STRAIGHT_SIDE,
                wheelDiameter: 622,
            };
        case RIDE_STYLE_CROSS:
            return {
                innerRimWidth: 21,
                rimType: RIM_TYPE_TUBULAR,
                wheelDiameter: 622,
            };
        case RIDE_STYLE_GRAVEL:
            return {
                innerRimWidth: 25,
                rimType: RIM_TYPE_TUBELESS_STRAIGHT_SIDE,
                wheelDiameter: 622,
            };
        case RIDE_STYLE_XCOUNTRY_MTB:
            return {
                innerRimWidth: 30,
                rimType: RIM_TYPE_TUBELESS_STRAIGHT_SIDE,
                wheelDiameter: 622,
            };
        case RIDE_STYLE_TRAIL_MTB:
            return {
                innerRimWidth: 30,
                rimType: RIM_TYPE_TUBELESS_STRAIGHT_SIDE,
                wheelDiameter: 622,
            };
        case RIDE_STYLE_ENDURO_MTB:
            return {
                innerRimWidth: 30,
                rimType: RIM_TYPE_TUBELESS_STRAIGHT_SIDE,
                wheelDiameter: 622,
            };
        case RIDE_STYLE_DOWNHILL_MTB:
            return {
                innerRimWidth: 30,
                rimType: RIM_TYPE_TUBELESS_CROCHET,
                wheelDiameter: 622,
            };
        case RIDE_STYLE_FAT:
            return {
                innerRimWidth: 100,
                rimType: RIM_TYPE_TUBELESS_CROCHET,
                wheelDiameter: 571,
            };
        default:
            return {};
    }
}

export function getDefaultTireSettings(rideStyle: string) {
    switch (rideStyle) {
        case RIDE_STYLE_ROAD:
            return {
                frontTireCasing: TIRE_CASING_STANDARD,
                frontTireWidth: 28,
                rearTireCasing: TIRE_CASING_STANDARD,
                rearTireWidth: 28,
            };
        case RIDE_STYLE_CROSS:
            return {
                frontTireCasing: TIRE_CASING_STANDARD,
                frontTireWidth: 32,
                rearTireCasing: TIRE_CASING_STANDARD,
                rearTireWidth: 32,
            };
        case RIDE_STYLE_GRAVEL:
            return {
                frontTireCasing: TIRE_CASING_STANDARD,
                frontTireWidth: 40,
                rearTireCasing: TIRE_CASING_STANDARD,
                rearTireWidth: 40,
            };
        case RIDE_STYLE_XCOUNTRY_MTB:
            return {
                frontTireCasing: TIRE_CASING_STANDARD,
                frontTireWidth: 2.3,
                rearTireCasing: TIRE_CASING_STANDARD,
                rearTireWidth: 2.3,
            };
        case RIDE_STYLE_TRAIL_MTB:
            return {
                frontTireCasing: TIRE_CASING_REINFORCED,
                frontTireWidth: 2.4,
                rearTireCasing: TIRE_CASING_REINFORCED,
                rearTireWidth: 2.4,
            };
        case RIDE_STYLE_ENDURO_MTB:
            return {
                frontTireCasing: TIRE_CASING_REINFORCED,
                frontTireWidth: 2.4,
                rearTireCasing: TIRE_CASING_REINFORCED,
                rearTireWidth: 2.4,
            };
        case RIDE_STYLE_DOWNHILL_MTB:
            return {
                frontTireCasing: TIRE_CASING_DOUBLE,
                frontTireWidth: 2.5,
                rearTireCasing: TIRE_CASING_DOUBLE,
                rearTireWidth: 2.5,
            };
        case RIDE_STYLE_FAT:
            return {
                frontTireCasing: TIRE_CASING_STANDARD,
                frontTireWidth: 3.8,
                rearTireCasing: TIRE_CASING_STANDARD,
                rearTireWidth: 3.8,
            };
        default:
            return {};
    }
}

export function getDefaultBikeWeight(rideStyle: string) {
    switch (rideStyle) {
        case RIDE_STYLE_ROAD:
            return 6.8;
        case RIDE_STYLE_CROSS:
            return 7.7;
        case RIDE_STYLE_GRAVEL:
            return 9;
        case RIDE_STYLE_XCOUNTRY_MTB:
            return 10.9;
        case RIDE_STYLE_TRAIL_MTB:
            return 11.8;
        case RIDE_STYLE_ENDURO_MTB:
            return 12.7;
        case RIDE_STYLE_DOWNHILL_MTB:
            return 15.9;
        case RIDE_STYLE_FAT:
            return 18.1;
        default:
            return 0;
    }
}

export function getDefaultSettingValues(rideStyle: string) {
    // get default setting values based on selected ride style
    switch (rideStyle) {
        case RIDE_STYLE_ROAD:
            return {
                bikeWeight: 6.8,
                frontTireCasing: TIRE_CASING_STANDARD,
                frontTireWidth: 28,
                innerRimWidth: 23,
                rearTireCasing: TIRE_CASING_STANDARD,
                rearTireWidth: 28,
                rimType: RIM_TYPE_TUBELESS_STRAIGHT_SIDE,
                surface: SURFACE_DRY,
                wheelDiameter: 622,
            };
        case RIDE_STYLE_CROSS:
            return {
                bikeWeight: 7.7,
                frontTireCasing: TIRE_CASING_STANDARD,
                frontTireWidth: 32,
                innerRimWidth: 21,
                rearTireCasing: TIRE_CASING_STANDARD,
                rearTireWidth: 32,
                rimType: RIM_TYPE_TUBULAR,
                surface: SURFACE_DRY,
                wheelDiameter: 622,
            };
        case RIDE_STYLE_GRAVEL:
            return {
                bikeWeight: 9,
                frontTireCasing: TIRE_CASING_STANDARD,
                frontTireWidth: 40,
                innerRimWidth: 23,
                rearTireCasing: TIRE_CASING_STANDARD,
                rearTireWidth: 40,
                rimType: RIM_TYPE_TUBELESS_STRAIGHT_SIDE,
                surface: SURFACE_DRY,
                wheelDiameter: 622,
            };
        case RIDE_STYLE_XCOUNTRY_MTB:
            return {
                bikeWeight: 10.9,
                frontTireCasing: TIRE_CASING_STANDARD,
                frontTireWidth: 2.3,
                innerRimWidth: 23,
                rearTireCasing: TIRE_CASING_STANDARD,
                rearTireWidth: 2.3,
                rimType: RIM_TYPE_TUBELESS_STRAIGHT_SIDE,
                surface: SURFACE_DRY,
                wheelDiameter: 622,
            };
        case RIDE_STYLE_TRAIL_MTB:
            return {
                bikeWeight: 11.8,
                frontTireCasing: TIRE_CASING_REINFORCED,
                frontTireWidth: 2.4,
                innerRimWidth: 28,
                rearTireCasing: TIRE_CASING_REINFORCED,
                rearTireWidth: 2.4,
                rimType: RIM_TYPE_TUBELESS_STRAIGHT_SIDE,
                surface: SURFACE_DRY,
                wheelDiameter: 622,
            };
        case RIDE_STYLE_ENDURO_MTB:
            return {
                bikeWeight: 12.7,
                frontTireCasing: TIRE_CASING_REINFORCED,
                frontTireWidth: 2.4,
                innerRimWidth: 30,
                rearTireCasing: TIRE_CASING_REINFORCED,
                rearTireWidth: 2.4,
                rimType: RIM_TYPE_TUBELESS_STRAIGHT_SIDE,
                surface: SURFACE_DRY,
                wheelDiameter: 622,
            };
        case RIDE_STYLE_DOWNHILL_MTB:
            return {
                bikeWeight: 15.9,
                frontTireCasing: TIRE_CASING_DOUBLE,
                frontTireWidth: 2.5,
                innerRimWidth: 30,
                rearTireCasing: TIRE_CASING_DOUBLE,
                rearTireWidth: 2.5,
                rimType: RIM_TYPE_TUBELESS_CROCHET,
                surface: SURFACE_DRY,
                wheelDiameter: 622,
            };
        case RIDE_STYLE_FAT:
            return {
                bikeWeight: 18.1,
                frontTireCasing: TIRE_CASING_STANDARD,
                frontTireWidth: 3.8,
                innerRimWidth: 100,
                rearTireCasing: TIRE_CASING_STANDARD,
                rearTireWidth: 3.8,
                rimType: RIM_TYPE_TUBELESS_CROCHET,
                surface: SURFACE_SNOW,
                wheelDiameter: 571,
            };
        default:
            return {};
    }
}

export const getInnerRimWidthValues = (rideStyle: string, rimOrCasing: string, isGoodYearSelection = false) => {
    if (rimOrCasing === RIM_TYPE_303_XPLR) {
        return {
            max: 32,
            min: 32,
        };
    }

    if (isGoodYearSelection) {
        return {
            max: 25,
            min: 23,
        };
    }

    switch (rideStyle) {
        case RIDE_STYLE_ROAD:
        case RIDE_STYLE_CROSS:
        case RIDE_STYLE_GRAVEL:
            return { max: 50, min: 15 };
        case RIDE_STYLE_XCOUNTRY_MTB:
        case RIDE_STYLE_TRAIL_MTB:
        case RIDE_STYLE_ENDURO_MTB:
        case RIDE_STYLE_DOWNHILL_MTB:
        case RIDE_STYLE_FAT:
            return { max: 100, min: 15 };
        default:
            return { max: 50, min: 15 };
    }
};

export const getCasingOptions = (rideStyle: string) => {
    switch (rideStyle) {
        case RIDE_STYLE_ROAD:
            return TIRE_CASING_ROAD;
        case RIDE_STYLE_CROSS:
            return TIRE_CASING_XCROSS;
        case RIDE_STYLE_GRAVEL:
            return TIRE_CASING_GRAVEL;
        default:
            return TIRE_CASING_OTHERS;
    }
};

export const getSurfaceTypes = (rideStyle: string) => ((rideStyle !== RIDE_STYLE_FAT)
    ? SURFACE_TYPES.filter((surfaceType) => surfaceType !== SURFACE_SNOW)
    : SURFACE_TYPES);

export const getWheelDiameterValues = (ridingStyle: string) => {
    switch (ridingStyle) {
        case RIDE_STYLE_ENDURO_MTB:
        case RIDE_STYLE_DOWNHILL_MTB:
        case RIDE_STYLE_TRAIL_MTB:
        case RIDE_STYLE_XCOUNTRY_MTB:
            return [559, 571, 622];
        case RIDE_STYLE_FAT:
            return [559, 571];
        case RIDE_STYLE_GRAVEL:
        case RIDE_STYLE_ROAD:
        case RIDE_STYLE_CROSS:
        default:
            return [571, 584, 622];
    }
};

export const getTireWidthSteps = (tireCasing: string, isTireInches: boolean, fatBike?: boolean) => {
    if (isTireInches && fatBike) {
        return 0.1;
    }
    if (isTireInches) {
        return 0.05;
    }
    switch (tireCasing) {
        case TIRE_CASING_GY_NSW:
        case TIRE_CASING_GY_SW:
            return 5;
        default:
            return 1;
    }
};

export const getGoodyearTireWidthSliderValues = (tireCasing: string) => {
    switch (tireCasing) {
        case TIRE_CASING_GY_SW:
            return {
                maxTireWidth: 35,
                minTireWidth: 30,
            };
        case TIRE_CASING_GY_NSW:
            return {
                maxTireWidth: 30,
                minTireWidth: 30,
            };
        case TIRE_CASING_GY_XPLR_SLICKS:
            return {
                maxTireWidth: 40,
                minTireWidth: 40,
            };
        case TIRE_CASING_GY_INTER:
            return {
                maxTireWidth: 45,
                minTireWidth: 45,
            };
        default:
            return {
                maxTireWidth: 35,
                minTireWidth: 30,
            };
    }
};

export const getTireWidthSliderValues = (rideStyle: string, rimType?: string) => {
    const isTubeless = rimType === RIM_TYPE_TUBELESS_STRAIGHT_SIDE;
    // const isRoadGravelCross = [RIDE_STYLE_GRAVEL, RIDE_STYLE_CROSS].includes(rideStyle);
    // if (isTubeless && isRoadGravelCross) {
    //     return {
    //         maxTireWidth: 40,
    //         minTireWidth: 28,
    //     };
    // }
    if (isTubeless && rideStyle === RIDE_STYLE_ROAD) {
        return {
            maxTireWidth: 50,
            minTireWidth: 28,
        };
    }
    switch (rideStyle) {
        case RIDE_STYLE_FAT:
            return {
                maxTireWidth: 5.0,
                minTireWidth: 3.8,
            };
        case RIDE_STYLE_DOWNHILL_MTB:
        case RIDE_STYLE_ENDURO_MTB:
        case RIDE_STYLE_TRAIL_MTB:
        case RIDE_STYLE_XCOUNTRY_MTB:
            return {
                maxTireWidth: 3.8,
                minTireWidth: 2.0,
            };
        case RIDE_STYLE_GRAVEL:
            return {
                maxTireWidth: 65,
                minTireWidth: 28,
            };
        case RIDE_STYLE_ROAD:
            return {
                maxTireWidth: 65,
                minTireWidth: 18,
            };
        case RIDE_STYLE_CROSS:
            return {
                maxTireWidth: 40,
                minTireWidth: 28,
            };
        default:
            return {
                maxTireWidth: 50,
                minTireWidth: 18,
            };
    }
};

export const getTireWidthSliderBaseValues = (tireCasing: string) => {
    switch (tireCasing) {
        case TIRE_CASING_GY_NSW:
        case TIRE_CASING_GY_SW:
            return 30;
        case TIRE_CASING_GY_XPLR_SLICKS:
            return 40;
        case TIRE_CASING_GY_INTER:
            return 45;
        default:
            return 30;
    }
};

export const isNumeric = (value: string) => /^-?\d+$/.test(value);

export const roundToNearestHalf = (value: number) => Math.round(value / 0.5) * 0.5;

// eslint-disable-next-line import/prefer-default-export
export const validateUrlParamsData = (urlParamValues: {
    bikeWeight?: string;
    frontTireCasing?: any;
    frontTireWidth?: any;
    innerRimWidth?: any;
    rearTireCasing?: any;
    rearTireWidth?: any;
    riderWeight?: number;
    rideStyle?: any;
    rimType?: any;
    selectedMassUnits?: any;
    surface?: any;
    wheelDiameter?: any;
    selectedPressureUnits?: any;
}) => {
    let isParamsDataValid = true;

    const {
        frontTireCasing,
        frontTireWidth,
        selectedMassUnits,
        rideStyle,
        rearTireCasing,
        rearTireWidth,
        selectedPressureUnits,
        wheelDiameter,
        rimType,
        innerRimWidth,
        surface,
    } = urlParamValues;

    if (!rideStyle) return false;

    const currentTireWidthLimit = getTireWidthSliderValues(rideStyle);
    const currentTireDiameter = getWheelDiameterValues(rideStyle);
    const currentCasingOptions = getCasingOptions(rideStyle);
    const currentSurfaceTypes = getSurfaceTypes(rideStyle);

    // Check if given mass unit is correct
    if (selectedMassUnits && !MASS_UNIT.includes(selectedMassUnits)) {
        isParamsDataValid = false;
    }

    // Check if given pressure unit is correct
    if (selectedPressureUnits && !PRESSURE_UNITS.includes(selectedPressureUnits)) {
        isParamsDataValid = false;
    }

    if (rideStyle) {
        // Check if given ride style is correct
        if (!RIDE_STYLE_OPTIONS.includes(rideStyle)) {
            isParamsDataValid = false;
        }
        // Check if given wheel diameter is available
        if (wheelDiameter && !currentTireDiameter.includes(Number(wheelDiameter))) {
            isParamsDataValid = false;
        }

        // Check if given front tire width is in range
        if (frontTireWidth && !(Number(frontTireWidth) >= currentTireWidthLimit.minTireWidth
        && Number(frontTireWidth) <= currentTireWidthLimit.maxTireWidth)) {
            isParamsDataValid = false;
        }
        // Check if given rear tire width is in range
        if (rearTireWidth && !(Number(rearTireWidth) >= currentTireWidthLimit.minTireWidth
        && Number(rearTireWidth) <= currentTireWidthLimit.maxTireWidth)) {
            isParamsDataValid = false;
        }
    }

    // Check if given rim type is valid
    if (rimType && !RIM_TYPE_OPTIONS.includes(rimType)) {
        isParamsDataValid = false;
    }

    // Check if given rim width is a number
    if (innerRimWidth && !isNumeric(innerRimWidth)) {
        isParamsDataValid = false;
    }

    if (frontTireCasing && !currentCasingOptions.includes(frontTireCasing)) {
        isParamsDataValid = false;
    }

    if (rearTireCasing && !currentCasingOptions.includes(rearTireCasing)) {
        isParamsDataValid = false;
    }

    if (surface && !currentSurfaceTypes.includes(surface)) {
        isParamsDataValid = false;
    }

    return isParamsDataValid;
};
