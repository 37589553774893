import { createContext, useContext } from 'react';
import { ComponentSummariesProps } from './types';

const ComponentSummariesContext = createContext<ComponentSummariesProps>({
    componentSummaries: {
        clear: () => {},
        fetch: () => {},
        fetchExample: () => {},
        list: [],
    },
});

export function useComponentSummaries() {
    const { componentSummaries } = useContext(ComponentSummariesContext);

    return componentSummaries;
}

export default ComponentSummariesContext;
