import React from 'react';
import { Moment } from 'moment';
import styles from './FilterDropdown.module.scss';

import Translate from '../../../../components/translate';

interface FilterDropdownProps {
    active?: number | boolean | Date | Moment | Record<string, never>;
    children: any;
    isOpen: boolean;
    label?: string;
    onClick: () => void;
    title: string;
}

const FilterDropdown = ({
    active,
    children,
    isOpen,
    label,
    onClick,
    title,
}: FilterDropdownProps) => (
    <div className={styles.container}>
        <button
            className={`${styles.badge} ${active ? styles.activeBadge : styles.inActiveBadge}`}
            type="button"
            onClick={onClick}
        >
            <Translate>{title}</Translate>
        </button>
        {isOpen && (
            <>
                <div
                    aria-hidden
                    className={styles.backDrop}
                    onClick={onClick}
                />
                <div className={`${styles.dropdownContainer}`}>
                    <div className={styles.contentHeader}>
                        <div className={styles.label}>
                            <Translate>{label}</Translate>
                        </div>
                        <button
                            className={styles.closeButton}
                            onClick={onClick}
                            type="button"
                        >
                            &#10005;
                        </button>
                    </div>
                    {children}
                </div>
            </>
        )}
    </div>
);

export default FilterDropdown;
