import TuneIcon from '@mui/icons-material/Tune';
import React from 'react';

import styles from './FilterDropdownHeader.module.scss';

import DesktopContainer from '../../../../components/desktopContainer';
import Drawer from '../../../../components/drawer';
import Translate from '../../../../components/translate';

interface FilterDropdownHeaderProps{
    children?: React.ReactNode;
    dropdownStyle?: React.CSSProperties;
    isOpen: boolean;
    label: string;
    onClick: () => void;
}

const FilterDropdownHeader = ({
    children,
    dropdownStyle,
    isOpen,
    label,
    onClick,
}: FilterDropdownHeaderProps) => (
    <div className={styles.container}>
        <button className={styles.button} onClick={onClick} type="button">
            <TuneIcon className={styles.filterIcon} />
        </button>
        {isOpen && (
            <DesktopContainer>
                <div aria-hidden className={styles.backDrop} onClick={onClick} />
                <div className={styles.dropdownContainer} style={dropdownStyle}>
                    <div className={styles.contentHeader}>
                        <div className={styles.label}>
                            <Translate>{label}</Translate>
                        </div>
                        <button className={styles.closeButton} onClick={onClick} type="button">
                            &#10005;
                        </button>
                    </div>
                    {children}
                </div>
            </DesktopContainer>
        )}
        <Drawer isOpen={isOpen} onClose={onClick}>
            <div className={styles.contentHeader}>
                <div className={styles.label}>
                    <Translate>{label}</Translate>
                </div>
                <button className={styles.closeButton} onClick={onClick} type="button">
                    &#10005;
                </button>
            </div>
            {children}
        </Drawer>
    </div>
);

export default FilterDropdownHeader;
