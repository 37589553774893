import axios from 'axios';
import { useEffect, useRef, useState } from 'react';

import ComponentSummariesContext from './ComponentSummariesContext';
import parseComponentSummary from './parseComponentSummary';
import { ComponentSummary } from './types';

import { useAuth } from '../auth';

import {
    makeCancellable,
    RequestType,
    URL_QUARQNET_API,
    URL_STATIC_DATA,
} from '../../constants';
import Logger from '../../Logger';

const URL_COMPONENT_SUMMARIES = `${URL_QUARQNET_API}componentsummaries/`;
const URL_COMPONENT_SUMMARIES_EXAMPLE = `${URL_STATIC_DATA}exampleactivities/mtb/componentSummaries/`;

function ComponentSummariesProvider({ children }: { children: any }) {
    const auth = useAuth();
    const [componentSummaries, setComponentSummaries] = useState<ComponentSummary[]>([]);
    const componentSummaryRequests = useRef(new Map<string, RequestType>());
    const fetchExampleComponentSummariesRequest = useRef< RequestType | null>(null);

    const clear = () => {
        if (fetchExampleComponentSummariesRequest.current) {
            fetchExampleComponentSummariesRequest.current.cancel();
        }

        // Cancel all pending activity http requests
        componentSummaryRequests.current.forEach((request) => request.cancel());
        componentSummaryRequests.current.clear();
    };

    useEffect(() => {
        if (!auth.accessToken) {
            clear();
            setComponentSummaries([]);
        }

        return clear;
    }, [auth.accessToken]);

    const getComponentSummary = (id: string) => componentSummaries
        // eslint-disable-next-line eqeqeq
        .find((componentSummary) => componentSummary.id == id);

    const fetchComponentSummary = async (id: string, useExisting = false) => {
        if (!id) return null;

        if (useExisting) {
            // Check if the bike exists
            const componentSummary = getComponentSummary(id);

            if (componentSummary) return componentSummary;
        }

        // Check if bike is already being fetched
        const currentRequest = componentSummaryRequests.current.get(id);

        if (currentRequest) {
            try {
                const { data } = await currentRequest.promise;

                return parseComponentSummary(data);
            } catch (error) {
                return null;
            }
        }

        const fetchRequest = makeCancellable(axios.get(`${URL_COMPONENT_SUMMARIES}${id}/`));
        componentSummaryRequests.current.set(id, fetchRequest);

        try {
            const { data } = await fetchRequest.promise;
            componentSummaryRequests.current.delete(id);

            const parsedComponentSummary = parseComponentSummary(data);

            setComponentSummaries([
                // Ensure no duplicates get added
                // eslint-disable-next-line eqeqeq
                ...componentSummaries.filter((componentSummary) => componentSummary.id != id),
                parsedComponentSummary,
            ]);

            return parsedComponentSummary;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching component summary', id, error);
                componentSummaryRequests.current.delete(id);
            }

            return null;
        }
    };

    // Fetch component summary based on an array list of components
    const fetchComponentSummaries = async (ids: string[] = [], useExisting = false) => {
        try {
            const summaries = await Promise.all(ids.map((id) => fetchComponentSummary(id, useExisting)));

            return summaries;
        } catch (error) {
            Logger.warn('Error fetching component summaries', ids, error);

            return [];
        }
    };

    const fetchExampleComponentSummaries = async () => {
        const currentRequest = fetchExampleComponentSummariesRequest.current;

        if (currentRequest) {
            try {
                const { data } = await currentRequest.promise;

                return data;
            } catch (error) {
                return null;
            }
        }

        fetchExampleComponentSummariesRequest.current = makeCancellable(Promise.all([
            axios.get(`${URL_COMPONENT_SUMMARIES_EXAMPLE}gpsSummary.json`),
            axios.get(`${URL_COMPONENT_SUMMARIES_EXAMPLE}heartSummary.json`),
            axios.get(`${URL_COMPONENT_SUMMARIES_EXAMPLE}powerSummary.json`),
            axios.get(`${URL_COMPONENT_SUMMARIES_EXAMPLE}rearDerailleurSummary.json`),
            axios.get(`${URL_COMPONENT_SUMMARIES_EXAMPLE}tyrewizFrontSummary.json`),
            axios.get(`${URL_COMPONENT_SUMMARIES_EXAMPLE}tyrewizRearSummary.json`),
        ]));

        try {
            const summaries = await fetchExampleComponentSummariesRequest.current.promise;

            fetchExampleComponentSummariesRequest.current = null;

            return summaries.map((value: ComponentSummary) => parseComponentSummary(value.data));
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching example component summaries', error);
                fetchExampleComponentSummariesRequest.current = null;
            }

            return null;
        }
    };

    return (
        <ComponentSummariesContext.Provider
            value={{
                componentSummaries: {
                    clear,
                    fetch: fetchComponentSummaries,
                    fetchExample: fetchExampleComponentSummaries,
                    list: componentSummaries,
                },
            }}
        >
            {children}
        </ComponentSummariesContext.Provider>
    );
}

export default ComponentSummariesProvider;
