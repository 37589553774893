import { func, shape } from 'prop-types';

const FUNCTIONS = {
    convertAltitudeFromSI: func,
    convertDistanceFromSI: func,
    convertDistanceToSI: func,
    convertEnergyFromSI: func,
    convertPressureFromSI: func,
    convertSpeedFromSI: func,
    convertTemperatureFromSI: func,
    convertWeightFromSI: func,
    convertWeightToSI: func,
    formatTime: func,
    getLabelAltitude: func,
    getLabelDistance: func,
    getLabelEnergy: func,
    getLabelPressure: func,
    getLabelSpeed: func,
    getLabelTemperature: func,
    getLabelWeight: func,
};

const UNITS_PROP_TYPES = {
    units: shape({
        ...FUNCTIONS,
    }),
};

export default UNITS_PROP_TYPES;
