import { useMemo } from 'react';

import styles from './NotificationsDashboard.module.scss';

import CheckBox from '../../../../components/checkbox';
import Translate from '../../../../components/translate';

const CHANNEL_APP = { label: 'COMMUNICATIONS_APP', value: 'app' };
const CHANNEL_EMAIL = { label: 'EMAIL', value: 'email' };
const CHANNEL_ACCESS_GROUPS = { label: 'ACCESS_GROUP', value: 'accessGroup' };

const COMMUNICATIONS_TRANSLATION_KEYS: any = {
    activities: 'COMMUNICATIONS_ACTIVITIES',
    alarms: 'COMMUNICATIONS_ALARMS',
    firmware: 'COMMUNICATIONS_FIRMWARE',
};

function getChannel(title: string) {
    switch (title) {
        case 'NOTIFICATIONS_PUSH':
            return CHANNEL_APP;
        case 'NOTIFICATIONS_EMAIL':
            return CHANNEL_EMAIL;
        case 'NOTIFICATIONS_ACCESS_GROUPS':
            return CHANNEL_ACCESS_GROUPS;
        default:
            return CHANNEL_ACCESS_GROUPS;
    }
}

interface filteredChannelsProps {
    name: string,
    enabled: boolean,
}

interface NotificationsDashboardProps {
    notificationPreferences?: any,
    onSelect: (arg: string) => void,
    onSelectAll?: (arg1: string, arg2: boolean) => void,
    title: string,
    userAccessGroups?: any,
}

const NotificationsDashboard = ({
    onSelect = () => {},
    onSelectAll = () => {},
    notificationPreferences = {},
    title = '',
    userAccessGroups = [],
}:NotificationsDashboardProps) => {
    const channel = getChannel(title);
    const filteredChannels = useMemo(
        () => {
            if (!notificationPreferences) return [];
            const { communications } = notificationPreferences;

            if (!communications) return [];
            return communications.filter(({ medium }: { medium: string }) => medium === channel.value);
        },
        [channel, notificationPreferences],
    );

    const isSelectAll = filteredChannels.every(({ enabled }: { enabled: boolean }) => enabled);

    return (
        <>
            <div className={styles.header}>
                <Translate>{title}</Translate>
            </div>
            <div style={{ paddingTop: '1rem' }}>
                <div className={styles.notificationsHeading}>
                    <div className={styles.notificationsPreferenceColumn}>
                        <Translate>COMMUNICATIONS_CATEGORY</Translate>
                    </div>
                    <div
                        className={`${styles.channelTitle} ${styles.notificationsPreferenceColumn}`}
                        id="data-test-channels-title-container"
                        key={`${channel.label}`}
                    >
                        <Translate>{channel.label}</Translate>
                    </div>
                </div>
            </div>
            {(filteredChannels.map(({ name: communicationName, enabled }: filteredChannelsProps) => (
                <label
                    className={styles.notificationsPreferenceRow}
                    htmlFor={`${communicationName}-${channel.label}`}
                    id="data-test-channels-selection-container"
                    key={communicationName}
                >
                    <div className={styles.notificationsPreferenceColumn}>
                        <Translate>
                            {COMMUNICATIONS_TRANSLATION_KEYS[communicationName] || communicationName}
                        </Translate>
                    </div>
                    <div className={`${styles.notificationsPreferenceColumn} ${styles.valueContainer}`}>
                        <CheckBox
                            id={`${communicationName}-${channel.label}`}
                            checked={enabled}
                            onChange={() => onSelect(communicationName)}
                            style={{ display: 'inline-block' }}
                        />
                    </div>
                </label>
            )))}
            {(filteredChannels.length > 1) && (
                <label
                    className={styles.notificationsPreferenceRow}
                    htmlFor={`${channel.label}-select-all`}
                >
                    <div className={styles.notificationsPreferenceColumn} style={{ textTransform: 'capitalize' }}>
                        <Translate>SELECT_ALL</Translate>
                    </div>
                    <div
                        className={`${styles.notificationsPreferenceColumn} ${styles.valueContainer}`}
                        id="data-test-select-all-selection-container"
                    >
                        <CheckBox
                            checked={isSelectAll}
                            id={`${channel.label}-select-all`}
                            onChange={() => onSelectAll(channel.value, !isSelectAll)}
                            style={{ display: 'inline-block' }}
                        />
                    </div>
                </label>
            )}
            {(userAccessGroups && userAccessGroups.map(({ name, member }: { name: string, member: boolean }) => (
                <label
                    className={styles.notificationsPreferenceRow}
                    htmlFor={`${name}-${channel.label}`}
                    id="data-test-channels-selection-container"
                    key={name}
                >
                    <div className={styles.notificationsPreferenceColumn}>
                        <Translate>
                            {name}
                        </Translate>
                    </div>
                    <div className={`${styles.notificationsPreferenceColumn} ${styles.valueContainer}`}>
                        <CheckBox
                            id={`${name}-${channel.label}`}
                            checked={member}
                            onChange={() => onSelect(name)}
                            style={{ display: 'inline-block' }}
                        />
                    </div>
                </label>
            )))}
        </>
    );
};

export default NotificationsDashboard;
