import { useEffect, useRef } from 'react';

import NotificationsDashboard from './notificationsDashboard';

import Button from '../../../components/button';
import Spinner from '../../../components/spinner';
import Translate from '../../../components/translate';
import WaffleBlock from '../../../components/waffleBlock';
import {
    AXS_TEXT_GREY,
    makeCancellable,
    RequestType,
} from '../../../constants';
import Logger from '../../../Logger';
import { useNexus } from '../../../providers';
import { useNotifications } from '../../../providers/notifications/NotificationsContext';
import { useSetState } from '../../../hooks';
import ErrorModal from '../../../views/errorModal';
import { useAccessGroups } from '../../../providers/accessGroups/AccessGroupContext';

const DEFAULT_STATE = {
    isFetching: false,
    isGenerating: false,
    isUpdating: false,
    notificationPreferences: null,
    updatingError: null,
    userAccessGroups: null,
};

function Notifications() {
    const [state, setState] = useSetState(DEFAULT_STATE);
    const nexus = useNexus();
    const notifications = useNotifications();
    const accessGroups = useAccessGroups();
    const {
        isFetching,
        isGenerating,
        isUpdating,
        notificationPreferences,
        updatingError,
        userAccessGroups,
    } = state;

    const fetchNotificationPreferencesRequest = useRef<RequestType | null>(null);
    const fetchUserAccessGroupsRequest = useRef<RequestType | null>(null);
    const generateNotificationRequest = useRef<RequestType | null>(null);
    const triggerMessagesRequest = useRef<RequestType | null>(null);
    const updateNotificationPreferenceRequests = useRef(new Set<RequestType>());
    const updateAccessGroupsRequests = useRef(new Set<RequestType>());

    async function fetchNotificationPreferences() {
        setState({ isFetching: true });

        const fetchRequest = makeCancellable(notifications.fetchNotificationPreferences());
        fetchNotificationPreferencesRequest.current = fetchRequest;
        try {
            const fetchedNotificationPreferences = await fetchRequest.promise;

            fetchNotificationPreferencesRequest.current = null;

            setState({ isFetching: false, notificationPreferences: fetchedNotificationPreferences });
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchNotificationPreferencesRequest.current = null;
            }

            setState({ isFetching: false });
        }
    }

    async function fetchUserAccessGroups() {
        setState({ isFetching: true });

        const fetchRequest = makeCancellable(accessGroups.fetch());

        fetchUserAccessGroupsRequest.current = fetchRequest;
        try {
            const fetchedUserGroups = await fetchRequest.promise;

            fetchUserAccessGroupsRequest.current = null;

            setState({ isFetching: false, userAccessGroups: fetchedUserGroups });
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchUserAccessGroupsRequest.current = null;
            }

            setState({ isFetching: false });
        }
    }

    async function updateAccessGroups() {
        if (!userAccessGroups || !userAccessGroups.length) return;

        setState({ isUpdating: true });

        const updateRequest = makeCancellable(
            accessGroups.updateAccessGroups(userAccessGroups),
        );

        updateAccessGroupsRequests.current.add(updateRequest);

        try {
            const updatedAccessGroups = await updateRequest.promise;
            updateAccessGroupsRequests.current.delete(updateRequest);

            if (updatedAccessGroups) {
                setState({ userAccessGroups: updatedAccessGroups });
            } else {
                setState({
                    isUpdating: false,
                    updatingError: new Error('ACCESS_GROUPS_UPDATE_ERROR'),
                });
            }

            if (!updateAccessGroupsRequests.current.size) {
                setState({ isUpdating: false });
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.error(error);
                updateAccessGroupsRequests.current.delete(updateRequest);

                if (!updateAccessGroupsRequests.current.size) {
                    setState({ isUpdating: false, updatingError: error });
                }
            }
        }
    }

    async function updateNotificationPreferences() {
        setState({ isUpdating: true });

        const updateRequest = makeCancellable(
            notifications.updateNotificationPreferences(notificationPreferences),
        );

        updateNotificationPreferenceRequests.current.add(updateRequest);

        try {
            const updatedNotifications = await updateRequest.promise;
            updateNotificationPreferenceRequests.current.delete(updateRequest);

            // Set updated notifications object
            if (updatedNotifications) {
                setState({ notificationPreferences: updatedNotifications });
            } else {
                setState({
                    isUpdating: false,
                    updatingError: new Error('COMMUNICATIONS_UPDATE_ERROR'),
                });
            }

            if (!updateNotificationPreferenceRequests.current.size) {
                setState({ isUpdating: false });
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.error(error);
                updateNotificationPreferenceRequests.current.delete(updateRequest);

                if (!updateNotificationPreferenceRequests.current.size) {
                    setState({ isUpdating: false, updatingError: error });
                }
            }
        }
    }

    function submitNotificationsUpdate() {
        updateNotificationPreferences();
        updateAccessGroups();
    }

    function toggleSelectAll(...allNotification: string[]) {
        const [communicationMedium, status] = allNotification;
        const newNotificationPreferences = {
            ...notificationPreferences,
            communications: notificationPreferences.communications.map((communication: any) => {
                const { medium } = communication;
                if (medium === communicationMedium) {
                    return { ...communication, enabled: status };
                }

                return communication;
            }),
        };
        setState({ notificationPreferences: newNotificationPreferences });
    }

    function toggleAccessGroupNotification(notificationName: string) {
        const newUserAccessGroups = userAccessGroups.map((accessGroup: any) => {
            const { name, member } = accessGroup;
            if (name === notificationName) {
                return { ...accessGroup, member: !member };
            }
            return accessGroup;
        });

        setState({ userAccessGroups: newUserAccessGroups });
    }

    function toggleNotification(notificationName: string) {
        const newNotificationPreferences = {
            ...notificationPreferences,
            communications: notificationPreferences.communications.map((communication: any) => {
                const { name, enabled } = communication;
                if (name === notificationName) {
                    return {
                        ...communication,
                        enabled: !enabled,
                    };
                }
                return communication;
            }),
        };

        setState({ notificationPreferences: newNotificationPreferences });
    }

    async function triggerNotification() {
        if (isGenerating) {
            return;
        }

        setState({ isGenerating: true });

        try {
            generateNotificationRequest.current = makeCancellable(
                notifications.generateNotification({
                    body: 'test',
                    notification_type: 'achievement',
                    title: 'Test',
                }),
            );
            const generatedNotification = await generateNotificationRequest.current.promise;
            generateNotificationRequest.current = null;

            Logger.log('generatedNotification', generatedNotification);

            triggerMessagesRequest.current = makeCancellable(notifications.triggerMessages());
            const messagesData = await triggerMessagesRequest.current.promise;
            triggerMessagesRequest.current = null;

            Logger.log('messagesData', messagesData);

            setState({ isGenerating: false });
        } catch (error: any) {
            if (!error.isCancelled) {
                generateNotificationRequest.current = null;
                triggerMessagesRequest.current = null;

                // Errors printed in called methods.
                setState({ isGenerating: false });
            }
        }
    }

    function renderCommunicationPreferences() {
        if (!notificationPreferences) {
            if (!isFetching) {
                return (
                    <div id="data-test-preferences-failed-text">
                        <Translate>COMMUNICATIONS_LOAD_ERROR</Translate>
                    </div>
                );
            }

            return null;
        }

        return (
            <>
                <NotificationsDashboard
                    onSelect={(params: string) => toggleNotification(params)}
                    onSelectAll={(...params: any[]) => toggleSelectAll(...params)}
                    notificationPreferences={notificationPreferences}
                    title="NOTIFICATIONS_PUSH"
                />
                <div style={{ paddingTop: '1rem' }}>
                    <NotificationsDashboard
                        onSelect={(params) => toggleNotification(params)}
                        onSelectAll={(...params: any[]) => toggleSelectAll(...params)}
                        notificationPreferences={notificationPreferences}
                        title="NOTIFICATIONS_EMAIL"
                    />
                </div>
                <WaffleBlock flag="public_beta" showIndicator>
                    <div style={{ paddingTop: '1rem' }}>
                        <NotificationsDashboard
                            onSelect={(params) => toggleAccessGroupNotification(params)}
                            title="NOTIFICATIONS_ACCESS_GROUPS"
                            userAccessGroups={userAccessGroups}
                        />
                    </div>
                </WaffleBlock>
                <div style={{ paddingTop: '2rem' }}>
                    <Button
                        color={AXS_TEXT_GREY}
                        onClick={() => submitNotificationsUpdate()}
                        type="submit"
                    >
                        <Translate>SAVE</Translate>
                    </Button>
                </div>
            </>
        );
    }

    function renderTriggerNotificationButton() {
        return (
            <WaffleBlock flag="test" showIndicator>
                <div style={{ paddingTop: '2rem' }}>
                    <Button
                        onClick={() => triggerNotification()}
                        style={{ maxWidth: 'initial' }}
                        type="button"
                    >
                        <Translate>NOTIFICATIONS_TRIGGER_BUTTON</Translate>
                    </Button>
                </div>
            </WaffleBlock>
        );
    }

    useEffect(() => {
        fetchNotificationPreferences();
        fetchUserAccessGroups();

        return () => {
            if (generateNotificationRequest.current) {
                generateNotificationRequest.current.cancel();
            }

            if (fetchNotificationPreferencesRequest.current) {
                fetchNotificationPreferencesRequest.current.cancel();
            }

            if (triggerMessagesRequest.current) {
                triggerMessagesRequest.current.cancel();
            }

            updateNotificationPreferenceRequests.current.forEach((request) => request.cancel());
            updateNotificationPreferenceRequests.current.clear();
        };
    }, []);

    return (
        <div>
            <Spinner loading={isFetching || isGenerating || isUpdating} />
            <ErrorModal
                error={updatingError}
                isOpen={!!updatingError}
                onClose={() => setState({ updatingError: null })}
            />
            {renderCommunicationPreferences()}
            {renderTriggerNotificationButton()}
        </div>
    );
}

export default Notifications;
