import ImportExportIcon from '@mui/icons-material/ImportExport';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './ActivitiesTableHeader.module.scss';

import FilterDropdownHeader from '../filterDropDownHeader';

import CheckboxForm from '../../../../views/checkboxForm';
import DateRangePicker from '../../../../components/dateRangePicker';
import RangeSlider from '../../../../components/rangeSlider';
import Translate from '../../../../components/translate';
import { useSetState } from '../../../../hooks';
import { ActivityType, useBikes, useUnits } from '../../../../providers';

const HEADERS = [
    { key: 'start_ts', label: 'DATE' },
    { key: 'name', label: 'ACTIVITY_NAME' },
    { key: 'type', label: 'TYPE' },
    { key: 'bike', label: 'BIKE' },
    { key: 'distance', label: 'DISTANCE' },
    { key: 'duration', label: 'DURATION' },
    { key: 'normalized_power', label: 'POWER' },
];
const MAX_DISTANCE = 200;
const MAX_DURATION = 24;
const MIN_DISTANCE = 0;
const MIN_DURATION = 0;
const DEFAULT_STATE = {
    distance: [MIN_DISTANCE, MAX_DISTANCE],
    duration: [MIN_DURATION, MAX_DURATION],
    filterActivityTypeOpen: false,
    filterBikeOpen: false,
    filterDateOpen: false,
    filterDistanceDurationOpen: false,
    from: null,
    to: null,
};

interface ActivitiesTableHeaderProps{
    activeActivityFilters: string[],
    activeBikeFilters: string[] | number[],
    activityTypes: ActivityType[],
    applyFilters: (value: {[key:string]: number[] | string} | {[key:string]: (string | number)[]}) => void,
    distanceFilters: number[],
    durationFilters: number[],
    fromDate: string,
    onSort: (key: string) => void,
    toDate: string,
}

const ActivitiesTableHeader = ({
    activeActivityFilters,
    activeBikeFilters,
    activityTypes,
    applyFilters,
    distanceFilters,
    durationFilters,
    fromDate,
    onSort,
    toDate,
}: ActivitiesTableHeaderProps) => {
    const [state, setState] = useSetState(DEFAULT_STATE);
    const bikes = useBikes();
    const units = useUnits();

    const {
        distance,
        duration,
        filterActivityTypeOpen,
        filterBikeOpen,
        filterDateOpen,
        filterDistanceOpen,
        filterDurationOpen,
        from,
        to,
    } = state;

    function applyDateFilters() {
        const newFromDate = (fromDate !== from);
        const newToDate = (toDate !== to);

        if (newFromDate || newToDate) {
            applyFilters({
                ...(newFromDate ? { fromDate: from } : {}),
                ...(newToDate ? { toDate: to } : {}),
            });
        }
    }

    function applyDistanceFilters() {
        const newDistance = JSON.stringify(distanceFilters) !== JSON.stringify(distance);

        if (newDistance) {
            applyFilters({
                ...(newDistance ? { distanceFilters: distance } : {}),
            });
        }
    }

    function applyDurationFilters() {
        const newDuration = JSON.stringify(durationFilters) !== JSON.stringify(duration);

        if (newDuration) {
            applyFilters({
                ...(newDuration ? { durationFilters: duration } : {}),
            });
        }
    }

    function toggleFilterDropdown(key: string) {
        const { [key]: dropdownFilter } = state;

        const dropdownKeys: Record<string, any> = [
            'filterActivityTypeOpen',
            'filterBikeOpen',
            'filterDateOpen',
            'filterDistanceOpen',
            'filterDurationOpen',
        ];

        delete dropdownKeys[key];

        const newState: Record<string, any> = {};
        newState[key] = !dropdownFilter;

        dropdownKeys.forEach((dropdownKey: string) => {
            const { [dropdownKey]: dropdown } = state;

            if (dropdown) {
                newState[dropdownKey] = false;
            }
        });

        setState(newState);
    }

    function toggleDistanceFilter() {
        if (filterDistanceOpen) {
            applyDistanceFilters();
        }

        toggleFilterDropdown('filterDistanceOpen');
    }

    function toggleDurationFilter() {
        if (filterDurationOpen) {
            applyDurationFilters();
        }
        toggleFilterDropdown('filterDurationOpen');
    }

    function toggleDateFilter() {
        if (filterDateOpen) {
            applyDateFilters();
        }

        toggleFilterDropdown('filterDateOpen');
    }

    function renderFilter(key: string) {
        const bikesList = bikes.list.map((bike) => ({ label: bike.name, value: bike.id }));

        switch (key) {
            case 'start_ts': {
                return (
                    <FilterDropdownHeader
                        isOpen={filterDateOpen}
                        label="DATE_RANGE"
                        onClick={() => toggleDateFilter()}
                    >
                        <div className={styles.dateFilterDropdown}>
                            <DateRangePicker
                                end={to}
                                start={from}
                                onSave={(newDateRange) => setState(newDateRange)}
                            />
                        </div>
                    </FilterDropdownHeader>
                );
            }
            case 'type': {
                return (
                    <FilterDropdownHeader
                        dropdownStyle={{ transform: 'translateX(-100%)' }}
                        isOpen={filterActivityTypeOpen}
                        label="ACTIVITY_TYPE"
                        onClick={() => toggleFilterDropdown('filterActivityTypeOpen')}
                    >
                        <CheckboxForm
                            checked={activeActivityFilters}
                            onChange={(typeFilters: (string | number)[]) => applyFilters({ typeFilters })}
                            options={activityTypes}
                        />
                        <div style={{ textAlign: 'right' }}>
                            <button
                                className={styles.clearButton}
                                onClick={() => applyFilters({ typeFilters: [] })}
                                type="button"
                            >
                                <Translate>CLEAR</Translate>
                            </button>
                        </div>
                    </FilterDropdownHeader>
                );
            }
            case 'bike': {
                return (
                    <FilterDropdownHeader
                        dropdownStyle={{ transform: 'translateX(-100%)' }}
                        isOpen={filterBikeOpen}
                        label="BIKES"
                        onClick={() => toggleFilterDropdown('filterBikeOpen')}
                    >
                        <CheckboxForm
                            checked={activeBikeFilters}
                            onChange={(bikeFilters: (string | number)[]) => applyFilters({ bikeFilters })}
                            options={bikesList}
                        />
                        {!bikesList.length && (
                            <>
                                <div style={{ padding: '0.5rem 1rem' }}>
                                    <Translate>NO_BIKES</Translate>
                                </div>
                                <div style={{ padding: '0.5rem 1rem' }}>
                                    <Link className={styles.createBike} to="/buildbike">
                                        <Translate>BIKE_ADD_NEW</Translate>
                                    </Link>
                                </div>
                            </>
                        )}
                        <div style={{ textAlign: 'right' }}>
                            <button
                                className={styles.clearButton}
                                onClick={() => applyFilters({ bikeFilters: [] })}
                                type="button"
                            >
                                <Translate>CLEAR</Translate>
                            </button>
                        </div>
                    </FilterDropdownHeader>
                );
            }
            case 'distance': {
                return (
                    <FilterDropdownHeader
                        dropdownStyle={{ transform: 'translateX(-100%)' }}
                        isOpen={filterDistanceOpen}
                        label="DISTANCE"
                        onClick={() => toggleDistanceFilter()}
                    >
                        <div className={styles.distanceDurationDropdown}>
                            <RangeSlider
                                label="DISTANCE"
                                max={MAX_DISTANCE}
                                min={MIN_DISTANCE}
                                onChange={(values) => setState({ distance: values })}
                                rangeValues={distance}
                                units={units.getLabelDistance().shorthand}
                            />
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            <button
                                className={styles.clearButton}
                                onClick={() => setState({ distance: [MIN_DISTANCE, MAX_DISTANCE] })}
                                type="button"
                            >
                                <Translate>CLEAR</Translate>
                            </button>
                        </div>
                    </FilterDropdownHeader>
                );
            }
            case 'duration': {
                return (
                    <FilterDropdownHeader
                        dropdownStyle={{ transform: 'translateX(-125%)' }}
                        isOpen={filterDurationOpen}
                        label="DURATION"
                        onClick={() => toggleDurationFilter()}
                    >
                        <div className={styles.distanceDurationDropdown}>
                            <RangeSlider
                                label="DURATION"
                                max={MAX_DURATION}
                                min={MIN_DURATION}
                                onChange={(values) => setState({ duration: values })}
                                rangeValues={duration}
                                units="HOURS"
                            />
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            <button
                                className={styles.clearButton}
                                onClick={() => setState({ duration: [MIN_DURATION, MAX_DURATION] })}
                                type="button"
                            >
                                <Translate>CLEAR</Translate>
                            </button>
                        </div>
                    </FilterDropdownHeader>
                );
            }
            default: {
                return null;
            }
        }
    }

    function renderTableHeaders() {
        return HEADERS.map(({ key, label }) => (
            <th className={styles.tableHeader} key={key}>
                <div className={styles.tableCell}>
                    <div className={styles.tableCellTitle}>
                        {(key !== 'type' && key !== 'bike' && key !== 'name') && (
                            <button onClick={() => onSort(key)} type="button">
                                <ImportExportIcon className={styles.sortIcon} />
                            </button>
                        )}
                        <Translate>{label}</Translate>
                    </div>
                    {renderFilter(key)}
                </div>
            </th>
        ));
    }

    return (
        <thead>
            <tr>
                {renderTableHeaders()}
                <th className={styles.tableHeader}>
                    <Translate>EDIT</Translate>
                    /
                    <Translate>DELETE</Translate>
                </th>
            </tr>
        </thead>
    );
};

export default ActivitiesTableHeader;
