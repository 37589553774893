import { ReactElement, ReactNode } from 'react';

import styles from './Card.module.scss';

import Translate from '../../../components/translate';

interface CardProps {
    children?: ReactNode,
    containerClassName?: string,
    containerStyles?: Record<string, unknown>,
    headerClassName?: string,
    headerStyles?: Record<string, unknown>,
    label?: string | ReactElement,
    logo?: string,
    style?: Record<string, unknown>,
}

const Card = ({
    children = null,
    containerClassName = '',
    headerClassName = '',
    headerStyles = {},
    label = '',
    logo = '',
    style = {},
    containerStyles = {},
}: CardProps) => (
    <div className={`${styles.container} ${containerClassName} ${containerStyles} `} style={style}>
        <div
            className={`${styles.header} ${headerClassName}`}
            style={headerStyles}
        >
            <div className={styles.headerContainer}>
                <img alt="" className={styles.icon} src={logo} />
                <div className={styles.title}>
                    <Translate>{label}</Translate>
                </div>
            </div>
        </div>
        <div>
            {children}
        </div>
    </div>
);

export default Card;
