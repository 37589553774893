import {
    arrayOf,
    bool,
    func,
    shape,
} from 'prop-types';

const COMPONENT_SUMMARIES_PROP_TYPES = {
    componentSummaries: shape({
        clear: func,
        fetch: func,
        fetchExample: func,
        fetchShockWizData: func,
        isFetching: bool,
        list: arrayOf(shape({})),
    }),
};

export default COMPONENT_SUMMARIES_PROP_TYPES;
