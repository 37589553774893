import moment from 'moment';

import styles from './RouteSummary.module.scss';
import RouteTrack from './routeTrack';

import Row from '../../row';

import { iconClock, iconMap } from '../../../../assets';
import Translate from '../../../../components/translate';
import { toLocaleString } from '../../../../constants';
import { Activity, ComponentSummary, useUnits } from '../../../../providers';

interface RouteSummaryProps {
    activity: Activity & { duration?: number };
    gpsComponent: ComponentSummary;
    gpsComponentFiltered: ComponentSummary;
}

const RouteSummary = ({ activity, gpsComponent, gpsComponentFiltered }: RouteSummaryProps) => {
    const units = useUnits();

    return (
        <div className={styles.rowContainer}>
            <Row
                borderBottomNone
                dividerComponent={
                    <RouteTrack gpsComponent={gpsComponent} gpsComponentFiltered={gpsComponentFiltered} />
                }
            >
                <div>
                    <div className={styles.row}>
                        <div className={styles.label}>
                            <Translate>ROUTE_START_STOP</Translate>
                        </div>
                        <div className={styles.timesContainer}>
                            <div className={styles.timeContainer}>
                                <div className={`${styles.time} ${styles.startTime}`} />
                                <div className={styles.value} id="data-test-route-start-time">
                                    {units.formatTime(activity.adjustedStartTs * 1000)}
                                </div>
                            </div>
                            <div className={styles.timeContainer}>
                                <div className={`${styles.time} ${styles.endTime}`} />
                                <div className={styles.value} id="data-test-route-end-time">
                                    {units.formatTime(activity.adjustedEndTs * 1000)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img alt="" className={styles.icon} src={iconMap} />
                        <div className={styles.label}>
                            <Translate>DISTANCE</Translate>
                        </div>
                        <div className={styles.distanceValue}>
                            {activity.activitysummary_set.length && activity.activitysummary_set[0].data.distance ? (
                                <>
                                    {toLocaleString(
                                        units.formatDistance(
                                            units.convertDistanceFromSI(activity.activitysummary_set[0].data.distance)
                                        )
                                    )}
                                    &nbsp;
                                    <Translate>{units.getLabelDistance().longhand}</Translate>
                                </>
                            ) : (
                                '--'
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.row}>
                        <img alt="" className={styles.icon} src={iconClock} />
                        <div className={styles.label}>
                            <Translate>MOVING_TIME</Translate>
                        </div>
                        <div className={styles.value}>
                            {gpsComponent
                                ? moment
                                      .duration(gpsComponent.data.moving_time, 'seconds')
                                      .format('h[:]mm[:]ss', { stopTrim: 'm' })
                                : '--'}
                        </div>
                    </div>
                    <div>
                        <div className={styles.label}>
                            <Translate>ELAPSED_TIME</Translate>
                        </div>
                        <div className={styles.value}>
                            {activity.duration
                                ? moment.duration(activity.duration, 'seconds').format('h[:]mm[:]ss', { stopTrim: 'm' })
                                : '--'}
                        </div>
                    </div>
                </div>
            </Row>
        </div>
    );
};

export default RouteSummary;
