import React from 'react';

import styles from './HeartZoneDistribution.module.scss';

import { LthrDisplay } from '../thresholdDisplay';
import ZoneDistribution from '../zoneDistribution';

import { ZONE_COLORS_HEART } from '../../../../constants';
import { ComponentSummary } from '../../../../providers';

const HEART_RATE_ZONE_TITLES = [
    'ENDURANCE',
    'MODERATE',
    'TEMPO',
    'THRESHOLD',
    'ANAEROBIC',
];

interface HeartZoneDistributionProps {
    disableEdit?: boolean;
    heartComponent?: ComponentSummary | null
    heartZones?: {min: number, max: number}[];
}

const HeartZoneDistribution = ({
    disableEdit = true,
    heartComponent = null,
    heartZones = [],
}: HeartZoneDistributionProps) => {
    if (!heartComponent || !heartZones) {
        return null;
    }

    return (
        <div className={styles.container}>
            <ZoneDistribution
                data={heartComponent.data.hr}
                units="UNITS_BPM"
                zoneColors={ZONE_COLORS_HEART}
                zones={heartZones}
                zoneTitles={HEART_RATE_ZONE_TITLES}
            />
            {!disableEdit && (
                <div className={styles.footer}>
                    <LthrDisplay />
                </div>
            )}
        </div>
    );
};

export default HeartZoneDistribution;
