import React, { lazy, Suspense, useState } from 'react';

import { logoRedBlackAXS } from '../../../../assets';
import Placeholder from '../../../../components/placeholder';
import Spinner from '../../../../components/spinner';
import Translate from '../../../../components/translate';

const GearExample = lazy(() => import('./gearExample'));

const GearPlaceholder = () => {
    const [header, setHeader] = useState('');

    return (
        <Placeholder
            header={header}
            imageSrc={logoRedBlackAXS}
            renderExample={() => (
                <Suspense fallback={<Spinner />}>
                    <GearExample onCarouselItemChange={setHeader} />
                </Suspense>
            )}
            subtitle="GEAR_NO_DATA"
        >
            <Translate>GEAR_NO_GEAR_INFO</Translate>
        </Placeholder>
    );
};

export default GearPlaceholder;
