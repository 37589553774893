import moment from 'moment';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import ActivityStatistics from './activityStatistics';
import styles from './ActivitySummary.module.scss';
import Map from './map';
import AssignBikeModal from './routeSummary/assignBikeModal';
import RouteSummary from './routeSummary/RouteSummary';
import SegmentChart from './segmentChart';
import SegmentTools from './segmentTools';

import ActivityDropdown from '../activityDropdown';
import Card from '../card';
import DeleteActivityModal from '../deleteActivityModal';
import EditActivityModal from '../editActivityModal';
import MobileActivityDropdown from '../mobileActivityDropdown';
import Row from '../row';
import ShareActivityModal from '../shareActivityModal';
import TrimActivityModal from '../trimActivityModal';

import { logoAxsLogo } from '../../../assets';
import { RequestType, SRAM_600 } from '../../../constants';
import Translate from '../../../components/translate';
import { useSetState } from '../../../hooks';
import {
    Activity, ComponentSummary, useActivities,
} from '../../../providers';
import BikeImage from '../../../views/bikeImage';
import { Bike } from '../../../providers/bikes';

const DEFAULT_STATE = {
    activityDropdownOpen: false,
    showAssignBikeModal: false,
    showDeleteActivity: false,
    showEditActivityModal: false,
    showShareActivity: false,
    showTrimActivity: false,
};

interface ActivitySummaryProps {
    activity: Activity,
    bike: Bike,
    deleteActivity: () => Promise<boolean>,
    disableEdit: boolean,
    downloadActivity: () => void,
    endTs: number,
    gpsComponent: ComponentSummary,
    gpsComponentFiltered: ComponentSummary,
    isFetchingActivity: boolean,
    isFetchingBike: boolean,
    isOwner: boolean,
    regenerateActivity: () => void,
    shockWiz: any,
    startTs: number,
    unassignBikeFromActivity: () => void,
    updateActivity: (data: any) => void,
    updateActivitySummary: (data:Record<string, unknown>) => RequestType | Promise<any>,
}

const ActivitySummary = ({
    activity,
    bike,
    deleteActivity,
    disableEdit,
    downloadActivity,
    endTs,
    gpsComponent,
    gpsComponentFiltered,
    isFetchingActivity,
    isFetchingBike,
    isOwner,
    regenerateActivity,
    shockWiz,
    startTs,
    unassignBikeFromActivity,
    updateActivity,
    updateActivitySummary,
}: ActivitySummaryProps) => {
    const [state, setState] = useSetState(DEFAULT_STATE);
    const activities = useActivities();
    const { search } = useLocation();
    const navigate = useNavigate();
    const {
        activityDropdownOpen,
        showAssignBikeModal,
        showDeleteActivity,
        showEditActivityModal,
        showShareActivity,
        showTrimActivity,
    } = state;

    const closeActivityDropdown = () => {
        setState({ activityDropdownOpen: false });
    };

    const toggleEditActivity = () => {
        setState({ showEditActivityModal: !showEditActivityModal });
    };

    const toggleActivityDropdown = () => {
        if (!disableEdit) {
            setState({ activityDropdownOpen: !activityDropdownOpen });
        }
    };

    const toggleAssignBike = () => {
        if (!disableEdit) {
            setState({ showAssignBikeModal: !showAssignBikeModal });
        }
    };

    const onActivitySave = (updatedActivity: Bike) => {
        if (!updatedActivity) return;

        toggleAssignBike();
    };

    const toggleDeleteActivity = () => {
        setState({ showDeleteActivity: !showDeleteActivity });
    };

    const toggleShareActivity = () => {
        setState({ showShareActivity: !showShareActivity });
    };

    const toggleTrimActivity = () => {
        setState({ showTrimActivity: !showTrimActivity });
    };

    const renderActivityDropdown = () => {
        if (disableEdit) return null;

        const activityDropdownProps = {
            bike,
            disableEdit,
            downloadActivity,
            isOwner,
            onClick: () => toggleActivityDropdown(),
            open: activityDropdownOpen,
            regenerateActivity,
            toggleDeleteModal: () => toggleDeleteActivity(),
            toggleEditActivity: () => toggleEditActivity(),
            toggleShareActivity: () => toggleShareActivity(),
            unassignBikeFromActivity,
        };

        return (
            <div className={styles.activityDropdown}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <ActivityDropdown {...activityDropdownProps} />
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <MobileActivityDropdown {...activityDropdownProps} />
            </div>
        );
    };

    const renderAssignBikeModal = () => {
        if (!isOwner) return null;

        return (
            <AssignBikeModal
                activity={activity}
                closeActivityDropdown={() => closeActivityDropdown()}
                onCancel={() => toggleAssignBike()}
                onSave={(updatedActivity) => onActivitySave(updatedActivity)}
                open={showAssignBikeModal}
                updateActivity={updateActivity}
            />
        );
    };

    const renderBike = () => {
        if (!isOwner && !bike) return null;

        let bikeElement = null;
        let bikeName = '--';

        if (bike) {
            bikeElement = (
                <Link to={`/bikerack/${bike.id}`}>
                    <BikeImage bike={bike} />
                </Link>
            );

            bikeName = bike.name;
        } else if (!isFetchingActivity && !isFetchingBike && isOwner) {
            bikeElement = (
                <button className={styles.bikeButton} onClick={() => toggleAssignBike()} type="button">
                    <Translate>ADD_BIKE</Translate>
                </button>
            );
        }

        return (
            <div className={styles.column} style={{ paddingLeft: '2rem' }}>
                <div className={styles.columnItemMain}>
                    <div className={styles.label}>
                        <Translate>BIKE</Translate>
                    </div>
                    <div className={styles.value}>{bikeName}</div>
                </div>
                <div className={styles.columnItemSecondary} style={{ width: 90 }}>
                    {bikeElement}
                </div>
            </div>
        );
    };

    const renderActivityOverview = () => {
        let activityTypeJSX = <div id="data-test-activity-type">--</div>;
        if (activity) {
            const activityType = activities.activityTypes.find((type) => activity.type === type.value);
            if (activityType) {
                activityTypeJSX = <Translate id="data-test-activity-type">{activityType.label}</Translate>;
            } else {
                activityTypeJSX = <div id="data-test-activity-type">{activity.type}</div>;
            }
        }

        return (
            <Row
                style={{ borderBottom: `1px solid ${SRAM_600}`, padding: '1rem 2rem' }}
                columnStyle={!isOwner ? {} : { maxWidth: '50%' }}
            >
                <div className={styles.column}>
                    <div className={styles.columnItemMain}>
                        <div className={styles.label}>
                            <Translate>ACTIVITY_NAME</Translate>
                        </div>
                        <div className={styles.value} id="data-test-activity-name">
                            {activity ? activity.name : ''}
                        </div>
                    </div>
                    <div className={styles.columnItemSecondary} style={{ paddingRight: '1rem' }}>
                        <div className={styles.label}>
                            <Translate>ACTIVITY_TYPE</Translate>
                        </div>
                        <div className={styles.activityType}>{activityTypeJSX}</div>
                    </div>
                </div>
                {renderBike()}
            </Row>
        );
    };

    const renderDeleteActivityModal = () => {
        if (!isOwner) return null;

        return (
            <DeleteActivityModal
                deleteActivity={() => deleteActivity()}
                onCancel={() => toggleDeleteActivity()}
                onDelete={() => navigate('/activities')}
                open={showDeleteActivity}
            />
        );
    };

    const renderEditActivityModal = () => {
        if (!isOwner) return null;

        const onSaveEdit = () => {
            toggleEditActivity();
            closeActivityDropdown();
        };

        return (
            <EditActivityModal
                activity={activity}
                activityTypes={activities.activityTypes}
                id="data-test-activity-modal"
                onSave={() => {
                    onSaveEdit();
                }}
                onCancel={() => toggleEditActivity()}
                open={showEditActivityModal}
                updateActivity={updateActivity}
            />
        );
    };

    const renderShareActivityModal = () => {
        if (disableEdit) return null;

        return (
            <ShareActivityModal
                activity={activity}
                isOwner={isOwner}
                onClose={() => {
                    toggleShareActivity();
                    closeActivityDropdown();
                }}
                open={showShareActivity}
                updateActivity={updateActivity}
            />
        );
    };

    const renderTrimActivityModal = () => {
        if (!isOwner) return null;

        return (
            <TrimActivityModal
                activity={activity}
                endTs={endTs}
                onClose={() => toggleTrimActivity()}
                open={showTrimActivity}
                startTs={startTs}
                updateActivitySummary={updateActivitySummary}
            />
        );
    };

    return (
        <Card
            label={(
                <div className={styles.activityHeaderContainer}>
                    <div className={styles.activityHeaderText}>
                        <span>
                            <Translate>RIDE_REPORT</Translate>
                            &nbsp;&nbsp;
                        </span>
                        {activity && (
                            <span className={styles.subTitle}>
                                {moment.utc(activity.adjustedStartTs * 1000).format('MMMM Do YYYY')}
                            </span>
                        )}
                    </div>
                    {renderActivityDropdown()}
                </div>
            )}
            logo={logoAxsLogo}
            style={{ marginTop: 0 }}
        >
            {renderAssignBikeModal()}
            {renderEditActivityModal()}
            {renderDeleteActivityModal()}
            {renderShareActivityModal()}
            {renderTrimActivityModal()}
            <div className={styles.content}>
                {renderActivityOverview()}
                <div className={styles.container}>
                    <RouteSummary
                        activity={activity}
                        gpsComponent={gpsComponent}
                        gpsComponentFiltered={gpsComponentFiltered}
                    />
                    <div className={styles.middleBorder} />
                    <ActivityStatistics
                        activity={activity}
                        id="data-test-activitysummary-statistics"
                        gpsComponent={gpsComponent}
                    />
                </div>
                <Map activity={activity} gpsComponent={gpsComponentFiltered} shockWiz={shockWiz} />
                <SegmentTools
                    activity={activity}
                    disableTrim={disableEdit}
                    endTs={endTs}
                    isOwner={isOwner}
                    onToggleTrimActivity={() => toggleTrimActivity()}
                    startTs={startTs}
                >
                    <SegmentChart
                        // activity={activity}
                        endTs={search ? endTs : null}
                        gpsComponent={gpsComponent}
                        startTs={search ? startTs : null}
                    />
                </SegmentTools>
            </div>
        </Card>
    );
};

export default ActivitySummary;
