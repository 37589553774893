import { useEffect, useState } from 'react';

import DisplayLabel from './displayLabel';
import styles from './RangeSlider.module.scss';

import Slider from '../slider';
import Translate from '../translate';

interface RangeSliderProps {
    label?: string,
    max: number,
    min: number,
    onChange: (value:number[]) => void,
    rangeValues: number[],
    units: string,
}

const RangeSlider = ({
    label = '',
    max = 10,
    min = 0,
    onChange = () => {},
    rangeValues = [],
    units = '',
}: RangeSliderProps) => {
    const [stagedValues, setStagedValues] = useState<number[]>([]);
    useEffect(() => {
        if (rangeValues.length === stagedValues.length
            && rangeValues.every((index, value) => value === stagedValues[index])) {
            return;
        }
        setStagedValues(rangeValues);
    }, [rangeValues]);

    return (
        <div className={styles.container}>
            <DisplayLabel
                label={label}
                max={max}
                min={min}
                rangeValues={rangeValues}
                stagedValues={stagedValues}
                units={units}
            />
            <div className={styles.sliderContainer}>
                <Slider
                    max={max}
                    min={min}
                    onChange={(_event: any, values: number[]) => setStagedValues(values)}
                    onChangeCommitted={(_event: any, values: number[]) => onChange(values)}
                    value={stagedValues}
                    valueLabelDisplay="auto"
                    valueLabelFormat={((value: number) => ((value === max)
                        ? <Translate>ANY</Translate>
                        : value
                    ))}
                />
            </div>
        </div>
    );
};

export default RangeSlider;
