import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './NewBike.module.scss';

import Button from '../../components/button';
import ContentContainer from '../../components/contentContainer';
import Spinner from '../../components/spinner';
import Translate, { useTranslation } from '../../components/translate';
import { makeCancellable, RequestType } from '../../constants';
import Logger from '../../Logger';
import { useBikes } from '../../providers';
import AxsAppCard from '../../views/axsAppCard';
import BikeCard from '../../views/bikeCard';
import BikeForm from '../../views/bikeForm';
import ErrorModal from '../../views/errorModal';

interface stateTypes {
    error: Error | null,
    imagePreview: any,
    isFetching: boolean,
}

const NewBike = () => {
    const [state, setState] = useState<stateTypes>({ error: null, imagePreview: null, isFetching: false });
    const navigate = useNavigate();
    const bikes = useBikes();
    const translate = useTranslation();
    const addBikeRequest = useRef<RequestType | null>(null);

    useEffect(() => () => {
        if (addBikeRequest.current) {
            addBikeRequest.current.cancel();
            addBikeRequest.current = null;
        }
    }, []);

    const saveBike = async (bike:any) => {
        if (!bike.name || !bike.name.length) return;

        setState({ ...state, isFetching: true });

        addBikeRequest.current = makeCancellable(bikes.addBike(bike));

        try {
            const newBike = await addBikeRequest.current.promise;

            addBikeRequest.current = null;

            if (newBike) {
                navigate(`/bikerack/${newBike.id}`);
            } else {
                setState({
                    ...state,
                    error: new Error('BIKE_NEW_ERROR'),
                    isFetching: false,
                });
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn(error);
                addBikeRequest.current = null;

                setState({
                    ...state,
                    error: new Error('BIKE_NEW_ERROR'),
                    isFetching: false,
                });
            }
        }
    };

    return (
        <ContentContainer>
            <ErrorModal
                error={state.error}
                onClose={() => setState({ ...state, error: null })}
            />
            <Spinner loading={state.isFetching} />
            <div className={styles.content}>
                <BikeCard
                    bike={{ name: translate('BIKE_NEW_PROFILE') }}
                    image={state.imagePreview}
                />
                <div className={styles.formContainer}>
                    <BikeForm
                        nameIsRequired
                        onImageSelected={(newImagePreview) => setState({ ...state, imagePreview: newImagePreview })}
                        onSubmit={(bike) => saveBike(bike)}
                    >
                        <div className={styles.buttonContainer}>
                            <Button className={styles.saveButton} type="submit">
                                <Translate>SAVE</Translate>
                            </Button>
                        </div>
                    </BikeForm>
                </div>
            </div>
            <AxsAppCard />
        </ContentContainer>
    );
};

NewBike.defaultProps = { navigate: null };

export default NewBike;
