import moment from 'moment';

import Logger from '../../Logger';
import { Activity } from './types';

/**
 * Checks if a timestamp is within the range of an Activites timestamps
 * @param {Activity} activity
 * @param {number} timestamp
 * @returns {boolean} true if the timestamp is within the range of the activity
 */
function checkTimestampIsValid(activity: Activity, timestamp: number) {
    if (!activity || !timestamp) {
        return false;
    }

    if (timestamp > activity.end_ts || timestamp < activity.start_ts) {
        return false;
    }

    return true;
}

function parseActivitySummarySet(activity: Activity) {
    const newActivitySummarySet = [];

    if (activity) {
        if (activity.activitysummary_set && activity.activitysummary_set.length) {
            let [activitySummarySet] = activity.activitysummary_set;

            if (!activitySummarySet.data) {
                Logger.warn(`Activity ${activity.id} is missing activitysummary_set data object`);

                activitySummarySet.data = {} as any;
            }

            let trimEndTsAdjusted = null;
            let trimStartTsAdjusted = null;

            if (
                checkTimestampIsValid(activity, activitySummarySet.data.trim_end_ts)
                && checkTimestampIsValid(activity, activitySummarySet.data.trim_start_ts)
            ) {
                trimEndTsAdjusted = activitySummarySet.data.trim_end_ts + activity.timezone_offset;
                trimStartTsAdjusted = activitySummarySet.data.trim_start_ts + activity.timezone_offset;
            } else if (activitySummarySet.data.trim_end_ts || activitySummarySet.data.trim_start_ts) {
                Logger.warn(`Activity ${activity.id} has invalid trimmed values`);
            }

            activitySummarySet = {
                ...activitySummarySet,
                data: {
                    ...activitySummarySet.data,
                    trimEndTsAdjusted,
                    trimStartTsAdjusted,
                },
            };

            newActivitySummarySet.push(activitySummarySet);
        }
    }

    return newActivitySummarySet;
}

export default function parseActivity(activity: Activity) {
    const timeOffset = Number.isFinite(activity.timezone_offset) ? activity.timezone_offset : moment().utcOffset() * 60;

    const newActivity = {
        ...activity,
        activitysummary_set: parseActivitySummarySet(activity),
        adjustedEndTs: activity.end_ts + timeOffset,
        adjustedStartTs: activity.start_ts + timeOffset,
        duration: activity.end_ts - activity.start_ts,
        timezone_offset: timeOffset,
    };

    return newActivity;
}
