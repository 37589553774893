import { scaleTime } from 'd3';
import {
    Area,
    Grid,
    Line,
    lineBisector,
    onMouseMove,
    onTouchMove,
    Scale,
    VerticalLine,
} from 'd3-charts-react';
import { useMemo } from 'react';
import { Axis, axisPropsFromTickScale, LEFT } from 'react-d3-axis';
import { useResizeDetector } from 'react-resize-detector';

import styles from './PowerLineChart.module.scss';

import FtpDisplay from '../thresholdDisplay/ftpDisplay';

import AltitudeChart from '../../altitudeChart';
import {
    categoriseData,
    extractTime,
    extractValue,
    formatPower,
    mutateScaleNice,
} from '../../chartHelpers';
import BikeComponentLink from '../../bikeComponentLink';

import CheckBox from '../../../../components/checkbox';
import ExpandChart from '../../../../views/expandChart';
import Translate from '../../../../components/translate';
import {
    RED_LIGHT,
    SRAM_200,
    toLocaleString,
    ZONE_COLORS_POWER,
} from '../../../../constants';
import { useSetState } from '../../../../hooks';
import { ComponentSummary, useUnits } from '../../../../providers';

interface PowerLineChartProps {
    disableEdit?: boolean,
    disableOwnerFeatures?: boolean,
    gpsComponent: ComponentSummary,
    powerComponent: ComponentSummary,
    powerZones: {min: number, max: number}[],
}

interface ArealistProps {
    powerComponent: ComponentSummary,
    powerZones: {min: number, max: number}[],
    scale:any,
    showCategories:boolean,
}

function AreaList({
    powerComponent,
    powerZones,
    scale,
    showCategories,
}: ArealistProps): any {
    if (!showCategories) return null;

    const extractTimeCategorised = (dataPoint: any, value: number, index: number) => (
        extractTime(powerComponent, value, dataPoint.startIndex + index)
    );

    const categorisedData = useMemo(
        () => categoriseData(powerComponent.data.power, powerZones) || [],
        [
            scale,
            powerComponent.data.power,
            powerZones,
            showCategories,
        ],
    );

    return categorisedData.map((dataPoint, index) => (
        <Area
            data={dataPoint.data}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            scale={scale}
            style={{
                fill: ZONE_COLORS_POWER[dataPoint.zoneIndex],
                stroke: ZONE_COLORS_POWER[dataPoint.zoneIndex],
            }}
            xExtractor={(value: number, newIndex: number) => extractTimeCategorised(
                dataPoint,
                value,
                newIndex,
            )}
            yExtractor={extractValue}
        />
    ));
}

function PowerLineChart({
    disableEdit = false,
    disableOwnerFeatures = false,
    gpsComponent,
    powerComponent,
    powerZones = [],
}: PowerLineChartProps) {
    const [state, setState] = useSetState({
        altitude: 0,
        power: 0,
        showCategories: !disableOwnerFeatures,
        timestamp: null,
    });
    const {
        altitude,
        power,
        showCategories,
        timestamp,
    } = state;
    const units = useUnits();

    const powerExtractor = (...params: any) => {
        const values = lineBisector(...params);

        setState({ power: values ? values[1] : 0 });
    };

    const renderChart = (scale: any) => {
        const xAxisProps = axisPropsFromTickScale(scale.x, 3);
        const yAxisProps = axisPropsFromTickScale(scale.y, 3);

        const width = scale.x.range()[1];
        const height = scale.y.range()[0];

        return (
            <div className={styles.chartContainer}>
                <svg
                    className={styles.chart}
                    height={height}
                    onMouseLeave={() => setState({ altitude: 0, power: 0, timestamp: null })}
                    onMouseMove={(event) => {
                        const hoveredTimeStamp = onMouseMove(event, scale);

                        if (hoveredTimeStamp) {
                            setState({ timestamp: hoveredTimeStamp });
                        }
                    }}
                    onTouchMove={(event) => {
                        const hoveredTimeStamp = onTouchMove(event, scale);

                        if (hoveredTimeStamp) {
                            setState({ timestamp: hoveredTimeStamp });
                        }
                    }}
                    width={width}
                >
                    {!showCategories && (
                        <AltitudeChart
                            gpsComponent={gpsComponent}
                            height={height}
                            onAltitudeChange={(value: number) => setState({ altitude: value })}
                            sync={timestamp}
                            width={width}
                        />
                    )}
                    <Grid
                        hideXTicks
                        scale={scale}
                        yLineStyle={{ stroke: SRAM_200 }}
                        yTicks={yAxisProps.values}
                    />
                    <Line
                        className={styles.hiddenLine}
                        data={powerComponent.data.power}
                        scale={scale}
                        style={!showCategories ? { stroke: RED_LIGHT } : null}
                        sync={timestamp}
                        valueExtractor={(...params: any) => powerExtractor(...params)}
                        xExtractor={(...params: any) => extractTime(powerComponent, ...params)}
                        yExtractor={extractValue}
                    />
                    <AreaList
                        powerComponent={powerComponent}
                        powerZones={powerZones}
                        scale={scale}
                        showCategories={showCategories}
                    />
                    <VerticalLine scale={scale} sync={timestamp} />
                    {timestamp && (
                        <circle
                            className={styles.indicator}
                            cx={scale.x(timestamp)}
                            cy={Math.min(scale.y(power), 400)}
                            r={7}
                        />
                    )}
                    <g className={styles.axis}>
                        <Axis
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...yAxisProps}
                            format={formatPower}
                            style={{ orient: LEFT }}
                        />
                    </g>
                    <g
                        className={styles.axis}
                        style={{ transform: `translateY(${height}px)` }}
                    >
                        <Axis
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...xAxisProps}
                            format={(inTime: number) => units.formatTime(inTime)}
                        />
                    </g>
                </svg>
                <div className={styles.mobileTimeScale}>
                    <div className={styles.startTime}>
                        {units.formatTime(powerComponent.adjustedStartTs * 1000)}
                    </div>
                    <div className={styles.endTime}>
                        {units.formatTime(powerComponent.adjustedEndTs * 1000)}
                    </div>
                </div>
            </div>
        );
    };

    const renderScale = () => {
        if (!powerComponent.data.power.length) {
            return (
                <div className={styles.noDataNote}>
                    <Translate>NO_DATA_AVAILABLE_NOTES</Translate>
                </div>
            );
        }

        const { width, height, ref } = useResizeDetector();

        return (
            <div ref={ref}>
                <Scale
                    height={height}
                    width={width}
                    xMax={powerComponent.adjustedEndTs * 1000}
                    xMin={powerComponent.adjustedStartTs * 1000}
                    xScale={scaleTime}
                    yMax={powerComponent.data.max_power * 1.05}
                    yMin={0}
                    yMutator={mutateScaleNice}
                >
                    {(scale: any) => renderChart(scale)}
                </Scale>
            </div>
        );
    };

    if (!powerComponent) return null;

    return (
        <ExpandChart title="POWER_CHART">
            <div className={styles.container}>
                <div className={styles.statisticsContainer}>
                    <div className={styles.statistic}>
                        {formatPower(power)}
                        &nbsp;
                        <Translate>UNITS_WATTS_SHORT</Translate>
                    </div>
                    {!showCategories && (
                        <div className={styles.statistic}>
                            {Number.isFinite(altitude) && (
                                <>
                                    {toLocaleString(altitude)}
                                    &nbsp;
                                    <Translate>{units.getLabelAltitude().shorthand}</Translate>
                                </>
                            )}
                        </div>
                    )}
                </div>
                <div className={styles.yAxisLabel}>
                    <Translate>UNITS_WATTS</Translate>
                </div>
                {renderScale()}
                <div className={styles.xAxisLabel}>
                    <Translate>TIME</Translate>
                </div>
                {!disableEdit && (
                    <div className={styles.footer}>
                        <FtpDisplay />
                        <BikeComponentLink componentSummary={powerComponent} />
                    </div>
                )}
                {!disableOwnerFeatures && (
                    <div className={styles.buttonGroup}>
                        <CheckBox
                            checked={!showCategories}
                            label="ELEVATION"
                            onChange={() => setState({ showCategories: !showCategories })}
                            style={{ flexDirection: 'row-reverse', marginRight: '2rem' }}
                        />
                    </div>
                )}
            </div>
        </ExpandChart>
    );
}

export default PowerLineChart;
