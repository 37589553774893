import React from 'react';

import styles from './MobileActivityDropdown.module.scss';

import Drawer from '../../../components/drawer';
import WaffleBlock from '../../../components/waffleBlock';
import Translate from '../../../components/translate';
import { Bike } from '../../../providers';

interface MobileActivityDropdownProps {
    bike: Bike,
    downloadActivity: () => void,
    isOwner: boolean,
    onClick: () => void,
    open: boolean,
    regenerateActivity: () => void,
    toggleDeleteModal: () => void,
    toggleEditActivity: () => void,
    toggleShareActivity: () => void,
    unassignBikeFromActivity: () => void,
}

const MobileActivityDropdown = ({
    bike,
    downloadActivity,
    isOwner,
    onClick,
    open,
    regenerateActivity,
    toggleDeleteModal,
    toggleEditActivity,
    toggleShareActivity,
    unassignBikeFromActivity,
}: MobileActivityDropdownProps) => (
    <>
        <Drawer isOpen={open} onClose={onClick} subheader="SELECT_OPTION">
            <WaffleBlock flag="test" showIndicator>
                <div className={styles.mobileButtonRow}>
                    <button
                        type="button"
                        className={`${styles.button} ${styles.actionButton}`}
                        onClick={() => {
                            onClick();
                            toggleShareActivity();
                        }}
                    >
                        <Translate>ACTIVITY_SHARE</Translate>
                    </button>
                </div>
            </WaffleBlock>
            {isOwner && (
                <>
                    <div className={styles.mobileButtonRow}>
                        <button
                            type="button"
                            className={`${styles.button} ${styles.actionButton}`}
                            onClick={() => {
                                onClick();
                                toggleEditActivity();
                            }}
                        >
                            <Translate>ACTIVITY_EDIT</Translate>
                        </button>
                    </div>
                    <div className={styles.mobileButtonRow}>
                        <button
                            className={`${styles.button} ${styles.actionButton}`}
                            onClick={() => {
                                onClick();
                                toggleDeleteModal();
                            }}
                            type="button"
                        >
                            <Translate>ACTIVITY_DELETE</Translate>
                        </button>
                    </div>
                    <WaffleBlock flag="test" showIndicator>
                        <div className={styles.mobileButtonRow}>
                            <button
                                className={`${styles.button} ${styles.actionButton}`}
                                onClick={() => {
                                    onClick();
                                    regenerateActivity();
                                }}
                                type="button"
                            >
                                <Translate>ACTIVITY_REGENERATE</Translate>
                            </button>
                        </div>
                    </WaffleBlock>
                    {bike && (
                        <div className={styles.mobileButtonRow}>
                            <button
                                className={`${styles.button} ${styles.actionButton}`}
                                onClick={() => {
                                    onClick();
                                    unassignBikeFromActivity();
                                }}
                                type="button"
                            >
                                <Translate>UNASSIGN_BIKE</Translate>
                            </button>
                        </div>
                    )}
                </>
            )}
            <div className={styles.mobileButtonRow}>
                <button
                    className={`${styles.button} ${styles.actionButton}`}
                    onClick={() => {
                        onClick();
                        downloadActivity();
                    }}
                    type="button"
                >
                    <Translate>ACTIVITY_DOWNLOAD</Translate>
                </button>
            </div>
        </Drawer>
    </>
);

export default MobileActivityDropdown;
