import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import Popover from '@mui/material/Popover';

import { useEffect, useState } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { AutoSizer } from 'react-virtualized';

import styles from './EagleChainLengthModal.module.scss';

import {
    imageChainlength,
    imageChainring,
    imageSetupCog,
    imageSetupKey,
} from '../../../assets';
import DesktopContainer from '../../../components/desktopContainer';
import MobileContainer from '../../../components/mobileContainer';
import Modal from '../../../components/modal';
import Translate, { useTranslation } from '../../../components/translate';
import { EAGLE_TRANSMISSION_SUPPORT_VIDEOS } from '../../../constants';

interface videoType {
    name: string,
    url: string,
}

const VideoItem = ({ index, style, data }: ListChildComponentProps<videoType[]>) => {
    const item = data[index];
    const id = item.url?.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)?.pop();

    return (
        <a
            style={{ ...style, height: '240px', width: '240px' }}
            href={item.url}
            key={item.url}
            rel="noreferrer"
            target="_blank"
        >
            <div className={styles.videoImageContainer}>
                <img
                    alt=""
                    className={styles.videoImage}
                    src={`https://img.youtube.com/vi/${id}/0.jpg`}
                />
            </div>
            <div className={styles.videoTitle}>
                <Translate>{item.name}</Translate>
            </div>
        </a>
    );
};

interface ChainLengthModalProps {
    bike: string,
    chainLength: number | null,
    chainStayLength: number | null,
    isOpen: boolean,
    onClose: () => void,
    setUpCogPosition: string,
    setUpCogPositionText: string,
    setUpCogImage: string,
    setUpKeyPos: string,
    setUpKeyPosImage: string,
    supportedChainrings: Array<string>,
}

const EagleChainLengthModal = ({
    bike,
    chainLength,
    chainStayLength,
    isOpen,
    onClose,
    setUpCogPosition,
    setUpCogPositionText,
    setUpCogImage,
    setUpKeyPos,
    setUpKeyPosImage,
    supportedChainrings,
}: ChainLengthModalProps) => {
    if (!isOpen || !chainStayLength || !chainLength || !setUpCogPosition) {
        return null;
    }

    const CHAIN_LENGTH_RESULTS = [
        {
            info: <Translate>SETUP_KEY_POSITION_INFO</Translate>,
            label: 'SETUP_KEY_POSITION',
            src: setUpKeyPosImage || imageSetupKey,
        },
        {
            info: <Translate>T_TYPE_CHAIN_LENGTH_INFO</Translate>,
            label: 'T_TYPE_CHAIN_LENGTH',
            src: imageChainlength,
        },
        {
            info: <Translate>SUPPORTS_CHAINRINGS_INFO</Translate>,
            label: 'SUPPORTS_CHAINRINGS',
            src: imageChainring,
        },
        {
            info: <Translate>SETUP_COG_INFO</Translate>,
            label: 'SETUP_COG',
            src: setUpCogImage || imageSetupCog,
        },
    ];

    const [selectedResult, setselectedResult] = useState(CHAIN_LENGTH_RESULTS[0]);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [openPopover, setOpenPopover] = useState(false);

    const translate = useTranslation();

    const setSelection = (selection: string) => {
        const selected = CHAIN_LENGTH_RESULTS.find(({ label }) => label === selection);
        if (selected) setselectedResult(selected);
    };

    function renderPopover() {
        return (
            <Popover
                anchorEl={anchorEl}
                open={openPopover}
                onClose={() => setOpenPopover(!openPopover)}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
                <div className={styles.popover}>
                    {selectedResult.info}
                </div>
            </Popover>
        );
    }

    return (
        <Modal
            className={styles.modalContainer}
            contentClassName={styles.content}
            contentLabel=""
            hideImage
            isOpen={isOpen}
            onClose={onClose}
        >
            <div className={styles.container}>
                <div className={styles.info}>
                    <div className={styles.chainLengthInfo}>
                        <div className={styles.header}>
                            <div className={styles.selectedBike}>
                                <Translate>BIKE</Translate>
                            : &nbsp;
                                {bike}
                            </div>
                            <div className={styles.selectedChainstay}>
                                <Translate>CHAINSTAY_LENGTH</Translate>
                            : &nbsp;
                                {chainStayLength}
                            </div>
                        </div>
                        <div className={styles.chainLengthResult}>
                            {renderPopover()}
                            <MobileContainer className={styles.row}>
                                <div className={styles.imageContainer}>
                                    <div className={styles.imageTitle}>
                                        <Translate>{selectedResult.label}</Translate>
                                        <button
                                            className={styles.infoButton}
                                            onClick={(event) => {
                                                setAnchorEl(event.currentTarget);
                                                setOpenPopover(true);
                                            }}
                                            id="infoButton"
                                            type="button"
                                        >
                                            <InfoOutlinedIcon className={styles.infoIcon} />
                                        </button>
                                    </div>
                                    <img
                                        alt={selectedResult.label}
                                        className={styles.image}
                                        src={selectedResult.src}
                                    />
                                </div>
                            </MobileContainer>
                            <button
                                className={`${styles.row} ${
                                    selectedResult.label === 'SETUP_KEY_POSITION'
                                        ? styles.rowSelected
                                        : {}}`}
                                key="setup-key"
                                onClick={() => setSelection('SETUP_KEY_POSITION')}
                                type="button"
                            >
                                <div className={styles.label}>
                                    <Translate>SETUP_KEY_POSITION</Translate>
                                </div>
                                <div className={styles.value}>
                                    {setUpKeyPos}
                                </div>
                            </button>
                            <button
                                className={`${styles.row}
                             ${selectedResult.label === 'T_TYPE_CHAIN_LENGTH' ? styles.rowSelected : {}}`}
                                key="chainlength"
                                onClick={() => setSelection('T_TYPE_CHAIN_LENGTH')}
                                type="button"
                            >
                                <div className={styles.label}>
                                    <Translate>T_TYPE_CHAIN_LENGTH</Translate>
                                </div>
                                <div className={styles.value}>
                                    {chainLength}
                                &nbsp;
                                    <Translate>LINKS</Translate>
                                </div>
                            </button>
                            <button
                                className={`${styles.row}
                            ${selectedResult.label === 'SUPPORTS_CHAINRINGS' ? styles.rowSelected : {}}`}
                                key="chainring"
                                onClick={() => setSelection('SUPPORTS_CHAINRINGS')}
                                type="button"
                            >
                                <div className={styles.label}>
                                    <Translate>SUPPORTS_CHAINRINGS</Translate>
                                </div>
                                <div className={styles.value}>
                                    {supportedChainrings.map((chainringSize, index) => {
                                        if (index === 0) {
                                            return `${chainringSize}`;
                                        }
                                        return ` & ${chainringSize}`;
                                    })}
                                </div>
                            </button>
                            <button
                                className={`${styles.row}
                            ${selectedResult.label === 'SETUP_COG' ? styles.rowSelected : {}}`}
                                key="setup-cog"
                                onClick={() => setSelection('SETUP_COG')}
                                type="button"
                            >
                                <div className={styles.label}>
                                    <Translate>SETUP_COG</Translate>
                                </div>
                                <div className={styles.value}>
                                    {setUpCogPositionText === 'E' ? '21T' : `${setUpCogPositionText} (${setUpCogPosition})`}
                                </div>
                            </button>
                        </div>
                        <a className={styles.link}
                            href="https://docs.sram.com/en-US/publications/5jblJ4SRpeHwjcuWG1vPy4"
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            <Translate>EAGLE_TRANSMISSION_USER_MANUAL</Translate>
                        </a>
                        <MobileContainer className={styles.footer}>
                            <div className={styles.warningSign}>
                                <WarningIcon />
                                <Translate>WARNING</Translate>
                                <WarningIcon />
                            </div>
                            <div className={styles.warningWrapper}>
                                {
                                    bike === translate('FULL_SUSPENSION') && (
                                        <div className={styles.warningBold}>
                                            <Translate>WARNING_INFO_FULL_SAG</Translate>
                                        </div>
                                    )
                                }
                                <br />
                                <div className={styles.warning}>
                                    <Translate>WARNING_INFO</Translate>
                                </div>
                            </div>
                        </MobileContainer>
                    </div>
                    <DesktopContainer className={styles.desktopContainer}>
                        <div className={styles.imageContainer}>
                            <div className={styles.imageTitle}>
                                <Translate>{selectedResult.label}</Translate>
                                <button
                                    className={styles.infoButton}
                                    onClick={(event) => {
                                        setAnchorEl(event.currentTarget);
                                        setOpenPopover(true);
                                    }}
                                    id="infoButton"
                                    type="button"
                                >
                                    <InfoOutlinedIcon className={styles.infoIcon} />
                                </button>
                            </div>
                            <img
                                alt={selectedResult.label}
                                className={styles.image}
                                src={selectedResult.src}
                            />
                        </div>
                    </DesktopContainer>
                </div>
                <DesktopContainer className={styles.desktopContainer}>
                    <div className={styles.footer}>
                        <div className={styles.warningSign}>
                            <WarningIcon />
                            <Translate>WARNING</Translate>
                            <WarningIcon />
                        </div>
                        <div className={styles.warningWrapper}>
                            {
                                bike === translate('FULL_SUSPENSION') && (
                                    <div className={styles.warningBold}>
                                        <Translate>WARNING_INFO_FULL_SAG</Translate>
                                        &nbsp;
                                    </div>
                                )
                            }
                            <div className={styles.warning}>
                                <Translate>WARNING_INFO</Translate>
                            </div>
                        </div>
                    </div>
                </DesktopContainer>
            </div>
            <div className={styles.videoContainerLabel}>
                <Translate>SUPPORT_VIDEOS</Translate>
            </div>
            <AutoSizer disableHeight>
                {({ width }) => (
                    <FixedSizeList
                        height={250}
                        width={width}
                        itemCount={EAGLE_TRANSMISSION_SUPPORT_VIDEOS.length}
                        itemData={EAGLE_TRANSMISSION_SUPPORT_VIDEOS}
                        itemSize={250}
                        layout="horizontal"
                    >
                        {VideoItem}
                    </FixedSizeList>
                )}
            </AutoSizer>
        </Modal>
    );
};

export default EagleChainLengthModal;
