export function binarySearch(array, value, extractor = (v) => v) {
    let minIndex = 0;
    let maxIndex = array.length - 1;

    while (minIndex <= maxIndex) {
        /* eslint-disable-next-line no-bitwise */
        const guessIndex = (maxIndex + minIndex) >> 1;
        const compare = value - extractor(array[guessIndex], guessIndex, array);
        if (compare > 0) {
            minIndex = guessIndex + 1;
        } else if (compare < 0) {
            maxIndex = guessIndex - 1;
        } else {
            return guessIndex;
        }
    }

    return null;
}

export function compareFirmwareVersion(firstVersion, secondVersion) {
    const EQUAL = 0;
    const GREATER = 1;
    const LESSER = -1;

    // Initial equality check
    if (firstVersion === secondVersion) {
        return EQUAL;
    }

    const firstVersionSplit = firstVersion
        .toString()
        .split('.')
        .map((semanticVersion) => Number.parseInt(semanticVersion));
    const secondVersionSplit = secondVersion
        .toString()
        .split('.')
        .map((semanticVersion) => Number.parseInt(semanticVersion));

    const maxSemanticVersions = Math.max(firstVersionSplit.length, secondVersionSplit.length);

    let firstVersionSemantic = 0;
    let secondVersionSemantic = 0;
    // If not equal, they must be less or greater than eachother
    let result = GREATER;
    for (let ii = 0; ii < maxSemanticVersions; ii++) {
        firstVersionSemantic = Number.isFinite(firstVersionSplit[ii])
            ? firstVersionSplit[ii]
            : 0;

        secondVersionSemantic = Number.isFinite(secondVersionSplit[ii])
            ? secondVersionSplit[ii]
            : 0;

        // If not equal, they must be less or greater than eachother
        if (firstVersionSemantic !== secondVersionSemantic) {
            if (firstVersionSemantic < secondVersionSemantic) {
                result = LESSER;
            }
            // If first not less than second, must be greater than

            // Equality determined, exit loop
            break;
        }
    }

    return result;
}

// copied from https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
// makes promises cancellable
export const makeCancellable = (promise) => {
    let isCancelled = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            /* eslint-disable prefer-promise-reject-errors */
            (val) => (isCancelled ? reject({ isCancelled: true }) : resolve(val)),
            (error) => (isCancelled ? reject({ isCancelled: true }) : reject(error)),
            /* eslint-enable prefer-promise-reject-errors */
        );
    });

    return {
        cancel() {
            isCancelled = true;
        },
        promise: wrappedPromise,
    };
};

export function roundValueBy(value, decimals) {
    const shift = 10 ** decimals;

    return Math.round(value * shift) / shift;
}

export function toLocaleString(number) {
    if (!number || !Number.isFinite(number)) {
        return number;
    }

    return number.toLocaleString();
}

// Function to generate UUID based on the date and time
export function generateUUID() {
    let date = new Date().getTime();// Timestamp

    // Time in microseconds since page-load or 0 if unsupported
    let dateP = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (character) => {
        let randomNumber = Math.random() * 16;// random number between 0 and 16

        if (date > 0) { // Use timestamp until depleted
            // eslint-disable-next-line no-bitwise
            randomNumber = (date + randomNumber) % 16 | 0;
            date = Math.floor(date / 16);
        } else { // Use microseconds since page-load if supported
            // eslint-disable-next-line no-bitwise
            randomNumber = (dateP + randomNumber) % 16 | 0;
            dateP = Math.floor(dateP / 16);
        }

        // eslint-disable-next-line no-bitwise
        return (character === 'x' ? randomNumber : ((randomNumber & 0x3) | 0x8)).toString(16);
    });
}
