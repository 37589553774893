import React from 'react';

import AuthenticatedRoutes from './AuthenticatedRoutes';

import UserProfileBlock from '../components/UserProfileBlock';
import WaffleBlock from '../components/waffleBlock';
import NexusBlock from '../components/nexusblock/NexusBlock';
import EmailNotVerifiedBlock from '../views/emailNotVerifiedBlock';
import EmailNotVerifiedModal from '../views/emailNotVerifiedModal';
import UnderMaintenanceModal from '../views/underMaintenanceModal';

const AuthenticatedRoutesWrapper = () => (
    <UserProfileBlock>
        <EmailNotVerifiedBlock BlockedComponent={EmailNotVerifiedModal}>
            <WaffleBlock BlockedComponent={UnderMaintenanceModal} flag="maintenance" not>
                <NexusBlock>
                    <AuthenticatedRoutes />
                </NexusBlock>
            </WaffleBlock>
        </EmailNotVerifiedBlock>
    </UserProfileBlock>
);

export default AuthenticatedRoutesWrapper;
