import { ReactNode } from 'react';

import styles from './ContentHeaderContainer.module.scss';

interface ContentHeaderContainerProps { children: ReactNode, className?: string, style?: any}

const ContentHeaderContainer = ({ children = null, className = '', style = {} }: ContentHeaderContainerProps) => (
    <div className={`${styles.container} ${className}`} style={style}>
        {children}
    </div>
);

export default ContentHeaderContainer;
