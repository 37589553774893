import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

interface TranslateOptionProps {
    children: string,
    disabled?: boolean,
    hidden?: boolean,
    params?: Record<string, ReactNode>,
    value?: string | number,
}

const TranslateOption = ({
    children,
    params,
    value,
    ...otherProps
}: TranslateOptionProps) => {
    const intl = useIntl();

    const translation = intl.formatMessage(
        { defaultMessage: children, id: children },
        params,
    );

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <option {...otherProps} value={value}>
            {translation}
        </option>
    );
};

export default TranslateOption;
