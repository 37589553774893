import React, { useEffect, useState } from 'react';
import styles from './modals.module.scss';
import Modal from '../../../components/modal';
import Translate from '../../../components/translate';

interface SelectionModalProps {
    open: boolean;
    onClose: () => void;
    list: any[];
    setOption: (value: string) => void;
    modalTitle: string;
}

const SelectionModal = ({
    open,
    onClose,
    list,
    setOption,
    modalTitle,
}:SelectionModalProps) => {
    const [searchValue, setSearchValue] = useState('');

    const isBrandOrModel = modalTitle === 'BIKE_BRAND' || modalTitle === 'MODEL';
    const filteredList = list.filter((item) => {
        if (typeof item === 'string') {
            return item.toLowerCase().includes(searchValue.toLowerCase());
        }
        return item.label.toLowerCase().includes(searchValue.toLowerCase());
    });

    const closeModal = () => {
        setSearchValue('');
        onClose();
    };

    return (
        <Modal
            key={modalTitle}
            className={!isBrandOrModel ? `${styles.modalContainer}` : `${styles.modalContainer} ${styles.withSearch}`}
            contentClassName={styles.infoContent}
            headerStyle={{ color: 'white', fontSize: '1.5rem' }}
            contentLabel="SelectionModal"
            isOpen={open}
            header={modalTitle}
            hideImage
            containerStyle={{ padding: 'none' }}
            onOverlayClick={() => closeModal()}
            overlayClassName="overlay-test"
            hideCloseButton
        >
            {isBrandOrModel && (
                <div className={styles.inputContainer}>
                    <input
                        className={styles.searchInput}
                        type="text"
                        placeholder="Search..."
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                </div>
            )}
            <div className={styles.listContainer}>
                {isBrandOrModel
                && (
                    <li key="not listed" className={styles.listItem}>
                        <button
                            className={styles.listButton}
                            type="button"
                            onClick={() => {
                                setOption('MY_BIKE_NOT_LISTED');
                                closeModal();
                            }}
                        >
                            <Translate>MY_BIKE_NOT_LISTED</Translate>
                        </button>
                    </li>
                )}
                {filteredList.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index} className={styles.listItem}>
                        <button
                            className={styles.listButton}
                            type="button"
                            onClick={() => {
                                setOption(item);
                                closeModal();
                            }}
                        >
                            <Translate>{typeof item === 'string' ? item : item.label}</Translate>
                        </button>
                    </li>
                ))}
            </div>
        </Modal>
    );
};

export default SelectionModal;
