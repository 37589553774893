import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

import Spinner from '../spinner';

import { DEEP_LINK_USERSETTINGS } from '../../constants';

const MobileAuthLoader = () => {
    const location = useLocation();
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    const initiateRedirect = () => {
        if (isMobile) {
            window.location.replace(DEEP_LINK_USERSETTINGS + location.search);
        }

        timeoutId = setTimeout(
            () => {
                timeoutId = null;
                window.location.replace('/');
            },
            5000,
        );
    };

    useEffect(() => {
        initiateRedirect();

        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, []);

    return <Spinner />;
};

export default MobileAuthLoader;
