import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../spinner';

import { makeCancellable, RequestType } from '../../constants';
import Logger from '../../Logger';

import { useAuth } from '../../providers/auth/AuthContext';
import { useNexus } from '../../providers/nexus/NexusContext';

const AuthLoader = () => {
    const auth = useAuth();
    const nexus = useNexus();
    const redirectPath = localStorage.getItem('callbackRedirectPathname') || '/';
    localStorage.removeItem('callbackRedirectPathname');
    const navigate = useNavigate();

    const handleAuthenticationRequest = useRef<RequestType | null>(null);
    const handleServiceLinkedRequest = useRef<RequestType | null>(null);

    const handleAuthentication = async () => {
        if (auth.isAuthenticated()) {
            navigate(redirectPath);

            return;
        }

        handleAuthenticationRequest.current = makeCancellable(auth.handleAuthentication());
        try {
            await handleAuthenticationRequest.current.promise;
            handleAuthenticationRequest.current = null;

            navigate(redirectPath);
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn(error);
                handleAuthenticationRequest.current = null;
                navigate(redirectPath);
            }
        }
    };

    const handleServiceLinked = async (linkedService: string) => {
        let redirectLink = '/logout';

        try {
            const accessToken = await auth.fetchAccessToken();
            const storedAccessToken = localStorage.getItem('accessToken');

            if (!storedAccessToken || (storedAccessToken !== accessToken)) {
                navigate(redirectLink);
            }
            redirectLink = sessionStorage.getItem('linkedServiceAuth0CallbackRedirect') || '/riderportal';

            sessionStorage.removeItem('linkedServiceAuth0CallbackRedirect');
        } catch (error: any) {
            // try to catch issues relating to accessing local storage for access token or profile 2
            Logger.log('Error accessing local storage', error);
            navigate(redirectLink);
        }

        handleServiceLinkedRequest.current = makeCancellable(nexus.handleServiceLinked(linkedService));

        try {
            const nexusLinkedId = await handleServiceLinkedRequest.current.promise;
            handleServiceLinkedRequest.current = null;

            sessionStorage.setItem('linkedServiceType', nexusLinkedId.service);
            navigate(redirectLink);
        } catch (error: any) {
            if (!error.isCancelled) {
                handleServiceLinkedRequest.current = null;
                // Error handled in handleServiceLinked
                navigate(redirectLink);
            }
        }
    };

    useEffect(() => {
        const linkedService = sessionStorage.getItem('linkedServiceAuth0Callback');

        if (linkedService) {
            sessionStorage.removeItem('linkedServiceAuth0Callback');
            handleServiceLinked(linkedService);
        } else {
            handleAuthentication();
        }

        return () => {
            if (handleAuthenticationRequest.current) {
                handleAuthenticationRequest.current.cancel();
                handleAuthenticationRequest.current = null;
            }

            if (handleServiceLinkedRequest.current) {
                handleServiceLinkedRequest.current.cancel();
                handleServiceLinkedRequest.current = null;
            }
        };
    }, []);

    return <Spinner />;
};

export default AuthLoader;
