import { createContext, useContext } from 'react';
import { AccessGroupProps } from './types.d';

const AccessGroupContext = createContext<AccessGroupProps>({
    accessGroups: {
        clear: () => {},
        fetch: () => Promise.resolve([]),
        list: [],
        updateAccessGroups: () => Promise.resolve([]),
    },
});

export function useAccessGroups() {
    const { accessGroups } = useContext(AccessGroupContext);

    return accessGroups;
}

export default AccessGroupContext;
