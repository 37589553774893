import React from 'react';

import styles from './AxsAppCard.module.scss';

import Translate from '../../components/translate';

import {
    logoAppleStore,
    logoAxsGray,
    logoPlaystore,
} from '../../assets';

const AxsAppCard = () => (
    <div className={styles.container}>
        <div className={styles.imageContainer}>
            <img alt="AXS Logo" className={styles.logo} src={logoAxsGray} />
        </div>
        <div className={styles.content}>
            <div className={styles.header}>
                <Translate>APP_INFO_TITLE</Translate>
            </div>
            <div className={styles.text}>
                <Translate>APP_INFO</Translate>
            </div>

            <div className={styles.appStoresContainer}>
                <a
                    className={styles.appStoreLink}
                    href="https://itunes.apple.com/us/app/sram-axs/id1430049231?ls=1&mt=8"
                    rel="noreferrer noopener"
                    target="_blank"
                >
                    <img
                        alt="Download on the App Store"
                        className={styles.appStoreImage}
                        src={logoAppleStore}
                    />
                </a>

                <a
                    className={styles.appStoreLink}
                    href="https://play.google.com/store/apps/details?id=com.sram.armyknife"
                    rel="noreferrer noopener"
                    target="_blank"
                >
                    <img
                        alt="Get it on Google Play"
                        className={styles.appStoreImage}
                        src={logoPlaystore}
                    />
                </a>
            </div>
        </div>
    </div>
);

export default AxsAppCard;
