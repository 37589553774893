import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import styles from './NavControls.module.scss';

import AccountDropdown from '../AccountDropdown';
import AxsFeedDropdown from '../AxsFeedDropdown';
import BikeRackDropdown from '../BikeRackDropdown';
import MessagesBadge from '../messagesBadge';

import Button from '../../../components/button';
import DesktopContainer from '../../../components/desktopContainer';
import Translate from '../../../components/translate';
import { WHITE } from '../../../constants';
import { useAuth } from '../../../providers/auth/AuthContext';

const NavControls = () => {
    const { isAuthenticated, isLoggingIn } = useAuth();
    const [openActiveCategory, setOpenActiveCategory] = useState<string|null>();
    const location = useLocation();

    const getPathCategory = () => {
        if (location.pathname.startsWith('/bikerack')) return 'bike';

        switch (location.pathname) {
            case '/activities':
            case '/activities/upload':
                return 'map';

            case '/components':
            case '/buildbike':
                return 'bike';

            case '/messages':
                return 'messages';

            case '/riderportal':
                return 'account';

            default:
                return null;
        }
    };

    const toggleActiveCategory = (category: string) => {
        if (category === 'messages' && openActiveCategory === 'messages') return null;
        setOpenActiveCategory((openActiveCategory !== category) ? category : null);
    };

    if (isAuthenticated() || isLoggingIn) {
        return (
            <>
                <DesktopContainer className={styles.navLogos}>
                    <AxsFeedDropdown
                        open={(openActiveCategory === 'axsFeedDropdownOpen')}
                        onClick={() => toggleActiveCategory('axsFeedDropdownOpen')}
                        onAuxClick={() => window.open('/activities', '_blank')}
                    />
                    <BikeRackDropdown
                        open={(openActiveCategory === 'bikesDropdownOpen')}
                        onAuxClick={() => window.open('/bikerack', '_blank')}
                        onClick={() => toggleActiveCategory('bikesDropdownOpen')}
                    />
                    <div className={styles.messagesContainer}>
                        <Link
                            className={styles.messagesLink}
                            onClick={() => toggleActiveCategory('messages')}
                            to="/messages"
                        >
                            <MessagesBadge
                                fontSize="large"
                                style={{ color: WHITE }}
                            />

                        </Link>
                        {((getPathCategory() === 'messages')
                         && openActiveCategory === 'messages')
                         && <div className={styles.backDrop} />}
                    </div>
                </DesktopContainer>
                <DesktopContainer className={styles.accountLogoContainer}>
                    <AccountDropdown
                        open={(openActiveCategory === 'accountDropdownOpen')}
                        onAuxClick={() => window.open('/activities', '_riderportal')}
                        onClick={() => toggleActiveCategory('accountDropdownOpen')}
                    />
                </DesktopContainer>
            </>
        );
    }

    return (
        <DesktopContainer>
            <Button className={styles.buttonLogin} Component={Link} to="/login">
                <Translate>LOGIN</Translate>
            </Button>
        </DesktopContainer>
    );
};

export default NavControls;
