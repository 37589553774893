import React, { useState } from 'react';

import styles from './BikeComponentPanel.module.scss';

import CollapsablePanel from '../../../components/collapsablePanel';

import { iconRightArrowRed } from '../../../assets';

interface BikeComponentPanelProps {
    children: any,
    hideBorder?: boolean,
    label: any,
    openOnMount: boolean,
    sublabel?: string,
}

const BikeComponentPanel = ({
    children,
    hideBorder = false,
    label = '',
    openOnMount = false,
    sublabel = '',
}: BikeComponentPanelProps) => {
    const [open, setOpen] = useState(openOnMount);

    return (
        <div className={styles.container}>
            {!hideBorder && <div className={styles.topBorder} />}
            <CollapsablePanel
                label={(
                    <div className={styles.headerContainer}>
                        <div className={styles.labelContainer}>
                            <div className={styles.labelHeaderContainer}>
                                {label}
                            </div>
                            <img
                                alt=""
                                className={`${styles.indicator} ${!open ? styles.rotateIcon : ''}`}
                                src={iconRightArrowRed}
                            />
                        </div>
                        {sublabel}
                    </div>
                )}
                onToggle={() => setOpen(!open)}
                openOnMount={openOnMount}
            >
                <div className={styles.content}>
                    {children}
                </div>
            </CollapsablePanel>
        </div>
    );
};

export default BikeComponentPanel;
