import { createContext, useContext } from 'react';
import { BikeComponentProps } from './types';

const BikeComponentsContext = createContext<BikeComponentProps>({
    bikeComponents: {
        // State
        bikeComponents: [],
        bikeComponentsCount: 0,
        bikeComponentsNotifications: {} as any,
        bikeComponentsServices: {},
        bikeComponentsTags: {},
        hasMore: true,
        isFetching: false,
        page: 1,
        productDetails: {},
        // Functions
        // eslint-disable-next-line sort-keys
        clear: () => {},
        createNexusComponent: () => {},
        deleteBikeComponent: () => false,
        fetch: () => null,
        fetchAvailableFirmwareVersion: () => {},
        fetchBikeComponentBySerial: () => {},
        fetchBikeComponents: () => [],
        fetchComponentBleServices: () => [],
        fetchComponentNotifications: () => [],
        fetchComponentServices: () => {},
        fetchComponentTags: () => {},
        fetchProductDetails: () => {},
        list: [],
        registerComponent: () => {},
    },
});

export function useBikeComponents() {
    const { bikeComponents } = useContext(BikeComponentsContext);

    return bikeComponents;
}

export default BikeComponentsContext;
