import { useEffect } from 'react';

import styles from './EditActivityModal.module.scss';

import Button from '../../../components/button';
import CheckBox from '../../../components/checkbox';
import Modal from '../../../components/modal';
import Spinner from '../../../components/spinner';
import TranslateOption from '../../../components/translateOption';
import Translate from '../../../components/translate';
import { AXS_TEXT_GREY, makeCancellable } from '../../../constants';
import { useSetState } from '../../../hooks';
import { Activity, Bike, useBikes } from '../../../providers';
import BikeCard from '../../../views/bikeCard';
import BikeForm from '../../../views/bikeForm';
import ErrorModal from '../../../views/errorModal';
import { ActivityUpdates } from '../../activities/activitiesTable/ActivitiesTable';

const DEFAULT_STATE = {
    activityUpdate: {},
    error: null,
    id: '',
    imagePreview: null,
    isActivityPublic: null,
    isFetchingBike: false,
    showNewBikeForm: false,
};

interface EditActivityModalProps {
    activity: Activity | undefined;
    activityTypes: { label: string, value: string }[];
    id?: string;
    onCancel: () => void;
    onSave: () => void;
    open: boolean;
    showShareActivity?: boolean;
    updateActivity: (value: ActivityUpdates) => void;
}

const EditActivityModal = ({
    activity,
    activityTypes,
    onCancel,
    onSave,
    open,
    id,
    showShareActivity,
    updateActivity,
}: EditActivityModalProps) => {
    if (!activity) return null;
    const [state, setState] = useSetState(DEFAULT_STATE);
    const {
        activityUpdate,
        error,
        imagePreview,
        isActivityPublic,
        isFetchingBike,
        showNewBikeForm,
    } = state;

    const bikes = useBikes();

    const addBikeRequests: any = new Set();
    let fetchBikeRequest: any = null;
    const updateActivityRequests = new Set();

    useEffect(() => {
        if (activity) {
            setState({ isActivityPublic: activity.public });
        }

        return () => {
            if (fetchBikeRequest) {
                fetchBikeRequest.cancel();
            }

            addBikeRequests.forEach((request: any) => request.cancel());
            addBikeRequests.clear();

            updateActivityRequests.forEach((request: any) => request.cancel());
            updateActivityRequests.clear();
        };
    }, []);

    const onActivityUpdate = (key:string, value: string | boolean) => {
        if (!key) return;

        const newActivityUpdate = {
            ...activityUpdate,
        };

        if (activity[key as keyof Activity] === value) {
            delete newActivityUpdate[key];
        } else {
            newActivityUpdate[key] = value;
        }

        setState({ activityUpdate: newActivityUpdate });
    };

    const getCurrentValue = (key: string) => {
        if (activityUpdate[key] != null) return activityUpdate[key];

        if (activity[key as keyof Activity] != null) return activity[key as keyof Activity];

        return '';
    };

    const getStagedActivityUpdate = () => {
        if (!Object.keys(activityUpdate).length) return null;

        return activityUpdate;
    };

    const fetchBike = async () => {
        if (fetchBikeRequest) {
            try {
                await fetchBikeRequest.promise;

                return;
            } catch (err) {
                return;
            }
        }

        const bikeId = activity.bike_uuid ? activity.bike_uuid : '';

        fetchBikeRequest = makeCancellable(bikes.fetchBike(bikeId, true));
        setState({ isFetchingBike: true });

        try {
            await fetchBikeRequest.promise;

            fetchBikeRequest = null;
            setState({ isFetchingBike: false });
        } catch (err: any) {
            if (!err.isCancelled) {
                fetchBikeRequest = null;
                setState({ isFetchingBike: false });
            }
        }
    };

    const toggleNewBikeForm = () => {
        setState({ showNewBikeForm: !showNewBikeForm });
    };

    const stageActivityType = (value: string) => {
        const activityType = value.toLowerCase();

        if (!activityTypes.find((type) => type.value === activityType)) return;

        onActivityUpdate('type', activityType);
    };

    const submitActivityUpdate = async () => {
        const activityUpdates = getStagedActivityUpdate();

        let updatedActivity = null;
        let updateError = null;

        if (activityUpdates) {
            const fetchRequest = makeCancellable(updateActivity(activityUpdates));
            updateActivityRequests.add(fetchRequest);

            try {
                updatedActivity = await fetchRequest.promise;
                updateActivityRequests.delete(fetchRequest);

                if (updatedActivity) {
                    // clear staged activity updates if successful update
                    setState({ activityUpdate: {} });
                } else {
                    updateError = new Error('ACTIVITY_UPDATE_ERROR');
                }
            } catch (err: any) {
                if (!err.isCancelled) {
                    updateError = new Error('ACTIVITY_UPDATE_ERROR');
                    updateActivityRequests.delete(fetchRequest);
                } else {
                    // Only exit if it has been cancelled
                    return;
                }
            }
        }

        if (updateError) {
            setState({ error: updateError });

            return;
        }

        if (updatedActivity) onSave();
    };

    const saveBike = async (bike: Bike) => {
        if (!bike.name || !bike.name.length) return null;

        const addBikeRequest = makeCancellable(bikes.addBike(bike));
        addBikeRequests.add(addBikeRequest);

        try {
            const newBike = await addBikeRequest.promise;
            addBikeRequests.delete(addBikeRequest);

            if (newBike) {
                setState({ showNewBikeForm: false });
            } else {
                setState({ error: new Error('BIKE_NEW_ERROR') });
            }

            return bike;
        } catch (err) {
            addBikeRequests.delete(addBikeRequest);

            return null;
        }
    };

    const renderNewBikeForm = () => {
        if (!showNewBikeForm) return null;

        return (
            <div className={styles.formContainer}>
                <BikeCard
                    bike={{ name: <Translate>BIKE_NEW_PROFILE</Translate> }}
                    image={imagePreview}
                />
                <BikeForm
                    id="data-test-bike-form"
                    nameIsRequired
                    onImageSelected={(newImagePreview) => setState({ imagePreview: newImagePreview })}
                    onSubmit={(bike) => saveBike(bike)}
                >
                    <div className={styles.buttonsContainer}>
                        <Button
                            woopraData="activity-edit-cancel"
                            className={styles.buttonEditBike}
                            color={AXS_TEXT_GREY}
                            inverse
                            onClick={() => toggleNewBikeForm()}
                        >
                            <Translate>CANCEL</Translate>
                        </Button>
                        <Button className={styles.buttonEditBike}
                            type="submit"
                            woopraData="activity-edit-save"
                        >
                            <Translate>SAVE</Translate>
                        </Button>
                    </div>
                </BikeForm>
            </div>
        );
    };

    const renderShareActivityForm = () => {
        if (!showShareActivity) return null;

        return (
            <>
                <div className={styles.title}>
                    <Translate>ACTIVITY_SHARE</Translate>
                </div>
                <div className={styles.selectContainer}>
                    <CheckBox
                        checked={isActivityPublic}
                        label="PUBLIC"
                        onChange={() => setState(
                            { isActivityPublic: !isActivityPublic },
                            () => onActivityUpdate('public', !isActivityPublic),
                        )}
                        style={{ color: 'initial', flexDirection: 'row-reverse', justifyContent: 'flex-end' }}
                    />
                </div>
            </>
        );
    };

    const renderInputs = () => {
        const bikeId = activityUpdate.bike_uuid || activity.bike_uuid;

        const assignedBike = bikes.list.find((bike) => bike.id === bikeId);

        return (
            <div className={styles.inputContainer}>
                <div className={styles.title}>
                    <Translate>BIKE_MY</Translate>
                </div>
                <div className={styles.selectContainer}>
                    <select
                        className={styles.inputField}
                        id="data-test-bike-select"
                        onChange={(event) => {
                            if (event.target.value === 'BIKE_ADD_NEW') {
                                toggleNewBikeForm();
                            } else {
                                const selectedBike = bikes.list.find((bike) => bike.name === event.target.value);
                                if (selectedBike) onActivityUpdate('bike_uuid', selectedBike.id);
                            }
                        }}
                        value={assignedBike ? assignedBike.name : ''}
                    >
                        <TranslateOption
                            disabled
                            hidden
                            value=""
                        >
                            BIKE_ASSIGN
                        </TranslateOption>
                        {bikes.list.map((bike) => (
                            <option key={bike.id} value={bike.name}>{bike.name}</option>
                        ))}
                        <TranslateOption value="BIKE_ADD_NEW">
                            BIKE_ADD_NEW_DOTTED
                        </TranslateOption>
                    </select>
                </div>
                <div className={styles.title}>
                    <Translate>ACTIVITY_NAME</Translate>
                </div>
                <div className={styles.selectContainer}>
                    <input
                        className={styles.input}
                        id="data-test-edit-activity-name"
                        onChange={(event) => onActivityUpdate('name', event.target.value)}
                        value={getCurrentValue('name')}
                        required
                    />
                </div>
                <div className={styles.title}>
                    <Translate>ACTIVITY_TYPE</Translate>
                </div>
                <div className={styles.selectContainer}>
                    <select
                        className={styles.inputField}
                        id="date-test-edit-activity-type"
                        onChange={(event) => stageActivityType(event.target.value)}
                        value={getCurrentValue('type')}
                    >
                        {activityTypes.map((type) => (
                            <TranslateOption key={type.value} value={type.value}>{type.label}</TranslateOption>
                        ))}
                    </select>
                </div>
                {renderShareActivityForm()}
            </div>
        );
    };

    const renderEditActivityForm = () => {
        if (showNewBikeForm) return null;

        return (
            <>
                <form
                    id="data-test-edit-activity-form"
                    onSubmit={(event) => {
                        event.preventDefault();

                        if (!getStagedActivityUpdate()) {
                            onCancel();
                        } else {
                            submitActivityUpdate();
                        }
                    }}
                    style={{ margin: 'auto', width: '100%' }}
                >
                    {renderInputs()}
                </form>
                <div className={styles.buttonsContainer}>
                    <Button
                        woopraData="activity-edit-cancel"
                        className={styles.button}
                        color={AXS_TEXT_GREY}
                        inverse
                        onClick={onCancel}
                    >
                        <Translate>CANCEL</Translate>
                    </Button>
                    <Button
                        woopraData="activity-edit-save"
                        className={styles.button}
                        onClick={() => {
                            if (!getStagedActivityUpdate()) {
                                onCancel();
                            } else {
                                submitActivityUpdate();
                            }
                        }}
                        type="button"
                    >
                        <Translate>SAVE</Translate>
                    </Button>
                </div>
            </>
        );
    };

    if (error) {
        return (
            <ErrorModal
                error={error}
                onClose={() => setState({ error: null })}
                onOverlayClick={() => {
                    setState({ error: null });
                    onCancel();
                }}
            />
        );
    }

    return (
        <Modal
            id={id}
            className={`${showNewBikeForm ? styles.bikeFormContainer : ''}`}
            contentClassName={`${styles.container} ${showNewBikeForm ? styles.bikeFormContent : ''}`}
            contentLabel="Edit Activity"
            dialog={!showNewBikeForm}
            header={showNewBikeForm ? '' : 'ACTIVITY_EDIT'}
            hideCloseButton
            hideImage
            isOpen={open}
            onAfterOpen={() => fetchBike()}
            onClose={() => {
                setState({
                    activityUpdate: {},
                    showNewBikeForm: false,
                });
                onCancel();
            }}
        >
            <Spinner loading={isFetchingBike} />
            {renderEditActivityForm()}
            {renderNewBikeForm()}
        </Modal>
    );
};

export default EditActivityModal;
