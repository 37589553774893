import { ReactNode, useContext } from 'react';

import Spinner from '../spinner';
import { NexusContext } from '../../providers/nexus';

const NexusBlock = ({ children = null }: { children: ReactNode }) => {
    const { nexus } = useContext(NexusContext);

    if (!nexus.nexusUserProfile) {
        return <Spinner />;
    }

    return children;
};

export default NexusBlock;
