import axios from 'axios';
import { useEffect, useRef, useState } from 'react';

import {
    makeCancellable,
    RequestType,
    URL_QUARQNET_API,
} from '../../constants';
import Logger from '../../Logger';

function useQatalystActivitySummaries(auth: any) {
    const [qatalystUserActivitySummaries, setQatalystUserActivitySummaries] = useState(null);
    const userActivitySummariesRequest = useRef<RequestType | null>();

    const fetchQatalystUserActivitySummaries = async () => {
        if (qatalystUserActivitySummaries) {
            return qatalystUserActivitySummaries;
        }

        if (userActivitySummariesRequest.current) {
            try {
                const { data } = await userActivitySummariesRequest.current.promise;

                return data;
            } catch (error) {
                return null;
            }
        }

        userActivitySummariesRequest.current = makeCancellable(axios.get(`${URL_QUARQNET_API}users/self/stats/`));

        try {
            const { data } = await userActivitySummariesRequest.current.promise;

            userActivitySummariesRequest.current = null;

            setQatalystUserActivitySummaries(data);

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.error('Error fetching Qatalyst User Activity Summaries', error);
                userActivitySummariesRequest.current = null;
            }

            return null;
        }
    };

    useEffect(() => {
        if (auth.accessToken) {
            fetchQatalystUserActivitySummaries();
        }

        return () => {
            if (userActivitySummariesRequest.current) {
                userActivitySummariesRequest.current.cancel();
            }
        };
    }, [auth.accessToken]);

    return { fetchQatalystUserActivitySummaries, qatalystUserActivitySummaries };
}

export default useQatalystActivitySummaries;
