const TWENTY_MINUTES_IN_SECONDS = 1200;

export function calculateRollingAverage(dataPoints, extractor = (value) => value) {
    const rollingAverage = [];
    let rollingSum = 0;

    if (dataPoints.length >= 30) {
        for (let i = 0; i < 30; i++) {
            rollingSum += extractor(dataPoints[i]);
        }

        for (let i = 30; i < dataPoints.length; i++) {
            rollingSum -= extractor(dataPoints[i - 30]);
            rollingSum += extractor(dataPoints[i]);

            const newPowerEntry = (rollingSum / 30) ** 4;

            rollingAverage.push(newPowerEntry);
        }
    }

    return rollingAverage;
}

export function calculateHighestFromWindow(data, segmentWindow, extractor = (value) => value) {
    if (!data || !segmentWindow) {
        return null;
    }

    if (data.length < segmentWindow) {
        return null;
    }

    let max = 0;
    let index = 0;

    for (let i = 0; i < segmentWindow; i++) {
        max += extractor(data[i]);
    }

    let currentTally = max;
    for (let i = segmentWindow; i < (data.length - segmentWindow); i++) {
        currentTally -= extractor(data[i - segmentWindow]);
        currentTally += extractor(data[i]);

        if (max < currentTally) {
            max = currentTally;
            index = i;
        }
    }

    max /= segmentWindow;

    return { index, max };
}

export function filterGearComponents(gearComponents, timeRange) {
    if (!gearComponents) {
        return [];
    }

    if (!timeRange) {
        return gearComponents;
    }

    const filteredComponents = gearComponents.map((gearComponent) => {
        if (gearComponent.data.ant_component_id !== 2) {
            return gearComponent;
        }

        const startIndex = Math.max(timeRange.startTs - gearComponent.data.adjustedTime[0], 0);
        const endIndex = Math.min(
            timeRange.endTs - gearComponent.data.adjustedTime[0],
            gearComponent.data.adjustedTime.length - 1,
        );

        const adjustedTime = gearComponent.data.adjustedTime.slice(startIndex, endIndex + 1);
        const fdGear = gearComponent.data.fd_gear.slice(startIndex, endIndex + 1);
        const rdGear = gearComponent.data.rd_gear.slice(startIndex, endIndex + 1);
        const time = gearComponent.data.time.slice(startIndex, endIndex + 1);

        let { fd_histogram, rd_histogram } = gearComponent.data;

        // Delete unKnown gears 31 & 6. Issue:812
        if (gearComponent.data.rd_gear.includes(31)) {
            rd_histogram = [];

            while (rdGear.includes(31)) {
                const index = rdGear.indexOf(31);
                rdGear.splice(index, 1);
            }

            for (let i = 0; i < rdGear.length; i++) {
                if (!rd_histogram[rdGear[i]]) rd_histogram[rdGear[i]] = 0;
                rd_histogram[rdGear[i]] += 1;
            }
        }

        if (gearComponent.data.fd_gear.includes(6)) {
            fd_histogram = [];

            while (fdGear.includes(6)) {
                const index = fdGear.indexOf(6);
                fdGear.splice(index, 1);
            }

            for (let i = 0; i < fdGear.length; i++) {
                if (!fd_histogram[fdGear[i]]) fd_histogram[fdGear[i]] = 0;
                fd_histogram[fdGear[i]] += 1;
            }
        }

        if (gearComponent.adjustedStartTs !== timeRange.startTs || gearComponent.adjustedEndTs !== timeRange.endTs) {
            // Generate histogram arrays
            fd_histogram = fd_histogram.map(() => 0);
            rd_histogram = rd_histogram.map(() => 0);

            for (let i = 0; i < time.length; i++) {
                fd_histogram[fdGear[i]] += 1;
                rd_histogram[rdGear[i]] += 1;
            }
        }

        return {
            ...gearComponent,
            adjustedEndTs: timeRange.endTs,
            adjustedStartTs: timeRange.startTs,
            data: {
                ...gearComponent.data,
                adjustedTime,
                fd_gear: fdGear,
                fd_histogram,
                rd_gear: rdGear,
                rd_histogram,
                time,
            },
        };
    });

    return filteredComponents;
}

export function filterGpsComponents(gpsComponents, timeRange) {
    if (!gpsComponents) {
        return [];
    }

    if (!timeRange) {
        return gpsComponents;
    }

    const filteredComponents = gpsComponents.map((gpsComponent) => {
        if (
            gpsComponent.adjustedStartTs === timeRange.startTs
            && gpsComponent.adjustedEndTs === timeRange.endTs
        ) {
            return gpsComponent;
        }

        const startIndex = Math.max(timeRange.startTs - gpsComponent.data.adjustedTime[0], 0);
        const endIndex = Math.min(
            timeRange.endTs - gpsComponent.data.adjustedTime[0],
            gpsComponent.data.adjustedTime.length - 1,
        );

        return {
            ...gpsComponent,
            adjustedEndTs: timeRange.endTs,
            adjustedStartTs: timeRange.startTs,
            data: {
                ...gpsComponent.data,
                adjustedTime: gpsComponent.data.adjustedTime.slice(startIndex, endIndex + 1),
                alt: gpsComponent.data.alt.slice(startIndex, endIndex + 1),
                filteredPosition: gpsComponent.data.position.slice(startIndex, endIndex + 1),
                lat: gpsComponent.data.lat.slice(startIndex, endIndex + 1),
                long: gpsComponent.data.long.slice(startIndex, endIndex + 1),
                speed: gpsComponent.data.speed.slice(startIndex, endIndex + 1),
                temp: gpsComponent.data.temp.slice(startIndex, endIndex + 1),
                time: gpsComponent.data.time.slice(startIndex, endIndex + 1),
            },
        };
    });

    return filteredComponents;
}

export function filterHeartComponents(heartComponents, timeRange, nexusUserProfile) {
    if (!heartComponents) {
        return [];
    }

    if (!timeRange) {
        return heartComponents;
    }

    const filteredComponents = heartComponents.map((heartComponent) => {
        const startIndex = Math.max(timeRange.startTs - heartComponent.data.adjustedTime[0], 0);
        const endIndex = Math.min(
            timeRange.endTs - heartComponent.data.adjustedTime[0],
            heartComponent.data.adjustedTime.length - 1,
        );

        const adjustedTime = heartComponent.data.adjustedTime.slice(startIndex, endIndex + 1);
        const hr = heartComponent.data.hr.slice(startIndex, endIndex + 1);
        const time = heartComponent.data.time.slice(startIndex, endIndex + 1);

        let {
            ave_hr,
            energy,
            max_hr,
            mean_maximal_hr_curve,
            min_hr,
        } = heartComponent.data;

        if (heartComponent.adjustedStartTs !== timeRange.startTs || heartComponent.adjustedEndTs !== timeRange.endTs) {
            ave_hr = 0;
            max_hr = -Infinity;
            min_hr = Infinity;

            hr.forEach((heartRate, index) => {
                if (heartRate > max_hr) {
                    max_hr = heartRate;
                }

                if (heartRate < min_hr) {
                    min_hr = heartRate;
                }

                ave_hr *= index;
                ave_hr += heartRate;
                ave_hr /= index + 1;
            });

            max_hr = Number.isFinite(max_hr) ? max_hr : 0;
            min_hr = Number.isFinite(min_hr) ? min_hr : 0;

            mean_maximal_hr_curve = mean_maximal_hr_curve
                .filter(([segmentWindow]) => (segmentWindow <= time.length))
                .map((data) => {
                    const newMeanMax = calculateHighestFromWindow(hr, data[0]);

                    return [data[0], hr[newMeanMax.index], newMeanMax.max];
                });

            // Energy calculation provided by Ben
            if (nexusUserProfile) {
                energy = (
                    -55.0969
                    + (0.6309 * ave_hr)
                    + (0.1988 * nexusUserProfile.weight)
                    + (0.2017 * nexusUserProfile.age)
                );
                energy /= 4.184;
                energy *= 60;
                energy *= (Math.abs(timeRange.endTs - timeRange.startTs) / 3600);
                energy = Math.round(energy);
            } else {
                energy = null;
            }
        }

        return {
            ...heartComponent,
            adjustedEndTs: timeRange.endTs,
            adjustedStartTs: timeRange.startTs,
            data: {
                ...heartComponent.data,
                adjustedTime,
                ave_hr: Math.round(ave_hr),
                energy,
                hr,
                max_hr,
                mean_maximal_hr_curve,
                min_hr,
                time,
            },
        };
    });

    return filteredComponents;
}

export function filterPowerComponents(powerComponents, timeRange) {
    if (!powerComponents) {
        return [];
    }

    if (!timeRange) {
        return powerComponents;
    }

    const filteredComponents = powerComponents.map((powerComponent) => {
        const startIndex = Math.max(timeRange.startTs - powerComponent.data.adjustedTime[0], 0);
        const endIndex = Math.min(
            timeRange.endTs - powerComponent.data.adjustedTime[0],
            powerComponent.data.adjustedTime.length - 1,
        );

        const adjustedTime = powerComponent.data.adjustedTime.slice(startIndex, endIndex + 1);
        const cadence = powerComponent.data.cadence.slice(startIndex, endIndex + 1);
        const power = powerComponent.data.power.slice(startIndex, endIndex + 1);
        const powerBalance = powerComponent.data.power_balance.slice(startIndex, endIndex + 1);
        const time = powerComponent.data.time.slice(startIndex, endIndex + 1);

        let {
            '20_min_normalized_power': twentyMinuteMeanMax,
            ave_cadence,
            ave_power,
            energy,
            max_cadence,
            max_power,
            mean_maximal_power_curves,
        } = powerComponent.data;

        if (powerComponent.adjustedStartTs !== timeRange.startTs || powerComponent.adjustedEndTs !== timeRange.endTs) {
            energy = 0;
            ave_cadence = 0;
            ave_power = 0;
            max_cadence = -Infinity;
            max_power = -Infinity;
            const powerHist = powerComponent.data.power_hist.map(() => 0);
            const cadenceHist = powerComponent.data.cadence_hist.map(() => 0);

            adjustedTime.forEach((ts, index) => {
                const currentCadence = cadence[index];
                const currentPower = power[index];

                if (currentPower > max_power) {
                    max_power = currentPower;
                }

                if (currentCadence > max_cadence) {
                    max_cadence = currentCadence;
                }

                energy += currentPower;
                powerHist[currentPower] += 1;
                cadenceHist[currentCadence] += 1;

                ave_cadence *= index;
                ave_cadence += currentCadence;
                ave_cadence /= index + 1;

                ave_power *= index;
                ave_power += currentPower;
                ave_power /= index + 1;
            });

            max_cadence = Number.isFinite(max_cadence) ? max_cadence : 0;
            max_power = Number.isFinite(max_power) ? max_power : 0;

            // Trim histogram arrays down to values that only exist in this segment
            powerHist.splice(0, max_power);
            cadenceHist.splice(0, max_cadence);

            const rollingAverage = calculateRollingAverage(power);
            mean_maximal_power_curves = mean_maximal_power_curves
                .filter(([segmentWindow]) => (segmentWindow < time.length))
                .map((data) => {
                    const newMeanMax = calculateHighestFromWindow(power, data[0]);
                    const newNormalised = calculateHighestFromWindow(rollingAverage, data[0]);

                    return [
                        data[0],
                        newNormalised && adjustedTime[newNormalised.index],
                        newNormalised && newNormalised.max ** 0.25,
                        // Mean max index
                        adjustedTime[newMeanMax.index],
                        newMeanMax.max,
                    ];
                });

            const foundTwentyMinuteMeanMax = mean_maximal_power_curves.find(([seconds]) => (
                seconds === TWENTY_MINUTES_IN_SECONDS
            ));

            if (foundTwentyMinuteMeanMax) {
                twentyMinuteMeanMax = Math.round(twentyMinuteMeanMax[2]);
            }

            energy /= 1000;
        }

        return {
            ...powerComponent,
            adjustedEndTs: timeRange.endTs,
            adjustedStartTs: timeRange.startTs,
            data: {
                ...powerComponent.data,
                '20_min_normalized_power': twentyMinuteMeanMax,
                adjustedTime,
                ave_cadence: Math.round(ave_cadence),
                ave_power: Math.round(ave_power),
                cadence,
                energy: Math.round(energy),
                max_cadence,
                max_power,
                mean_maximal_power_curves,
                power,
                power_balance: powerBalance,
                time,
            },
        };
    });

    return filteredComponents;
}

export function filterReverbComponents(reverbComponents, timeRange) {
    if (!reverbComponents) {
        return null;
    }

    if (!timeRange) {
        return reverbComponents;
    }

    const filteredComponents = reverbComponents.map((reverbComponent) => {
        if (
            reverbComponent.adjustedStartTs === timeRange.startTs
            && reverbComponent.adjustedEndTs === timeRange.endTs
        ) {
            return reverbComponent;
        }

        const actuationEvents = reverbComponent.data.actuation_events.filter(({ adjustedTs }) => (
            (!timeRange.startTs || adjustedTs >= timeRange.startTs)
            && (!timeRange.endTs || adjustedTs <= timeRange.endTs)
        ));

        const totalActuations = actuationEvents.reduce((currentTotal, { actuations }) => currentTotal + actuations, 0);

        return {
            ...reverbComponent,
            adjustedEndTs: timeRange.endTs,
            adjustedStartTs: timeRange.startTs,
            data: {
                ...reverbComponent.data,
                actuation_events: actuationEvents,
                total_actuations: totalActuations,
            },
        };
    });

    return filteredComponents;
}

export function filterTyreComponents(tyreComponents, timeRange) {
    if (!tyreComponents) {
        return null;
    }

    if (!timeRange) {
        return tyreComponents;
    }

    const filteredComponents = tyreComponents.map((tyreComponent) => {
        const startIndex = Math.max(timeRange.startTs - tyreComponent.data.adjustedTime[0], 0);
        const endIndex = Math.min(
            timeRange.endTs - tyreComponent.data.adjustedTime[0],
            tyreComponent.data.adjustedTime.length - 1,
        );

        const adjustedTime = tyreComponent.data.adjustedTime.slice(startIndex, endIndex + 1);
        const pressure = tyreComponent.data.pressure.slice(startIndex, endIndex + 1);
        const time = tyreComponent.data.time.slice(startIndex, endIndex + 1);

        return {
            ...tyreComponent,
            adjustedEndTs: timeRange.endTs,
            adjustedStartTs: timeRange.startTs,
            data: {
                ...tyreComponent.data,
                adjustedTime,
                pressure,
                time,
            },
        };
    });

    return filteredComponents;
}

export function getTimeRange(activity, location) {
    const timeRange = { endTs: 0, startTs: 0 };

    if (activity) {
        if (!activity.activitysummary_set || !activity.activitysummary_set.length) return timeRange;

        timeRange.endTs = activity.adjustedEndTs;
        timeRange.startTs = activity.adjustedStartTs;

        const { trimEndTsAdjusted, trimStartTsAdjusted } = activity.activitysummary_set[0].data;

        if (trimEndTsAdjusted && trimEndTsAdjusted < activity.adjustedEndTs) {
            timeRange.endTs = trimEndTsAdjusted;
        }

        if (trimStartTsAdjusted && trimStartTsAdjusted > activity.adjustedStartTs) {
            timeRange.startTs = trimStartTsAdjusted;
        }
    }

    if (location.search) {
        const params = new URLSearchParams(location.search);

        const parameterStartTs = Number.parseInt(params.get('start_ts') || '');
        const parameterEndTs = Number.parseInt(params.get('end_ts') || '');

        if (activity) {
            if (Number.isFinite(parameterEndTs) && parameterEndTs < activity.adjustedEndTs) {
                timeRange.endTs = parameterEndTs;
            }

            if (Number.isFinite(parameterStartTs) && parameterStartTs > activity.adjustedStartTs) {
                timeRange.startTs = parameterStartTs;
            }
        }
    }

    return timeRange;
}
