import axios from 'axios';
import
{
    Fragment,
    ReactNode,
    useEffect,
    useRef,
    useState,
} from 'react';
import { IntlProvider } from 'react-intl';

import en_US from '../../assets/data/locale/en_US';
import {
    makeCancellable,
    REACT_APP_APPLANGA_API,
    REACT_APP_APPLANGA_APP,
    REACT_APP_APPLANGA_TOKEN,
    RequestType,
} from '../../constants';
import { useNexus } from '../../providers/nexus/NexusContext';
import { useAuth } from '../../providers/auth/AuthContext';

const Translations = ({ children }: { children: ReactNode }) => {
    const { isLoggingIn, isAuthenticated } = useAuth();
    const { nexusUserProfile } = useNexus();
    const [selectedLanguage, setSelectedLanguage] = useState<Record<string, string>>(en_US);
    const setLanguageRequest = useRef<RequestType | null>(null);
    const languageCode = nexusUserProfile ? nexusUserProfile.language_code : window.navigator.language;
    const languageCodeSplit = languageCode.split('-');

    if (languageCodeSplit[1]) {
        languageCodeSplit[1] = languageCodeSplit[1].toLowerCase();
    }

    function getLanguageCodeKey() {
        switch (languageCodeSplit[0]) {
            case 'de':
                return 'de-DE';
            case 'es':
                return 'es-ES';
            case 'fr':
                return 'fr-FR';
            case 'it':
                return 'it-IT';
            case 'ja':
                return 'ja';
            case 'ko':
                return 'ko';
            case 'nl':
                return 'nl-NL';
            case 'pt':
                return 'pt-PT';
            case 'th':
                return 'th';
            case 'zh':
                switch (languageCodeSplit[1]) {
                    case 'cn':
                        return 'zh-CN';
                    case 'tw':
                        return 'zh-TW';
                    default:
                        return '';
                }
            default:
                return '';
        }
    }

    const setNewLanguage: () => void = async () => {
        if (setLanguageRequest.current) {
            setLanguageRequest.current.cancel();
        }

        const langKey = getLanguageCodeKey();

        setLanguageRequest.current = makeCancellable(axios.create().get(
            // eslint-disable-next-line max-len
            `${REACT_APP_APPLANGA_API}?app=${REACT_APP_APPLANGA_APP}&requestedLanguages=["en","${langKey || ''}"]`,
            { headers: { Authorization: `Bearer ${REACT_APP_APPLANGA_TOKEN}` } },
        ));

        try {
            const { data } = await setLanguageRequest.current.promise;

            if (!data) return;

            setLanguageRequest.current = null;
            let { entries } = data.data.en.main;

            if (langKey) {
                let selectedLangEntries = data.data[langKey].main.entries;

                // filter all untranslated IDs
                const filteredEntriesList = Object.keys(selectedLangEntries).filter((key) => selectedLangEntries[key].v)
                    .map((key) => ({ [key]: selectedLangEntries[key] }));

                selectedLangEntries = Object.assign({}, ...filteredEntriesList);

                entries = { ...entries, ...selectedLangEntries };
            }

            const translations = {} as typeof entries;

            Object.keys(entries).forEach((key) => {
                translations[key] = entries[key].v;
            });

            setSelectedLanguage(translations);
        } catch (error: any) {
            if (!error.isCancelled) {
                setLanguageRequest.current = null;
            }
        }
    };

    useEffect(() => {
      // Handle condition when user is authenticated but still fetching user profile
      if (isAuthenticated() && !nexusUserProfile) return;

      // Wait until loggingIn is done
      if (!isLoggingIn) setNewLanguage();
    }, [languageCode, isLoggingIn]);

    // Create a custom missingTranslation handler
    const missingTranslation = (defaultMessage: string, options: { id?: string }) => options?.id;

    return (
        <IntlProvider
            messages={selectedLanguage}
            locale="en"
            defaultLocale="en"
            onError={() => missingTranslation}
            textComponent={Fragment}
        >
            {children}
        </IntlProvider>
    );
};

export default Translations;
