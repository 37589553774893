import styles from './modals.module.scss';
import Translate from '../../../components/translate';
import { SRAM_600 } from '../../../constants';

interface SelectionModalAltProps {
    open: boolean;
    onClose: () => void;
    list: any[];
    setOption: (value: string) => void;
}

const SelectionModalAlt = ({
    open,
    onClose,
    list,
    setOption,
}: SelectionModalAltProps) => {
    if (!open) {
        return null;
    }
    const closeModal = () => {
        onClose();
    };

    return (
        <div  className={styles.modalContainerAlt}>
            <div key={list[0]} className={styles.listContainerAlt}>
                {list.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <>
                        <li key={index} className={styles.listItemAlt}
                            style={{
                                borderBottom: index !== list.length - 1 ? `1px solid ${SRAM_600}` : '',
                            }}
                        >
                            <button
                                className={styles.listButton}
                                type="button"
                                onClick={() => {
                                    setOption(item);
                                    closeModal();
                                }}
                            >
                                <Translate>{typeof item === 'string' ? item : item.label}</Translate>
                            </button>
                        </li>
                    </>
                ))}
            </div>
        </div>
    );
};

export default SelectionModalAlt;
