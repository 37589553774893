import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';

import { FIREBASE_CONFIG } from '../../constants';

const firebaseApp = initializeApp(FIREBASE_CONFIG);
const analytics = getAnalytics(firebaseApp);

const FirebaseAnalytics = (type:string, properties: any, userId?: string) => {
    if (userId) {
        setUserId(analytics, userId);
    }

    logEvent(analytics, type, properties);
};

export default FirebaseAnalytics;
