import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './ActivitiesTableRow.module.scss';

import Translate, { useTranslation } from '../../../../components/translate';

import { defaultBike, iconGrayX } from '../../../../assets';
import {
    makeCancellable, RequestType, toLocaleString, URL_STATIC_DATA,
} from '../../../../constants';
import { Activity, useBikes, useUnits } from '../../../../providers';
import { Bike } from '../../../../providers/bikes/types';

interface ActivitiesTableRowProps {
    activity: Activity | null,
    onDelete: (id?: string) => void,
    onEdit: (id?: string) => void
}

function getDateTime(activity: Activity | null) {
    if (!activity) return;

    const dateMoment = moment.utc(activity.adjustedStartTs * 1000);
    const relativeMoment = moment.utc((moment.utc().valueOf() + (activity.timezone_offset * 1000)));

    // eslint-disable-next-line consistent-return
    return dateMoment.calendar(
        relativeMoment,
        {
            lastDay: 'ddd, DD/MM/YYYY',
            lastWeek: 'ddd, DD/MM/YYYY',
            nextDay: 'ddd, DD/MM/YYYY',
            nextWeek: 'ddd, DD/MM/YYYY',
            sameDay: 'ddd, DD/MM/YYYY',
            sameElse: 'ddd, DD/MM/YYYY',
        },
    );
}

const ActivitiesTableRow = ({
    activity = null,
    onDelete = () => {},
    onEdit = () => {},
}: ActivitiesTableRowProps) => {
    const [bike, setBike] = useState<Bike | null>();
    const units = useUnits();
    const bikes = useBikes();
    const translate = useTranslation();
    const fetchBikeRequest = useRef< RequestType | null>(null);

    const data = activity?.activitysummary_set.length ? activity.activitysummary_set[0].data : null;

    const fetchBike = async () => {
        if (!activity?.bike_uuid) return;

        fetchBikeRequest.current = makeCancellable(bikes.fetchBike(activity.bike_uuid, true));

        try {
            const newBike = await fetchBikeRequest.current.promise;
            fetchBikeRequest.current = null;

            setBike(newBike);
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchBikeRequest.current = null;

                setBike(null);
            }
        }
    };

    useEffect(() => {
        fetchBike();

        return () => {
            if (fetchBikeRequest.current) {
                fetchBikeRequest.current.cancel();
                fetchBikeRequest.current = null;
            }
        };
    }, [activity]);

    const formatValue = (type: string, value: any) => {
        if (!value) return '--';

        switch (type) {
            case 'normalisedPower':
                return `${toLocaleString(value)} ${translate('UNITS_WATTS_SHORT')}`;
            case 'distance': {
                const convertedDistance = units.formatDistance(units.convertDistanceFromSI(value));
                const distanceSuffix = translate(units.getLabelDistance().shorthand);
                return `${toLocaleString(convertedDistance)} ${distanceSuffix}`;
            }
            case 'duration':
                return units.formatDuration(value);
            default:
                return value;
        }
    };

    const renderBike = () => {
        if (!bike) return '--';

        const bikeImage = bike.image && bike.image.replace('https://static.quarqnet.com/', URL_STATIC_DATA);

        return (
            <Link to={`/bikerack/${bike.id}`}>
                <img
                    alt=""
                    className={styles.bikeImage}
                    src={(bikeImage || defaultBike)}
                />
            </Link>
        );
    };

    return (
        <tr className={styles.row} key={activity?.id}>
            <td className={styles.activityDate}>
                {getDateTime(activity)}
            </td>
            <td className={styles.activityNameCell}>
                <Link className={styles.activityName} to={`/activities/${activity?.id}`}>
                    {activity?.name}
                </Link>
            </td>
            <td className={styles.activityType}>
                {formatValue('type', activity?.type)}
            </td>
            <td className={styles.bikeCell}>
                {renderBike()}
            </td>
            <td>
                {formatValue('distance', data?.distance)}
            </td>
            <td>
                {formatValue('duration', data?.duration)}
            </td>
            <td>
                {formatValue('normalisedPower', data?.normalized_power)}
            </td>
            <td>
                <div className={styles.buttonsGroup}>
                    <button
                        className={`${styles.button} ${styles.buttonEdit}`}
                        onClick={() => onEdit(activity?.id)}
                        type="button"
                    >
                        <Translate>EDIT</Translate>
                    </button>
                    <button
                        className={`${styles.button} ${styles.buttonDelete}`}
                        onClick={() => onDelete(activity?.id)}
                        type="button"
                    >
                        <img alt="delete" className={styles.deleteIcon} src={iconGrayX} />
                    </button>
                </div>
            </td>
        </tr>
    );
};

export default ActivitiesTableRow;
