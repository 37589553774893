import { useEffect, useRef, useState } from 'react';

import DefaultButton from './defaultButton';
import styles from './EditDrivetrain.module.scss';
import EditDrivetrainModal from './editDrivetrainModal';

import { makeCancellable } from '../../../../../constants';
import ErrorModal from '../../../../../views/errorModal';
import { BikeUpdates } from '../../../../../providers/bikes/types';
import {
    Activity,
    Bike,
    ComponentSummary,
} from '../../../../../providers';
import Spinner from '../../../../../components/spinner';

interface EditDrivetrainProps {
    activity: Activity;
    bike: Bike;
    ButtonComponent?: any;
    disableEdit: boolean | undefined;
    gearComponent: ComponentSummary | undefined;
    updateActivity: (data: {bike_uuid: string}) => void;
    updateBike: (bikeUpdates: BikeUpdates) => void;
}

const EditDrivetrain = ({
    activity,
    bike,
    ButtonComponent = DefaultButton,
    disableEdit = false,
    gearComponent,
    updateActivity,
    updateBike,
}: EditDrivetrainProps) => {
    const [error, setError] = useState<Error | null>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [showEditGearingModal, setShowEditGearingModal] = useState<boolean>(false);

    const updateBikeRequests = useRef(new Set());

    useEffect(() => () => {
        updateBikeRequests.current.forEach((request: any) => request.cancel());
        updateBikeRequests.current.clear();
    }, []);

    const triggerUpdateBike = async (
        bikeUpdates: {
            casette?: {name: string, teeth: number[]}
            chainring?: {name: string, teeth: number[]}
        },
    ) => {
        if (!Object.keys(bikeUpdates).length) return null;

        setIsFetching(true);

        const fetchRequest = makeCancellable(updateBike(bikeUpdates));
        updateBikeRequests.current.add(fetchRequest);

        try {
            const updatedBike = await fetchRequest.promise;
            updateBikeRequests.current.delete(fetchRequest);

            if (!updateBikeRequests.current.size) {
                setIsFetching(false);
            }

            if (updatedBike) {
                setShowEditGearingModal(!showEditGearingModal);
            } else {
                setError(new Error('DRIVETRAIN_UPDATE_ERROR'));
            }

            return updatedBike;
        } catch (err: any) {
            if (!err.isCancelled) {
                updateBikeRequests.current.delete(fetchRequest);

                setError(new Error('DRIVETRAIN_UPDATE_ERROR'));

                if (!updateBikeRequests.current.size) {
                    setIsFetching(false);
                }
            }

            return null;
        }
    };

    const renderButton = () => (
        <ButtonComponent
            disabled={disableEdit}
            onClick={() => setShowEditGearingModal(!showEditGearingModal)}
        />
    );

    if (error) {
        return (
            <ErrorModal
                error={error}
                onClose={() => setError(null)}
                onOverlayClick={() => {
                    setError(null);
                    setShowEditGearingModal(!showEditGearingModal);
                }}
            />
        );
    }

    return (
        <div className={styles.container}>
            <Spinner loading={isFetching} />
            <EditDrivetrainModal
                activity={activity}
                bike={bike}
                gearComponent={gearComponent}
                onCancel={() => setShowEditGearingModal(!showEditGearingModal)}
                onSave={(bikeUpdates) => triggerUpdateBike(bikeUpdates)}
                open={showEditGearingModal}
                updateActivity={updateActivity}
            />
            {renderButton()}
        </div>
    );
};

export default EditDrivetrain;
