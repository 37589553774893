import { createContext, useContext } from 'react';
import { UnitsProps } from './types.d';

const UnitsContext = createContext<UnitsProps>({
    units: {
        // state
        isFetching: false,
        units: [],
        // function
        // eslint-disable-next-line sort-keys
        convertAltitudeFromSI: () => 0,
        convertDistanceFromSI: () => 0,
        convertDistanceToSI: () => 0,
        convertEnergyFromSI: () => 0,
        convertHeightFromSI: () => 0,
        convertHeightToSI: () => 0,
        convertPressureFromSI: () => 0,
        convertSpeedFromSI: () => 0,
        convertTemperatureFromSI: () => 0,
        convertTemperatureToSI: () => 0,
        convertWeightFromSI: () => 0,
        convertWeightToSI: () => 0,
        convertWorkFromSI: () => 0,
        formatAltitude: () => 0,
        formatDate: () => '',
        formatDistance: () => 0,
        formatDuration: () => '',
        formatEnergy: () => 0,
        formatHeight: () => 0,
        formatPressure: () => 0,
        formatSpeed: () => 0,
        formatTemperature: () => 0,
        formatTime: () => '',
        formatWeight: () => 0,
        formatWork: () => 0,
        getLabelAltitude: () => '',
        getLabelDistance: () => '',
        getLabelEnergy: () => '',
        getLabelHeight: () => '',
        getLabelPressure: () => '',
        getLabelSpeed: () => '',
        getLabelTemperature: () => '',
        getLabelWeight: () => '',
        getUnit: () => '',
    },
});

export function useUnits() {
    const { units } = useContext(UnitsContext);

    return units;
}

export default UnitsContext;
