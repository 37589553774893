import React from 'react';

import InlineStatistic from './inlineStatistic';
import styles from './Statistic.module.scss';

import Translate from '../../../components/translate';

interface StatisticProps {
    children?: React.ReactNode;
    disableBorder?: boolean;
    inline?: boolean;
    label?: string;
    labelParams?: Record<string, unknown>;
    units?: string;
    id?: string;
}

const Statistic = ({
    children,
    disableBorder,
    inline,
    label,
    labelParams,
    units,
    id,
}: StatisticProps) => {
    const value = children
        ? (
            <>
                {children}
                {units && (
                    <>
                        &nbsp;
                        <Translate>{units}</Translate>
                    </>
                )}
            </>
        )
        : '--';

    if (inline) {
        return <InlineStatistic disableBorder={disableBorder} label={label} labelParams={labelParams} value={value} />;
    }

    return (
        <div>
            <div className={styles.label} id={id}>
                <Translate params={labelParams}>{label}</Translate>
            </div>
            <div className={styles.value}>
                {value}
            </div>
        </div>
    );
};

export default Statistic;
