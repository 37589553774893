import { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import Notification from './notification';
import styles from './Notifications.module.scss';

import Spinner from '../../components/spinner';
import Translate from '../../components/translate';
import { makeCancellable, RequestType } from '../../constants';
import Logger from '../../Logger';
import { useNotifications } from '../../providers/notifications/NotificationsContext';

const Notifications = () => {
    const [isMarkingAllAsRead, setIsMarkingAllAsRead] = useState(false);
    const notificationsContainerRef = useRef(null);
    const notifications = useNotifications();
    const { list, isFetching } = notifications;
    const markAllAsReadRequest = useRef<RequestType | null>(null);

    useEffect(() => () => {
        if (markAllAsReadRequest.current) {
            markAllAsReadRequest.current.cancel();
            markAllAsReadRequest.current = null;
        }
    }, []);

    const markAllAsRead = async () => {
        if (markAllAsReadRequest.current) {
            try {
                await markAllAsReadRequest.current.promise;
                return;
            } catch (error) {
                return;
            }
        }

        setIsMarkingAllAsRead(true);
        markAllAsReadRequest.current = makeCancellable(notifications.markAllAsRead());

        try {
            await markAllAsReadRequest.current.promise;
            markAllAsReadRequest.current = null;

            setIsMarkingAllAsRead(false);
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn(error);
                markAllAsReadRequest.current = null;
            }
        }
    };

    const renderNoMessages = () => {
        if (list.length || isFetching) return null;

        return (
            <div className={styles.noMessages}>
                <Translate>MESSAGES_NONE</Translate>
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <Spinner loading={notifications.isFetching || isMarkingAllAsRead} />
            <div className={styles.content} ref={notificationsContainerRef}>
                <button
                    className={styles.markAllButton}
                    onClick={() => markAllAsRead()}
                    type="button"
                >
                    <Translate>MESSAGES_MARK_ALL_AS_READ</Translate>
                </button>
                {renderNoMessages()}
                <InfiniteScroll
                    dataLength={list.length}
                    hasMore={notifications.hasMore}
                    next={notifications.fetch}
                    loader={<Spinner />}
                >
                    {notifications.list.map((notification) => (
                        <Notification key={notification.id} notification={notification} />
                    ))}
                </InfiniteScroll>
            </div>
        </div>
    );
};

export default Notifications;
