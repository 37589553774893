import { scaleTime } from 'd3';
import { Area, Line, Scale } from 'd3-charts-react';
import { useCallback } from 'react';

import { extractTime, extractValue, sanitizeAltitude } from '../chartHelpers';

import { SRAM_200, TRANSPARENT } from '../../../constants';
import { useNexus } from '../../../providers/nexus/NexusContext';
import { useUnits } from '../../../providers/units/UnitsContext';
import { ComponentSummary } from '../../../providers';

interface AltitudeLineChartProps {
    gpsComponent: ComponentSummary,
    height: number,
    heightPercentage?: number,
    onAltitudeChange?: (altitude: number) => void,
    style?: any,
    sync?: boolean,
    width?: number,
}

const AltitudeLineChart = ({
    gpsComponent,
    height = 400,
    heightPercentage = 0.3,
    onAltitudeChange = () => { /* do nothing */ },
    style = null,
    sync,
    width = 700,
}: AltitudeLineChartProps) => {
    const { nexusUserProfile } = useNexus();
    const { convertAltitudeFromSI } = useUnits();
    const valueExtractor = useCallback(
        (
            data: { [x: string]: any; },
            _scale: any,
            _pathRef: any,
            timestamp: { getTime: () => number; },
        ) => {
            if (!timestamp) {
                onAltitudeChange(0);
                return;
            }

            const timestampInSeconds = Math.round(timestamp.getTime() / 1000);

            const dataPointIndex = gpsComponent.data.adjustedTime.findIndex(
                (time: number) => time === timestampInSeconds,
            );
            const altitude = data[dataPointIndex];

            onAltitudeChange(altitude ? convertAltitudeFromSI(altitude) : 0);
        },
        [onAltitudeChange, nexusUserProfile],
    );

    if (!gpsComponent) return null;

    // Sanitise the data to remove null values
    gpsComponent.data.alt = sanitizeAltitude(gpsComponent.data.alt)

    return (
        <Scale
            height={height}
            width={width}
            xMax={gpsComponent.adjustedEndTs * 1000}
            xMin={gpsComponent.adjustedStartTs * 1000}
            xScale={scaleTime}
            yMax={gpsComponent.data.max_alt + (
                Math.abs(gpsComponent.data.max_alt - gpsComponent.data.min_alt)
                / heightPercentage
                - Math.abs(gpsComponent.data.max_alt - gpsComponent.data.min_alt)
            )}
            yMin={gpsComponent.data.min_alt - (Math.abs(gpsComponent.data.min_alt) * 0.05)}
        >
            {(scale: any) => (
                <>
                    <Line
                        data={gpsComponent.data.alt}
                        scale={scale}
                        style={{ stroke: TRANSPARENT }}
                        xExtractor={(...params: any) => extractTime(gpsComponent, ...params)}
                        yExtractor={extractValue}
                        valueExtractor={valueExtractor}
                        sync={sync}
                    />
                    <Area
                        data={gpsComponent.data.alt}
                        scale={scale}
                        style={{
                            fill: SRAM_200,
                            stroke: SRAM_200,
                            ...style,
                        }}
                        xExtractor={(...params: any) => extractTime(gpsComponent, ...params)}
                        yExtractor={extractValue}
                    />
                </>
            )}
        </Scale>
    );
};

export default AltitudeLineChart;
