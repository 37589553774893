import React from 'react';

import styles from './BikeComponentDownloads.module.scss';

import Translate from '../../../components/translate';

interface BikeComponentDownloadsProps {
    downloads: Array<{
        name: string,
        url: string,
    }>,
    title: string,
}

const BikeComponentDownloads = ({ downloads, title }: BikeComponentDownloadsProps) => {
    if (!downloads || !downloads.length) {
        return null;
    }

    const checkIfPublications = (urlTitle: string) => {
        if (urlTitle.includes('publications')) {
            return urlTitle.replace('https://www.sram.com', '');
        }
        return urlTitle;
    };

    const sortedDownloads = downloads.map((data) => {
        if (data.url.includes('publications')) {
            return { ...data, item: 0 };
        }
        return { ...data, item: 1 };
    });

    sortedDownloads.sort((a, b) => a.item - b.item);

    return (
        <div>
            {title && (
                <div className={styles.title}>
                    <Translate>{title}</Translate>
                </div>
            )}
            <div>
                {sortedDownloads.map((link) => (
                    <div className={styles.linkContainer} key={link.url}>
                        <a
                            className={styles.link}
                            href={checkIfPublications(link.url)}
                            rel="noreferrer"
                            target="_blank"
                        >
                            {link.name}
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BikeComponentDownloads;
