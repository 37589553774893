import axios from 'axios';
import { ReactNode, useEffect, useRef } from 'react';

import NotificationsContext from './NotificationsContext';
import { Notification } from './types.d';

import { useAuth } from '../auth';
import { useNexus } from '../nexus';

import { makeCancellable, RequestType, URL_API } from '../../constants';
import { useSetState } from '../../hooks';
import Logger from '../../Logger';

interface NotificationsProviderProps { children: ReactNode }

const URL_COMMUNICATION_PREFERENCES = `${URL_API}communicationprefs/`;
const URL_NOTIFICATIONS = `${URL_API}notifications/`;

const DEFAULT_STATE = {
    hasMore: true,
    isFetching: false,
    notificationPreferences: null,
    notifications: [],
    page: 1,
    unreadCount: 0,
};

function NotificationsProvider({ children }: NotificationsProviderProps) {
    const [state, setState] = useSetState(DEFAULT_STATE);
    const notificationsRef = useRef<Notification []>([]);
    const auth = useAuth();
    const nexus = useNexus();

    const {
        hasMore,
        notificationPreferences,
        notifications,
        page,
        unreadCount,
    } = state;

    const initialNotificationTotal = useRef<number>(0);

    const fetchNotificationPreferencesRequest = useRef<RequestType | null>(null);
    const generateNotificationRequests = useRef(new Set<RequestType | null>());
    const notificationsRequest = useRef<RequestType | null>(null);
    // Requests for updating a notification
    const notificationRequests = useRef(new Set<RequestType | null>());
    const updateNotificationPreferencesRequests = useRef(new Set<RequestType | null>());

    const clear = () => {
        initialNotificationTotal.current = 0;

        if (fetchNotificationPreferencesRequest.current) {
            fetchNotificationPreferencesRequest.current.cancel();
            fetchNotificationPreferencesRequest.current = null;
        }

        generateNotificationRequests.current.forEach((request) => request?.cancel());
        generateNotificationRequests.current.clear();

        if (notificationsRequest.current) {
            notificationsRequest.current.cancel();
            notificationsRequest.current = null;
        }

        notificationRequests.current.forEach((request) => request?.cancel());
        notificationRequests.current.clear();

        updateNotificationPreferencesRequests.current.forEach((request) => request?.cancel());
        updateNotificationPreferencesRequests.current.clear();

        setState(DEFAULT_STATE);
    };

    const fetchNewNotifications = async () => {
        let totalNewNotifications: Notification[] = [];

        try {
            for (let i = 1; i <= page; i++) {
                // Await in loop is intentional here
                // eslint-disable-next-line no-await-in-loop
                const { data, headers } = await axios.get(URL_NOTIFICATIONS, { params: { page: i } });

                setState({ unreadCount: Number.parseInt(headers['new-count']) });
                initialNotificationTotal.current = Number.parseInt(headers['total-count']);

                const newNotifications = data.filter(({ id }:{ id: string}) => (
                    !notificationsRef.current.find((notification: Notification) => notification.id === id)
                ));

                totalNewNotifications = [
                    ...totalNewNotifications,
                    ...newNotifications,
                ];

                // Notifications have overlapped
                if (newNotifications.length < 10) {
                    break;
                }
            }
        } catch (error) {
            Logger.warn('Error Fetching New Notifications', error);
        }

        notificationsRef.current = [
            ...notificationsRef.current.filter(
                ({ id }: { id: string }) => !totalNewNotifications.find((newNotification) => (
                    newNotification.id === id
                )),
            ),
            ...totalNewNotifications,
        ].sort((a, b) => (b.create_ts - a.create_ts));

        setState({ notifications: [...notificationsRef.current] });

        return totalNewNotifications;
    };

    const fetchNotificationPreferences = async () => {
        if (notificationPreferences) {
            return notificationPreferences;
        }
        if (fetchNotificationPreferencesRequest.current) {
            try {
                const { data } = await fetchNotificationPreferencesRequest.current.promise;
                return data && data[0];
            } catch (error) {
                return null;
            }
        }

        fetchNotificationPreferencesRequest.current = makeCancellable(axios.get(URL_COMMUNICATION_PREFERENCES));
        try {
            const { data } = await fetchNotificationPreferencesRequest.current.promise;
            fetchNotificationPreferencesRequest.current = null;

            setState({ notificationPreferences: data && data[0] });

            return data && data[0];
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching notification preferences', error);
                fetchNotificationPreferencesRequest.current = null;
            }

            return null;
        }
    };

    const fetchNotifications = async () => {
        // Don't refetch is fetching
        if (notificationsRequest.current) {
            try {
                const { data } = await notificationsRequest.current.promise;
                return data;
            } catch (error) {
                return null;
            }
        }

        if (!hasMore) {
            return null;
        }

        setState({ isFetching: true });
        // notificationsRequest.current cancellable
        notificationsRequest.current = makeCancellable(axios.get(URL_NOTIFICATIONS, { params: { page } }));
        try {
            const { data, headers } = await notificationsRequest.current.promise;
            notificationsRequest.current = null;

            if (page === 1) {
                initialNotificationTotal.current = Number.parseInt(headers['total-count']);
            }

            const newNotifications = [
                ...notifications.filter(
                    ({ id }: { id: string}) => !data.find(
                        (newNotification: Notification) => newNotification.id === id,
                    ),
                ),
                ...data,
            ].sort((a, b) => (b.create_ts - a.create_ts));

            setState({
                hasMore: newNotifications.length < initialNotificationTotal.current,
                isFetching: false,
                notifications: newNotifications,
                page: page + 1,
                unreadCount: Number.parseInt(headers['new-count']),
            });

            notificationsRef.current = newNotifications;

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching notifications', page, error);
                notificationsRequest.current = null;

                setState({ hasMore: false, isFetching: false });
            }

            return null;
        }
    };

    const markAllAsRead = async () => {
        // Start the fetch request
        const fetchRequest = makeCancellable(axios.post(
            `${URL_NOTIFICATIONS}viewed/`,
            { ids: 'all' },
        ));

        // Add the cancellable promise to the set
        notificationRequests.current.add(fetchRequest);
        try {
            // Await the response
            const { data } = await fetchRequest.promise;
            // Remove the finished promise from the set
            notificationRequests.current.delete(fetchRequest);

            setState({
                // Override the notification with the updated notification
                notifications: notifications.map((notification: Notification) => (
                    data.find(({ id }: { id: string }) => id === notification.id) || notification
                )).sort((a: Notification, b: Notification) => (b.create_ts - a.create_ts)),
                unreadCount: 0,
            });

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error marking all notifications as read', error);

                // Remove the finished promise from the set
                notificationRequests.current.delete(fetchRequest);
            }

            return null;
        }
    };

    const updateNotification = async (id: string, notificationUpdates: { view_ts : number }) => {
        // Start the fetch request
        const fetchRequest = makeCancellable(axios.patch(
            `${URL_NOTIFICATIONS}${id}/`,
            notificationUpdates,
        ));

        // Add the cancellable promise to the set
        notificationRequests.current.add(fetchRequest);

        try {
            // Await the response
            const { data } = await fetchRequest.promise;
            // Remove the finished promise from the set
            notificationRequests.current.delete(fetchRequest);

            const oldNotification = notifications.find((notification: Notification) => notification.id === id);
            setState({
                // Override the notification with the updated notification
                notifications: notifications
                    .map((notification: Notification) => ((notification.id === id) ? data : notification))
                    .sort((a: Notification, b: Notification) => (b.create_ts - a.create_ts)),
                unreadCount: (oldNotification && !oldNotification.view_ts)
                    ? Math.max(unreadCount - 1, 0)
                    : unreadCount,
            });

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error updating notification', id, notificationUpdates, error);

                // Remove the finished promise from the set
                notificationRequests.current.delete(fetchRequest);
            }

            return null;
        }
    };

    const updateNotificationPreferences = async (updates: any) => {
        if (!notificationPreferences) {
            return null;
        }

        const { user } = updates;

        const updateRequest = makeCancellable(
            axios.patch(
                `${URL_COMMUNICATION_PREFERENCES}${user}/`,
                updates,
            ),
        );

        updateNotificationPreferencesRequests.current.add(updateRequest);
        try {
            const { data } = await updateRequest.promise;

            updateNotificationPreferencesRequests.current.delete(updateRequest);

            setState({ notificationPreferences: data });

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error updating notification preferences', error);
                updateNotificationPreferencesRequests.current.delete(updateRequest);
            }

            return null;
        }
    };

    const generateNotification = async (newNotification: any) => {
        if (!newNotification) {
            return null;
        }
        // eslint-disable-next-line no-param-reassign
        newNotification.owner = nexus.nexusUserProfile.id;

        // Start the fetch request
        const fetchRequest = makeCancellable(axios.post(
            URL_NOTIFICATIONS,
            newNotification,
        ));
        generateNotificationRequests.current.add(fetchRequest);

        try {
            const { data } = await fetchRequest.promise;
            generateNotificationRequests.current.delete(fetchRequest);

            // ID duplications should be handled on the backend, this should be safe
            setState({
                notifications: [...notifications, data].sort((a, b) => (b.create_ts - a.create_ts)),
                unreadCount: unreadCount + 1,
            });

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error generating notification', newNotification, error);

                generateNotificationRequests.current.delete(fetchRequest);
            }

            return null;
        }
    };

    const triggerMessages = async () => {
        try {
            const { data } = await axios.post(
                `${URL_API}messages/`,
                { users: [nexus.nexusUserProfile.id] },
            );

            return data;
        } catch (error) {
            Logger.warn('Error triggering push notification', error);
            return null;
        }
    };

    useEffect(() => {
        if (!auth.accessToken) {
            clear();
            return;
        }

        fetchNotifications();
        fetchNotificationPreferences();
    }, [auth.accessToken]);

    useEffect(() => () => {
        clear();
    }, []);

    return (
        <NotificationsContext.Provider
            value={{
                notifications: {
                    ...state,
                    clear,
                    fetch: () => fetchNotifications(),
                    fetchNewNotifications,
                    fetchNotificationPreferences,
                    generateNotification,
                    list: notifications,
                    markAllAsRead,
                    triggerMessages,
                    updateNotification,
                    updateNotificationPreferences,
                },
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
}

export default NotificationsProvider;
