import React from 'react';

import styles from './MenuItem.module.scss';

interface MenuItemProps {
    className?: string,
    children: React.ReactNode,
}

const MenuItem = ({ className = '', children, ...otherProps }: MenuItemProps) => (
    <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
        className={`${styles.container} ${className}`}
    >
        {children}
    </div>
);

export default MenuItem;
