import {
    func,
    number,
    shape,
} from 'prop-types';
import React from 'react';

import { SRAM_RED } from '../../../../../../constants';

const PressureAlarmLine = ({ pressure, scale, strokeDasharray }) => {
    if (!pressure) {
        return null;
    }

    return (
        <line
            stroke={SRAM_RED}
            strokeDasharray={strokeDasharray}
            x1="0"
            x2="100%"
            y1={scale.y(pressure)}
            y2={scale.y(pressure)}
        />
    );
};

PressureAlarmLine.defaultProps = {
    pressure: 0,
    strokeDasharray: 11,
};

PressureAlarmLine.propTypes = {
    pressure: number,
    scale: shape({ x: func, y: func }).isRequired,
    strokeDasharray: number,
};

export default PressureAlarmLine;
