import React from 'react';
import { useLocation } from 'react-router-dom';

import styles from './TyrePressureGuidePage.module.scss';

import { logoZipp } from '../../assets';
import ContentContainer from '../../components/contentContainer';
import Translate from '../../components/translate';
import TyrePressureGuide2 from '../../views/tyrePressureGuide2';

const TyrePressureGuidePage = () => {
    const location = useLocation();

    return (
        <div className={styles.container}>
            <ContentContainer style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '1312px',
                paddingTop: 0,
            }}
            >
                <div
                    className={styles.headerContainer}
                    id="data-test-tire-pressure-header"
                >
                    {(location.hash === '#referrer=zipp') && <img alt="" className={styles.imageZipp} src={logoZipp} />}
                    <h3 className={styles.header}>
                        <Translate>TIRE_PRESSURE_GUIDE</Translate>
                    </h3>
                </div>

                <TyrePressureGuide2 />

            </ContentContainer>
        </div>
    );
};

export default TyrePressureGuidePage;
