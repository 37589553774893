import React from 'react';

import styles from './AccountLinkingErrorModal.module.scss';

import { iconEmailVerified } from '../../../assets';
import Button from '../../../components/button';
import Modal from '../../../components/modal';
import Translate from '../../../components/translate';

interface AccountLinkingErrorModalProps {
    message: string | null,
    open: boolean,
    toggle: () => void,
}

const AccountLinkingErrorModal = ({ message, open, toggle }: AccountLinkingErrorModalProps) => (
    <Modal
        contentClassName={styles.container}
        contentLabel="Error Linking Account"
        dialog
        header="ACCOUNT_LINK_ERROR"
        hideCloseButton
        imageSrc={iconEmailVerified}
        imageStyle={{ height: '3rem', marginBottom: 0, marginTop: 'auto' }}
        isOpen={!!open}
        onClose={toggle}
    >
        <Translate>{message}</Translate>
        <div className={styles.buttonContainer}>
            <Button
                className={styles.button}
                inverse
                onClick={toggle}
                type="button"
            >
                <Translate>CLOSE</Translate>
            </Button>
        </div>
    </Modal>
);

export default AccountLinkingErrorModal;
