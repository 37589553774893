import { ElementType } from 'react';
import { BeatLoader } from 'react-spinners';

import styles from './Spinner.module.scss';

import { SRAM_400 } from '../../constants';

interface SpinnerProps{
        color?: string,
        Component?: ElementType,
        loading?: boolean,
        margin?: number,
        height?: number,
        width?: number,
        radius?: number,
    }

const Spinner = ({
    color = SRAM_400,
    Component = BeatLoader,
    loading = true,
    margin,
    height,
    width,
    radius,
}: SpinnerProps) => {
    if (!loading) {
        return null;
    }

    return (
        <div className={styles.container} data-testid="spinner">
            <Component color={color} margin={margin} height={height} width={width} radius={radius} />
        </div>
    );
};

export default Spinner;
