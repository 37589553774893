import { useState } from 'react';

import styles from './FtpDisplay.module.scss';
import FtpModal from './ftpModal';

import Translate from '../../../../../components/translate';
import { toLocaleString } from '../../../../../constants';
import { useNexus } from '../../../../../providers/nexus/NexusContext';

const FtpDisplay = ({ disableEdit = false }: { disableEdit?: boolean }) => {
    const [showFtpModal, setShowFtpModal] = useState(false);
    const { nexusUserProfile } = useNexus();
    const ftp = nexusUserProfile ? nexusUserProfile.ftp : 200;

    return (
        <div className={styles.container}>
            <FtpModal
                onCancel={() => setShowFtpModal(!showFtpModal)}
                onSave={() => setShowFtpModal(!showFtpModal)}
                open={showFtpModal}
            />
            <span>
                <Translate
                    params={{
                        value: (
                            <button
                                className={styles.ftpValue}
                                disabled={disableEdit}
                                onClick={() => setShowFtpModal(!showFtpModal)}
                                type="button"
                            >
                                <Translate params={{ FTP: (ftp ? toLocaleString(ftp) : '--') }}>
                                    BASED_ON_MY_FTP
                                </Translate>
                                &nbsp;
                                <Translate>UNITS_WATTS</Translate>
                            </button>
                        ),
                    }}
                >
                    BASED_ON
                </Translate>
            </span>
        </div>
    );
};

export default FtpDisplay;
