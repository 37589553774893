import axios from 'axios';
import moment from 'moment';
import 'moment-duration-format';
import { ReactNode, useEffect, useRef } from 'react';

import {
    UNIT_FORMATTERS,
    UNIT_LABELS,
    UNIT_SYSTEMS,
    UNITS,
    UNITS_IMPERIAL,
    UNITS_METRIC,
} from './constants';
import UnitsContext from './UnitsContext';
import {
    Category, InOperations, Operations, Unit,
} from './types.d';

import { useSetState } from '../../hooks';
import { useNexus } from '../nexus/NexusContext';

import {
    makeCancellable,
    MOMENT_TWELVE_HOUR_FORMAT,
    MOMENT_TWENTY_FOUR_HOUR_FORMAT,
    RequestType,
    URL_API,
} from '../../constants';
import Logger from '../../Logger';

const INVERSE_OPERATORS: Operations = {
    '*': '/',
    '+': '-',
    '-': '+',
    '/': '*',
};

async function fetchAllUnits() {
    let allUnits: any[] = [];
    let total = 0;
    let page = 0;
    const page_size = 20;
    do {
        page += 1;
        // eslint-disable-next-line no-await-in-loop
        const { data, headers } = await axios.get(`${URL_API}advancedunits/`, { params: { page, page_size } });
        if (page === 1) {
            total = Number.parseInt(headers['total-count']);
        }

        allUnits = allUnits.concat(data);
    } while (allUnits.length < total);

    return allUnits;
}

function generateOperations(expression: any) {
    return expression
        .split('')
        // Get operator indexes
        .map((charactor : string, index: number) => (charactor.match(/[/*+=]/) ? index : -1))
        .filter((indexOfCharacter: number) => (indexOfCharacter !== -1))
        .map((operatorIndex: number, index: number, array: string[]) => {
            if (index === array.length - 1) {
                return [
                    expression.charAt(operatorIndex),
                    Number(expression.substring(operatorIndex + 1)),
                ];
            }

            return [
                expression.charAt(operatorIndex),
                Number(expression.substring(operatorIndex + 1, array[index + 1])),
            ];
        });
}

function convert(value: number, inOperations: InOperations[], fromSI = true) {
    const operations: any[] = fromSI
        ? inOperations
        : inOperations
            .map(([operation, transformer]) => ([INVERSE_OPERATORS[operation], transformer]))
            .reverse();
    let convertedValue: number = value;

    operations.forEach(([operator, transformer]) => {
        switch (operator) {
            case '*':
                convertedValue *= transformer;
                break;
            case '+':
                convertedValue += transformer;
                break;
            case '-':
                convertedValue -= transformer;
                break;
            case '/':
                convertedValue /= transformer;
                break;
            default:
                break;
        }
    });

    return convertedValue;
}

interface NexusProviderProps { children: ReactNode }

function UnitsProvider({ children }: NexusProviderProps) {
    const [state, setState] = useSetState({ isFetching: true, units: [] });
    const { units } = state;
    const nexus = useNexus();

    const requestUnits = useRef<RequestType | null>(null);

    const formatDate = (inTime: Date) => moment.utc(inTime).calendar();

    const formatDuration = (inDuration: string) => {
        if (!inDuration) {
            return null;
        }

        return moment
            .duration(inDuration, 'seconds')
            .format('h[:]mm[:]ss', { stopTrim: 'm' });
    };

    const getUnitMetricCategory = (category: Category) => UNITS_METRIC[category];

    const getAdvancedUnit = (category: any, overrideUserProfile?: any) => {
        const profileToUse = overrideUserProfile || nexus.nexusUserProfile;

        if (!profileToUse || !profileToUse.advanced_units) {
            return getUnitMetricCategory(category);
        }

        return profileToUse.advanced_units[category];
    };

    const getUnit = (category: Category, overrideUnit?: string | null, overrideUserProfile?: any) => {
        const profileToUse = overrideUserProfile || nexus.nexusUserProfile;

        let unit = overrideUnit && overrideUnit.toLowerCase();
        if (!unit && profileToUse && profileToUse.units) {
            switch (profileToUse.units) {
                case UNIT_SYSTEMS.IMPERIAL:
                    unit = UNITS_IMPERIAL[category];
                    break;
                case UNIT_SYSTEMS.ADVANCED:
                    unit = getAdvancedUnit(category, overrideUserProfile);
                    break;
                case UNIT_SYSTEMS.METRIC:
                    unit = UNITS_METRIC[category];
                    break;
                default:
                    break;
            }
        }

        if (!unit) {
            unit = UNITS_METRIC[category];
        }

        return unit;
    };

    const getLabel = (category: Category, overrideUnit?: string) => {
        const unit = getUnit(category, overrideUnit);

        return UNIT_LABELS[unit] || { longhand: unit, shorthand: unit };
    };

    const getLabelAltitude = (inUnit?: string) => getLabel('altitude', inUnit);

    const getLabelDistance = (inUnit?: string) => getLabel('distance', inUnit);

    const getLabelEnergy = (inUnit?: string) => getLabel('energy', inUnit);

    const getLabelHeight = (inUnit?: string) => getLabel('height', inUnit);

    const getLabelPressure = (inUnit?: string) => getLabel('pressure_tire', inUnit);

    const getLabelSpeed = (inUnit?: string) => getLabel('speed', inUnit);

    const getLabelTemperature = (inUnit?: string) => getLabel('temperature', inUnit);

    const getLabelWeight = (inUnit?: string) => getLabel('mass', inUnit);

    const convertUnit = (value: number, category: Category, fromSI = true, overrideUnit: string) => {
        const unitCategory: Unit = units.find(({ name }: { name: string}) => name === category);

        if (!unitCategory) {
            return value;
        }

        const unit = getUnit(category, overrideUnit);

        let unitConversion = unitCategory.conversions.find(({ label }) => label === unit);

        if (!unitConversion) {
            unitConversion = unitCategory.conversions.find(({ label }) => label === UNITS_METRIC[category]);
        }

        if (!unitConversion) {
            return value;
        }

        return convert(value, unitConversion.operations, fromSI);
    };

    const convertAltitudeFromSI = (value:number, inUnit: string) => convertUnit(value, 'altitude', true, inUnit);

    const convertDistanceFromSI = (value:number, inUnit: string) => convertUnit(value, 'distance', true, inUnit);

    const convertDistanceToSI = (value:number, inUnit: string) => convertUnit(value, 'distance', false, inUnit);

    const convertEnergyFromSI = (value:number, inUnit: string) => convertUnit(value, 'energy', true, inUnit);

    const convertHeightFromSI = (value:number, inUnit: string) => convertUnit(value, 'height', true, inUnit);

    const convertHeightToSI = (value:number, inUnit: string) => convertUnit(value, 'height', false, inUnit);

    const convertPressureFromSI = (value:number, inUnit: string) => convertUnit(value, 'pressure_tire', true, inUnit);

    const convertSpeedFromSI = (value:number, inUnit: string) => convertUnit(value, 'speed', true, inUnit);

    const convertTemperatureFromSI = (value:number, inUnit: string) => convertUnit(value, 'temperature', true, inUnit);

    const convertTemperatureToSI = (value:number, inUnit: string) => convertUnit(value, 'temperature', false, inUnit);

    const convertWeightFromSI = (value:number, inUnit: string) => convertUnit(value, 'mass', true, inUnit);

    const convertWeightToSI = (value:number, inUnit: string) => convertUnit(value, 'mass', false, inUnit);

    const convertWorkFromSI = (value:number, inUnit: string) => convertUnit(value, 'work', true, inUnit);

    const fetchUnits = async () => {
        requestUnits.current = makeCancellable(fetchAllUnits());

        try {
            const data = await requestUnits.current.promise;

            requestUnits.current = null;

            const updatedUnits: Unit[] = data.map((unit: Unit) => ({
                ...unit,
                conversions: unit.conversions.map((conversion) => ({
                    ...conversion,
                    operations: generateOperations(conversion.expression),
                })),
            }));

            setState({ isFetching: false, units: updatedUnits });

            return updatedUnits;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching unit conversions', error);
                requestUnits.current = null;
                setState({ isFetching: false });
            }

            return null;
        }
    };

    const format = (value: number, category: Category, overrideUnit: string) => {
        const unit = getUnit(category, overrideUnit);

        const formatter = UNIT_FORMATTERS[unit] || ((inValue) => inValue);

        return formatter(value);
    };

    const formatAltitude = (value: number, unit: string) => format(value, 'altitude', unit);

    const formatDistance = (value: number, unit: string) => format(value, 'distance', unit);

    const formatEnergy = (value: number, unit: string) => format(value, 'energy', unit);

    const formatHeight = (value: number, unit: string) => format(value, 'height', unit);

    const formatPressure = (value: number, unit: string) => format(value, 'pressure_tire', unit);

    const formatSpeed = (value: number, unit: string) => format(value, 'speed', unit);

    const formatTemperature = (value: number, unit: string) => format(value, 'temperature', unit);

    const isTime24Hour = (inUnit?: string) => {
        const timeUnit = inUnit || getAdvancedUnit('time_format');

        return !!timeUnit && (timeUnit.toLowerCase() === UNITS.hour_24);
    };

    const formatTime = (inTime: Date | number) => {
        if (isTime24Hour()) {
            return moment.utc(inTime).format(MOMENT_TWENTY_FOUR_HOUR_FORMAT);
        }

        return moment.utc(inTime).format(MOMENT_TWELVE_HOUR_FORMAT);
    };

    const formatWeight = (value: number, unit: string) => format(value, 'mass', unit);

    const formatWork = (value: number, unit: string) => format(value, 'work', unit);

    useEffect(() => {
        fetchUnits();

        return () => {
            if (requestUnits.current) {
                requestUnits.current.cancel();
                requestUnits.current = null;
            }
        };
    }, []);

    return (
        <UnitsContext.Provider
            value={{
                units: {
                    ...state,
                    convertAltitudeFromSI,
                    convertDistanceFromSI,
                    convertDistanceToSI,
                    convertEnergyFromSI,
                    convertHeightFromSI,
                    convertHeightToSI,
                    convertPressureFromSI,
                    convertSpeedFromSI,
                    convertTemperatureFromSI,
                    convertTemperatureToSI,
                    convertWeightFromSI,
                    convertWeightToSI,
                    convertWorkFromSI,
                    formatAltitude,
                    formatDate,
                    formatDistance,
                    formatDuration,
                    formatEnergy,
                    formatHeight,
                    formatPressure,
                    formatSpeed,
                    formatTemperature,
                    formatTime,
                    formatWeight,
                    formatWork,
                    getLabelAltitude,
                    getLabelDistance,
                    getLabelEnergy,
                    getLabelHeight,
                    getLabelPressure,
                    getLabelSpeed,
                    getLabelTemperature,
                    getLabelWeight,
                    getUnit,
                },
            }}
        >
            {children}
        </UnitsContext.Provider>
    );
}

export { convert, generateOperations };

export default UnitsProvider;
