import { useEffect, useRef } from 'react';

import styles from './FirmwareList.module.scss';

import { formatFirmwareByModel, sortFirmwareVersions } from '../helper';
import Releases from '../releases';

import Logger from '../../../Logger';
import Translate from '../../../components/translate';
import { makeCancellable, RequestType } from '../../../constants';
import { useSetState } from '../../../hooks';
import { useAuth, useDeviceModels } from '../../../providers';

interface FirmwareListProps { deviceType: any }

const FirmwareList = ({ deviceType }: FirmwareListProps) => {
    const auth = useAuth();
    const [state, setState] = useSetState({ firmwareVersions: [], loadingFirmwareVersions: false });
    const deviceModels = useDeviceModels();

    const fetchFirmwareRequest = useRef<RequestType | null>(null);
    const fetchReleaseNotesRequest = useRef<RequestType | null>(null);

    const isPowerTrainSystem = deviceType.name === 'POWERTRAIN_SYSTEM';

    const fetchFirmware = async () => {
        setState({ loadingFirmwareVersions: true });

        // Filtered by model code
        const uniqueDevices = deviceType.devices.filter(
            (device: any, index: number, devices: any) => index === devices.findIndex((item: any) => (
                item.model_code === device.model_code
            )),
        );

        fetchFirmwareRequest.current = makeCancellable(
            Promise.all(uniqueDevices.map(
                ({ model_id }: { model_id: number }) => deviceModels.fetchFirmware(model_id),
            )),
        );

        try {
            const data = await fetchFirmwareRequest.current.promise;

            const formattedFirmwareVersions = formatFirmwareByModel(data.flat(), deviceType.devices);
            // If no clean firmwareVersions found, no need to sort
            if (formattedFirmwareVersions.length) {
                // Sort notes by version
                sortFirmwareVersions(formattedFirmwareVersions);
            }

            setState({ firmwareVersions: formattedFirmwareVersions, loadingFirmwareVersions: false });
        } catch (error: any) {
            if (!error.isCancelled) {
                setState({ loadingFirmwareVersions: false });
            }
            // Cancelled
        }
    };

    const fetchReleaseNotes = async () => {
        setState({ loadingFirmwareVersions: true });

        fetchReleaseNotesRequest.current = makeCancellable(deviceModels.fetchReleaseNotes());

        try {
            const data = await fetchReleaseNotesRequest.current.promise;
            if (data) {
                setState({ firmwareVersions: data, loadingFirmwareVersions: false });
                return data;
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn(error);
                setState({ loadingFirmwareVersions: false });
                fetchReleaseNotesRequest.current = null;
            }
        }
        return null;
    };

    useEffect(() => {
        if (isPowerTrainSystem) {
            fetchReleaseNotes();
        } else fetchFirmware();

        return () => {
            if (fetchFirmwareRequest.current) {
                fetchFirmwareRequest.current.cancel();
                fetchFirmwareRequest.current = null;
            }
            if (fetchReleaseNotesRequest.current) {
                fetchReleaseNotesRequest.current.cancel();
                fetchReleaseNotesRequest.current = null;
            }
        };
    }, [auth.accessToken]);

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <div className={styles.title}>
                    <Translate>{deviceType.name}</Translate>
                </div>
            </div>
            <div className={styles.releaseNotesContainer}>
                <Releases
                    firmwareVersions={state.firmwareVersions}
                    loadingFirmwareVersions={state.loadingFirmwareVersions}
                    isPowerTrainSystem={isPowerTrainSystem}
                />
            </div>
        </div>
    );
};

export default FirmwareList;
