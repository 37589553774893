import React from 'react';

import { useAuth } from '../../providers/auth/AuthContext';

interface EmailNotVerifiedBlockProps {
    BlockedComponent?: React.FC;
    children?: React.ReactNode;
    renderBlocked: () => React.ReactNode | null;
}

// Renders the children if the email is verified
const EmailNotVerifiedBlock = ({
    BlockedComponent,
    children,
    renderBlocked = () => null,
}: EmailNotVerifiedBlockProps) => {
    const auth = useAuth();
    const isEmailVerified = auth.isEmailVerified();

    if (isEmailVerified === false) {
        return BlockedComponent
            ? <BlockedComponent />
            : renderBlocked();
    }

    return children;
};

export default EmailNotVerifiedBlock;
