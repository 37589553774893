import { useReducer } from 'react';

export default function useSetState(initialState: Record<string, any>) {
    const reducer = (state: any, newState: any) => ({
        ...state,
        ...newState,
    });

    const [state, setState] = useReducer(reducer, initialState);

    return [state, setState];
}
