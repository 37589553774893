import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './NotFoundCard.module.scss';

import Button from '../../components/button';
import Translate from '../../components/translate';

interface NotFoundCardProps {
    title: string;
}

const NotFoundCard = ({ title = 'Not Found' }: NotFoundCardProps) => {
    const navigate = useNavigate();

    return (
        <div className={styles.container} id="data-test-not-found-card">
            <div className={styles.content}>
                <div className={styles.header}>
                    <p>
                        <Translate>{title}</Translate>
                    </p>
                </div>
                <div className={styles.buttonContainer}>
                    <Button
                        style={{ margin: 0 }}
                        type="button"
                        onClick={() => navigate(-1)}
                        inverse
                    >
                        <Translate>GO_BACK</Translate>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default NotFoundCard;
