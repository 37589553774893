import React from 'react';

import styles from './UnderMaintenanceModal.module.scss';

import { iconDelete } from '../../assets';
import Modal from '../../components/modal';
import Translate from '../../components/translate';
import { useAuth } from '../../providers/auth/AuthContext';

const UnderMaintenanceModal = () => {
    const { logout } = useAuth();
    return (
        <Modal
            contentClassName={styles.container}
            contentLabel="Under Maintenance"
            disableOverlayClick
            dialog
            header="UNDER_MAINTENANCE"
            hideCloseButton
            imageAlt="Cross"
            imageSrc={iconDelete}
            imageStyle={{ height: '5rem', marginBottom: 0, marginTop: 'auto' }}
            isOpen
        >
            <Translate>UNDER_MAINTENANCE_APOLOGY</Translate>
            <button
                className={styles.logOutButton}
                onClick={logout}
                type="button"
            >
                <Translate>LOG_OUT</Translate>
            </button>
        </Modal>
    );
};

export default UnderMaintenanceModal;
