import styles from './modals.module.scss';
import Modal from '../../../components/modal';
import Translate from '../../../components/translate';
import { imageIdlerPulley } from '../../../assets';

const IdlerPullyInfo = ({
    open, onClose,
}:
    {open: boolean, onClose: () => void}) => (
    <Modal
        className={styles.modalContainerInfo}
        contentClassName={styles.infoContent}
        headerStyle={{ color: 'white', fontSize: '1.5rem' }}
        contentLabel="InfoModal"
        isOpen={open}
        header="IDLER_PULLEY"
        hideImage
        onClose={() => onClose()}
        containerStyle={{ padding: 'none' }}
        onOverlayClick={() => onClose()}
        darkMode
    >

        <div className={styles.imageContainer}>
            <img
                alt="Setup Key"
                className={styles.image}
                src={imageIdlerPulley}
                style={{ width: '100%' }}
            />
        </div>
        <div className={styles.infoText}>
            <Translate>HAVE_A_HIGH_PIVOT</Translate>
        </div>
        <div className={styles.infoText}>
            <Translate>MOBILE_IDLER_PULLEY_INFO</Translate>
        </div>
    </Modal>
);

export default IdlerPullyInfo;
