import styles from './modals.module.scss';
import Modal from '../../../components/modal';
import Translate from '../../../components/translate';
import {
    imageSetupCog,
    imageXPLRsetUpCog,
} from '../../../assets';

const SetupCogInfo = ({
    open, onClose, isXPLR,
}:
    {open: boolean, onClose: () => void, isXPLR: boolean }) => {
    const setupText = isXPLR ? 'XPLR_SETUP_COG_INFO' : 'SETUP_COG_INFO';
    const setUpImage = isXPLR ? imageXPLRsetUpCog : imageSetupCog;

    return (
        <Modal
            className={styles.modalContainerInfo}
            contentClassName={styles.infoContent}
            headerStyle={{ color: 'white', fontSize: '1.5rem' }}
            contentLabel="InfoModal"
            isOpen={open}
            header="SETUP_COG"
            hideImage
            onClose={() => onClose()}
            containerStyle={{ padding: 'none' }}
            onOverlayClick={() => onClose()}
            darkMode
        // hideCloseButton
        >

            <div className={styles.imageContainer}>
                <img
                    alt="Setup Key"
                    className={styles.image}
                    src={setUpImage}
                    style={{ width: '100%' }}
                />
            </div>
            <div className={styles.infoText}>
                <Translate>{setupText}</Translate>
            </div>
        </Modal>
    );
};

export default SetupCogInfo;
