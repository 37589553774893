import { createContext, useContext } from 'react';
import { ZONES } from '../../assets';
import { ACTIVITY_TYPES } from '../../constants';
import { ActivitiesProps } from './types';

const ActivitiesContext = createContext<ActivitiesProps>({
    activities: {
        activityTypes: ACTIVITY_TYPES,
        hasMore: false,
        heartZones: ZONES.HEART_ZONES,
        isFetching: false,
        list: [],
        page: 1,
        powerZones: ZONES.POWER_TRAINING_ZONES,
        previousFilters: '',
        totalCount: 0,
        // eslint-disable-next-line sort-keys
        clear: () => {},
        delete: () => {},
        download: () => {},
        fetch: () => {},
        fetchActivitiesTypes: () => {},
        fetchActivity: () => {},
        fetchExampleActivity: () => {},
        fetchShockWiz: () => {},
        fetchTrainingZones: () => {},
        regenerateActivity: () => {},
        triggerGarminSync: () => false,
        triggerWahooSync: () => false,
        updateActivity: () => {},
        updateActivitySummary: () => {},
        uploadActivity: () => false,
    },
});

export function useActivities() {
    const { activities } = useContext(ActivitiesContext);

    return activities;
}

export default ActivitiesContext;
