import { useEffect, useRef } from 'react';
import Moment from 'react-moment';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './Bike.module.scss';
import BikeComponentItem from './bikeComponentItem';
import BikeDropdown from './bikeDropdown';
import BikeTutorial from './bikeTutorial';
import DeleteBikeModal from './editBike/deleteBikeModal';
import EditBike from './editBike';
import GearingForm from './gearingForm';
import MobileBikeDropdown from './mobileBikeDropdown';

import AxsAppCard from '../../views/axsAppCard';
import BikeCard from '../../views/bikeCard';
import AddBikeComponent from '../bikeComponents/addBikeComponent';
import ContentContainer from '../../components/contentContainer';
import ErrorModal from '../../views/errorModal';
import ImageContainer from '../../components/imageContainer';
import NotFoundCard from '../../views/notFoundCard';
import Spinner from '../../components/spinner';
import Translate from '../../components/translate';
import WaffleBlock from '../../components/waffleBlock';

import { bannerAXSComponents, bannerAXSDrivetrain, logoQuarqWhite } from '../../assets';
import { makeCancellable, RequestType } from '../../constants';
import { useSetState } from '../../hooks';
import Logger from '../../Logger';
import { useAuth, useBikes, useNexus } from '../../providers';
import TirePressureGuideCard from './tirePressureGuideCard/TirePressureGuideCard';

const MANUFACTURER_AXS = 268;
const EXAMPLE = 'example';

const DEFAULT_STATE = {
    bike: null,
    components: [],
    error: null,
    imagePreview: null,
    isDeleting: false,
    isFetchingBike: true,
    isSaving: false,
    showBikeDropdown: false,
    showDeleteBikePopup: false,
    showEdit: false,
};

function Bike() {
    const [state, setState] = useSetState(DEFAULT_STATE);
    const {
        bike,
        components,
        error,
        imagePreview,
        isDeleting,
        isFetchingBike,
        isSaving,
        showBikeDropdown,
        showDeleteBikePopup,
        showEdit,
    } = state;
    const { uuid } = useParams();
    const navigate = useNavigate();
    const auth = useAuth();
    const nexus = useNexus();
    const bikes = useBikes();

    const deleteBikeRequest = useRef<RequestType | null>(null);
    const fetchBikeRequest = useRef<RequestType | null>(null);
    const fetchComponentSetRequest = useRef<RequestType | null>(null);
    const updateBikeRequests = useRef(new Set<RequestType>());

    useEffect(() => () => {
        if (deleteBikeRequest.current) {
            deleteBikeRequest.current.cancel();
        }

        if (fetchBikeRequest.current) {
            fetchBikeRequest.current.cancel();
        }

        if (updateBikeRequests.current) {
            updateBikeRequests.current.forEach((request: RequestType) => request.cancel());
        }

        if (fetchComponentSetRequest.current) {
            fetchComponentSetRequest.current.cancel();
        }
    }, []);

    const fetchBike = async () => {
        if (!uuid) return;

        if (fetchBikeRequest.current) {
            try {
                await fetchBikeRequest.current.promise;
            } catch (err) {
                return;
            }
        }

        fetchBikeRequest.current = makeCancellable(bikes.fetchBike(uuid));

        try {
            const data = await fetchBikeRequest.current.promise;

            setState({
                bike: data,
                components: (uuid === EXAMPLE) ? data.component_set : [],
                isFetchingBike: false,
            });

            fetchBikeRequest.current = null;
        } catch (err: any) {
            if (!err.isCancelled) {
                fetchBikeRequest.current = null;
                setState({ isFetchingBike: false });
            }
        }
    };

    useEffect(() => {
        fetchBike();
    }, [uuid]);

    const fetchComponentSet = async () => {
        if (!uuid) return;

        if (uuid === EXAMPLE) return;

        if (fetchComponentSetRequest.current) {
            try {
                await fetchComponentSetRequest.current.promise;
            } catch (err) {
                return;
            }
        }

        fetchComponentSetRequest.current = makeCancellable(bikes.fetchBikeComponentSet(uuid));

        try {
            const data = await fetchComponentSetRequest.current.promise;
            fetchComponentSetRequest.current = null;

            if (data) {
                setState({ components: data });
            }
        } catch (err: any) {
            if (!err.isCancelled) {
                fetchComponentSetRequest.current = null;
            }
        }
    };

    useEffect(() => {
        if (bike) fetchComponentSet();
    }, [bike]);

    const deleteBike = async () => {
        if (!uuid) return;

        if (deleteBikeRequest.current) {
            try {
                await deleteBikeRequest.current.promise;

                return;
            } catch (err) {
                // Handled below
                return;
            }
        }

        setState({ isDeleting: true });
        deleteBikeRequest.current = makeCancellable(bikes.deleteBike(uuid));

        try {
            await deleteBikeRequest.current.promise;
            deleteBikeRequest.current = null;

            setState({ isDeleting: false });

            navigate('/bikerack');
        } catch (err: any) {
            if (!err.isCancelled) {
                Logger.warn(err);
                deleteBikeRequest.current = null;

                setState({ isDeleting: false });
            }
        }
    };

    const updateBike = async (bikeUpdates = {}) => {
        if (!Object.keys(bikeUpdates).length) {
            Logger.log('No Bike Updates Exist');
            return;
        }

        setState({ isSaving: true });
        const fetchRequest = makeCancellable(bikes.updateBike(bike.id, bikeUpdates));
        updateBikeRequests.current.add(fetchRequest);

        try {
            const newBike = await fetchRequest.promise;
            updateBikeRequests.current.delete(fetchRequest);

            if (newBike) {
                setState({
                    bike: newBike,
                    imagePreview: null,
                    isSaving: !!updateBikeRequests.current.size,
                    showEdit: false,
                });
            } else {
                setState({ error: new Error('Error Updating Bike'), isSaving: false });
            }
        } catch (err: any) {
            if (!err.isCancelled) {
                updateBikeRequests.current.delete(fetchRequest);
                setState({ error: err, isSaving: !!updateBikeRequests.current.size });
            }
        }
    };

    const toggleEdit = () => {
        if (uuid === EXAMPLE) return;

        setState({ imagePreview: null, showEdit: !showEdit });
    };

    const renderAxsComponents = () => {
        if (!components.length) return null;

        const axsComponents = components.filter(
            ({ manufacturer }: { manufacturer: number }) => (manufacturer === MANUFACTURER_AXS),
        );

        if (!axsComponents.length) return null;

        return axsComponents.map((bikeComponent: any) => (
            <BikeComponentItem bikeComponent={bikeComponent} key={bikeComponent.id} />
        ));
    };

    const renderQuarqComponents = () => {
        if (!components) return null;

        const quarqComponents = components.filter(({ manufacturer }: { manufacturer: number }) => (manufacturer === 7));

        if (!quarqComponents.length) return null;

        return (
            <div className={styles.cardContents}>
                {quarqComponents.map((bikeComponent: any) => (
                    <BikeComponentItem bikeComponent={bikeComponent} key={bikeComponent.id} />
                ))}
            </div>
        );
    };

    const renderComponentsCard = () => (
        <div className={styles.cardContainer} style={{ marginTop: '2.5rem' }}>
            <ImageContainer className={styles.cardBanner} src={bannerAXSComponents} />
            <div className={styles.cardContents}>
                <div className={styles.axsContainer}>
                    <div className={styles.cardTitle}>
                        <Translate>BIKE_SYSTEM_COMPONENTS</Translate>
                    </div>
                    <div style={{ color: '#59595b' }}>
                        <Translate>BIKE_SYSTEM_COMPONENTS_DESCRIPTION</Translate>
                    </div>
                </div>
                {renderAxsComponents()}
                <div className={styles.quarqHeader}>
                    <div className={styles.headerSlope} />
                    <div className={styles.cardHeader}>
                        <img alt="" className={styles.quarqLogo} src={logoQuarqWhite} />
                    </div>
                </div>
                {renderQuarqComponents()}
                <TirePressureGuideCard bike={bike} />
            </div>
        </div>
    );

    const renderAxsAppBanner = () => {
        if ((!bike || components.length) && bike.id !== EXAMPLE) return null;

        return <AxsAppCard />;
    };

    const renderBikeDropdown = () => {
        const bikeDropdownProps = {
            onClick: () => setState({ showBikeDropdown: !showBikeDropdown }),
            onDeleteBike: () => setState({ showDeleteBikePopup: true }),
            open: showBikeDropdown,
            toggleEdit: () => toggleEdit(),
        };

        if ((!auth.isLoggingIn && !auth.isAuthenticated()) || (bike.id === EXAMPLE)) return null;

        return (
            <div className={styles.optionsIconContainer}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <BikeDropdown {...bikeDropdownProps} />
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <MobileBikeDropdown {...bikeDropdownProps} />
            </div>
        );
    };

    const renderBike = () => (
        <div className={styles.cardContainer}>
            <ErrorModal error={error} onClose={() => setState({ error: null })} />
            <BikeCard bike={bike} image={imagePreview}>
                {renderBikeDropdown()}
            </BikeCard>
            <EditBike
                bike={bike}
                onImageSelected={(newImagePreview: any) => setState({ imagePreview: newImagePreview })}
                onSubmit={(bikeUpdates: any) => updateBike(bikeUpdates)}
                show={showEdit}
                toggleShow={() => toggleEdit()}
            />
            <DeleteBikeModal
                onCancel={() => setState({ showDeleteBikePopup: false })}
                onDelete={() => deleteBike()}
                open={showDeleteBikePopup}
            />
        </div>
    );

    const renderGearingForm = () => (
        <GearingForm
            bike={bike}
            disabled={(uuid === EXAMPLE)}
            updateBike={(bikeUpdates: any) => updateBike(bikeUpdates)}
            savingBike={isSaving}
        />
    );

    const renderDrivetrainComponents = () => {
        if (!bike) return null;

        return (
            <div className={styles.cardContainer} style={{ marginTop: '2.5rem' }}>
                <ImageContainer className={styles.cardBanner} src={bannerAXSDrivetrain} />
                <div className={styles.cardContents}>
                    <div className={styles.axsContainer}>
                        <div className={styles.cardTitle}>
                            <Translate>BIKE_DRIVETRAIN_COMPONENTS</Translate>
                        </div>
                        <div style={{ color: '#59595b' }}>
                            <Translate>BIKE_DRIVETRAIN_DESCRIPTION</Translate>
                        </div>
                    </div>
                    <div>
                        {renderGearingForm()}
                    </div>
                </div>
            </div>
        );
    };

    const renderRetiredComponent = () => {
        if (!bike.data || !bike.data.retired) return null;

        return (
            <div className={styles.cardContainer} style={{ marginTop: '2.5rem' }}>
                <div className={styles.cardContents}>
                    <div className={styles.axsContainer}>
                        <div className={styles.retiredCard}>
                            <div className={styles.cardTitle}>
                                <Translate>RETIRED</Translate>
                                <div className={styles.retiredText}>
                                    {bike.data.retired}
                                </div>
                            </div>
                            <div className={styles.retiredText}>
                                <Moment format="MMMM Do, YYYY" unix>
                                    {bike.data.retired_date}
                                </Moment>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderTutorial = () => {
        const { nexusUserProfile } = nexus;
        /* TODO Improve this when activities can be uploaded within AXS Web
         * The issue here is that nexusUserProfile's user_journey.activityCount
         * won't be updated until the page is refreshed or the nexusUserProfile is updated
         */

        if ((!nexusUserProfile
            || (nexusUserProfile.user_journey && nexusUserProfile.user_journey.activityCount))
            && bike.id !== EXAMPLE) return null;

        return <BikeTutorial />;
    };

    const renderContent = () => {
        if (isFetchingBike) return null;

        if (!bike) return <NotFoundCard title="Bike Not Found" />;

        return (
            <>
                {renderBike()}
                <WaffleBlock flag="comp_reg">
                    <AddBikeComponent bike={bike} />
                </WaffleBlock>
                {renderRetiredComponent()}
                <div style={{ marginTop: '2.5rem' }}>
                    {renderComponentsCard()}
                </div>
                {renderDrivetrainComponents()}
                {renderAxsAppBanner()}
                {renderTutorial()}
            </>
        );
    };

    return (
        <ContentContainer>
            <Spinner loading={isFetchingBike || isSaving || isDeleting} />
            {renderContent()}
        </ContentContainer>
    );
}

export default Bike;
