import { useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';

import FirmwareList from './firmwareList';
import styles from './ReleaseNotes.module.scss';

import Fade from '../../components/fade';
import Spinner from '../../components/spinner';
import Translate from '../../components/translate';
import Logger from '../../Logger';
import { useAuth, useDeviceModels } from '../../providers';

const DEVICE_TYPE_POWER_METER = 133;
const DEVICE_TYPE_POWER_TRAIN = 999;

const ReleaseNotes = () => {
    const deviceModels = useDeviceModels();
    const { deviceType } = useParams();
    const [devices, setDevices] = useState<Record<string, any>>([]);
    const auth = useAuth();

    const extendedDeviceModels = deviceModels.deviceTypes
         && [...deviceModels.deviceTypes, { device_type: DEVICE_TYPE_POWER_TRAIN, name: 'POWERTRAIN_SYSTEM' }]
             .sort((a, b) => a.name.localeCompare(b.name));

    const fetchDeviceModels = async () => {
        try {
            const rawDevices = await deviceModels.fetch();
            const newDeviceTypes = deviceModels.deviceTypes.map((type) => {
                const newDevices = rawDevices.filter((device: any) => device.device_type === type.device_type);
                if (type.device_type === DEVICE_TYPE_POWER_METER) {
                    return ({
                        ...type,
                        devices: [
                            ...newDevices,
                            ...rawDevices.filter(({ model_id }: { model_id: number }) => model_id === 7),
                        ],
                    });
                }
                return ({ ...type, devices: newDevices });
            });
            setDevices(newDeviceTypes);
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn(error);
            }
        }
    };

    useEffect(() => {
        if (!auth.isLoggingIn) {
            deviceModels.fetchDeviceTypes();
        }
    }, [auth.isAuthenticated(), auth.isLoggingIn]);

    useEffect(() => {
        if (deviceModels.deviceTypes.length) {
            fetchDeviceModels();
        }
    }, [deviceModels.deviceTypes]);

    const renderDevices = () => extendedDeviceModels.map((type) => (
        <div
            key={type.device_type}
            // style={{ marginTop: type.device_type === DEVICE_TYPE_POWER_TRAIN ? '2rem' : 0 }}
        >
            <Link
                className={styles.sideMenuButton}
                // eslint-disable-next-line eqeqeq
                style={((deviceType == type.device_type) ? { fontWeight: 500 } : undefined)}
                to={`/releasenotes/${type.device_type}`}
                type="button"
            >
                <Translate>{type.name}</Translate>
            </Link>
        </div>
    ));

    const renderFirmware = () => {
        if (!devices.length) return <Spinner />;

        if (!deviceType) return <Navigate replace to={`/releasenotes/${deviceModels.deviceTypes[0].device_type}`} />;

        const selectedType = devices.find((device: any) => device.device_type == deviceType);

        if (!selectedType && deviceType !== '999') {
            return <Navigate replace to={`/releasenotes/${deviceModels.deviceTypes[0].device_type}`} />;
        }

        return (
            <Fade className={styles.fade} CSSTransitionKey={deviceType}>
                {selectedType ? <FirmwareList deviceType={selectedType} />
                    : <FirmwareList deviceType={{ device_type: 999, name: 'POWERTRAIN_SYSTEM' }} />}
            </Fade>
        );
    };

    const renderSideMenu = () => (
        <div className={styles.sideMenu}>
            <div className={styles.header}>
                <Translate>DEVICES</Translate>
            </div>
            {renderDevices()}
        </div>
    );

    return (
        <div className={styles.container}>
            {renderSideMenu()}
            <div className={styles.content}>
                {renderFirmware()}
            </div>
        </div>
    );
};

export default ReleaseNotes;
