import {
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
} from 'react-share';
import { useState } from 'react';

import styles from './ShareActivityModal.module.scss';

import CheckBox from '../../../components/checkbox';
import Drawer from '../../../components/drawer';
import Modal from '../../../components/modal';
import Translate from '../../../components/translate';
import { SHARE_URL } from '../../../constants';
import { Activity } from '../../../providers';

interface ShareActivityModalProps {
    activity: Activity | null,
    isOwner: boolean,
    onClose: () => void,
    open: boolean,
    updateActivity: (updates: any) => void,
}

const ShareActivityModal = ({
    activity = null,
    isOwner = false,
    onClose = () => {},
    open = false,
    updateActivity = () => {},
}: ShareActivityModalProps) => {
    const [waitingOnActivityUpdate, setWaitingOnActivityUpdate] = useState(false);

    const toggleActivityPublic = async () => {
        if (waitingOnActivityUpdate) return;

        setWaitingOnActivityUpdate(true);
        await updateActivity({ public: !activity?.public });
        setWaitingOnActivityUpdate(false);
    };

    const renderShareMethods = (containerStyle?: any) => {
        if (!activity?.public) {
            return (
                <div className={`${styles.shareMethods} ${containerStyle}`}>
                    <div className={styles.notPublicText}>
                        <Translate>ACTIVITY_NOT_PUBLIC</Translate>
                    </div>
                </div>
            );
        }

        return (
            <div className={`${styles.shareMethods} ${containerStyle}`}>
                <FacebookShareButton
                    data-woopra-track="share-activity-facebook"
                    className={styles.shareButton}
                    url={`${SHARE_URL}activities/${activity.id}`}
                >
                    <FacebookIcon className={styles.shareButtonContent} />
                </FacebookShareButton>
                <TwitterShareButton
                    className={styles.shareButton}
                    data-woopra-track="share-activity-twitter"
                    url={`${SHARE_URL}activities/${activity.id}`}
                >
                    <TwitterIcon className={styles.shareButtonContent} />
                </TwitterShareButton>
            </div>
        );
    };

    const renderCheckBox = () => (
        <CheckBox
            checked={!!activity && (waitingOnActivityUpdate ? !activity?.public : activity?.public)}
            disabled={!isOwner}
            label="PUBLIC"
            onChange={() => toggleActivityPublic()}
            style={{ flexDirection: 'row-reverse' }}
        />
    );

    const renderDesktopContainer = () => (
        <Modal
            className={styles.desktopContainer}
            contentLabel=""
            dialog
            header="ACTIVITY_SHARE"
            hideImage
            isOpen={open}
            onClose={onClose}
        >
            {renderShareMethods(styles.desktopShareMethods)}
            {renderCheckBox()}
        </Modal>
    );

    const renderMobileContainer = () => (
        <Drawer isOpen={open} onClose={onClose}>
            <div className={styles.mobileContainer}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        <Translate>ACTIVITY_SHARE</Translate>
                    </div>
                    <div className={styles.mobilePublicToggleContainer}>
                        {renderCheckBox()}
                    </div>
                </div>
                {renderShareMethods()}
            </div>
        </Drawer>
    );

    return (
        <>
            {renderDesktopContainer()}
            {renderMobileContainer()}
        </>
    );
};

export default ShareActivityModal;
