import React from 'react';
import { Navigate } from 'react-router-dom';

import Spinner from '../../../components/spinner';

import { useAuth } from '../../../providers/auth/AuthContext';

const HandleEmailVerificationRedirect = ({ email = '' }: {email: string | null}) => {
    const { isFetchingAuth0Profile, userProfile } = useAuth();

    if (isFetchingAuth0Profile) return <Spinner />;

    if (!userProfile || userProfile.email === email?.toLowerCase()) {
        return <Navigate replace to="/" />;
    }

    return <Navigate replace to="/logout" />;
};

export default HandleEmailVerificationRedirect;
