import moment from 'moment';

import { DEVICE_TYPES } from '../../constants';

const CHAINRING_UNKNOWN_MIN = 7;
const CHAINRING_UNKNOWN_MAX = 255;
const CASSETTE_COG_UNKNOWN = 255;

function parseFrontDerailleur(gearComponent) {
    if (gearComponent.data.num_chainrings < CHAINRING_UNKNOWN_MIN) {
        return;
    }

    let lastKnownChainring = 0;
    let highestChainring = 0;

    for (let i = 0; i < gearComponent.data.fd_gear.length; i++) {
        if (gearComponent.data.fd_gear[i] >= (CHAINRING_UNKNOWN_MIN - 1)) {
            // eslint-disable-next-line no-param-reassign
            gearComponent.data.fd_gear[i] = lastKnownChainring;
            // eslint-disable-next-line no-param-reassign
            gearComponent.data.fd_histogram[lastKnownChainring] += 1;
        } else {
            lastKnownChainring = gearComponent.data.fd_gear[i];
            if (gearComponent.data.fd_gear[i] > highestChainring) {
                highestChainring = gearComponent.data.fd_gear[i];
            }
        }
    }

    // eslint-disable-next-line no-param-reassign
    gearComponent.data.num_chainrings = highestChainring + 1;
    gearComponent.data.fd_histogram.splice(gearComponent.data.num_chainrings);
}

function parseRearDerailleur(gearComponent) {
    // eslint-disable-next-line no-param-reassign
    gearComponent.data.rd_histogram = [];

    if (gearComponent.data.num_cogs >= CASSETTE_COG_UNKNOWN) {
        let lastKnownCog = 0;
        let highestValidCog = 0;
        for (let i = 0; i < gearComponent.data.rd_gear.length; i++) {
            if (gearComponent.data.rd_gear[i] >= (CASSETTE_COG_UNKNOWN - 1)) {
                // eslint-disable-next-line no-param-reassign
                gearComponent.data.rd_gear[i] = lastKnownCog;
                const chainring = gearComponent.data.fd_gear[i];
                // eslint-disable-next-line no-param-reassign
                gearComponent.data[`rd_histogram_chain_${chainring}`][lastKnownCog] += 1;
            } else {
                lastKnownCog = gearComponent.data.rd_gear[i];
                if (gearComponent.data.rd_gear[i] > highestValidCog) {
                    highestValidCog = gearComponent.data.rd_gear[i];
                }
            }
        }

        // eslint-disable-next-line no-param-reassign
        gearComponent.data.num_cogs = highestValidCog + 1;
    }

    if (gearComponent.data.rd_histogram_chain_1) {
        if (gearComponent.data.rd_histogram_chain_1.length > gearComponent.data.num_cogs) {
            gearComponent.data.rd_histogram_chain_1.splice(gearComponent.data.num_cogs);
        }

        // eslint-disable-next-line no-param-reassign
        gearComponent.data.rd_histogram = gearComponent.data.rd_histogram_chain_1;
    }

    if (gearComponent.data.rd_histogram_chain_0) {
        if (gearComponent.data.rd_histogram_chain_0.length > gearComponent.data.num_cogs) {
            gearComponent.data.rd_histogram_chain_0.splice(gearComponent.data.num_cogs);
        }

        // Add together time tallies for rd gears in both chainrings
        // eslint-disable-next-line no-param-reassign
        gearComponent.data.rd_histogram = gearComponent.data.rd_histogram_chain_0.map(
            (value, index) => ((index < gearComponent.data.rd_histogram.length)
                ? value + gearComponent.data.rd_histogram[index]
                : value
            ),
        );
    }

    for (let i = gearComponent.data.num_chainrings; i <= CHAINRING_UNKNOWN_MAX; i++) {
        if (gearComponent.data[`rd_histogram_chain_${i}`]) {
            if (gearComponent.data[`rd_histogram_chain_${i}`].length > gearComponent.data.num_cogs) {
                gearComponent.data[`rd_histogram_chain_${i}`].splice(gearComponent.data.num_cogs);
            }

            // Add together time tallies for rd gears in both chainrings
            // eslint-disable-next-line no-param-reassign
            gearComponent.data.rd_histogram = gearComponent.data[`rd_histogram_chain_${i}`].map(
                // eslint-disable-next-line no-loop-func
                (value, index) => (index < gearComponent.data.rd_histogram.length
                    ? value + gearComponent.data.rd_histogram[index]
                    : value
                ),
            );
        }
    }
}

function parseGear(componentSummary) {
    if (componentSummary.data.ant_component_id !== 2) {
        return componentSummary;
    }

    parseFrontDerailleur(componentSummary);

    parseRearDerailleur(componentSummary);

    return {
        ...componentSummary,
        data: {
            ...componentSummary.data,
            fd_histogram: componentSummary.data.fd_histogram || [componentSummary.data.fd_gear.length],
        },
    };
}

// Converts Quarqnet gps structure into a form usable by the web app
function parseGps(componentSummary) {
    return {
        ...componentSummary,
        data: {
            ...componentSummary.data,
            // Maps lat, long and time into a format that is directly usable for google maps
            position: componentSummary.data.lat.map((lat, index) => ({
                lat,
                lng: componentSummary.data.long[index],
                ts: componentSummary.data.adjustedTime[index],
            })),
        },
    };
}

// Converts Quarqnet power structure into a form usable by the web app
function parsePower(componentSummary) {
    return {
        ...componentSummary,
        data: {
            ...componentSummary.data,
            power_balance: componentSummary.data.power_balance || [],
        },
    };
}

function parseReverb(componentSummary, timeOffset) {
    return {
        ...componentSummary,
        data: {
            ...componentSummary.data,
            actuation_events: componentSummary.data.actuation_events.map((actuation) => ({
                ...actuation,
                adjustedTs: actuation.ts + timeOffset,
            })),
        },
    };
}

// Converts the component summary into a structure usable by the web app
export default function parseComponentSummary(componentSummary) {
    const timeOffset = Number.isFinite(componentSummary.timezone_offset)
        ? componentSummary.timezone_offset
        : moment().utcOffset() * 60;

    const newComponentSummary = {
        ...componentSummary,
        adjustedEndTs: componentSummary.end_ts + timeOffset,
        adjustedStartTs: componentSummary.start_ts + timeOffset,
        data: {
            ...componentSummary.data,
            adjustedTime: (componentSummary.data.time || []).map(((ts) => ts + timeOffset)),
            max_alt: componentSummary.data.max_alt + 1,
            min_alt: componentSummary.data.min_alt - 1,
        },
    };

    switch (newComponentSummary.device_type) {
        case DEVICE_TYPES.gears: return parseGear(newComponentSummary);
        case DEVICE_TYPES.gps: return parseGps(newComponentSummary);
        case DEVICE_TYPES.power: return parsePower(newComponentSummary);
        case DEVICE_TYPES.reverb: return parseReverb(newComponentSummary, timeOffset);
        case DEVICE_TYPES.heartRate:
        case DEVICE_TYPES.shockWiz:
        case DEVICE_TYPES.tirePressure:
        default:
            return newComponentSummary;
    }
}
