import { useState } from 'react';

import styles from './ProfileImage.module.scss';

import { URL_STATIC_DATA } from '../../constants';
import { useNexus } from '../../providers/nexus/NexusContext';

interface ProfileImageProps{ className?: string, id?:string, onError?: () => void }

const ProfileImage = ({ id, className = '', onError = () => {} }: ProfileImageProps) => {
    const { cachedProfileImage, nickname } = useNexus();
    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <img
            id={id}
            alt={nickname}
            className={`${styles.profileImage} ${className}`}
            onError={onError}
            onLoad={() => setImageLoaded(true)}
            style={!imageLoaded ? { display: 'none' } : {}}
            src={cachedProfileImage && (
                cachedProfileImage.replace('https://static.quarqnet.com/', URL_STATIC_DATA)
            )}
        />
    );
};

export default ProfileImage;
