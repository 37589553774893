import { styled } from '@mui/material/styles';
import {Checkbox as MaterialCheckBox} from '@mui/material'
import React from 'react';

import styles from './Checkbox.module.scss';

const StyledCheckbox = styled(MaterialCheckBox)(({ theme }) => ({
    '&.Mui-checked': {
      color: '#E51937', // Color when the checkbox is checked
    },
}));

interface CheckboxProps {
    showBorder?: boolean;
    value?: string;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = ({ showBorder, value, ...otherProps }: CheckboxProps) => (
    <div className={`${styles.container} ${showBorder ? styles.border : null}`}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <StyledCheckbox {...otherProps} />
        <div className={styles.selection}>
            {value}
        </div>
    </div>
);

export default Checkbox;
