import React from 'react';

import styles from './DeleteBikeModal.module.scss';

import { iconDelete } from '../../../../assets';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import Translate from '../../../../components/translate';
import { AXS_TEXT_GREY } from '../../../../constants';

interface DeleteBikeModalProps {
    open: boolean;
    onDelete: () => void;
    onCancel: () => void;
}

const DeleteBikeModal = ({ open, onDelete, onCancel }: DeleteBikeModalProps) => (
    <Modal
        contentClassName={styles.container}
        contentLabel="Delete Bike"
        dialog
        header="ARE_YOU_SURE"
        hideCloseButton
        // Overiding styles needs inline to avoid using !important
        imageStyle={{ height: '5rem', marginBottom: 0, marginTop: 'auto' }}
        imageSrc={iconDelete}
        isOpen={open}
        onClose={onCancel}
    >
        <Translate>BIKE_DELETE_WARNING</Translate>
        <div className={styles.buttonContainer}>
            <Button color={AXS_TEXT_GREY} inverse onClick={onCancel}>
                <Translate>CANCEL</Translate>
            </Button>
            <Button onClick={onDelete}>
                <Translate>DELETE</Translate>
            </Button>
        </div>
    </Modal>
);

export default DeleteBikeModal;
