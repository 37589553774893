export const AXS_TEXT_DARK = '#333333';
export const AXS_TEXT_DARK_BLUE = '#1413FF';
export const AXS_TEXT_LIGHT_GREY = '#7B7C7F';
export const AXS_TEXT_GREY = '#58595B';
export const AXS_WEB_BG = '#F4F4F9';
export const BLACK = '#000000';
export const BLUE_CHALK = '#4285F4';
export const BLUE_LIGHT = '#2666ff';
export const BLUE_MILD = '#135ACE';
export const BLUE_MODERATE = '#3D97C4';
export const GRAY_VERY_DARK = '#666666';
export const GREEN_CHALK = '#34A853';
export const GREEN_FOLAGE = '#008000';
export const GREEN_LIGHT = '#16A64B';
export const OPAQUE_LIGHTEST = '#0000001A';
export const OPAQUE_LIGHT = '#00000030';
export const OPAQUE = '#00000033';
export const OPAQUE_DARK = '#00000080';
export const ORANGE = '#FF6639';
export const PINK = '#E81E79';
export const PINK_BABY = '#E5719B';
export const PINK_BRIGHT = '#DB3070';
export const PINK_DARK = '#A8123F';
export const PINK_LIGHT = '#DE3070';
export const PINK_RED = '#E1003A';
export const PINK_VIVID = '#EB046B';
export const RED = '#FF0000';
export const RED_LIGHT = '#CF0A2C';
export const SRAM_900 = '#202124';
export const SRAM_800 = '#3C4043';
export const SRAM_700 = '#5f6368';
export const SRAM_600 = '#80868B';
export const SRAM_500 = '#9AA0A6';
export const SRAM_400 = '#BBBFC4';
export const SRAM_300 = '#DADCE0';
export const SRAM_200 = '#E8EAED';
export const SRAM_100 = '#F1F3F4';
export const SRAM_50 = '#F8F9FA';
export const SRAM_RED = '#E51937';
export const TRANSPARENT = '#00000000';
export const WHITE = '#FFFFFF';
export const YELLOW = '#FFCC3F';
export const YELLOW_LIGHT = '#FEC843';
