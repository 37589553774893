import styles from './modals.module.scss';
import Modal from '../../../components/modal';
import Translate from '../../../components/translate';
import {
    imageTTypeChainLength,
    imageXPLRChainlength114,
    imageXPLRChainlength126,
} from '../../../assets';

const ChainLengthInfo = ({
    open, onClose, isXPLR,
}:
    {open: boolean, onClose: () => void, isXPLR: boolean }) => {
    const header = isXPLR ? 'XPLR_CHAIN_LENGTH' : 'CHAIN_LENGTH';
    const setupText = isXPLR ? 'XPLR_CHAIN_LENGTH_INFO' : 'T_TYPE_CHAIN_LENGTH_INFO';
    const setUpImage = isXPLR ? imageXPLRChainlength114 : imageTTypeChainLength;

    return (
        <Modal
            className={styles.modalContainerInfo}
            contentClassName={styles.infoContent}
            headerStyle={{ color: 'white', fontSize: '1.5rem' }}
            contentLabel="InfoModal"
            isOpen={open}
            header={header}
            hideImage
            onClose={() => onClose()}
            containerStyle={{ padding: 'none' }}
            onOverlayClick={() => onClose()}
            darkMode
        >

            <div className={styles.imageContainer}>
                {isXPLR && (
                    <img
                        alt="Chain Length"
                        data-testid="imageChainlength"
                        className={styles.image}
                        src={imageXPLRChainlength126}
                        style={{ marginBottom: '2rem', width: '100%' }}
                    />
                )}
                <img
                    alt="Chain Length"
                    data-testid="imageChainlength"
                    className={styles.image}
                    src={setUpImage}
                    style={{ width: '100%' }}
                />
            </div>
            <div className={styles.infoText}>
                <Translate>{setupText}</Translate>
            </div>
            {!isXPLR && (
                <div className={styles.infoText}>
                    <Translate>XPLR_VIDEO_INFO</Translate>
                </div>
            )}
        </Modal>
    );
};

export default ChainLengthInfo;
