import { ReactNode } from 'react';

import styles from './WaffleBlock.module.scss';

import Spinner from '../spinner';

import { iconBetaFirmware } from '../../assets';
import { useAuth } from '../../providers/auth/AuthContext';

interface WaffleBlockProps {
    BlockedComponent?: ReactNode,
    children: any,
    flag: string,
    not?: boolean,
    showIndicator?: boolean,
}

const WaffleBlock = ({
    BlockedComponent = null,
    children = null,
    flag,
    not = false,
    showIndicator,
}: WaffleBlockProps) => {
    const { isAuthenticated, isFetchingWaffleFlags, isFlagActive } = useAuth();

    if (!isAuthenticated()) return null;

    if (isFetchingWaffleFlags) return <Spinner />;

    let renderChildren = isFlagActive(flag);
    renderChildren = not ? !renderChildren : renderChildren;

    if (renderChildren && showIndicator) {
        return (
            <>
                <div className={styles.iconContainer}>
                    <img alt="" className={styles.icon} src={iconBetaFirmware} />
                </div>
                {children}
            </>
        );
    }

    if (renderChildren) return children;

    return BlockedComponent;
};

export default WaffleBlock;
