import { node } from 'prop-types';
import React, { ErrorInfo, ReactNode } from 'react';

import ErrorPage from '../errorPage';

import Logger from '../../Logger';

interface Props {
    children?: ReactNode;
  }

  interface State {
    hasError: boolean;
  }

class ErrorBoundary extends React.Component <Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        Logger.error(error, errorInfo);
    }

    render() {
        const { children } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            return (<ErrorPage />);
        }

        return children;
    }
}

export default ErrorBoundary;
