import { createContext } from 'react';

import { DeviceModelsProviderType } from './types';

const DefaultDeviceModelContext = {
    deviceTypes: [],
    fetch: () => {},
    fetchDeviceTypes: () => {},
    fetchFirmware: () => {},
    fetchReleaseNotes: () => {},
    getFirmware: () => {},
    list: [],
};

const DeviceModelsContext = createContext<DeviceModelsProviderType>(DefaultDeviceModelContext);

export default DeviceModelsContext;
