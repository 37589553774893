import React from 'react';

import styles from './NavBarDropdown.module.scss';

import Dropdown from '../dropdown';

import Translate from '../../../components/translate';

interface NavBarDropdownProps {
    buttonClassName?: string,
    buttonStyle?: Record<string, unknown>,
    children?: React.ReactNode,
    containerClassName?: string,
    containerStyle?: Record<string, unknown>,
    dropdownStyle?: Record<string, unknown>,
    id?: string,
    label?: string | React.ReactElement,
    onAuxClick?: React.MouseEventHandler<HTMLButtonElement>,
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
    open?: boolean,
}

const NavBarDropdown = ({
    buttonClassName,
    buttonStyle = {},
    children,
    containerClassName,
    containerStyle,
    dropdownStyle,
    id,
    label,
    onAuxClick,
    onClick,
    open,
}: NavBarDropdownProps) => (
    <Dropdown
        buttonClassName={buttonClassName}
        buttonContent={(
            <>
                <div style={{ textTransform: 'uppercase' }}>
                    <Translate>{label}</Translate>
                </div>
                {open && <div className={styles.backDrop} />}
            </>
        )}
        buttonStyle={buttonStyle}
        containerClassName={containerClassName}
        containerStyle={containerStyle}
        dropdownClassName={styles.dropdown}
        dropdownStyle={dropdownStyle}
        id={id}
        onAuxClick={onAuxClick}
        onClick={onClick}
        open={open}
    >
        {children}
    </Dropdown>
);

export default NavBarDropdown;
