import memoize from 'memoize-one';
import { useState } from 'react';

import TyreWizLineChart from './tyreLineChart';
import styles from './TyreWizLineCharts.module.scss';

import ToggleSelector from '../../../../components/toggleSelector';
import { BLUE_MODERATE, PINK_VIVID } from '../../../../constants';
import ExpandChart from '../../../../views/expandChart';
import { ComponentSummary } from '../../../../providers';

const parseTyreComponents = memoize((tyreComponents) => {
    const front = tyreComponents.find(({ data: { position } }: {data: any, position: number }) => position === 1);
    const other = tyreComponents.find(({ data: { position } }: {data: any, position: number}) => (
        (position !== 1 && position !== 2)
    ));
    const rear = tyreComponents.find(({ data: { position } }: {data: any, position: number}) => position === 2);

    return { front, other, rear };
});

interface TyreWizLineChartProps {
    disableEdit?: boolean,
    gpsComponent: ComponentSummary,
    tyreComponents: ComponentSummary[] | null
}

function TyreWizLineCharts({ disableEdit = true, gpsComponent, tyreComponents }: TyreWizLineChartProps) {
    const [showAltitude, setShowAltitude] = useState(false);
    const [sync, setSync] = useState<any | null>(null);

    function renderToggler() {
        return (
            <div className={styles.toggleContainer}>
                <ToggleSelector
                    activeButton={showAltitude}
                    buttonClassName={styles.button}
                    buttons={[
                        { label: 'TEMPERATURE', value: false },
                        { label: 'ELEVATION', value: true },
                    ]}
                    color="#5F6368"
                    onToggle={() => setShowAltitude(!showAltitude)}
                />
            </div>
        );
    }

    function renderFront(front: any) {
        if (!front) {
            return null;
        }

        return (
            <ExpandChart renderModalFooter={renderToggler()} title="TYREWIZ">
                <TyreWizLineChart
                    colour={BLUE_MODERATE}
                    disableEdit={disableEdit}
                    gpsComponent={gpsComponent}
                    setSync={(inSync) => setSync(inSync)}
                    showAltitude={showAltitude}
                    sync={sync}
                    title="TIRE_FRONT"
                    tyreWiz={front}
                />
            </ExpandChart>
        );
    }

    function renderOther(other: any) {
        if (!other) {
            return null;
        }

        return (
            <ExpandChart renderModalFooter={renderToggler()} title="TYREWIZ">
                <TyreWizLineChart
                    disableEdit={disableEdit}
                    gpsComponent={gpsComponent}
                    setSync={(inSync) => setSync(inSync)}
                    showAltitude={showAltitude}
                    sync={sync}
                    title="TIRE_OTHER"
                    tyreWiz={other}
                />
            </ExpandChart>
        );
    }

    function renderRear(rear: any) {
        if (!rear) {
            return null;
        }

        return (
            <ExpandChart renderModalFooter={renderToggler()} title="TYREWIZ">
                <TyreWizLineChart
                    colour={PINK_VIVID}
                    disableEdit={disableEdit}
                    gpsComponent={gpsComponent}
                    setSync={(inSync) => setSync(inSync)}
                    showAltitude={showAltitude}
                    sync={sync}
                    title="TIRE_REAR"
                    tyreWiz={rear}
                />
            </ExpandChart>
        );
    }

    const parsedComponents = parseTyreComponents(tyreComponents);

    return (
        <div className={styles.container}>
            {renderFront(parsedComponents.front)}
            {renderRear(parsedComponents.rear)}
            {renderOther(parsedComponents.other)}
            {renderToggler()}
        </div>
    );
}

export default TyreWizLineCharts;
