import React from 'react';

import styles from './BikeImage.module.scss';

import BikeAlertBadge from '../bikeAlertBadge';
import ImageContainer from '../../components/imageContainer';

import { defaultBike } from '../../assets';
import { URL_STATIC_DATA } from '../../constants';

const BikeImage = ({ bike }: Record<string, any>) => (
    <div className={styles.container}>
        <ImageContainer
            alt=""
            src={(bike.image && bike.image.replace('https://static.quarqnet.com/', URL_STATIC_DATA))
                || defaultBike}
        />
        <BikeAlertBadge className={styles.alertBadge} show />
    </div>
);

export default BikeImage;
