import { ReactNode } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import styles from './Fade.module.scss';

interface FadeProps{ children: ReactNode, className?: string, CSSTransitionKey: number | string }

const Fade = ({ children, className = '', CSSTransitionKey = '' }: FadeProps) => (
    <TransitionGroup className={className}>
        <CSSTransition
            classNames={{
                enter: styles.fadeEnter,
                enterActive: styles.fadeEnterActive,
                exit: styles.fadeExit,
            }}
            key={CSSTransitionKey}
            timeout={{ enter: 300, exit: 0 }}
        >
            {children}
        </CSSTransition>
    </TransitionGroup>
);

export default Fade;
