import axios from 'axios';
import { useEffect, useState } from 'react';

import styles from './TyreWiz.module.scss';

import BikeComponentLink from '../../../bikeComponentLink';
import Row from '../../../row';
import Statistic from '../../../statistic';

import Logger from '../../../../../Logger';
import { iconBell, iconThumb, iconWarning } from '../../../../../assets';
import Translate from '../../../../../components/translate';
import {
    DEVICE_TYPES,
    makeCancellable,
    SRAM_RED,
    toLocaleString,
    URL_API,
} from '../../../../../constants';
import { useUnits } from '../../../../../providers/units/UnitsContext';
import { Bike } from '../../../../../providers';

interface TyreWizProps {
    bike?: Bike,
    className: string,
    disableEdit: boolean,
    tyreWiz: any,
}

const TyreWiz = ({
    bike,
    className,
    disableEdit,
    tyreWiz,
}: TyreWizProps) => {
    const units = useUnits();
    const [state, setState] = useState<any>({ isFetching: false, tyrewizConfig: [] });
    const { tyrewizConfig } = state;

    if (!tyreWiz) {
        return null;
    }

    let label = 'TIRE_UNKNOWN';
    switch (tyreWiz.data.position) {
        case 1:
            label = 'TIRE_FRONT';
            break;
        case 2:
            label = 'TIRE_REAR';
            break;
        default:
            break;
    }

    async function fetchTyreWizPressure() {
        setState({ isFetching: true, tyrewizConfig: [] });

        const fetchRequest = makeCancellable(axios.get(`${URL_API}bleservices/tyrewiz_config/`));

        try {
            const { data } = await fetchRequest.promise;

            if (!data) {
                setState({ ...state, isFetching: false });

                return;
            }

            setState({ isFetching: false, tyrewizConfig: data });
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching TyreWizPressure');
                setState({ ...state, isFetching: false });
            }
        }
    }

    useEffect(() => {
        fetchTyreWizPressure();
    }, []);

    let alarmTriggered = 0;
    let tyreComponent = null;
    let hideLowHighPressureValue = false;

    function getLowHighPressureValue() {
        if (tyreWiz.serial) {
            const tyreCom = tyrewizConfig.length
                ? (tyrewizConfig.find(({ serial }:{ serial: string }) => serial === tyreWiz.serial))
                : null;

            if (tyreCom) {
                return tyreCom;
            }
        }

        if (bike && bike.component_set.length) {
            const tyreWizComponent = bike.component_set.find((component: any) => (
                component.device_type === DEVICE_TYPES.tirePressure
                && component.data.position === tyreWiz.data.position));

            if (tyreWizComponent) {
                return tyrewizConfig.find(({ serial }:{ serial: string }) => serial === tyreWizComponent.serial);
            }
        }

        // Check if user has only two tyreWiz components
        if (tyrewizConfig.length < 3) {
            return tyrewizConfig.find(({ wheel }:{ wheel: number }) => wheel === tyreWiz.data.position);
        }

        hideLowHighPressureValue = true;

        return null;
    }

    if (tyrewizConfig.length) {
        tyreComponent = getLowHighPressureValue();

        if (tyreComponent) {
            if (tyreWiz.data.last_pressure < tyreComponent.low_pressure) {
                alarmTriggered = 1;
            } else if (tyreWiz.data.last_pressure > tyreComponent.high_pressure) {
                alarmTriggered = 2;
            }
        }
    }

    return (
        <div className={`${styles.container} ${className}`}>
            <div className={styles.title}>
                <Translate>{label}</Translate>
            </div>
            {alarmTriggered ? (
                <div className={styles.alertContainer}>
                    <img alt="" className={styles.alertIcon} src={iconWarning} />
                    <div className={styles.alertLabelContainer}>
                        {(alarmTriggered === 1) && (
                            <div className={styles.subTitle}>
                                <Translate>TIRE_PRESSURE_LOW_ALERT</Translate>
                            </div>
                        )}
                        {(alarmTriggered === 2) && (
                            <div className={styles.subTitle}>
                                <Translate>TIRE_PRESSURE_HIGH_ALERT</Translate>
                            </div>
                        )}
                    </div>
                </div>
            ) : null}
            <div
                className={styles.lastPressureContainer}
                style={alarmTriggered ? { backgroundColor: SRAM_RED } : undefined}
            >
                <div className={styles.lastPressure}>
                    {toLocaleString(
                        units.formatPressure(
                            units.convertPressureFromSI(tyreWiz.data.last_pressure),
                        ),
                    )}
                    &nbsp;
                    <Translate>{units.getLabelPressure().shorthand}</Translate>
                </div>

                <img alt="" className={styles.statusIcon} src={alarmTriggered ? iconBell : iconThumb} />

            </div>
            {(!hideLowHighPressureValue) && (
                <Row borderBottomNone>
                    <Statistic label="TIRE_PRESSURE_LOW">
                        {(alarmTriggered === 1) && (
                            <>
                                {toLocaleString(
                                    units.formatPressure(
                                        units.convertPressureFromSI(tyreComponent.low_pressure),
                                    ),
                                )}
                            &nbsp;
                                <Translate>{units.getLabelPressure().shorthand}</Translate>
                            </>
                        )}
                    </Statistic>
                    <Statistic label="TIRE_PRESSURE_HIGH">
                        {(alarmTriggered === 2) && (
                            <>
                                {toLocaleString(
                                    units.formatPressure(
                                        units.convertPressureFromSI(tyreComponent.high_pressure),
                                    ),
                                )}
                            &nbsp;
                                <Translate>{units.getLabelPressure().shorthand}</Translate>
                            </>
                        )}
                    </Statistic>
                </Row>
            )}
            {!disableEdit && <BikeComponentLink componentSummary={tyreWiz} />}
        </div>
    );
};

export default TyreWiz;
