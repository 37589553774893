import React from 'react';

import styles from './ErrorPage.module.scss';

import Footer from '../../views/footer';
import Navbar from '../../views/navbar';
import Translate from '../../components/translate';

const ErrorPage = () => (
    <div className={styles.container}>
        <Navbar hideControls />
        <div className={styles.content}>
            <div className={styles.errorContainer}>
                <div className={styles.title}>
                    <Translate>ERROR_EMBARRASSING</Translate>
                </div>
                <div className={styles.subtitle}>
                    <Translate>ERROR_NOT_WORKING</Translate>
                </div>
                <div className={styles.help}>
                    <Translate>ERROR_TIPS_TITLE</Translate>
                </div>
                <div>
                    <ul className={styles.suggestions}>
                        <li>
                            <Translate>ERROR_TIPS_ONE</Translate>
                        </li>
                        <li>
                            <Translate>ERROR_TIPS_TWO</Translate>
                        </li>
                        <li>
                            <Translate>ERROR_TIPS_THREE</Translate>
                            &nbsp;
                            <a
                                href="https://sram.uservoice.com/forums/923569-sram-axs-web"
                                rel="noreferrer noopener"
                                target="_blank"
                            >
                                <Translate>ERROR_FEEDBACK</Translate>
                            </a>
                            .
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <Footer hideSupportLink />
    </div>
);

export default ErrorPage;
