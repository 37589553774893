import { useEffect, useRef, useState } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';

import styles from './TrimActivityModal.module.scss';

import Button from '../../../components/button';
import Modal from '../../../components/modal';
import Translate from '../../../components/translate';
import { AXS_TEXT_GREY, RequestType } from '../../../constants';
import ErrorModal from '../../../views/errorModal';
import { Activity } from '../../../providers';

function isSegmentActive(location: Location) {
    const params = new URLSearchParams(location.search);
    const locationEndTs = params.get('end_ts');
    const locationStartTs = params.get('start_ts');

    return !!(locationStartTs || locationEndTs);
}

interface TrimActivityModalProps {
    activity: Activity,
    endTs: number,
    onClose: () => void,
    open: boolean,
    startTs: number,
    updateActivitySummary: (data:Record<string, unknown>) => RequestType | any,
}

const TrimActivityModal = ({
    activity = {} as Activity,
    endTs = 0,
    onClose = () => {},
    open = false,
    startTs = 0,
    updateActivitySummary,
}: TrimActivityModalProps) => {
    const [error, setError] = useState<any>();
    const location: Location = useLocation();
    const updateActivitySummaryRequests = useRef(new Set<RequestType>());
    const navigate = useNavigate();

    const saveActivityTrim = async () => {
        let trimmedEndTs = 0;
        let trimmedStartTs = 0;

        if (isSegmentActive(location)) {
            trimmedEndTs = endTs - activity.timezone_offset;
            trimmedStartTs = startTs - activity.timezone_offset;
        }

        const fetchRequest: RequestType = updateActivitySummary({
            data: { trim_end_ts: trimmedEndTs, trim_start_ts: trimmedStartTs },
        });

        updateActivitySummaryRequests.current.add(fetchRequest);

        try {
            await fetchRequest;
            updateActivitySummaryRequests.current.delete(fetchRequest);

            navigate(`/activities/${activity.id}`);
        } catch (_error) {
            updateActivitySummaryRequests.current.delete(fetchRequest);
            setError(new Error('ACTIVITY_UPDATE_ERROR'));
        }
    };

    useEffect(() => () => {
        updateActivitySummaryRequests.current.forEach((request: RequestType) => request.cancel());
        updateActivitySummaryRequests.current.clear();
    }, []);

    if (error) {
        return (
            <ErrorModal
                error={error}
                onClose={() => setError(null)}
                onOverlayClick={() => {
                    setError(null);
                    onClose();
                }}
            />
        );
    }

    return (
        <Modal
            contentLabel="TRIM_ACTIVITY"
            dialog
            header="TRIM_ACTIVITY"
            hideCloseButton
            hideImage
            isOpen={open}
            onClose={onClose}
        >
            <Translate>{isSegmentActive(location) ? 'TRIM_WARNING' : 'TRIM_RESTORE_WARNING'}</Translate>
            <div className={styles.buttonsContainer}>
                <Button color={AXS_TEXT_GREY} inverse onClick={onClose}>
                    <Translate>CANCEL</Translate>
                </Button>
                <Button
                    onClick={() => {
                        onClose();
                        saveActivityTrim();
                    }}
                    type="button"
                >
                    <Translate>{isSegmentActive(location) ? 'SAVE' : 'RESTORE'}</Translate>
                </Button>
            </div>
        </Modal>
    );
};

export default TrimActivityModal;
