import { ReactNode, useState } from 'react';

import Button from '../button/Button';
import styles from './Placeholder.module.scss';

import Modal from '../modal';
import Translate from '../translate';

import { AXS_TEXT_DARK, SRAM_200 } from '../../constants';

interface PlaceholderProps {
    children: ReactNode,
    containerStyle?: Record<string, unknown>,
    header: string,
    imageAlt?: string,
    imageClassName?: string,
    imageSrc: string,
    imageStyle?: Record<string, unknown>,
    renderExample: () => ReactNode,
    subtitle: string,
    subtitleStyle?: Record<string, unknown>,
}

const Placeholder = ({
    children = null,
    containerStyle = {},
    header = '',
    imageAlt = '',
    imageClassName = '',
    imageSrc = '',
    imageStyle = {},
    renderExample = () => null,
    subtitle = '',
    subtitleStyle = {},
}: PlaceholderProps) => {
    const [showExampleComponent, setShowExampleComponent] = useState(false);

    return (
        <div className={styles.container} style={containerStyle}>
            <Modal
                contentLabel="Example"
                isOpen={showExampleComponent}
                header={(
                    <div className={styles.header}>
                        <Translate>{header}</Translate>
                    </div>
                )}
                hideImage
                hideCloseButton
                contentClassName={styles.modalContent}
                className={styles.modalContainer}
                onOverlayClick={() => setShowExampleComponent(!showExampleComponent)}
            >
                <div className={styles.contentModal}>
                    {renderExample()}
                    <div style={{ paddingBottom: '2rem', paddingTop: '1rem' }}>
                        <Button
                            style={{
                                border: `1px solid ${AXS_TEXT_DARK}`,
                                color: AXS_TEXT_DARK,
                            }}
                            color={SRAM_200}
                            inverse
                            onClick={() => setShowExampleComponent(!showExampleComponent)}
                        >
                            <Translate>CLOSE</Translate>
                        </Button>
                    </div>
                </div>
            </Modal>
            <img
                alt={imageAlt}
                className={`${styles.image} ${imageClassName}`}
                src={imageSrc}
                style={imageStyle}
            />
            <div className={styles.subtitle} style={subtitleStyle}>
                <Translate>{subtitle}</Translate>
            </div>
            <div className={styles.content}>
                <div className={styles.description}>{children}</div>
                <div className={styles.button}>
                    <Button
                        style={{
                            border: `1px solid ${AXS_TEXT_DARK}`,
                            color: AXS_TEXT_DARK,
                        }}
                        color={SRAM_200}
                        inverse
                        onClick={() => setShowExampleComponent(!showExampleComponent)}
                    >
                        <Translate>SHOW_EXAMPLE</Translate>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Placeholder;
