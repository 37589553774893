import React from 'react';
import { Link } from 'react-router-dom';

import styles from './MenuItemButton.module.scss';

interface MenuItemButtonProps {
    className?: string,
    id?: string,
    to: string,
    style?: React.CSSProperties,
    onClick: () => void,
    children: React.ReactNode,
}

const MenuItemButton = ({
    to, onClick, className, style, id, children,
}: MenuItemButtonProps) => (
    <Link
        // eslint-disable-next-line react/jsx-props-no-spreading
        to={to}
        onClick={onClick}
        style={style}
        id={id}
        className={`${styles.container} ${className}`}
    >
        {children}
    </Link>
);

export default MenuItemButton;
