import React, { CSSProperties } from 'react';

import styles from './DefaultButton.module.scss';

import Translate from '../../../../../../components/translate';

interface DefaultButtonProps {
    disabled?: boolean;
    onClick?: () => void;
    style?: CSSProperties;
}

const DefaultButton = ({ disabled = false, onClick, style }: DefaultButtonProps) => (
    <button
        className={styles.edit}
        disabled={disabled}
        onClick={onClick}
        style={style}
        type="button"
    >
        <Translate>DRIVETRAIN_EDIT</Translate>
    </button>
);

export default DefaultButton;
