import { compareFirmwareVersion } from '../../constants';

function formatFirmwareByModel(firmwares, devices) {
    const uniqueFirmwares = Array.from(new Set(
        firmwares.map((firmware) => firmware.version || firmware.firmware_version),
    ));

    let beta = 0;

    return uniqueFirmwares.map((version) => {
        const currentModels = firmwares.filter(
            (firmware) => firmware.version === version || firmware.firmware_version === version,
        );
        const uniqueReleaseNotes = Array.from(new Set(currentModels.map(({ release_notes }) => release_notes)));
        const models = uniqueReleaseNotes.map((releaseNotes) => {
            const currentFirmwares = currentModels.filter(({ release_notes }) => release_notes === releaseNotes);

            return {
                modelList: currentFirmwares.map((firmware) => {
                    beta = firmware.beta;

                    return devices.find(({ model_id }) => model_id === firmware.model);
                }),
                releaseNotes,
            };
        });

        return { beta, models, version };
    });
}

function formatText(text) {
    if (!text) {
        return null;
    }

    return text
        .replaceAll('    ', '\n')
        .replaceAll('  ', '\n')
        .replaceAll('*', '\n*')
        .replaceAll('\n\n*', '\n*')
        .replaceAll('\n \n*', '\n*')
        .replaceAll('###', '\n###');
}

// Sorts so greatest version is first
function sortFirmwareVersions(versions) {
    // Imports are flipped to reverse order of sort, this archives greatest first sort
    versions.sort((firstVersion, secondVersion) => compareFirmwareVersion(secondVersion.version, firstVersion.version));
}

export { formatFirmwareByModel, formatText, sortFirmwareVersions };
