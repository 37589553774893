import { ReactNode, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from './CollapsablePanel.module.scss';

import Translate from '../translate';

interface CollapsablePanelProps {
    buttonClassName?: string,
    buttonStyles?: Record<string, unknown>,
    children: any,
    containerClassName?: string,
    containerStyles?: Record<string, unknown>,
    label: string | ReactNode,
    onToggle: (value?:any) => void,
    openOnMount: boolean,
}

const CollapsablePanel = ({
    buttonClassName = '',
    buttonStyles = {},
    containerClassName = '',
    containerStyles = {},
    children = null,
    label = null,
    onToggle = () => {},
    openOnMount = false,
}: CollapsablePanelProps) => {
    const [maxHeight, setMaxHeight] = useState<any>(0);
    const [open, setOpen] = useState(openOnMount);
    const contentRef = useRef<any>(null);

    return (
        <div className={containerClassName} style={containerStyles}>
            <button
                data-testid="collapsable-panel-btn"
                className={`${styles.toggleButton} ${buttonClassName}`}
                style={buttonStyles}
                onClick={() => {
                    setOpen(!open);
                    onToggle();
                }}
                type="button"
            >
                <Translate>{label}</Translate>
            </button>

            <CSSTransition
                appear
                in={open}
                onEnter={() => setMaxHeight(0)}
                onEntering={() => setMaxHeight(contentRef.current?.scrollHeight)}
                onEntered={() => setMaxHeight(null)}
                onExit={() => setMaxHeight(contentRef.current?.scrollHeight)}
                onExiting={() => setMaxHeight(0)}
                timeout={{ enter: 200 }}
            >
                <div
                    className={styles.collapsableContainer}
                    ref={contentRef}
                    style={{ maxHeight }}
                    data-testid="inner-collapsable-panel-btn"
                >
                    {children}
                </div>
            </CSSTransition>
        </div>
    );
};

export default CollapsablePanel;
