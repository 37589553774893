import React from 'react';
import styles from './modals.module.scss';
import Modal from '../../../components/modal';
import Translate from '../../../components/translate';
import {
    imageChainring,
} from '../../../assets';

const ChainRingInfo = ({
    open, onClose,
}:
    {open: boolean, onClose: () => void, }) => (
    <Modal
        className={styles.modalContainerInfo}
        contentClassName={styles.infoContent}
        headerStyle={{ color: 'white', fontSize: '1.5rem' }}
        contentLabel="InfoModal"
        isOpen={open}
        header="CHAIN_RING_SUPPORT"
        hideImage
        onClose={() => onClose()}
        containerStyle={{ padding: 'none' }}
        onOverlayClick={() => onClose()}
        darkMode
    >

        <div className={styles.imageContainer}>
            <img
                alt="Chainring"
                className={styles.image}
                src={imageChainring}
                style={{ width: '100%' }}
            />
        </div>
        <div className={styles.infoText}>
            <Translate>SUPPORTS_CHAINRINGS_INFO</Translate>
        </div>
    </Modal>
);

export default ChainRingInfo;
