import { ReactNode } from 'react';

import Spinner from './spinner';

import { useAuth } from '../providers/auth/AuthContext';

const UserProfileBlock = ({ children = null }: { children: ReactNode }) => {
    // Users transitioning over without logging out and logging back in
    // will have a small delay while fetching the auth0 user profile, so render the Spinner
    const { userProfile } = useAuth();

    if (!userProfile) {
        return <Spinner />;
    }

    return children;
};

export default UserProfileBlock;
