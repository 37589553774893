import InfiniteScroll from 'react-infinite-scroll-component';

import AddBikeComponent from './addBikeComponent';
import styles from './BikeComponents.module.scss';

import BikeComponentItem from '../bike/bikeComponentItem';
import ContentContainer from '../../components/contentContainer';
import Spinner from '../../components/spinner';
import Translate from '../../components/translate';
import WaffleBlock from '../../components/waffleBlock';
import { useBikeComponents } from '../../providers/bikeComponents/BikeComponentsContext';

const BikeComponents = () => {
    const {
        fetchBikeComponents,
        hasMore,
        isFetching,
        list,
    } = useBikeComponents();
    const renderComponentsList = () => {
        if (!list.length) {
            return (
                <div className={styles.noComponentFoundText}>
                    <Translate>NO_BIKE_COMPONENT_NOTES</Translate>
                </div>
            );
        }

        return list.map((bikeComponent) => (
            <BikeComponentItem bikeComponent={bikeComponent} key={bikeComponent.id} />
        ));
    };

    return (
        <div className={styles.container}>
            <Spinner loading={isFetching} />
            <div className={styles.header}>
                <Translate>MY_COMPONENTS</Translate>
            </div>
            <ContentContainer className={styles.contentContainer}>
                <WaffleBlock flag="comp_reg">
                    <AddBikeComponent />
                </WaffleBlock>
                <div className={styles.content}>
                    <InfiniteScroll
                        dataLength={list.length}
                        hasMore={hasMore}
                        next={fetchBikeComponents}
                        loader
                    >
                        {renderComponentsList()}
                    </InfiniteScroll>
                </div>
            </ContentContainer>
        </div>
    );
};

export default BikeComponents;
