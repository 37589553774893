import { useNavigate } from 'react-router-dom';

import styles from './AXSAppTutorial.module.scss';

import {
    axsAppOne,
    axsAppTwo,
    axsBanner,
    logoAppleStore,
    logoPlaystore,
    messageExample,
    pairGearingToBikeComputerAndAXS,
} from '../../../assets';
import Button from '../../../components/button';
import PairingFinePrint from '../../../views/pairingFinePrint';
import Translate from '../../../components/translate';
import { BLUE_MILD, SRAM_700 } from '../../../constants';

// eslint-disable-next-line max-len
const PAIR_LINK = 'https://www.sram.com/en/sram/road/campaigns/pairing-garmin-and-wahoo-with-gearing';

interface AXSAppTutorialProps {
    showAxsAppContent: boolean;
    showPairingContent: boolean;
}

const AXSAppTutorial = ({ showAxsAppContent, showPairingContent }: AXSAppTutorialProps) => {
    if (!showAxsAppContent && !showPairingContent) return null;

    const navigate = useNavigate();

    const renderAppScreens = () => (
        <div className={styles.headerContainer}>
            <div className={styles.subHeader}>
                <Translate>APP_INFO_TITLE</Translate>
            </div>
            <div className={styles.text}>
                <Translate>TUTORIAL_APP_INFO_ONE</Translate>
                    &nbsp;
                <Translate>TUTORIAL_APP_INFO_BIKE</Translate>
            </div>
            <div className={styles.appScreens}>
                <div className={styles.appSreenImageContainer}>
                    <img alt="" className={styles.appScreenImage} src={axsAppOne} />
                </div>
                <div className={styles.appSreenImageContainer}>
                    <img alt="" className={styles.appScreenImage} src={axsAppTwo} />
                </div>
            </div>

            <div className={styles.appStoresContainer}>
                <a
                    className={styles.appStoreLink}
                    href="https://itunes.apple.com/us/app/sram-axs/id1430049231?ls=1&mt=8"
                    rel="noreferrer noopener"
                    target="_blank"
                >
                    <img alt="Download on the App Store" className={styles.appStoreImage} src={logoAppleStore} />
                </a>
                <a
                    className={styles.appStoreLink}
                    href="https://play.google.com/store/apps/details?id=com.sram.armyknife"
                    rel="noreferrer noopener"
                    target="_blank"
                >
                    <img alt="Get it on Google Play" className={styles.appStoreImage} src={logoPlaystore} />
                </a>
            </div>
        </div>
    );

    const renderLinkedApps = () => (
        <div className={styles.headerContainer} style={{ paddingTop: '2rem' }}>
            <div className={styles.header}>
                <Translate>TUTORIAL_LINKED_TITLE</Translate>
            </div>
            <div style={{ paddingBottom: '1rem', paddingTop: '2rem', width: '100%' }}>
                <div className={styles.text} style={{ paddingBottom: '2rem' }}>
                    <Translate>TUTORIAL_LINKED_INFO</Translate>
                </div>
                <Button
                    color={SRAM_700}
                    style={{ display: 'block', marginLeft: 'auto' }}
                    onClick={() => navigate('/riderportal')}
                >
                    <Translate>TUTORIAL_LINK_BUTTON</Translate>
                </Button>
            </div>
        </div>
    );

    const renderNotificationTutorial = () => (
        <div className={styles.headerContainer}>
            <div className={styles.subHeader}>
                    AXS.SRAM.COM
            </div>
            <div className={styles.text}>
                <Translate>TUTORIAL_NOTIFICATIONS</Translate>
            </div>
            <div className={styles.appScreens}>
                <img alt="" className={styles.notificationImage} src={messageExample} />
            </div>
        </div>
    );

    const renderPairingTutorial = () => (
        <div className={styles.headerContainer} style={{ paddingTop: '2rem' }}>
            <div className={styles.header}>
                <Translate>TUTORIAL_PAIR_SHARE_TITLE</Translate>
            </div>
            <div className={styles.subHeader}>
                <Translate>BLE_ANT</Translate>
            </div>
            <div className={styles.text}>
                <p>
                    <Translate>TUTORIAL_PAIR_INFO_ONE</Translate>
                </p>
                <p>
                    <Translate
                        params={{
                            pairLink: (
                                <a
                                    href={PAIR_LINK}
                                    rel="noreferrer noopener"
                                    style={{ color: BLUE_MILD }}
                                    target="_blank"
                                >
                                    <Translate>TUTORIAL_PAIR_INFO_TWO_PAIR_LINK</Translate>
                                </a>
                            ),
                        }}
                    >
                            TUTORIAL_PAIR_INFO_TWO
                    </Translate>
                </p>
            </div>
            <div className={styles.appScreens}>
                <img alt="" className={styles.appScreenImage} src={pairGearingToBikeComputerAndAXS} />
            </div>
        </div>
    );

    const renderAxsAppContent = () => {
        if (!showAxsAppContent) return null;

        return (
            <div id="data-test-asx-app-content">
                {renderAppScreens()}
                {renderNotificationTutorial()}
            </div>
        );
    };

    const renderPairingContent = () => {
        if (!showPairingContent) return null;

        return (
            <div id="data-test-pairing-content">
                {renderPairingTutorial()}
                {renderLinkedApps()}
                <PairingFinePrint className={styles.finePrint} />
            </div>
        );
    };

    return (
        <div className={styles.container} id="data-test-asx-app-tutorial">
            <img alt="" className={styles.axsAppLogo} src={axsBanner} />
            <div className={styles.content}>
                <div className={styles.headerContainer}>
                    <div className={styles.header}>
                        <Translate>TUTORIAL_TITLE</Translate>
                    </div>
                    <div className={styles.text}>
                        <Translate>TUTORIAL_SUBTITLE_SHORT</Translate>
                    </div>
                </div>
                {renderAxsAppContent()}
                {renderPairingContent()}
            </div>
        </div>
    );
};

AXSAppTutorial.defaultProps = {
    showAxsAppContent: true,
    showPairingContent: true,
};

export default AXSAppTutorial;
