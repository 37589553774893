import { scaleTime } from 'd3';
import {
    Chart,
    Grid,
    onMouseMove,
    onTouchMove,
    Scale,
} from 'd3-charts-react';
import { useEffect } from 'react';
import { Axis, axisPropsFromTickScale, LEFT } from 'react-d3-axis';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import styles from './SegmentChart.module.scss';

import AltitudeChart from '../../altitudeChart';
import { mutateScaleNice } from '../../chartHelpers';

import Translate, { useTranslation } from '../../../../components/translate';
import { SRAM_400, toLocaleString } from '../../../../constants';
import { useSetState } from '../../../../hooks';
import { ComponentSummary, useUnits } from '../../../../providers';

const CHART_WIDTH = 700;
const DEFAULT_STATE = {
    draggingActiveSegment: null,
    draggingStartTs: null,
    segmentEndTs: null,
    segmentStartTs: null,
};

interface SegmentChartProps {
    endTs: number | null,
    gpsComponent: ComponentSummary,
    startTs: number | null,
    t?: (value: string) => string,
}

function SegmentChart({
    endTs,
    gpsComponent,
    startTs,
}: SegmentChartProps) {
    const [state, setState] = useSetState(DEFAULT_STATE);
    const {
        draggingActiveSegment,
        draggingStartTs,
        segmentEndTs,
        segmentStartTs,
    } = state;
    const units = useUnits();
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const translate = useTranslation();

    function onMouseDown(newSegmentStartTs: number, scale: any) {
        if (!endTs || !startTs) {
            setState({
                draggingStartTs: newSegmentStartTs,
                segmentEndTs: newSegmentStartTs,
                segmentStartTs: newSegmentStartTs,
            });

            return;
        }

        const currentPostion = scale.x(newSegmentStartTs);
        const currentStartTs = scale.x(startTs * 1000);
        const currentEndTs = scale.x(endTs * 1000);

        if ((currentPostion < currentStartTs + 3 && currentPostion > currentStartTs - 3)
        || (currentPostion < 6 && currentStartTs === 0)) {
            setState({ segmentEndTs: new Date(startTs * 1000), segmentStartTs: new Date(endTs * 1000) });

            return;
        }

        if ((currentPostion > currentEndTs - 3 && currentPostion < currentEndTs + 3)
        || (currentPostion > 693.5 && currentEndTs === CHART_WIDTH)) {
            setState({ segmentEndTs: new Date(endTs * 1000), segmentStartTs: new Date(startTs * 1000) });

            return;
        }

        if (currentStartTs - 3 < currentPostion && currentPostion < currentEndTs - 3) {
            setState({
                draggingActiveSegment: true,
                draggingStartTs: newSegmentStartTs,
                segmentEndTs: newSegmentStartTs,
                segmentStartTs: newSegmentStartTs,
            });

            return;
        }

        setState({
            draggingStartTs: newSegmentStartTs,
            segmentEndTs: newSegmentStartTs,
            segmentStartTs: newSegmentStartTs,
        });
    }

    function formatAltitude(altitude: number) {
        const { shorthand } = units.getLabelAltitude();

        return `${toLocaleString(units.convertAltitudeFromSI(altitude))} ${translate(shorthand)}`;
    }

    function routeToSegment() {
        if (!segmentStartTs) return;

        const baseURl = `/activities/${id}`;

        const shouldReverseTimestamps = (segmentStartTs > segmentEndTs);
        const newEndTs = shouldReverseTimestamps ? segmentStartTs : segmentEndTs;
        const newStartTs = shouldReverseTimestamps ? segmentEndTs : segmentStartTs;

        let sanitisedStartTs = newStartTs
            ? Math.round(newStartTs.getTime() / 1000)
            : gpsComponent.adjustedStartTs;

        if (sanitisedStartTs < gpsComponent.adjustedStartTs) {
            sanitisedStartTs = gpsComponent.adjustedStartTs;
        }

        let sanitisedEndTs = newEndTs
            ? Math.round(newEndTs.getTime() / 1000)
            : gpsComponent.adjustedEndTs;

        if (sanitisedEndTs > gpsComponent.adjustedEndTs) {
            sanitisedEndTs = gpsComponent.adjustedEndTs;
        }

        if (sanitisedEndTs !== sanitisedStartTs) {
            navigate(`${baseURl}?start_ts=${sanitisedStartTs}&end_ts=${sanitisedEndTs}`);
            setState({ draggingActiveSegment: null, draggingStartTs: null });
            return;
        }

        setState({
            draggingActiveSegment: null,
            draggingStartTs: null,
            segmentEndTs: null,
            segmentStartTs: null,
        });
    }

    function updateSegment(hoveredTimeStamp: number) {
        const params = new URLSearchParams(location.search);
        const newStartTs = Number.parseInt(params.get('start_ts') || '');
        const newEndTs = Number.parseInt(params.get('end_ts') || '');

        if (!segmentStartTs || !hoveredTimeStamp) return;

        if (draggingActiveSegment) {
            const timeDifference = hoveredTimeStamp - draggingStartTs;

            setState({
                segmentEndTs: new Date((newEndTs * 1000) + timeDifference),
                segmentStartTs: new Date((newStartTs * 1000) + timeDifference),
            });
        } else {
            setState({ segmentEndTs: hoveredTimeStamp });
        }
    }

    function renderExpandableLine(scale: any) {
        const params = new URLSearchParams(location.search);
        const newStartTs = Number.parseInt(params.get('start_ts') || '');
        const newEndTs = Number.parseInt(params.get('end_ts') || '');

        if (!Number.isFinite(endTs) && !Number.isFinite(startTs)) {
            return null;
        }

        const shouldReverseTimestamps = (newStartTs > newEndTs);
        const activeEndTs = shouldReverseTimestamps ? newStartTs : newEndTs;
        const activeStartTs = shouldReverseTimestamps ? newEndTs : newStartTs;

        const endPosition = scale.x(segmentEndTs || activeEndTs * 1000);
        const startPosition = scale.x(segmentStartTs || activeStartTs * 1000);

        if (endPosition === startPosition) return null;

        return (
            <>
                <g
                    className={styles.resize}
                    transform={`translate(${(endPosition < CHART_WIDTH ? endPosition : CHART_WIDTH) - 3}, 0)`}
                    x="0"
                >
                    <rect height="100%" style={{ fill: 'transparent' }} width="6" />
                </g>
                <g
                    className={styles.resize}
                    transform={`translate(${(startPosition > 0 ? startPosition : 3) - 3}, 0)`}
                >
                    <rect height="100%" style={{ fill: 'transparent' }} width="6" />
                </g>
            </>
        );
    }

    function renderSegmentWindow(scale: any) {
        const params = new URLSearchParams(location.search);
        const currentStartTs = Number.parseInt(params.get('start_ts') || '');
        const currentEndTs = Number.parseInt(params.get('end_ts') || '');

        if (!segmentStartTs && (!Number.isFinite(currentEndTs) && !Number.isFinite(currentStartTs))) {
            return null;
        }

        const newStartTs = segmentStartTs || currentStartTs * 1000;
        const newEndTs = segmentEndTs || currentEndTs * 1000;

        if (!newStartTs || !newEndTs) return null;

        const shouldReverseTimestamps = (newStartTs > newEndTs);
        const activeEndTs = shouldReverseTimestamps ? newStartTs : newEndTs;
        const activeStartTs = shouldReverseTimestamps ? newEndTs : newStartTs;

        const width = scale.x(activeEndTs) - scale.x(activeStartTs);

        return (
            <rect
                className={styles.segmentWindow}
                clipPath="url(#SegmentChart_ClipPath)"
                height="100%"
                width={width}
                x={scale.x(activeStartTs)}
                y={0}
            />
        );
    }

    function renderChart(scale: any) {
        const xAxisProps = axisPropsFromTickScale(scale.x, 3);
        const yAxisProps = axisPropsFromTickScale(scale.y, 3);

        return (
            <div className={styles.chartContainer}>
                <Chart className={styles.chart} viewBox={`0 0 ${CHART_WIDTH} 100`}>
                    <defs>
                        <clipPath id="SegmentChart_ClipPath">
                            <rect
                                height="100%"
                                width="100%"
                                x={0}
                                y={0}
                            />
                        </clipPath>
                    </defs>
                    <AltitudeChart
                        gpsComponent={gpsComponent}
                        height={100}
                        heightPercentage={1}
                        style={{ fill: SRAM_400 }}
                    />
                    <Grid hideXTicks hideYTicks scale={scale} />
                    <g className={styles.axis}>
                        <Axis
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...yAxisProps}
                            format={(altitude: number) => formatAltitude(altitude)}
                            style={{ orient: LEFT }}
                        />
                    </g>
                    <g className={`${styles.axis} ${styles.xAxis}`}>
                        <Axis
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...xAxisProps}
                            format={units.formatTime}
                        />
                    </g>
                    <g
                        onMouseDown={(event) => onMouseDown(onMouseMove(event, scale), scale)}
                        onTouchStart={(event) => onMouseDown(onTouchMove(event, scale), scale)}
                        onMouseMove={(event) => updateSegment(onMouseMove(event, scale))}
                        onTouchEnd={() => routeToSegment()}
                        onTouchMove={(event) => updateSegment(onTouchMove(event, scale))}
                    >
                        <rect
                            height="100%"
                            opacity={0}
                            width="100%"
                            x={0}
                            y={0}
                        />
                        {renderSegmentWindow(scale)}
                        {renderExpandableLine(scale)}
                    </g>
                </Chart>
                <div className={styles.mobileTimeScale}>
                    <div>
                        {units.formatTime(gpsComponent.adjustedStartTs * 1000)}
                    </div>
                    <div>
                        {units.formatTime(gpsComponent.adjustedEndTs * 1000)}
                    </div>
                </div>
            </div>
        );
    }

    function renderScale() {
        return (
            <Scale
                height={100}
                xMax={gpsComponent.adjustedEndTs * 1000}
                xMin={gpsComponent.adjustedStartTs * 1000}
                xScale={scaleTime}
                yMax={gpsComponent.data.max_alt * 1.05}
                yMin={Math.max((gpsComponent.data.min_alt * 0.95), 0)}
                yMutator={mutateScaleNice}
            >
                {(scale: any) => renderChart(scale)}
            </Scale>
        );
    }

    const onMouseUp = () => routeToSegment();

    useEffect(() => {
        window.addEventListener('mouseup', onMouseUp);

        return () => {
            window.removeEventListener('mouseup', onMouseUp);
        };
    }, [onMouseUp]);

    useEffect(() => {
        setState({ segmentEndTs: null, segmentStartTs: null });
    }, [location.search]);

    if (!gpsComponent) {
        return null;
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <Translate>ELEVATION_MAP</Translate>
            </div>
            <div className={styles.subtitle}>
                <Translate>ELEVATION_MAP_INSTRUCTIONS</Translate>
            </div>
            {renderScale()}
        </div>
    );
}

export default SegmentChart;
