import styles from './TirePressureGuideCard.module.scss';

import Translate from '../../../components/translate';
import { AXS_TEXT_GREY } from '../../../constants';
import { Bike } from '../../../providers';

interface TirePressureGuideProps {
    bike: Bike;
}

const TirePressureGuideCard = ({ bike } : TirePressureGuideProps) => {
    const handleNavigate = () => {
        window.open(`${window.location.origin}/guides/tire/pressure?bikeWeight=${bike.weight.toString()}`, '_blank');
    };

    return (
        <>
            <div className={styles.container}>
                <div className={styles.cardTitle}>
                    <Translate>TIRE_PRESSURE_GUIDE_MEASURE_IMPROVE</Translate>
                </div>
                <div style={{ color: AXS_TEXT_GREY }}>
                    <Translate>TIRE_PRESSURE_GUIDE_DESCRIPTION</Translate>
                </div>
                <div className={styles.content}>
                    <div className={styles.guideButtonContainer}>
                        <button
                            className={styles.guideButton}
                            type="button"
                            onClick={() => handleNavigate()}
                        >
                            <Translate>TIRE_PRESSURE_GUIDE</Translate>
                        </button>
                    </div>
                    <div>
                        <Translate>TIRE_PRESSURE_GUIDE_MORE_INFO</Translate>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TirePressureGuideCard;
