import React from 'react';

import styles from './Row.module.scss';

interface RowProps {
    borderBottomNone?: boolean;
    children: React.ReactNode;
    columnStyle?: Record<string, unknown>;
    dividerComponent?: React.ReactNode;
    style?: Record<string, unknown>;
}

const Row = (props: RowProps) => {
    const {
        borderBottomNone,
        children,
        columnStyle,
        dividerComponent,
        style,
    } = props;

    // Ensures that null children are not mapped
    const validChildren = React.Children.toArray(children).filter((child) => (
        React.isValidElement(child)
    ));

    return (
        <div
            className={`${styles.row} ${styles.flex} ${borderBottomNone ? styles.borderBottomNone : ''}`}
            style={style}
        >
            {validChildren.map((child: any, index) => (
                <React.Fragment key={child.key}>
                    <div className={styles.column} style={columnStyle}>{child}</div>
                    {(index < validChildren.length - 1) && (
                        dividerComponent
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default Row;
