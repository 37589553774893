import { useEffect } from 'react';

import Spinner from '../spinner';

import { useAuth } from '../../providers/auth/AuthContext';

const Logout = () => {
    const auth = useAuth();

    useEffect(() => {
        auth.logout()
    }, [])

    return <Spinner />;
};

export default Logout;
