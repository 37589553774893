import Checkbox from './checkbox';

import { useTranslation } from '../../components/translate';

function getLabel(option: {label: string, value: string} | string): string {
    const isObject = (typeof option === 'object');

    return isObject ? option.label : option;
}

function getValue(option: {label: string, value: string} | string): string | number {
    const isObject = (typeof option === 'object');

    return isObject ? option.value : option;
}

interface CheckboxFormProps {
    checked: (string | number)[]
    onChange: (value: (string | number)[]) => void,
    options: {label: string, value: string}[]
}

const CheckboxForm = ({ checked = [], onChange, options }: CheckboxFormProps) => {
    const translate = useTranslation();

    return (
        <>
            {options.map((option: {label: string, value: string}) => {
                const label = getLabel(option);
                const value = getValue(option);

                return (
                    <Checkbox
                        checked={checked.includes(value)}
                        key={value}
                        onChange={(event: any) => {
                            const newChecked = options
                                .filter((inOption: any) => {
                                    if (getValue(inOption) !== value) {
                                        return checked.includes(getValue(inOption));
                                    }

                                    return event.target.checked;
                                })
                                .map(getValue);

                            onChange(newChecked);
                        }}
                        value={translate(label)}
                    />
                );
            })}
        </>
    );
};

export default CheckboxForm;
