import React, { lazy, Suspense } from 'react';

import accountStyles from '../accounts/Accounts.module.scss';

import AXSTutorial from '../../bikes/axsAppTutorial';
import Spinner from '../../../components/spinner';
import Translate from '../../../components/translate';

const RideReportExample = lazy(() => import('../../activities/rideReportExample'));

function Tutorials() {
    return (
        <div>
            <div className={accountStyles.header}>
                <Translate>TUTORIALS</Translate>
            </div>
            <Suspense fallback={<Spinner />}>
                <RideReportExample />
            </Suspense>
            <AXSTutorial
                showAxsAppContent
                showPairingContent
            />
        </div>
    );
}

export default Tutorials;
