import React from 'react';
import { Link } from 'react-router-dom';

import styles from './BikeComponentCard.module.scss';

import BikeImage from '../../../views/bikeImage';
import DesktopContainer from '../../../components/desktopContainer';
import Translate from '../../../components/translate';
import { CHAIN_CALULATOR_REF_MODELS } from '../../../constants';
import { Bike } from '../../../providers/bikes';
import { BikeComponent } from '../../../providers/bikeComponents';

const renderComponentPosition = (component: number) => {
    switch (component) {
        case 1:
            return 'FRONT TIRE';
        case 2:
            return 'REAR TIRE';
        default:
            return 'UNKNOWN';
    }
};

const checkComponentModelId = (model: number) => {
    if (!model) return false;

    const showChainLengthGuideButton = CHAIN_CALULATOR_REF_MODELS.includes(model);
    return showChainLengthGuideButton;
};

interface BikeComponentCardProps {
    bike: Bike;
    bikeComponent: BikeComponent;
    componentTags: string[];
    position: number;
    productDetails: any;
}

const BikeComponentCard = ({
    bike,
    bikeComponent,
    componentTags,
    position,
    productDetails,
}: BikeComponentCardProps) => {
    const tyrePressureUrl = bike && bike.weight
        ? `${window.location.origin}/guides/tire/pressure?bikeWeight=${bike.weight}`
        : `${window.location.origin}/guides/tire/pressure`;

    return (
        <div className={styles.container}>
            <img
                alt=""
                className={styles.image}
                src={(productDetails && productDetails.image) || bikeComponent.mobile_display_icon_url}
            />
            <div className={styles.content}>
                <div className={styles.headerContainer}>
                    <div className={styles.textContainer}>
                        <div className={styles.header}>
                            {(
                                bikeComponent.mobile_display_name_key
                            || (productDetails && productDetails.name)
                            || bikeComponent.name
                            )}
                        </div>
                    </div>
                    <DesktopContainer>
                        {bike && (
                            <div className={styles.bikeContainer}>
                                <Link className={styles.bike} to={`/bikerack/${bike.id}`}>
                                    <BikeImage bike={bike} />
                                </Link>
                            </div>
                        )}
                    </DesktopContainer>
                </div>
                <div className={styles.componentContainer}>
                    <div>
                        <div className={styles.informationContent}>
                            <div className={styles.informationLabel}>
                                <Translate>MODEL</Translate>
                            &#58;&nbsp;
                            </div>
                            <div className={styles.informationValue}>
                                {bikeComponent.model_code}
                            </div>
                        </div>
                        <div className={styles.informationContent}>
                            <div className={styles.informationLabel}>
                                <Translate>SERIAL_NUMBER</Translate>
                            &#58;&nbsp;
                            </div>
                            <div className={styles.informationValue}>
                                {bikeComponent.serial}
                            </div>
                        </div>
                        {(bikeComponent.device_type === 303) && (
                            <div className={styles.informationContent}>
                                <div className={styles.informationLabel} id="data-test-position">
                                    <Translate>BIKE_COMPONENT_POSITION</Translate>
                                &#58;&nbsp;
                                </div>
                                <div className={styles.informationValue}>
                                    {renderComponentPosition(position)}
                                </div>
                            </div>
                        )}
                        {(componentTags && !!componentTags.length) && (
                            <div className={styles.informationContent}>
                                <div className={styles.informationLabel} id="data-test-status">
                                    <Translate>BIKE_COMPONENT_STATUS</Translate>
                                &#58;&nbsp;
                                </div>
                                <div className={styles.informationValue}>
                                    {componentTags.map((tag) => (
                                        <div className={styles.statusTag} key={tag}>
                                            {tag}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    {(bikeComponent.device_type === 303 || bikeComponent.model_code?.startsWith('WH')) && (
                        <div className={styles.guideButtonContainer}>
                            <button
                                className={`${styles.button} ${styles.guideButton}`}
                                style={{ padding: '0 1rem' }}
                                type="button"
                                onClick={() => window.open(tyrePressureUrl, '_blank')}
                            >
                                <Translate>TIRE_PRESSURE_GUIDE</Translate>
                            </button>
                        </div>
                    )}
                    {checkComponentModelId(bikeComponent.model) && (
                        <div className={styles.guideButtonContainer}>
                            <button
                                className={`${styles.button} ${styles.guideButton}`}
                                style={{ padding: '0 1rem' }}
                                type="button"
                            >
                                <Link className={styles.chainGuideLink} target="_blank" to="/chainlengthcalculator">
                                    <Translate>FULL_MOUNT_CHAIN_CALCULATOR</Translate>
                                </Link>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BikeComponentCard;
