import React from 'react';

import styles from './EditBike.module.scss';

import Button from '../../../components/button';
import Translate from '../../../components/translate';
import { AXS_TEXT_DARK } from '../../../constants';
import { Bike } from '../../../providers';
import BikeForm from '../../../views/bikeForm';

interface EditBikeProps {
    bike: any,
    onImageSelected: (image: File) => void;
    onSubmit: (bike: Bike) => void;
    show: boolean;
    toggleShow: () => void;
}

const EditBike = ({
    bike,
    onImageSelected,
    onSubmit,
    show,
    toggleShow,
}: EditBikeProps) => {
    if (!show) {
        return null;
    }

    return (
        <div className={styles.container}>
            <BikeForm
                bike={bike}
                onImageSelected={onImageSelected}
                onSubmit={onSubmit}
            >
                <div className={styles.buttonContainer}>
                    <Button
                        color={AXS_TEXT_DARK}
                        inverse
                        onClick={toggleShow}
                        type="button"
                    >
                        <Translate>CANCEL</Translate>
                    </Button>
                    <Button className={styles.buttonSave} type="submit">
                        <Translate>SAVE</Translate>
                    </Button>
                </div>
            </BikeForm>
        </div>
    );
};

export default EditBike;
