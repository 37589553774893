import Translate from '../../../components/translate';
import styles from './RideType.module.scss';
import RideStyleChip from '../rideStyleChip/RideStyleChip';

import { ReactComponent as rideTypeRoad } from '../../../assets/icons/rideTypeRoad.svg';
import { ReactComponent as rideTypeCycleCross } from '../../../assets/icons/rideTypeCycleCross.svg';
import { ReactComponent as rideTypeGravel } from '../../../assets/icons/rideTypeGravel.svg';
import { ReactComponent as rideTypeMTBCross } from '../../../assets/icons/rideTypeMTBCross.svg';
import { ReactComponent as rideTypeMTBTrail } from '../../../assets/icons/rideTypeMTBTrail.svg';
import { ReactComponent as rideTypeEnduro } from '../../../assets/icons/rideTypeEnduro.svg';
import { ReactComponent as rideTypeDownHill } from '../../../assets/icons/rideTypeDownHill.svg';
import { ReactComponent as rideTypeFat } from '../../../assets/icons/rideTypeFat.svg';
import { ReactComponent as rideSurfaceDry } from '../../../assets/icons/rideSurfaceDry.svg';
import { ReactComponent as rideSurfaceWet } from '../../../assets/icons/rideSurfaceWet.svg';
import { ReactComponent as rideSurfaceSnow } from '../../../assets/icons/rideSurfaceSnow.svg';

interface RideTypeProps {
    selectedRideStyle: string;
    selectedSurface: string;
    onSelect: (value: {[key: string]: string}) => void;
    setRideStyle: (value: string) => void;
}

const RideType = ({
    selectedRideStyle, selectedSurface, onSelect, setRideStyle,
}: RideTypeProps) => {
    const rideStyles = [
        { icon: rideTypeRoad, style: 'RIDE_STYLE_ROAD' },
        { icon: rideTypeCycleCross, style: 'RIDE_STYLE_CROSS' },
        { icon: rideTypeGravel, style: 'RIDE_STYLE_GRAVEL' },
        { icon: rideTypeMTBCross, style: 'RIDE_STYLE_XCOUNTRY_MTB' },
        { icon: rideTypeMTBTrail, style: 'RIDE_STYLE_TRAIL_MTB' },
        { icon: rideTypeEnduro, style: 'RIDE_STYLE_ENDURO_MTB' },
        { icon: rideTypeDownHill, style: 'RIDE_STYLE_DOWNHILL_MTB' },
        { icon: rideTypeFat, style: 'RIDE_STYLE_FAT' },
    ];

    const surfaces = [
        { icon: rideSurfaceDry, surface: 'SURFACE_DRY' },
        { icon: rideSurfaceWet, surface: 'SURFACE_WET' },
        ...(selectedRideStyle === 'RIDE_STYLE_FAT' ? [{ icon: rideSurfaceSnow, surface: 'SURFACE_SNOW' }] : []),
    ];

    const onRideStyleChange = (value: string) => {
        if (value === 'RIDE_STYLE_FAT') onSelect({ surface: 'SURFACE_SNOW' });

        if (selectedSurface === 'SURFACE_SNOW' && value !== 'RIDE_STYLE_FAT') {
            onSelect({ surface: 'SURFACE_DRY' });
        }
        setRideStyle(value);
    };

    return (
        <div className={styles.container}>
            <Translate>RIDE_TYPE</Translate>
            <div className={styles.section}>
                <Translate>RIDE_STYLE</Translate>
                <div className={styles.chips}>
                    {rideStyles.map((rideType) => (
                        <RideStyleChip
                            key={rideType.style}
                            rideStyle={rideType.style}
                            Icon={rideType.icon}
                            onSelect={() => onRideStyleChange(rideType.style)}
                            selected={selectedRideStyle === rideType.style}
                        />
                    ))}
                </div>
            </div>
            <div className={styles.section}>
                <Translate>SURFACE</Translate>
                <div className={styles.chips}>
                    {surfaces.map((surface) => (
                        <RideStyleChip
                            key={surface.surface}
                            rideStyle={surface.surface}
                            Icon={surface.icon}
                            onSelect={() => {
                                onSelect({ surface: surface.surface });
                            }}
                            selected={selectedSurface === surface.surface}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RideType;
