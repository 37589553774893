import Dropzone from 'react-dropzone';

import styles from './ActivityUpload.module.scss';
import UploadRow from './uploadRow';

import { logoAxsLogo } from '../../assets';
import Button from '../../components/button';
import ContentContainer from '../../components/contentContainer';
import Modal from '../../components/modal';
import NotificationPoll from '../../views/notificationPoll';
import Spinner from '../../components/spinner';
import Translate from '../../components/translate';
import { useSetState } from '../../hooks';
import { useActivities } from '../../providers';
import ErrorModal from '../../views/errorModal';

interface stagedFileProp {
    name: string;
    progress: number;
    failed?: boolean;
    type: string;
}

const parseFile = (file: any) => {
    const updatedFile: any = new Blob([file], { type: file.type });
    updatedFile.name = file.name;
    updatedFile.progress = 0;

    return updatedFile;
};

const ActivityUpload = () => {
    const [state, setState] = useSetState({
        isUploading: false,
        shouldPollForNotifications: false,
        showUploadedErrorModal: false,
        showUploadedModal: false,
        stagedFiles: [],
    });
    const activities = useActivities();

    const {
        isUploading,
        shouldPollForNotifications,
        showUploadedErrorModal,
        showUploadedModal,
        stagedFiles,
    } = state;

    const hasFilesToUpload = stagedFiles.some(
        (stagedFile: stagedFileProp) => (stagedFile.failed || stagedFile.progress !== 100),
    );

    const stageFiles = (files: any[]) => setState({
        stagedFiles: [
            ...stagedFiles,
            // Don't add duplicates
            ...files.filter((file) => !stagedFiles.some(({ name }: any) => file.name === name)),
        ],
    });

    const unstageFile = (file: any) => setState({
        stagedFiles: stagedFiles.filter((stagedFile: stagedFileProp) => stagedFile.name !== file.name),
    });

    const updateFile = (fileName: string, key: string, value: any) => setState({
        stagedFiles: stagedFiles.map((stagedFile: any) => {
            if (stagedFile.name === fileName) {
                stagedFile[key] = value;
            }

            return stagedFile;
        }),
    });

    const upload = async () => {
        // Upload new files or previously failed to upload files
        const files = stagedFiles.filter(
            (stagedFile: stagedFileProp) => ((stagedFile.progress === 0) || stagedFile.failed),
        );

        if (!files.length) return;

        files.forEach((file: stagedFileProp) => {
            updateFile(file.name, 'failed', false);
            updateFile(file.name, 'progress', 0);
        });

        setState({ isUploading: true, shouldPollForNotifications: false });

        const uploadedAllFilesSettledResponse = await Promise.allSettled(
            files.map(async (file: any) => {
                const progressConfig = {
                    onUploadProgress: ({ loaded, total }: {loaded: number, total: number}) => {
                        const progress = Math.floor((loaded / total) * 100);
                        updateFile(file.name, 'progress', progress);
                    },
                };

                const successfullyUploaded = await activities.uploadActivity(file, progressConfig);

                updateFile(file.name, 'failed', !successfullyUploaded);

                return successfullyUploaded;
            }),
        );

        // Check if the response includes a failed upload
        // https://stackoverflow.com/questions/63783735/type-error-on-response-of-promise-allsettled
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const successfullyUploadedAllFiles = uploadedAllFilesSettledResponse.every(({ value } : boolean) => value);

        setState({
            isUploading: false,
            shouldPollForNotifications: true,
            showUploadedErrorModal: !successfullyUploadedAllFiles,
            showUploadedModal: successfullyUploadedAllFiles,
        });
    };

    const renderNotificationPoll = () => {
        if (!shouldPollForNotifications) return null;

        return <NotificationPoll />;
    };

    const renderDropZone = () => (
        <Dropzone
            accept={{ 'application/octet-stream': ['.fit'] }}
            noClick
            onDrop={(files) => stageFiles((files.map((file) => parseFile(file))))}
        >
            {({
                getRootProps,
                getInputProps,
                isDragActive,
                open,
            }) => (
                <div
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...getRootProps()}
                    className={styles.dropContainer}
                    style={isDragActive
                        ? { border: '2px dashed #58595B', color: '#58595B' }
                        : undefined}
                >
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <input {...getInputProps()} />
                    <div className={styles.dropContainerText}>
                        <Translate>DRAG_AND_DROP_FILES</Translate>
                    </div>
                    {stagedFiles.map((file: any) => (
                        <UploadRow
                            isUploading={isUploading}
                            file={file}
                            key={file.name}
                            onDelete={() => unstageFile(file)}
                        />
                    ))}
                    <div className={styles.chooseFileContainer}>
                        <button
                            className={styles.chooseFileButton}
                            onClick={open}
                            type="button"
                        >
                            <Translate>CHOOSE_FILES</Translate>
                        </button>
                    </div>
                </div>
            )}
        </Dropzone>
    );

    const renderUploadedErrorModal = () => (
        <ErrorModal
            error={{ message: 'ACTIVITY_UPLOAD_ERROR' }}
            isOpen={showUploadedErrorModal}
            onClose={() => setState({ showUploadedErrorModal: false })}
        />
    );

    const renderUploadedModal = () => (
        <Modal
            contentClassName={styles.uploadedModal}
            contentLabel="Success"
            dialog
            header="SUCCESS"
            hideCloseButton
            imageSrc={logoAxsLogo}
            imageStyle={{ marginBottom: 0, marginTop: 'auto' }}
            isOpen={showUploadedModal}
            onClose={() => setState({ showUploadedModal: false })}
        >
            <Translate>ACTIVITY_UPLOAD_SUCCESS</Translate>
            <div className={styles.buttonContainer}>
                <Button
                    className={styles.accountLinkedModalButton}
                    inverse
                    onClick={() => setState({ showUploadedModal: false })}
                    type="button"
                >
                    <Translate>CLOSE</Translate>
                </Button>
            </div>
        </Modal>
    );

    return (
        <ContentContainer className={styles.container}>
            <Spinner loading={isUploading} />
            {renderNotificationPoll()}
            {renderUploadedErrorModal()}
            {renderUploadedModal()}
            <div>
                <h4>
                    <Translate>ACTIVITY_UPLOAD_FILES</Translate>
                </h4>
            </div>
            {renderDropZone()}
            <div className={styles.supportedFilesText}>
                <Translate>SUPPORTED_FILES</Translate>
                &nbsp;(.fit)
            </div>
            <div className={styles.uploadButtonContainer}>
                <Button
                    className={styles.uploadButton}
                    disabled={!hasFilesToUpload || isUploading}
                    onClick={() => upload()}
                    style={{ textTransform: 'uppercase' }}
                    type="button"
                >
                    <Translate>UPLOAD</Translate>
                </Button>
            </div>
        </ContentContainer>
    );
};

export default ActivityUpload;
