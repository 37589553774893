import Modal from '../../components/modal';
import Translate from '../../components/translate';

import styles from './ErrorModal.module.scss';

import { iconError } from '../../assets';

interface ErrorModalProps {
    disableOverlayClick?: boolean,
    error: any,
    isOpen?: boolean,
    onClose: () => void,
    onOverlayClick?: () => void,
}

const ErrorModal = ({
    disableOverlayClick = false,
    error = null,
    isOpen = true,
    onClose = () => {},
    onOverlayClick = () => { },
}: ErrorModalProps) => {
    if (!error) {
        return null;
    }

    let message = 'PLEASE_TRY_AGAIN';
    if (error) {
        message = error.message;

        if (error.response && error.response.data) {
            message = error.response.data.msg;
        }
    }

    return (
        <Modal
            contentClassName={styles.container}
            contentLabel="Error"
            dialog
            disableOverlayClick={disableOverlayClick}
            header="ERROR"
            imageSrc={iconError}
            imageStyle={{ height: '5rem', margin: 0 }}
            isOpen={isOpen}
            onClose={onClose}
            onOverlayClick={onOverlayClick}
        >
            <Translate>
                {message}
            </Translate>
        </Modal>
    );
};

export default ErrorModal;
