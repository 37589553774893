import {
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie-consent';

import styles from './TyrePressureGuide2.module.scss';
import {
    calculateRecommendedPressure,
    getCasingOptions,
    getDefaultBikeWeight,
    getDefaultTireSettings,
    getDefaultWheelSettings,
    getWheelDiameterValues,
    isGoodYearTire,
    isNumeric,
    RIDE_STYLE_CROSS,
    RIDE_STYLE_GRAVEL,
    RIDE_STYLE_ROAD,
    RIM_TYPE_TUBELESS_STRAIGHT_SIDE,
    roundToNearestHalf,
    validateUrlParamsData,
    WHEEL_FRONT,
    WHEEL_REAR,
} from './tyrePressureGuideHelpers2';

import Button from '../../components/button';
import Spinner from '../../components/spinner';
import Translate from '../../components/translate';
import {
    ISO_TIRE_PRESSURE_RANGE,
    roundValueBy, SRAM_RED,
    toLocaleString,
} from '../../constants';
import {
    Bike, useAuth, useNexus, useUnits,
} from '../../providers';
import { convertInchesToMM, UNITS } from '../../providers/units/constants';
import { useSetState } from '../../hooks';

import Logger from '../../Logger';
import FirebaseAnalytics from '../../components/firebaseAnalytics';
import { checkCookieConsent } from '../../components/analytics/Analytics';

import RideType from './rideType';
import RiderSettings from './riderSettings';
import WheelsetSetting from './wheelsetSettings';
import TireSettings from './tireSettings';
import UnitToggle from './unitToggle';

interface TyrePressureGuideState {
    bikeWeight: number,
    frontPressure: number | null,
    frontTireCasing: string,
    frontTireWidth: number,
    innerRimWidth: number,
    rearPressure: number | null,
    rearTireCasing: string,
    rearTireWidth: number,
    riderWeight: number,
    rideStyle: string,
    rimType: string,
    selectedMassUnits: string,
    selectedPressureUnits: string,
    showFrontTireWidthWarning: boolean,
    showRearTireWidthWarning: boolean,
    showTirePressureWarning: boolean,
    showTireTypeRimWarning: boolean,
    surface: string,
    tirePresetValues: boolean,
    wheelsetPresetValues: boolean,
    wheelDiameter: number,
}

const DEFAULT_STATE: TyrePressureGuideState = {
    bikeWeight: 0,
    frontPressure: null,
    frontTireCasing: '',
    frontTireWidth: 0,
    innerRimWidth: 0,
    rearPressure: null,
    rearTireCasing: '',
    rearTireWidth: 0,
    riderWeight: 0,
    rideStyle: 'RIDE_STYLE_ROAD',
    rimType: '',
    selectedMassUnits: UNITS.kg,
    selectedPressureUnits: UNITS.bar,
    showFrontTireWidthWarning: false,
    showRearTireWidthWarning: false,
    showTirePressureWarning: false,
    showTireTypeRimWarning: false,
    surface: 'SURFACE_DRY',
    tirePresetValues: false,
    wheelDiameter: 0,
    wheelsetPresetValues: false,
};

function TyrePressureGuide2() {
    const units = useUnits();
    const navigate = useNavigate();
    const { search } = useLocation();
    const cookieConsent = Cookies.get('CookieConsent');
    const { nexusUserProfile } = useNexus();
    const auth = useAuth();
    const [state, setState] = useSetState({
        ...DEFAULT_STATE,
        bikeWeight: getDefaultBikeWeight('RIDE_STYLE_ROAD'),
    });
    const [isCalculating, setIsCalculating] = useState(false);

    const {
        bikeWeight,
        frontPressure,
        frontTireCasing,
        frontTireWidth,
        innerRimWidth,
        rearPressure,
        rearTireCasing,
        rearTireWidth,
        riderWeight,
        rideStyle,
        rimType,
        selectedMassUnits,
        selectedPressureUnits,
        showFrontTireWidthWarning,
        showRearTireWidthWarning,
        showTirePressureWarning,
        showTireTypeRimWarning,
        surface,
        wheelsetPresetValues,
        tirePresetValues,
        wheelDiameter,
    } = state;

    const tireDefaultSettings = useMemo(() => getDefaultTireSettings(rideStyle), [rideStyle]);
    const wheelDefaultSettings = useMemo(() => getDefaultWheelSettings(rideStyle), [rideStyle]);
    const queryParam = new URLSearchParams(search);

    const hasBeenOverridenWithNexusUserProfile = useRef(false);

    const setValuesFromUrlParams = () => {
        let qrValues = {};
        Object.keys(state).forEach((_value : string) => {
            const item = queryParam.get(_value);
            if (item !== null) {
                qrValues = { ...qrValues, [_value]: isNumeric(item) ? Number(item) : item };
            }
        });

        const bikeWeightParam = queryParam.get('bikeWeight');

        if (Object.keys(qrValues).length === 1 && bikeWeightParam && Number.isFinite(Number(bikeWeightParam))) {
            const convertedBikeWeight = roundValueBy(units.convertWeightFromSI(Number(bikeWeightParam),
                units.getUnit('mass')), 2);
            return setState({
                bikeWeight: convertedBikeWeight,
            });
        }

        const isValid = validateUrlParamsData(qrValues);

        if (!isValid) {
            return navigate('/guides/tire/pressure');
        }

        const bikeSettings = { ...DEFAULT_STATE, ...qrValues };
        return setState(bikeSettings);
    };

    const eventLogger = async () => {
        const eventFields = {
            bikeWeight,
            frontPressure,
            frontTireCasing,
            frontTireWidth,
            innerRimWidth,
            rearPressure,
            rearTireCasing,
            rearTireWidth,
            riderWeight,
            rideStyle,
            rimType,
            selectedMassUnits,
            selectedPressureUnits,
            showFrontTireWidthWarning,
            showRearTireWidthWarning,
            showTirePressureWarning,
            showTireTypeRimWarning,
            surface,
            wheelDiameter,
        };

        const properties = {
            ...eventFields,
            action: 'Tire Pressure Calculation',
            label: 'Tire Pressure Calculated on Submit',
            source: window.location.href,
        };

        FirebaseAnalytics('Tire_Pressure_Calculation', properties);

        if (window.woopra) {
            window.woopra.track('Tire_Pressure_Calculation', properties);
        }

        Logger.log('TirePressureFormFields', eventFields);
    };

    const startCalcAnimation = () => {
        setIsCalculating(true);
        setTimeout(() => {
            setIsCalculating(false);
        }, 600);
    };

    const getDefaultSettings = (defaultType: string, inRideStyle?: string) => {
        const rideStyleToUse = inRideStyle || rideStyle;

        if (!rideStyleToUse) return null;

        const rideStylePresetValues = defaultType === 'wheelSet'
            ? getDefaultWheelSettings(rideStyleToUse) : getDefaultTireSettings(rideStyleToUse);
        const sanitizedPresetValues = { ...rideStylePresetValues };

        return sanitizedPresetValues;
    };

    const overrideWithNexusUserProfile = () => {
        const newState: any = {
            selectedMassUnits: units.getUnit('mass'),
            selectedPressureUnits: units.getUnit('pressure_tire'),
        };

        if (nexusUserProfile && Number.isFinite(nexusUserProfile.weight)) {
            newState.riderWeight = roundValueBy(
                units.formatWeight(units.convertWeightFromSI(nexusUserProfile.weight)),
                1,
            );
        }

        if (Number.isFinite(bikeWeight)) {
            newState.bikeWeight = roundValueBy(
                units.convertWeightFromSI(units.convertWeightToSI(bikeWeight, selectedMassUnits)),
                2,
            );
        }

        setState(newState);
    };

    const isTireInches = () => {
        if (!rideStyle) return false;

        return (![RIDE_STYLE_ROAD, RIDE_STYLE_CROSS, RIDE_STYLE_GRAVEL].includes(rideStyle));
    };

    const getTirePressureWarnings = (newFrontPressure: number, newRearPressure: number) => {
        if (rimType === RIM_TYPE_TUBELESS_STRAIGHT_SIDE
            || isGoodYearTire(frontTireCasing) || isGoodYearTire(rearTireCasing)) {
            const frontTireWidthMM = isTireInches() ? convertInchesToMM(frontTireWidth) : frontTireWidth;
            const rearTireWidthMM = isTireInches() ? convertInchesToMM(rearTireWidth) : rearTireWidth;

            const checkPressureWarning = (tireWidth: number, tireType: string) => {
                const tirePressureRange = ISO_TIRE_PRESSURE_RANGE.find(
                    (range) => tireWidth >= range.minTireWidth && tireWidth <= range.maxTireWidth,
                );

                if (tirePressureRange) {
                    const { pressureThreshold } = tirePressureRange;
                    const newPressure = tireType === 'front' ? newFrontPressure : newRearPressure;
                    return newPressure > pressureThreshold;
                }
                return false;
            };

            const hasFrontPressureWarning = checkPressureWarning(frontTireWidthMM, 'front');
            const hasRearPressureWarning = checkPressureWarning(rearTireWidthMM, 'rear');

            return hasFrontPressureWarning || hasRearPressureWarning;
        }

        return false;
    };

    const updatePressureSuggestions = () => {
        startCalcAnimation();
        const config = {
            bikeWeight: Math.round(units.convertWeightToSI(bikeWeight, selectedMassUnits)),
            frontTireCasing,
            frontTireWidth,
            innerRimWidth,
            rearTireCasing,
            rearTireWidth,
            riderWeight: Math.round(units.convertWeightToSI(riderWeight, selectedMassUnits)),
            rideStyle,
            rimType,
            surface,
            wheelDiameter,
        };

        const newFrontPressure = calculateRecommendedPressure({
            ...config,
            tireCasing: frontTireCasing,
            tireWidth: isTireInches() ? convertInchesToMM(frontTireWidth) : frontTireWidth,
            wheelPosition: WHEEL_FRONT,
        });

        const newRearPressure = calculateRecommendedPressure({
            ...config,
            tireCasing: rearTireCasing,
            tireWidth: isTireInches() ? convertInchesToMM(rearTireWidth) : rearTireWidth,
            wheelPosition: WHEEL_REAR,
        });

        setState({
            frontPressure: newFrontPressure,
            rearPressure: newRearPressure,
            showTirePressureWarning: getTirePressureWarnings(newFrontPressure, newRearPressure),
        });

        if (checkCookieConsent(cookieConsent) || auth.isAuthenticated()) eventLogger();
    };

    const checkTireAndRimWidthsCompatibility = () => {
        const showWarning = false;
        const showFrontWarning = false;
        const showRearWarning = false;

        // Only show warning for road/cyclecross/gravel bikes
        // Commented out while compareTireAndRimWidthCompatibility is returning false for all cases
        // if (!isTireInches()) {
        //     if (compareTireAndRimWidthCompatibility(frontTireWidth, innerRimWidth)) {
        //         showWarning = true;
        //         showFrontWarning = true;
        //     }

        //     if (compareTireAndRimWidthCompatibility(rearTireWidth, innerRimWidth)) {
        //         showWarning = true;
        //         showRearWarning = true;
        //     }
        // }

        setState({
            showFrontTireWidthWarning: showFrontWarning,
            showRearTireWidthWarning: showRearWarning,
            showTireTypeRimWarning: showWarning,
        });
    };

    const handleRideStyleChange = (value: string) => {
        const defaultBikeWeight = getDefaultBikeWeight(value);
        const bikeWeightConverted = queryParam.get('bikeWeight') ? bikeWeight
            : roundValueBy(
                units.convertWeightFromSI(defaultBikeWeight || 0, selectedMassUnits),
                2,
            );
        let newState = { bikeWeight: bikeWeightConverted, rideStyle: value };
        if (wheelsetPresetValues) {
            const rideStylePresetValues = getDefaultSettings('wheelSet', newState.rideStyle);
            newState = {
                ...newState,
                ...rideStylePresetValues,
            };
        }
        if (tirePresetValues) {
            const rideStylePresetValues = getDefaultSettings('tireSet', newState.rideStyle);
            newState = {
                ...newState,
                ...rideStylePresetValues,
            };
        }

        setState(newState);
    };

    const convertMassInputs = (oldUnit: string, value: string) => {
        if (!oldUnit || oldUnit === value) return;

        const newBikeWeight = bikeWeight
            ? roundValueBy(
                units.convertWeightFromSI(
                    units.convertWeightToSI(bikeWeight, oldUnit),
                    value,
                ),
                2,
            )
            : bikeWeight;

        const newRiderWeight = riderWeight
            ? roundToNearestHalf(
                units.formatWeight(
                    units.convertWeightFromSI(
                        units.convertWeightToSI(riderWeight, oldUnit),
                        value,
                    ),
                    value,
                ),
            )
            : riderWeight;

        setState({ bikeWeight: newBikeWeight, riderWeight: newRiderWeight, selectedMassUnits: value });
    };

    const handleCheckTirePreset = () => {
        setState({ tirePresetValues: !tirePresetValues });

        const previousPresetValue = !tirePresetValues;
        if (previousPresetValue) {
            setState(getDefaultTireSettings(rideStyle));
        }
    };

    const handleCheckWheelsetPreset = () => {
        setState({ wheelsetPresetValues: !wheelsetPresetValues });

        const previousPresetValue = !wheelsetPresetValues;
        if (previousPresetValue) {
            setState(getDefaultWheelSettings(rideStyle));
        }
    };

    useEffect(() => {
        const currentWheelSetValues = { innerRimWidth, rimType, wheelDiameter };
        if (JSON.stringify(wheelDefaultSettings) !== JSON.stringify(currentWheelSetValues)) {
            setState({ wheelsetPresetValues: false });
        }
    }, [wheelDiameter, rimType, innerRimWidth]);

    useEffect(() => {
        const currentTireValues = {
            frontTireCasing, frontTireWidth, rearTireCasing, rearTireWidth,
        };
        if (JSON.stringify(tireDefaultSettings) !== JSON.stringify(currentTireValues)) {
            setState({ tirePresetValues: false });
        }
    }, [frontTireCasing, frontTireWidth, rearTireCasing, rearTireWidth]);

    useEffect(() => {
        if (!hasBeenOverridenWithNexusUserProfile.current) {
            if (auth.isAuthenticated() && nexusUserProfile) {
                hasBeenOverridenWithNexusUserProfile.current = true;
                overrideWithNexusUserProfile();
            }
        }

        if (search) {
            setValuesFromUrlParams();
        }
    }, [auth.isAuthenticated(), nexusUserProfile]);

    useEffect(() => {
        checkTireAndRimWidthsCompatibility();
    }, [
        frontTireCasing,
        innerRimWidth,
        rearTireCasing,
        rideStyle,
        rimType,
        surface,
    ]);

    const isInvalidOptions = (options: (string | number)[], value: string | number) => !options.includes(value);

    useEffect(() => {
        if (!frontTireCasing && !rearTireCasing && !wheelDiameter) return;

        const updatedState = {
            frontTireCasing: isInvalidOptions(getCasingOptions(rideStyle), frontTireCasing) ? '' : frontTireCasing,
            rearTireCasing: isInvalidOptions(getCasingOptions(rideStyle), rearTireCasing) ? '' : rearTireCasing,
            wheelDiameter: isInvalidOptions(getWheelDiameterValues(rideStyle), wheelDiameter) ? '' : wheelDiameter,
        };

        setState(updatedState);
    }, [rideStyle]);

    const renderInputWarnings = () => {
        if (!showTirePressureWarning && !showTireTypeRimWarning) return null;

        return (
            <div className={styles.inputWarningContainer}>
                {showTireTypeRimWarning && (
                    <div id="data-test-compatible-warning">
                        <Translate>TIRE_PRESSURE_GUIDE_COMPATIBLE_WARNING</Translate>
                    </div>
                )}
                {showTirePressureWarning && (
                    <div id="data-test-pressure-warning">
                        <Translate>TIRE_PRESSURE_GUIDE_PRESSURE_WARNING</Translate>
                    </div>
                )}
            </div>
        );
    };

    const renderRecommendedPressure = () => {
        const displayUnits = units.getLabelPressure(selectedPressureUnits).shorthand;
        let displayFrontPressure: string | number = 0;
        let displayRearPressure: string | number = 0;

        if (showTireTypeRimWarning) {
            displayFrontPressure = '--';
            displayRearPressure = '--';
        } else if (units) {
            displayFrontPressure = frontPressure
                ? toLocaleString(
                    units.formatPressure(
                        units.convertPressureFromSI(frontPressure, selectedPressureUnits),
                        selectedPressureUnits,
                    ),
                )
                : '--';

            displayRearPressure = rearPressure
                ? toLocaleString(
                    units.formatPressure(
                        units.convertPressureFromSI(rearPressure, selectedPressureUnits),
                        selectedPressureUnits,
                    ),
                )
                : '--';
        }

        return (
            <>
                <div className={styles.pressureTitle}>
                    <Translate>TIRE_PRESSURE_SUGGESTIONS</Translate>
                </div>
                <div className={styles.recommendedPressureContainer}>
                    <div>
                        <div className={styles.pressureLabel}>
                            <Translate>TIRE_FRONT</Translate>
                        </div>
                        <div className={`${styles.pressureContainer} ${isCalculating ? styles.calcAnimate : ''}`}>
                            <div
                                className={styles.pressureValue}
                                id="data-test-front-pressure"
                            >
                                <span>{displayFrontPressure}</span>
                                &nbsp;
                                {((displayFrontPressure !== '--')
                                    ? <Translate>{displayUnits}</Translate>
                                    : null
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={styles.pressureLabel}>
                            <Translate>TIRE_REAR</Translate>
                        </div>
                        <div className={`${styles.pressureContainer} ${isCalculating ? styles.calcAnimate : ''}`}>
                            <div
                                className={styles.pressureValue}
                                id="data-test-rear-pressure"
                            >
                                <span>{displayRearPressure}</span>
                                &nbsp;
                                {((displayRearPressure !== '--')
                                    ? <Translate>{displayUnits}</Translate>
                                    : null
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <UnitToggle
                        onTogglePressure={(value) => setState({ selectedPressureUnits: value })}
                        selectedPressureUnits={selectedPressureUnits}
                    />
                </div>
            </>
        );
    };

    return (
        <div className={`${styles.container}`}>
            <Spinner loading={auth.isAuthenticated() && !nexusUserProfile} />
            <div className={styles.info}>
                <Translate>TIRE_PRESSURE_GUIDE_INFO</Translate>
            </div>
            <div className={styles.inputsContainer}>
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        updatePressureSuggestions();
                    }}
                >
                    <RideType
                        selectedRideStyle={rideStyle}
                        selectedSurface={surface}
                        onSelect={setState}
                        setRideStyle={handleRideStyleChange}
                    />
                    <RiderSettings
                        onChange={setState}
                        riderWeight={riderWeight}
                        selectedMassUnits={selectedMassUnits}
                        bikeWeight={bikeWeight}
                        onToggleMass={(value) => convertMassInputs(selectedMassUnits, value)}
                    />
                    <TireSettings
                        checkedPreset={tirePresetValues}
                        onChangePreset={handleCheckTirePreset}
                        frontTireCasing={frontTireCasing}
                        frontTireWidth={frontTireWidth}
                        isTireInches={isTireInches()}
                        onChange={setState}
                        rearTireCasing={rearTireCasing}
                        rearTireWidth={rearTireWidth}
                        rideStyle={rideStyle}
                        showFrontTireWidthWarning={showFrontTireWidthWarning}
                        showRearTireWidthWarning={showRearTireWidthWarning}
                        rimType={rimType}
                    />
                    <WheelsetSetting
                        checkedPreset={wheelsetPresetValues}
                        onChangePreset={handleCheckWheelsetPreset}
                        wheelDiameter={wheelDiameter}
                        rideStyle={rideStyle}
                        rimType={rimType}
                        innerRimWidth={innerRimWidth}
                        onChange={setState}
                        isTireInches={isTireInches()}
                        showTireTypeRimWarning={showTireTypeRimWarning}
                        rearTireCasing={rearTireCasing}
                        frontTireCasing={frontTireCasing}
                    />

                    <div className={styles.saveButtonContainer}>
                        <Button
                            className={styles.button}
                            color={SRAM_RED}
                            type="submit"
                        >
                            <Translate>TIRE_PRESSURE_CALCULATE</Translate>
                        </Button>
                    </div>
                </form>
                <div className={styles.rightCol}>
                    {renderInputWarnings()}
                    <div className={styles.pressureSuggestions}>
                        {renderRecommendedPressure()}
                    </div>
                    <div className={styles.disclaimer}>
                &#9888;&nbsp;
                        <Translate>TIRE_PRESSURE_DISCLAIMER</Translate>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TyrePressureGuide2;
