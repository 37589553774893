import auth0 from 'auth0-js';

import { AUTH_CONFIG } from './constants';
import Logger from './Logger';

const auth0WebAuth = new auth0.WebAuth(AUTH_CONFIG);

function auth0ParseHash(inWebAuth = auth0WebAuth) {
    return new Promise((resolve, reject) => {
        inWebAuth.parseHash((error, authResult) => {
            if (error) {
                Logger.warn(error);
                reject(error);
                return;
            }

            if (!authResult) {
                const authResultError = new Error('No data provided by auth0');
                Logger.warn(authResultError);
                reject(authResultError);
                return;
            }

            if (!authResult.accessToken) {
                const accessTokenError = new Error('No access token provided by auth0');
                Logger.warn(accessTokenError);
                reject(accessTokenError);
                return;
            }

            if (!authResult.idToken) {
                const idTokenError = new Error('No id token provided by auth0');
                Logger.warn(idTokenError);
                reject(idTokenError);
                return;
            }

            if (!authResult.idTokenPayload) {
                const idTokenError = new Error('No idTokenPayload provided by auth0');
                Logger.warn(idTokenError);
                reject(idTokenError);
                return;
            }

            resolve(authResult);
        });
    });
}

export { auth0ParseHash };

export default auth0WebAuth;
