import { scaleLog } from 'd3';
import {
    Area,
    Grid,
    Line,
    lineBisector,
    onMouseMove,
    onTouchMove,
    Scale,
    VerticalLine,
} from 'd3-charts-react';
import React, { useCallback, useMemo, useState } from 'react';
import { Axis, axisPropsFromTickScale, LEFT } from 'react-d3-axis';
import { useResizeDetector } from 'react-resize-detector';

import styles from './HeartRateCurveChart.module.scss';

import { LthrDisplay } from '../thresholdDisplay';

import {
    categoriseData,
    CURVE_CHART_DURATION_TICK_VALUES,
    formatHeartRate,
    mutateScaleNice,
} from '../../chartHelpers';

import Translate from '../../../../components/translate';
import { SRAM_200, ZONE_COLORS_HEART } from '../../../../constants';
import { ComponentSummary, useUnits } from '../../../../providers';
import ExpandChart from '../../../../views/expandChart';

const DURATION_EXTRACTOR = ([duration] : number[]) => duration;
const MEAN_MAX_EXTRACTOR = ([, , meanMax] : number[]) => meanMax;

interface HeartRateCurveChartProps {
    disableEdit?: boolean;
    disableOwnerFeatures?: boolean;
    heartComponent: ComponentSummary;
    heartZones: {max: number, min: number}[];
}

const HeartRateCurveChart = ({
    disableEdit,
    disableOwnerFeatures,
    heartComponent,
    heartZones,
}: HeartRateCurveChartProps) => {
    const [duration, setDuration] = useState(0);
    const [heartRate, setHeartRate] = useState(0);
    const { formatDuration } = useUnits();

    const categorisedData = useMemo(
        () => (
            // Normalized Categorised Data
            heartComponent
                ? categoriseData(
                    heartComponent.data.mean_maximal_hr_curve,
                    heartZones,
                    MEAN_MAX_EXTRACTOR,
                )
                : []
        ),
        [heartComponent, heartZones],
    );

    const heartRateExtractor = useCallback(
        (...params: any) => {
            const values = lineBisector(...params);

            setHeartRate(values ? values[1] : 0);
        },
        [setHeartRate],
    );

    const [
        xMax,
        yMax,
        yMin,
        hasCurveData,
    ] = useMemo(
        () => {
            if (!heartComponent) {
                return [null, null, null, null];
            }

            const newHasCurveData = (
                heartComponent.data.mean_maximal_hr_curve
                && heartComponent.data.mean_maximal_hr_curve.length
            );
            if (!newHasCurveData) {
                return [
                    Math.max(heartComponent.data.time.length, 1),
                    heartComponent.data.max_hr,
                    heartComponent.data.min_hr,
                    newHasCurveData,
                ];
            }

            const newXMax = DURATION_EXTRACTOR(
                heartComponent.data.mean_maximal_hr_curve[
                    heartComponent.data.mean_maximal_hr_curve.length - 1
                ],
            );
            const newYmax = MEAN_MAX_EXTRACTOR(heartComponent.data.mean_maximal_hr_curve[0]);
            const newYMin = heartComponent.data.mean_maximal_hr_curve.reduce(
                (currentMin: number, dataPoint: any) => (
                    Math.min(currentMin, MEAN_MAX_EXTRACTOR(dataPoint))
                ),
                // init currentMin
                newYmax,
            );

            return [
                newXMax,
                newYmax,
                newYMin,
                newHasCurveData,
            ];
        },
        [heartComponent],
    );

    if (!heartComponent) {
        return null;
    }

    if (!heartComponent.data.mean_maximal_hr_curve.length) {
        return (
            <div className={styles.noDataNote}>
                <Translate>NO_DATA_AVAILABLE_NOTES</Translate>
            </div>
        );
    }

    const { width, height, ref } = useResizeDetector();

    return (
        <ExpandChart title="HEART_RATE_CURVE">
            <div className={styles.container}>
                <div className={styles.statisticsContainer}>
                    <div className={styles.statistic}>
                        {formatHeartRate(heartRate)}
                        &nbsp;
                        <Translate>UNITS_BPM</Translate>
                    </div>
                    <div className={styles.statistic}>
                        {formatDuration(duration.toString()) || 0}
                    </div>
                </div>
                <div className={styles.yAxisLabel}>
                    <Translate>UNITS_BPM</Translate>
                </div>
                <div ref={ref}>
                    <Scale
                        height={height}
                        width={width}
                        xMax={xMax}
                        xMin={1}
                        xScale={scaleLog}
                        yMax={yMax * 1.05}
                        yMin={Math.max((yMin * 0.95), 0)}
                        yMutator={mutateScaleNice}
                    >
                        {(scale: {
                            x: (arg0: number) => string | number | undefined;
                            y: (arg0: number) => string | number | undefined;
                        }) => {
                            const xAxisProps = axisPropsFromTickScale(scale.x, 3);
                            const yAxisProps = axisPropsFromTickScale(scale.y, 3);

                            return (
                                <div className={styles.chartContainer}>
                                    <svg
                                        className={styles.chart}
                                        height={height}
                                        onMouseMove={(event) => {
                                            const hoveredHeartRate = onMouseMove(event, scale);

                                            if (hoveredHeartRate === null) {
                                                setDuration(0);
                                                setHeartRate(0);
                                                return;
                                            }

                                            setDuration(hoveredHeartRate);
                                        }}
                                        onMouseLeave={() => {
                                            setDuration(0);
                                            setHeartRate(0);
                                        }}
                                        onTouchMove={(event) => {
                                            const hoveredHeartRate = onTouchMove(event, scale);

                                            if (hoveredHeartRate === null) {
                                                setDuration(0);
                                                setHeartRate(0);
                                                return;
                                            }

                                            setDuration(hoveredHeartRate);
                                        }}
                                        width={width}
                                    >
                                        <Grid
                                            hideXTicks
                                            scale={scale}
                                            yLineStyle={{ stroke: SRAM_200 }}
                                            yTicks={yAxisProps.values}
                                        />
                                        {disableOwnerFeatures && (
                                            <Area
                                                data={heartComponent.data.mean_maximal_hr_curve}
                                                scale={scale}
                                                style={{ fill: SRAM_200 }}
                                                xExtractor={DURATION_EXTRACTOR}
                                                yExtractor={MEAN_MAX_EXTRACTOR}
                                            />
                                        )}
                                        <Line
                                            className={disableOwnerFeatures ? styles.line : styles.hiddenLine}
                                            data={heartComponent.data.mean_maximal_hr_curve}
                                            scale={scale}
                                            sync={duration}
                                            valueExtractor={heartRateExtractor}
                                            xExtractor={DURATION_EXTRACTOR}
                                            yExtractor={MEAN_MAX_EXTRACTOR}
                                        />
                                        {!disableOwnerFeatures && (
                                            categorisedData.map(({ data, zoneIndex }, index) => (
                                                <Area
                                                    data={data}
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    key={index}
                                                    scale={scale}
                                                    style={{
                                                        fill: ZONE_COLORS_HEART[zoneIndex],
                                                        stroke: ZONE_COLORS_HEART[zoneIndex],
                                                    }}
                                                    xExtractor={DURATION_EXTRACTOR}
                                                    yExtractor={MEAN_MAX_EXTRACTOR}
                                                />
                                            ))
                                        )}
                                        <VerticalLine scale={scale} sync={duration} />
                                        {duration && (
                                            <circle
                                                className={styles.indicator}
                                                cx={scale.x(duration)}
                                                cy={scale.y(heartRate)}
                                                r={7}
                                            />
                                        )}
                                        <g className={styles.axis}>
                                            <Axis
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...yAxisProps}
                                                format={formatHeartRate}
                                                style={{ orient: LEFT }}
                                            />
                                        </g>
                                        <g
                                            className={styles.axis}
                                            style={{ transform: `translateY(${height}px)` }}
                                        >
                                            <Axis
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...xAxisProps}
                                                format={formatDuration}
                                                values={(hasCurveData
                                                    ? CURVE_CHART_DURATION_TICK_VALUES
                                                    : xAxisProps.values
                                                )}
                                            />
                                        </g>
                                    </svg>
                                </div>
                            );
                        }}
                    </Scale>
                </div>
                <div className={styles.xAxisLabel}>
                    <Translate>DURATION</Translate>
                </div>
                {!disableEdit && (
                    <div className={styles.footer}>
                        <LthrDisplay />
                    </div>
                )}
            </div>
        </ExpandChart>
    );
};

export default HeartRateCurveChart;
