import { createContext, useContext } from 'react';
import { NotificationProps } from './types.d';

const NotificationsContext = createContext<NotificationProps>({
    notifications: {
        // State
        hasMore: true,
        isFetching: false,
        notifications: [],
        page: 1,
        unreadCount: 0,
        // Functions
        // eslint-disable-next-line sort-keys
        clear: () => {},
        fetch: () => Promise.resolve([]),
        fetchNewNotifications: () => Promise.resolve([]),
        fetchNotificationPreferences: () => Promise.resolve(null),
        generateNotification: () => {},
        list: [],
        markAllAsRead: () => Promise.resolve({} as any),
        triggerMessages: () => Promise.resolve({} as any),
        updateNotification: () => Promise.resolve({} as any),
        updateNotificationPreferences: () => Promise.resolve({} as any),
    },
});

export function useNotifications() {
    const { notifications } = useContext(NotificationsContext);

    return notifications;
}

export default NotificationsContext;
