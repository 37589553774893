import { bool } from 'prop-types';
import { useState } from 'react';

import styles from './LthrDisplay.module.scss';
import LthrModal from './lthrModal';

import Translate from '../../../../../components/translate';
import { toLocaleString } from '../../../../../constants';
import { useNexus } from '../../../../../providers/nexus/NexusContext';

const DEFAULT_LTHR = 170;

interface LthrDisplayProps {
    disableEdit?: boolean;
}

const LthrDisplay = ({ disableEdit = false }: LthrDisplayProps) => {
    const [showLthrModal, setShowLthrModal] = useState(false);
    const { nexusUserProfile } = useNexus();

    const lthr = nexusUserProfile ? nexusUserProfile.lactate_threshold : DEFAULT_LTHR;

    return (
        <div className={styles.container}>
            <LthrModal
                onCancel={() => setShowLthrModal(!showLthrModal)}
                onSave={() => setShowLthrModal(!showLthrModal)}
                open={showLthrModal}
            />
            <span>
                <Translate
                    params={{
                        value: (
                            <button
                                className={styles.lthrValue}
                                disabled={disableEdit}
                                onClick={() => setShowLthrModal(!showLthrModal)}
                                type="button"
                            >
                                <Translate
                                    params={{
                                        LTHR: (
                                            Number.isFinite(lthr)
                                                ? toLocaleString(lthr)
                                                : '--'
                                        ),
                                    }}
                                >
                                    BASED_ON_MY_LTHR
                                </Translate>
                                &nbsp;
                                <Translate>UNITS_BPM</Translate>
                            </button>
                        ),
                    }}
                >
                    BASED_ON
                </Translate>
            </span>
        </div>
    );
};

export default LthrDisplay;
