import React from 'react';
import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';
// JQuery only included for bootstrap. Do not use jquery.
import 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

import 'd3-charts-react/dist/index.css';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';

import './index.scss';
import App from './App';

const root = createRoot(document.getElementById('root'));
Modal.setAppElement('#root');
root.render(<App />)
