import { createContext, useContext } from 'react';
import { AuthProps } from './types.d';

// Create's authentication context to be use anywhere in the app
const AuthContext = createContext<AuthProps>({
    auth: {
        // State
        accessToken: '',
        expiresAt: 0,
        isFetchingAuth0Profile: false,
        isLoggingIn: false,
        nexusLinkedIds: [],
        userProfile: null,
        userSessionError: null,
        // Functions
        // eslint-disable-next-line sort-keys
        fetchAccessToken: () => {},
        fetchAuth0Profile: () => {},
        fetchWaffleJS: () => {},
        handleAuthentication: () => {},
        handleServiceLinked: () => {},
        isAuthenticated: () => false,
        isEmailVerified: () => {},
        isFlagActive: () => false,
        linkService: () => {},
        login: () => {},
        logout: () => {},
        requestAccountDelete: () => {},
        requestEmailChange: () => {},
        requestPasswordChange: () => {},
        resendVerificationEmail: () => false,
    },
});

export function useAuth() {
    const { auth } = useContext(AuthContext);

    return auth;
}

export default AuthContext;
