import React from 'react';

import styles from './PairingFinePrint.module.scss';

import Translate from '../../components/translate';

import { BLUE_MILD } from '../../constants';

// eslint-disable-next-line max-len
const BIKE_COMPUTERS_GARMIN = 'EDGE 1000, 1030, 1030 PLUS, 830, 820 PLUS, 820, 530, 520, 520 PLUS, KAROO 2, ELEMNT ROAM, SUPER PRO GPS, iGS620, iGS618, ROX 12.0 Sport, Mega XL GPS, COACHSMART, ROX GPS 11.0, PROSHIFT';
const BIKE_COMPUTERS_HAMMERHEAD = 'Karoo 2';
const BIKE_COMPUTERS_WAHOO = 'ELEMNT, ELEMNT Roam, ELEMNT Bolt';
// eslint-disable-next-line max-len
const BIKE_WATCHES_GARMIN = 'FENIX (5/5S/5X, 5/5S/5X PLUS, 6/6S/6S/6X Pro), FORERUNNER 935XT, 945 LTE, 745, ENDURO, DESCENT MK2/MK2i, TACTIX DELTA, MARQ SERIES, QUATIX (5, SAPPHIRE), TACTIX CHARLIE, DESCENT MK1, D2 CHARLIE';

interface PairingFinePrintProps {
    className?: string;
}

const PairingFinePrint = ({ className }: PairingFinePrintProps) => (
    <div className={`${styles.container} ${className}`}>
        <div>
            <Translate>TUTORIAL_PAIR_FOOTNOTE_ONE</Translate>
        </div>
        <div className={styles.point}>
            <Translate
                params={{
                    computers: (
                        <span className={styles.compatibleList}>
                            {BIKE_COMPUTERS_HAMMERHEAD}
                        </span>
                    ),
                }}
            >
                TUTORIAL_PAIR_FOOTNOTE_ONE_COMPUTERS_HAMMERHEAD
            </Translate>
        </div>
        <div className={styles.point}>
            <Translate
                params={{
                    computers: (
                        <span className={styles.compatibleList}>
                            {BIKE_COMPUTERS_GARMIN}
                        </span>
                    ),
                }}
            >
                TUTORIAL_PAIR_FOOTNOTE_ONE_COMPUTERS_GARMIN
            </Translate>
            &nbsp;
            <a href="https://www.thisisant.com/directory/filter/~/~/~/276/" rel="noreferrer" target="_blank">
                <Translate>SOURCE</Translate>
            </a>
        </div>
        <div className={styles.point}>
            <Translate
                params={{
                    watches: (
                        <span className={styles.compatibleList}>
                            {BIKE_WATCHES_GARMIN}
                        </span>
                    ),
                }}
            >
                TUTORIAL_PAIR_FOOTNOTE_ONE_WATCHES_GARMIN
            </Translate>
            &nbsp;
            <a href="https://www.thisisant.com/directory/filter/~/~/~/276/" rel="noreferrer" target="_blank">
                <Translate>SOURCE</Translate>
            </a>
        </div>
        <div className={styles.point}>
            <Translate
                params={{
                    computers: (
                        <span className={styles.compatibleList}>
                            {BIKE_COMPUTERS_WAHOO}
                        </span>
                    ),
                }}
            >
                TUTORIAL_PAIR_FOOTNOTE_ONE_COMPUTERS_WAHOO
            </Translate>
        </div>
        <br />
        <div>
            <Translate>
                TUTORIAL_PAIR_FOOTNOTE_TWO
            </Translate>
        </div>
    </div>
);

export default PairingFinePrint;
