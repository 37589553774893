import styles from './Releases.module.scss';

import { formatText } from '../helper';

import { iconAlphaFirmware, iconBetaFirmware, iconEngineeringFirmware } from '../../../assets';
import Spinner from '../../../components/spinner';
import Translate from '../../../components/translate';

interface ReleasesProps { firmwareVersions: any, loadingFirmwareVersions: boolean, isPowerTrainSystem?: boolean }

type FirmwareIcons = {
    [key: number]: string;
};

const FIRMWARE_ICON: FirmwareIcons = {
    1: iconBetaFirmware,
    2: iconAlphaFirmware,
    3: iconEngineeringFirmware,
};

const Releases = ({ firmwareVersions, loadingFirmwareVersions, isPowerTrainSystem = false }: ReleasesProps) => {
    if (loadingFirmwareVersions) return <Spinner />;

    if (!firmwareVersions.length) {
        return (
            <div className={styles.content}>
                <div className={styles.header}>
                    <Translate>NO_RELEASE_NOTES</Translate>
                </div>
            </div>
        );
    }

    if (isPowerTrainSystem) {
        return firmwareVersions.map(({
            beta, bts, release_notes, version,
        }: any) => (
            <div className={styles.powerTrainSystemContainer} key={version}>
                <div className={styles.header}>
                    <Translate>FIRMWARE_VERSION</Translate>
                    &nbsp;
                    {`(${version})`}
                    &nbsp;
                    {beta in FIRMWARE_ICON && <img alt="" src={FIRMWARE_ICON[beta]} />}
                </div>
                <div className={styles.componentsContainer}>
                    <div className={styles.componentsHeader}>
                        <Translate>COMPONENTS</Translate>
                    </div>
                    {bts.map((component: any, idx: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                        <div key={idx}>
                            <div className={styles.component}>
                                <Translate>{component.name}</Translate>
                                 &nbsp;
                                {`(${component.version})`}
                            </div>
                        </div>
                    ))}
                </div>
                <div>
                    <div className={styles.releaseNotes}>
                        {formatText(release_notes) || <Translate>NO_RELEASE_NOTES</Translate>}
                    </div>
                </div>
            </div>
        ));
    }

    return firmwareVersions.map(({ beta, models, version }: any) => (
        <div className={styles.content} key={version}>
            <div className={styles.header}>
                <Translate>FIRMWARE_VERSION</Translate>
                &nbsp;
                {`(${version})`}
                &nbsp;
                {beta in FIRMWARE_ICON && <img alt="" src={FIRMWARE_ICON[beta]} />}
            </div>
            {models.map(({ modelList, releaseNotes }: any, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className={styles.componentsContainer}>
                    <div className={styles.componentsHeader}>
                        <Translate>COMPONENTS</Translate>
                    </div>
                    <div className={styles.devicesContainer}>
                        {modelList
                            .sort((a: any, b: any) => a.mobile_display_name_key.localeCompare(b.mobile_display_name_key))
                            .map((device: any) => (
                                <div className={styles.deviceContainer} key={device.model_id}>
                                    <img alt="" className={styles.icon} src={device.mobile_display_icon_url} />
                                    <div className={styles.deviceDescriptionContainer}>
                                        <div className={styles.deviceName}>
                                            {device.mobile_display_name_key && device.mobile_display_name_key.toLowerCase()}
                                        </div>
                                        <div className={styles.deviceCode}>
                                            {device.model_code}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className={styles.releaseNotes}>
                        {formatText(releaseNotes) || <Translate>NO_RELEASE_NOTES</Translate>}
                    </div>
                </div>
            ))}
        </div>
    ));
};

export default Releases;
