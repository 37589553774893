import React, { ReactNode } from 'react';

import styles from './Statistic.module.scss';

import Translate from '../../../components/translate';

interface StatisticsProps {
    children: ReactNode,
    label: string,
    units?: string,
 }

const Statistic = ({ children, label, units }: StatisticsProps) => {
    if (!children) {
        return null;
    }

    return (
        <tr className={styles.row}>
            <td className={`${styles.column} ${styles.label}`}>
                <Translate>{label}</Translate>
            </td>
            <td className={styles.column}>
                {children}
                &nbsp;
                <Translate>{units}</Translate>
            </td>
        </tr>
    );
};

export default Statistic;
