import React, { useEffect, useRef, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FadeLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import { useActivities, useAuth, useNexus } from '../../../providers';
import {
    makeCancellable,
    SERVICE_APPLE,
    SERVICE_GARMIN_CONNECT,
    SERVICE_GOOGLE,
    SERVICE_WAHOO,
} from '../../../constants';
import MobileContainer from '../../../components/mobileContainer';
import Translate from '../../../components/translate';
import styles from './externalServices.module.scss';
import Logger from '../../../Logger';
import Spinner from '../../../components/spinner';
import ConfirmationModal from '../../mobileUpdateAccount/modals/ConfirmationModal';

const ExternalServices = () => {
    const auth = useAuth();
    const nexus = useNexus();
    const navigate = useNavigate();
    const { triggerWahooSync, triggerGarminSync } = useActivities();
    const { nexusUserProfile, nexusLinkedIds, triggerNexusIdsSync } = nexus;

    const linkedServiceType = sessionStorage.getItem('linkedServiceType');
    sessionStorage.removeItem('linkedServiceType');
    const [isTogglingService, setIsTogglingService] = useState<boolean>(false);
    const [justLinkedAccountService, setJustLinkedAccountService] = useState(linkedServiceType);
    const [linkServiceErrorMessage, setLinkServiceErrorMessage] = useState< string | boolean | null>(null);

    const linkServiceRequests = useRef(new Map());
    const nexusServiceUnlinkRequests = useRef(new Map());

    useEffect(() => {
        if (!justLinkedAccountService) {
            triggerNexusIdsSync();
        }
    }, []);

    useEffect(() => {
        if (justLinkedAccountService === SERVICE_WAHOO) {
            triggerWahooSync();
        }

        if (justLinkedAccountService === SERVICE_GARMIN_CONNECT) {
            triggerGarminSync();
        }

        return () => {
            linkServiceRequests.current.forEach((request) => request.cancel());
            linkServiceRequests.current.clear();

            nexusServiceUnlinkRequests.current.forEach((request) => request.cancel());
            nexusServiceUnlinkRequests.current.clear();
        };
    }, [nexusLinkedIds]);


    async function toggleLinkedServiceNexus(inService: string) {
        const currentRequest = nexusServiceUnlinkRequests.current.get(inService);
        if (currentRequest) return;

        const linkedService = nexus.nexusLinkedIds.find(({ service }: { service: string }) => service === inService);
        setIsTogglingService(true);

        try {
            if (!linkedService) {
            const fetchRequest = makeCancellable(auth.linkService(inService, "/mobile/riderportal"));
            linkServiceRequests.current.set(inService, fetchRequest);

            const isSuccess = await fetchRequest.promise;

            linkServiceRequests.current.delete(inService);

            if (isSuccess === null) {
                setLinkServiceErrorMessage("LINK_SERVICES_BROWSER_ERROR");
                return;
            }

            if (!isSuccess) {
                setLinkServiceErrorMessage("LINK_SERVICES_ERROR");
            }

            return;
        }

            const fetchRequest = makeCancellable(nexus.unlinkNexusService(linkedService.id));

            nexusServiceUnlinkRequests.current.set(inService, fetchRequest);

            await fetchRequest.promise;
        } catch (error: any) {
            if (!error.isCancelled) {
            setLinkServiceErrorMessage("LINK_SERVICES_ERROR");
            Logger.warn(error);
            }
        } finally {
            setIsTogglingService(false);
        }
    }


    const handleLinkAccountClick = (serviceID: string) => {
        switch (serviceID) {
            case 'HAMMER_HEAD':
                window.open('https://dashboard.hammerhead.io/accounts', '_blank');
                return;
            case 'MOBILE_HOW_TO_CONNECT':
                window.open('https://www.sram.com/en/sram/road/campaigns/pairing-your-bike-computer-with-SRAM-etap-axs-components', '_blank');
                return;
            default:
                toggleLinkedServiceNexus(serviceID);
        }
    };

    const activityImportApps = [
        {
            disabled: false,
            serviceID: 'MOBILE_HOW_TO_CONNECT',
            title: 'MOBILE_HOW_TO_CONNECT',
            url: 'https://www.sram.com/en/sram/road/campaigns/pairing-your-bike-computer-with-SRAM-etap-axs-components',
            value: '',
        },
        {
            disabled: false,
            serviceID: 'HAMMER_HEAD',
            title: 'HAMMER_HEAD',
            url: 'https://dashboard.hammerhead.io/accounts',
            value: 'VISIT_WEBSITE',
        },
        {
            disabled: false,
            serviceID: SERVICE_GARMIN_CONNECT,
            title: 'GARMIN_CONNECT',
            url: '/update-account',
            value: null,
        },
        {
            disabled: false,
            serviceID: SERVICE_WAHOO,
            title: 'WAHOO',
            url: '/update-account',
            value: null,
        },
    ];

    const socials = [
        {
            serviceID: SERVICE_GOOGLE,
            title: 'GOOGLE',
            url: '/update-account',
            value: null,
        },
        {
            serviceID: SERVICE_APPLE,
            title: 'APPLE',
            url: '/update-account',
            value: null,
        },
    ];

    const renderAccountRow = ({ title, serviceID, value }: {title:string, serviceID: string, value: string | null }) => {
        const isLinked = nexusLinkedIds.find(({ service }: { service: string }) => (
            service === serviceID
        ));
        return (
            <>
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => handleLinkAccountClick(serviceID)}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            handleLinkAccountClick(serviceID);
                        }
                    }}
                    className={styles.row}
                >
                    <Translate>{title}</Translate>

                    <div className={styles.rowEnd}>
                        {value === null && (
                            <div className={styles.value}>
                                {(isLinked
                                    ? <Translate>DISCONNECT</Translate>
                                    : <Translate>CONNECT</Translate>
                                )}
                            </div>
                        )}
                        {value && (
                            <div className={styles.value}>
                                <Translate>{value}</Translate>
                            </div>
                        )}
                        <div>
                            <ArrowForwardIosIcon />
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const renderSuccessModal = () => (
        <ConfirmationModal
            open={!!justLinkedAccountService}
            header="SUCCESS"
            msg="ACCOUNT_LINK_NORMAL"
            option1={{
                onClick: () => {setJustLinkedAccountService(null)},
                text: 'OK',
            }}
            subheader=""
        />
    );

    const renderErrorModal = () => (
        <ConfirmationModal
            open={linkServiceErrorMessage !== null}
            header="ERROR"
            msg={linkServiceErrorMessage?.toString() || 'LINK_SERVICES_ERROR'}
            option1={{
                onClick: () => {
                    setLinkServiceErrorMessage(null);
                },
                text: 'OK',
            }}
            subheader=""
        />
    );

    return (
        <>
            <Spinner loading={isTogglingService} Component={FadeLoader} margin={-12} height={6} width={2} />
            <MobileContainer className={styles.section}>
                <div className={styles.sectionTitle}>
                    <Translate>MOBILE_SOCIALS</Translate>
                </div>
                <div className={styles.rowDesc}>
                    <Translate>MOBILE_SOCIALS_LOGIN_DESC</Translate>
                </div>
                {socials.map((item) => renderAccountRow(item))}
                <div />
            </MobileContainer>
            <MobileContainer className={styles.section}>
                <div className={styles.sectionTitle}>
                    <Translate>MOBILE_ACTIVITY_IMPORT_APPS</Translate>
                </div>
                <div className={styles.rowDesc}>
                    <Translate>MOBILE_ACTIVITY_IMPORT_APPS_DESC</Translate>
                </div>
                {activityImportApps.map((item) => renderAccountRow(item))}
                <div />
            </MobileContainer>
            {renderSuccessModal()}
            {renderErrorModal()}
        </>
    );
};

export default ExternalServices;
