import { lazy, Suspense, useState } from 'react';

import styles from './PowerPlaceholder.module.scss';

import FtpModal from '../thresholdDisplay/ftpDisplay/ftpModal';

import { iconPowerZones } from '../../../../assets';
import Placeholder from '../../../../components/placeholder';
import Spinner from '../../../../components/spinner';
import Translate from '../../../../components/translate';

const PowerExample = lazy(() => import('./powerExample'));

interface PowerExampleProps {
    powerZones: {min: number, max: number}[];
}

const PowerPlaceholder = ({ powerZones }: PowerExampleProps) => {
    const [header, setHeader] = useState('');
    const [showFtpModal, setShowFtpModal] = useState(false);

    const renderExample = () => (
        <Suspense fallback={<Spinner />}>
            <PowerExample
                onCarouselItemChange={(value) => setHeader(value)}
                powerZones={powerZones}
            />
        </Suspense>
    );

    return (
        <>
            <FtpModal
                onCancel={() => setShowFtpModal(!showFtpModal)}
                onSave={() => setShowFtpModal(!showFtpModal)}
                open={showFtpModal}
            />
            <Placeholder
                header={header}
                imageSrc={iconPowerZones}
                renderExample={() => renderExample()}
                subtitle="POWER_NO_DATA"
            >
                <Translate
                    params={{
                        ftpLink: (
                            <button
                                className={styles.modalButton}
                                onClick={() => setShowFtpModal(!showFtpModal)}
                                type="button"
                            >
                                FTP
                            </button>
                        ),
                    }}
                >
                    POWER_NO_DATA_INFO
                </Translate>
            </Placeholder>
        </>
    );
};

export default PowerPlaceholder;
