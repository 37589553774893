import React from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

import styles from './BikeComponentStatistics.module.scss';
import Statistic from './statistic';

import Translate from '../../components/translate';

import {
    iconBatteryCritical,
    iconBatteryGood,
    iconBatteryLow,
    iconBatteryUnknown,
} from '../../assets';
import { roundValueBy, toLocaleString } from '../../constants';
import { useUnits } from '../../providers/units/UnitsContext';

function sanitizeValue(value: number) {
    if (!Number.isFinite(value)) return null;

    return toLocaleString(value);
}

interface BikeComponentStatisticsProps {
    bikeComponent: any,
    componentBleServices: [],
 }

const BikeComponentStatistics = ({ bikeComponent, componentBleServices }: BikeComponentStatisticsProps) => {
    const { convertPressureFromSI, formatPressure, getLabelPressure } = useUnits();

    function getService(serviceName: string) {
        let service: any = null;

        switch (serviceName) {
            case 'sram_usage_status':
                service = componentBleServices.find(
                    (bleService: any) => bleService.service_name === 'sram_usage_status',
                );
                break;
            case 'sram_battery_status':
                service = componentBleServices.find(
                    (bleService: any) => bleService.service_name === 'sram_battery_status',
                );
                break;
            case 'sram_version':
                service = componentBleServices.find(
                    (bleService: any) => bleService.service_name === 'sram_version',
                );
                break;
            case 'kilo_config':
                service = componentBleServices.find(
                    (bleService: any) => bleService.service_name === 'kilo_config',
                );
                break;
            case 'pressure_config':
                service = componentBleServices.find(
                    (bleService: any) => bleService.service_name === 'pressure_config',
                );
                break;
            case 'drivetrain_config':
                service = componentBleServices.find(
                    (bleService: any) => bleService.service_name === 'drivetrain_config',
                );
                break;
            case 'drivetrain_status':
                service = componentBleServices.find(
                    (bleService: any) => bleService.service_name === 'drivetrain_status',
                );
                break;
            case 'enhanced_mode':
                service = componentBleServices.find(
                    (bleService: any) => bleService.service_name === 'enhanced_mode',
                );
                break;
            default:
                service = null;
                break;
        }
        return service;
    }

    function getMode(service: any) {
        let mode = null;
        switch (service.mode) {
            case 1:
                mode = 'Compensating';
                break;
            case 2:
                mode = 'Sequential';
                break;
            default:
                break;
        }
        return mode;
    }

    function getSequence(service: any) {
        let sequence = null;
        switch (service.sequence) {
            case 7: {
                sequence = '10-28T';
                break;
            }
            case 10: {
                sequence = '10-33T';
                break;
            }
            case 11: {
                sequence = '10-36T';
                break;
            }
            case 12: {
                sequence = '10-30T';
                break;
            }
            default: {
                sequence = 'Unknown';
                break;
            }
        }
        return sequence;
    }

    const renderNoStatistics = () => (
        <div className="flex" style={{ justifyContent: 'center' }}>
            <Translate>COMPONENT_STATISTICS_NONE</Translate>
        </div>
    );

    const renderActuations = () => {
        // eslint-disable-next-line camelcase
        const service = getService('sram_usage_status');

        if (!service || !service.actuation_count) return null;

        if (!Number.isFinite(service.actuation_count)
            || service.actuation_count <= 0
        ) return null;

        return (
            <Statistic label="ACTUATION_COUNT">
                {sanitizeValue(service.actuation_count)}
            </Statistic>
        );
    };

    const renderBatteryStatus = () => {
        const service = getService('sram_battery_status');

        if (!service || !service.soc) return null;

        if (!Number.isFinite(service.soc)) return null;

        let batteryStatusImageElement = null;
        switch (service.soc) {
            case 1: {
                batteryStatusImageElement = (
                    <img
                        alt=""
                        className={styles.batteryIcon}
                        src={iconBatteryCritical}
                    />
                );
                break;
            }
            case 2: {
                batteryStatusImageElement = (<img alt="" className={styles.batteryIcon} src={iconBatteryLow} />);
                break;
            }
            case 3: {
                batteryStatusImageElement = (
                    <img
                        alt=""
                        className={styles.batteryIcon}
                        src={iconBatteryGood}
                    />
                );
                break;
            }
            default: {
                batteryStatusImageElement = (
                    <img
                        alt=""
                        className={styles.batteryIcon}
                        src={iconBatteryUnknown}
                    />
                );
                break;
            }
        }

        return (
            <Statistic label="BATTERY_STATUS">
                {batteryStatusImageElement}
            </Statistic>
        );
    };

    const renderBatteryVolts = () => {
        const service = getService('sram_battery_status');

        if (!service || !service.voltage) return null;

        return (
            <Statistic label="VOLTAGE" units="UNITS_VOLTS_SHORT">
                {service.voltage.toFixed(2)}
            </Statistic>
        );
    };

    const renderLastUpdatedSource = () => {
        const updatedTs = Math.max(...componentBleServices.map((service: any) => service.updated_ts));

        if (!updatedTs) return null;

        return (
            <Statistic label="BIKE_COMPONENT_LAST_UPDATED">
                <Moment format="MMM Do YYYY, LT" unix>
                    {updatedTs}
                </Moment>
            </Statistic>
        );
    };

    const renderFirmwareVersion = () => {
        const service = getService('sram_version');

        if (!service || !service.app) return null;

        let releaseNotesLink = '/releasenotes';
        if (bikeComponent.device_type) {
            releaseNotesLink = releaseNotesLink.concat(`/${bikeComponent.device_type}`);
        }

        // If powermeter
        if (bikeComponent.device_type === 301) {
            // Get first three chars of model_code
            switch (bikeComponent.model_code.substr(0, 3)) {
                // Is PowerMeter
                case 'PM-':
                    releaseNotesLink = releaseNotesLink.concat('/sram_powermeters');
                    break;
                // Is Hub
                case 'HU-':
                    releaseNotesLink = releaseNotesLink.concat('/powertap_hub');
                    break;
                // Is Pedal
                case 'PD-':
                    releaseNotesLink = releaseNotesLink.concat('/powertap_pedal');
                    break;
                default:
            }
        }

        return (
            <Statistic label="FIRMWARE_VERSION">
                <Link to={releaseNotesLink}>
                    {service.app}
                </Link>
            </Statistic>
        );
    };

    const renderEnergyAccumulator = () => {
        const service = getService('kilo_config');

        if (!service || !service.lifetime_kilojoules) return null;

        if (
            !Number.isFinite(service.lifetime_kilojoules)
            || service.lifetime_kilojoules <= 0
        ) {
            return null;
        }

        return (
            <Statistic label="LIFETIME_KILOJOULES" units="UNITS_KJ">
                {sanitizeValue(service.lifetime_kilojoules)}
            </Statistic>
        );
    };

    const renderRevolutionsAccumulator = () => {
        const service = getService('kilo_config');

        if (!service || !service.lifetime_revolutions) return null;

        if (!Number.isFinite(service.lifetime_revolutions)
            || service.lifetime_revolutions <= 0
        ) {
            return null;
        }

        return (
            <Statistic label="LIFETIME_REVOLUTIONS">
                {sanitizeValue(service.lifetime_revolutions)}
            </Statistic>
        );
    };

    const renderSecondsAccumulator = () => {
        const service = getService('sram_usage_status');

        if (!service || !service.runtime) return null;

        return (
            <Statistic label="OPERATING_TIME">
                {toLocaleString(
                    roundValueBy(service.runtime / 3600, 1),
                )}
                &nbsp;
                <Translate>HOURS</Translate>
            </Statistic>
        );
    };

    const renderTyrewiz = () => {
        const service = getService('pressure_config');

        if (!service || (!service.low_pressure && !service.high_pressure)) return null;

        const { shorthand } = getLabelPressure();
        let lowPressure = null;
        let highPressure = null;

        if (Number.isFinite(service.low_pressure)) {
            lowPressure = sanitizeValue(
                formatPressure(convertPressureFromSI(service.low_pressure)),
            );
        }

        if (Number.isFinite(service.high_pressure)) {
            highPressure = sanitizeValue(
                formatPressure(convertPressureFromSI(service.high_pressure)),
            );
        }

        return (
            <>
                <Statistic label="TIRE_PRESSURE_LOW_ALERT" units={shorthand}>
                    {lowPressure}
                </Statistic>
                <Statistic label="TIRE_PRESSURE_HIGH_ALERT" units={shorthand}>
                    {highPressure}
                </Statistic>
            </>
        );
    };

    const renderAvailableTrimPosition = () => {
        const service = getService('drivetrain_config');

        if (!service || !service.rd_num_trim) return null;

        if (!Number.isFinite(service.rd_num_trim)
            || service.rd_num_trim <= 0
        ) return null;

        return (
            <Statistic label="AVAILABLE_TRIM_POSITIONS">
                {sanitizeValue(service.rd_num_trim)}
            </Statistic>
        );
    };

    const renderCurrentTrimPosition = () => {
        const service = getService('drivetrain_status');

        if (!service || !service.rd_trim) return null;

        if (!Number.isFinite(service.rd_trim)
        || service.rd_trim <= 0
        ) return null;

        return (
            <Statistic label="CURRENT_TRIM_POSITION">
                {sanitizeValue(service.rd_trim)}
            </Statistic>
        );
    };

    const renderEnhancedModeInfo = () => {
        const service = getService('enhanced_mode');

        if (!service) return null;

        const mode = getMode(service);
        const sequence = getSequence(service);

        return (
            <>
                {service.enabled !== null && (
                    <Statistic label="ENABLED">
                        {service.enabled ? 'On' : 'Off'}
                    </Statistic>
                )}

                {service.mode !== null && (
                    <Statistic label="MODE">
                        {mode}
                    </Statistic>
                )}

                {(service.enabled && service.sequence) && (
                    <Statistic label="SEQUENCE">
                        {sequence}
                    </Statistic>
                )}

                {(service.enabled && Number.isFinite(service.compensating_size)) && (
                    <Statistic label="COMPENSATING_SIZE">
                        {sanitizeValue(service.compensating_size)}
                    </Statistic>
                )}
            </>
        );
    };

    if (!componentBleServices.length) {
        return renderNoStatistics();
    }

    const statisticsElements = [
        renderActuations(),
        renderBatteryStatus(),
        renderBatteryVolts(),
        renderFirmwareVersion(),
        renderEnergyAccumulator(),
        renderRevolutionsAccumulator(),
        // Until we fix our saved operating time in Nexus
        // renderSecondsAccumulator(),
        renderTyrewiz(),
        renderLastUpdatedSource(),
        renderAvailableTrimPosition(),
        renderCurrentTrimPosition(),
        renderEnhancedModeInfo(),
    ];

    // Check to see if any of the renderable statistics exist
    if (statisticsElements.every((element) => !element)) {
        return renderNoStatistics();
    }

    return (
        <table className={styles.statTable}>
            <tbody>
                {statisticsElements.map((element, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={index}>
                        {element}
                    </React.Fragment>
                ))}
            </tbody>
        </table>
    );
};

export default BikeComponentStatistics;
