import { useEffect, useRef } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

import styles from './EmailVerificationPage.module.scss';
import HandleEmailVerificationRedirect from './handleEmailVerificationRedirect';

import { iconDelete, logoSramWhite } from '../../assets';
import Spinner from '../../components/spinner';
import Translate from '../../components/translate';
import {
    DEEP_LINK_ARMY_KNIFE_ANDROID,
    DEEP_LINK_ARMY_KNIFE_IOS,
    DEEP_LINK_PRODUCT_REGISTRATION,
    DEEP_LINK_SHOCKWIZ,
    DEEP_LINK_SRAM_COM,
    DEEP_LINK_TRAILHEAD,
    makeCancellable,
    RequestType,
} from '../../constants';
import { useSetState } from '../../hooks';
import Logger from '../../Logger';
import { useAuth } from '../../providers';
import ErrorModal from '../../views/errorModal';

const ALLOWED_REDIRECTS = ['product_registration', 'sram_axs', 'shockwiz', 'sram.com', 'rockshox'];

const deepLinkUrl: any = {
    // eslint-disable-next-line camelcase
    product_registration: DEEP_LINK_PRODUCT_REGISTRATION,
    shockwiz: DEEP_LINK_SHOCKWIZ,
    'sram.com': DEEP_LINK_SRAM_COM,
    rockshox: DEEP_LINK_TRAILHEAD,
    sram_axs: isIOS ? DEEP_LINK_ARMY_KNIFE_IOS : DEEP_LINK_ARMY_KNIFE_ANDROID,
};

const DEFAULT_STATE = {
    emailSentSuccessfully: false,
    error: null,
    isSendingEmail: false,
    showRedirect: false,
};

function EmailVerificationPage() {
    const [state, setState] = useSetState(DEFAULT_STATE);
    const resendVerificationEmailRequest = useRef<RequestType | null>(null);
    const timeoutId = useRef<NodeJS.Timeout | null>(null);
    const auth = useAuth();
    const location = useLocation();

    const {
        emailSentSuccessfully,
        error,
        isSendingEmail,
        showRedirect,
    } = state;

    const getRedirectLink = () => {
        const searchParams = new URLSearchParams(location.search);
        const redirect = searchParams.get('redirect');

        // redirect parameter must be on approved list
        if (redirect && ALLOWED_REDIRECTS.includes(redirect.toLowerCase())) {
            return deepLinkUrl[redirect.toLowerCase()];
        }

        return null;
    };

    const resendVerificationEmail = async () => {
        const searchParams = new URLSearchParams(location.search);
        const email = searchParams.get('email');

        if (!email) return;

        if (resendVerificationEmailRequest.current) {
            try {
                await resendVerificationEmailRequest.current.promise;

                return;
            } catch (err) {
                return;
            }
        }

        setState({ emailSentSuccessfully: false, isSendingEmail: true });

        resendVerificationEmailRequest.current = makeCancellable(auth.resendVerificationEmail(email));

        try {
            const isSuccess = await resendVerificationEmailRequest.current.promise;
            resendVerificationEmailRequest.current = null;

            if (isSuccess) {
                setState({ emailSentSuccessfully: isSuccess, isSendingEmail: false });
            } else {
                setState({ error: new Error('EMAIL_VERIFICATION_ERROR'), isSendingEmail: false });
            }
        } catch (err: any) {
            if (!err.isCancelled) {
                Logger.warn('Error resending email verification', err);
                setState({ error: new Error('EMAIL_VERIFICATION_ERROR'), isSendingEmail: false });
                resendVerificationEmailRequest.current = null;
            }
        }
    };

    const initiateRedirect = () => {
        const redirectLink = getRedirectLink();

        // if mobile and has valid redirect link try to launch mobile app
        if (redirectLink) {
            window.location.replace(redirectLink);
        }

        timeoutId.current = setTimeout(
            () => {
                timeoutId.current = null;
                setState({ showRedirect: true });
            },
            5000,
        );
    };

    const isSuccessful = () => {
        const searchParams = new URLSearchParams(location.search);
        const success = searchParams.get('success');

        if (searchParams.get('error')
            || !searchParams.get('success')
            || (!success)
            || (success.toLowerCase() !== 'true')) return false;

        return true;
    };

    const toLogin = () => {
        const redirectLink = getRedirectLink();

        // if mobile and has valid redirect link try to launch mobile app
        if (isMobile && redirectLink) {
            window.location.replace(redirectLink);
            setTimeout(() => window.location.replace('/logout'), 3000);
        } else {
            window.location.replace('/logout');
        }
    };

    const renderDescription = () => {
        const searchParams = new URLSearchParams(location.search);
        const errorMsg = searchParams.get('error');

        if (emailSentSuccessfully) {
            return <Translate>EMAIL_VERIFICATION_CHECK</Translate>;
        }

        if (errorMsg) {
            return (errorMsg.toLowerCase() === 'expired')
                ? <Translate>EMAIL_VERIFICATION_EXPIRED_DESCRIPTION</Translate>
                : <Translate>EMAIL_VERIFICATION_INVALID_DESCRIPTION</Translate>;
        }

        if (!isSuccessful()) {
            return <Translate>EMAIL_VERIFICATION_UNSUCCESSFUL_DESCRIPTION</Translate>;
        }

        return <Translate>EMAIL_VERIFICATION_SUCCESSFUL_DESCRIPTION</Translate>;
    };

    const renderInstructions = () => {
        if (!isSuccessful()) {
            return (
                <button
                    className={styles.resendButton}
                    id="data-test-resend-email-verification"
                    onClick={() => resendVerificationEmail()}
                    type="button"
                >
                    <Translate>EMAIL_VERIFICATION_RESEND</Translate>
                </button>
            );
        }

        return (
            <>
                <Translate>EMAIL_VERIFICATION_REDIRECT</Translate>
                <br />
                <Translate>EMAIL_VERIFICATION_REDIRECT_IF_FAIL</Translate>
            </>
        );
    };

    const renderRedirectRoute = () => {
        const searchParams = new URLSearchParams(location.search);
        const email = searchParams.get('email');

        if (!showRedirect) return null;

        return <HandleEmailVerificationRedirect email={email} />;
    };

    const renderTick = () => {
        const searchParams = new URLSearchParams(location.search);
        const email = searchParams.get('email');

        if (emailSentSuccessfully) {
            return (
                <div className={styles.email}>
                    {email}
                </div>
            );
        }

        if (searchParams.get('error') || (!isSuccessful())) {
            return (
                <div className={styles.emailSentContainer}>
                    <img
                        alt=""
                        className={styles.emailSentErrorIcon}
                        src={iconDelete}
                    />
                </div>
            );
        }

        return (
            <div className={styles.emailSentContainer}>
                <div className={styles.emailSentSuccessIcon}>
                    &#10003;
                </div>
            </div>
        );
    };

    const renderVerificationOutcome = () => {
        const searchParams = new URLSearchParams(location.search);
        const errorMsg = searchParams.get('error');

        if (emailSentSuccessfully) {
            return <Translate>EMAIL_SENT</Translate>;
        }

        if (errorMsg) {
            switch (errorMsg.toLowerCase()) {
                case 'expired':
                    return <Translate>EMAIL_VERIFICATION_EXPIRED</Translate>;
                default:
                    return <Translate>EMAIL_VERIFICATION_INVALID</Translate>;
            }
        }

        if (!isSuccessful()) {
            return <Translate>EMAIL_VERIFICATION_UNSUCCESSFUL</Translate>;
        }

        return <Translate>EMAIL_VERIFICATION_SUCCESSFUL</Translate>;
    };

    useEffect(() => {
        if (isSuccessful()) {
            initiateRedirect();
        }

        return () => {
            if (resendVerificationEmailRequest.current) {
                resendVerificationEmailRequest.current.cancel();
                resendVerificationEmailRequest.current = null;
            }

            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
        };
    }, []);

    if (error) {
        return (
            <ErrorModal
                error={error}
                onClose={() => setState({ error: null })}
                onOverlayClick={() => setState({ error: null })}
            />
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <div className={`${styles.headerContainer}`}>
                    <div className={styles.imageContainer}>
                        <img
                            alt=""
                            className={styles.image}
                            src={logoSramWhite}
                        />
                    </div>
                    <div className={styles.title}>
                        <Translate>SRAM_AXS</Translate>
                    </div>
                </div>
                <Spinner loading={isSendingEmail} />
                {renderRedirectRoute()}
                <div className={styles.content}>
                    <div className={styles.subtitle}>
                        <div className={styles.title}>
                            {renderVerificationOutcome()}
                        </div>
                    </div>
                    {renderTick()}
                    <div className={styles.description}>
                        {renderDescription()}
                    </div>
                </div>
                <div className={styles.resendContainer}>
                    {renderInstructions()}
                </div>
                <button
                    className={styles.refreshButton}
                    onClick={() => toLogin()}
                    type="button"
                >
                    <span className={styles.refreshButtonText}>
                        <Translate>EMAIL_VERIFICATION_CONTINUE</Translate>
                    </span>
                </button>
            </div>
        </div>
    );
}

export default EmailVerificationPage;
