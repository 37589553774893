import { SnackbarProvider } from 'notistack';
import { Navigate, Route, Routes } from 'react-router-dom';

import AuthenticatedRoutesWrapper from './AuthenticatedRoutesWrapper';
import styles from './Routes.module.scss';

import Login, { AuthLoader, MobileAuthLoader } from '../components/login';
import Logout from '../components/logout';
import ScrollToTop from '../components/ScrollToTop';
import UnitsBlock from '../components/unitsBlock';
import Activity from '../pages/activity';
import Bike from '../pages/bike';
import BikeComponent from '../pages/bikeComponent';
import ChangedEmailWrapper from '../pages/changedEmailWrapper';
import EmailVerificationPage from '../pages/emailVerificationPage';
import DeleteAccountConfirmWrapper from '../pages/deleteAccountConfirmWrapper';
import ForceLogoutUserWrapper from '../pages/ForceLogoutUserWrapper';
import ReleaseNotes from '../pages/releaseNotes';
import TyrePressureGuidePage from '../pages/tyrePressureGuidePage';
import FullMountChainLengthCalculator from '../pages/fullMountChainLengthCalculator';
import Footer from '../views/footer';
import MobileTabBar from '../views/mobileTabBar';
import MobileChainLengthCalc from '../mobilePages/mobileChainLengthCalc';
import Navbar from '../views/navbar';

import Analytics from '../components/analytics';
import { useAuth } from '../providers/auth/AuthContext';
import BikeSetUp from '../mobilePages/bikeSetUp';

const BikeRackRoutes = () => {
    const { isAuthenticated, isLoggingIn } = useAuth();
    return (
        <Analytics>
            <ScrollToTop>
                <SnackbarProvider
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    autoHideDuration={10000}
                    content={(key, notification) => <div key={key} style={{ width: '100%' }}>{notification}</div>}
                    maxSnack={5}
                >
                    <div className={styles.container}>
                        <Navbar />
                        <Routes>
                            <Route element={<DeleteAccountConfirmWrapper />} path="/deleteconfirmed" />
                            <Route element={<EmailVerificationPage />} path="/emailverification" />
                            <Route
                                element={(
                                    <>
                                        <div className={styles.content}>
                                            <UnitsBlock>
                                                <ForceLogoutUserWrapper>
                                                    <ChangedEmailWrapper>
                                                        <Routes>
                                                            <Route
                                                                path="/chainlengthcalculator"
                                                                element={
                                                                    <Navigate replace to="/guides/fullmount/chain/calculator" />
                                                                }
                                                            />
                                                            <Route
                                                                path="/guides/chain/calculator"
                                                                element={
                                                                    <Navigate replace to="/guides/fullmount/chain/calculator" />
                                                                }
                                                            />
                                                            <Route
                                                                element={(
                                                                    <FullMountChainLengthCalculator />
                                                                )}
                                                                path="/guides/fullmount/chain/calculator"
                                                            />
                                                            <Route
                                                                element={(
                                                                    <MobileChainLengthCalc />
                                                                )}
                                                                path="/guides/mobile/fullmount/chain/calculator"
                                                            />
                                                            <Route
                                                                element={(
                                                                    <BikeSetUp />
                                                                )}
                                                                path="/guides/mobile/fullmount/chain/calculator/setup"
                                                            />
                                                            <Route
                                                                path="/tirepressureguide"
                                                                element={
                                                                    <Navigate replace to="/guides/tire/pressure" />
                                                                }
                                                            />
                                                            <Route
                                                                element={<TyrePressureGuidePage />}
                                                                path="/guides/tire/pressure"
                                                            />
                                                            <Route
                                                                element={<TyrePressureGuidePage />}
                                                                path="/guides/tyre/pressure"
                                                            />
                                                            {(!isLoggingIn && !isAuthenticated()) && (
                                                                <>
                                                                    <Route
                                                                        element={<Activity />}
                                                                        path="/activities/:id"
                                                                    />
                                                                    <Route
                                                                        element={<Bike />}
                                                                        path="/bikerack/:uuid"
                                                                    />
                                                                    <Route
                                                                        element={<BikeComponent />}
                                                                        path="/components/:id"
                                                                    />
                                                                </>
                                                            )}
                                                            <Route element={<ReleaseNotes />} path="/releasenotes">
                                                                <Route element={<ReleaseNotes />} path=":deviceType" />
                                                            </Route>
                                                            <Route element={<Login />} path="/login" />
                                                            <Route element={<AuthLoader />} path="/callback" />
                                                            <Route element={<MobileAuthLoader />}
                                                                path="/mobilecallback"
                                                            />
                                                            {(!isLoggingIn && !isAuthenticated()) && (
                                                                <Route element={<Navigate to="/login" />} path="*" />
                                                            )}
                                                            <Route element={<Logout />} path="/logout" />
                                                            <Route element={<AuthenticatedRoutesWrapper />} path="*" />
                                                        </Routes>
                                                    </ChangedEmailWrapper>
                                                </ForceLogoutUserWrapper>
                                            </UnitsBlock>
                                        </div>
                                        <Footer />
                                        <MobileTabBar />
                                    </>
                                )}
                                path="*"
                            />
                        </Routes>
                    </div>
                </SnackbarProvider>
            </ScrollToTop>
        </Analytics>
    );
};

export default BikeRackRoutes;
