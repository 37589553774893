import { lazy, Suspense, useState } from 'react';

import styles from './HeartRatePlaceholder.module.scss';

import LthrModal from '../thresholdDisplay/lthrDisplay/lthrModal';

import { iconHeartRateZones } from '../../../../assets';
import Placeholder from '../../../../components/placeholder';
import Spinner from '../../../../components/spinner';
import Translate from '../../../../components/translate';

const HeartRateExample = lazy(() => import('./heartRateExample'));

interface HeartRatePlaceholderProps {
    heartZones: {min: number, max: number}[];
}

const HeartRatePlaceholder = ({ heartZones }: HeartRatePlaceholderProps) => {
    const [header, setHeader] = useState('');
    const [showLthrModal, setShowLthrModal] = useState(false);

    const renderExample = () => (
        <Suspense fallback={<Spinner />}>
            <HeartRateExample
                heartZones={heartZones}
                onCarouselItemChange={(value) => setHeader(value)}
            />
        </Suspense>
    );

    return (
        <>
            <LthrModal
                onCancel={() => setShowLthrModal(!showLthrModal)}
                onSave={() => setShowLthrModal(!showLthrModal)}
                open={showLthrModal}
            />
            <Placeholder
                header={header}
                renderExample={() => renderExample()}
                imageSrc={iconHeartRateZones}
                subtitle="HEART_RATE_NO_DATA"
            >
                <Translate
                    params={{
                        lthrLink: (
                            <button
                                className={styles.modalButton}
                                onClick={() => setShowLthrModal(!showLthrModal)}
                                type="button"
                            >
                                <Translate>LTHR</Translate>
                            </button>
                        ),
                    }}
                >
                    HEART_RATE_NO_DATA_INFO
                </Translate>
            </Placeholder>
        </>
    );
};

export default HeartRatePlaceholder;
