import { useEffect, useRef, useState } from 'react';

import styles from './WeightModal.module.scss';

import Button from '../../../../../components/button';
import Modal from '../../../../../components/modal';
import Spinner from '../../../../../components/spinner';
import Translate from '../../../../../components/translate';
import { AXS_TEXT_GREY, makeCancellable, RequestType } from '../../../../../constants';
import { useNexus, useUnits } from '../../../../../providers';
import ErrorModal from '../../../../../views/errorModal';

const renderHeader = () => (
    <>
        <Translate>RIDER_WEIGHT_ENTER</Translate>
        <div className={styles.subHeader}>
            <Translate>USER_UPDATE_WARNING</Translate>
        </div>
    </>
);

interface WeightModalProps {
    id?: string,
    onCancel: () => void,
    onSave: (arg0:boolean) => void,
    open: boolean,
}

function WeightModal({
    onCancel,
    onSave,
    open,
    id = '',
}: WeightModalProps) {
    const [error, setError] = useState<Error | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [profileToUpdate, setProfileToUpdate] = useState<any>({});
    const { convertWeightFromSI, convertWeightToSI, getLabelWeight } = useUnits();
    const nexus = useNexus();

    const updateNexusUserProfileRequests = useRef(new Set<RequestType>());

    useEffect(() => (() => {
        updateNexusUserProfileRequests.current.forEach((request) => request.cancel());
        updateNexusUserProfileRequests.current.clear();
    }), []);

    function getCurrentValue(key: string) {
        if (profileToUpdate[key] != null) {
            return profileToUpdate[key];
        }

        if (nexus.nexusUserProfile && nexus.nexusUserProfile[key] != null) {
            return nexus.nexusUserProfile[key];
        }

        return '';
    }

    function stageProfileUpdate(key: string, value: number) {
        if (!key) {
            return;
        }

        const newProfileToUpdate = { ...profileToUpdate };

        if (nexus.nexusUserProfile[key] === value) {
            delete newProfileToUpdate[key];
        } else {
            newProfileToUpdate[key] = value;
        }

        setProfileToUpdate(newProfileToUpdate);
    }

    function stageNumber(key: string, value: number) {
        let newNumber = Number(value);

        if (Number.isNaN(newNumber)) {
            newNumber = 1;
        }

        stageProfileUpdate(key, newNumber);
    }

    function stageWeight(value: number) {
        let newWeight = Number(value);

        newWeight = convertWeightToSI(newWeight);
        newWeight = typeof newWeight === 'string' ? Number.parseInt(newWeight) : newWeight;

        stageNumber('weight', newWeight);
    }

    async function submitProfileUpdate() {
        // Only update the nexus profile if there has been changes
        if (Object.keys(profileToUpdate).length) {
            // updateNexusUserProfile catches all errors
            setIsFetching(true);

            const fetchRequest = makeCancellable(
                nexus.updateNexusUserProfile(profileToUpdate),
            );

            updateNexusUserProfileRequests.current.add(fetchRequest);

            try {
                const updatedNexusUserProfile = await fetchRequest.promise;

                updateNexusUserProfileRequests.current.delete(fetchRequest);

                if (!updateNexusUserProfileRequests.current.size) {
                    setIsFetching(false);
                }

                if (updatedNexusUserProfile) {
                    setProfileToUpdate({});
                    onSave(true);
                } else {
                    setError(new Error('Error Updating Weight'));
                }
            } catch (err: any) {
                if (!err.isCancelled) {
                    updateNexusUserProfileRequests.current.delete(fetchRequest);
                    setError(new Error('Error Updating Weight'));
                    if (!updateNexusUserProfileRequests.current.size) {
                        setIsFetching(false);
                    }
                }
            }
        } else {
            onCancel();
        }
    }

    function renderWeight() {
        const weight = getCurrentValue('weight');

        return convertWeightFromSI(weight).toString();
    }

    if (error) {
        return (
            <ErrorModal
                error={error}
                onClose={() => setError(null)}
                onOverlayClick={() => {
                    setError(null);
                    onCancel();
                }}
            />
        );
    }
    return (
        <Modal
            id={id}
            contentClassName={styles.container}
            contentLabel="This will apply to all activities"
            dialog
            header={renderHeader()}
            hideCloseButton
            hideImage
            isOpen={open}
            onClose={() => {
                setProfileToUpdate({});
                onCancel();
            }}
        >
            <Spinner loading={isFetching} />
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    submitProfileUpdate();
                }}
                style={{ margin: 'auto', width: '100%' }}
            >
                <div className={styles.row}>
                    <div className={styles.rowLabel}>
                        <Translate>{getLabelWeight().longhand}</Translate>
                    </div>
                    <input
                        className={styles.inputField}
                        id="weight"
                        min={1}
                        onChange={(event) => stageWeight(Number(event.target.value))}
                        placeholder="---"
                        required
                        type="number"
                        value={renderWeight()}
                    />
                </div>
            </form>
            <div className={styles.buttonsContainer}>
                <Button
                    className={styles.button}
                    color={AXS_TEXT_GREY}
                    inverse
                    onClick={onCancel}
                >
                    <Translate>CANCEL</Translate>
                </Button>
                <Button
                    className={styles.button}
                    onClick={() => submitProfileUpdate()}
                    type="button"
                >
                    <Translate>SAVE</Translate>
                </Button>
            </div>
        </Modal>
    );
}

export default WeightModal;
