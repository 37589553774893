import React from 'react';
import { Link } from 'react-router-dom';

import styles from './ActivityNavigation.module.scss';

import ContentHeaderContainer from '../../../components/contentHeaderContainer';
import Translate from '../../../components/translate';
import { Activity } from '../../../providers';

interface ActivityNavigationProps {
    activity: Activity
}

const ActivityNavigation = ({ activity }: ActivityNavigationProps) => (
    <ContentHeaderContainer>
        {activity.prev_activity_id && (
            <Link
                className={styles.link}
                to={`/activities/${activity.prev_activity_id}`}
            >
                &lt;&nbsp;
                <Translate>PREVIOUS</Translate>
            </Link>
        )}
        {activity.next_activity_id && (
            <Link
                className={styles.link}
                to={`/activities/${activity.next_activity_id}`}
            >
                <Translate>NEXT</Translate>
                &nbsp;&gt;
            </Link>
        )}
    </ContentHeaderContainer>
);

export default ActivityNavigation;
