import {
    arrayOf,
    bool,
    func,
    number,
    shape,
    string,
} from 'prop-types';

const NOTIFICATION_SHAPE = shape({
    active: bool,
    archived: bool,
    bike: string,
    bike_url: string,
    body: string,
    component: number,
    component_url: string,
    create_ts: number,
    deliver_ts: number,
    id: number,
    // limit: null
    // limit_url: null
    notification_type: string,
    notification_url: string,
    owner: string,
    owner_id: number,
    owner_url: string,
    title: string,
    url: string,
    view_ts: number,
});

const FUNCTIONS = {
    fetch: func,
    generateNotification: func,
    list: arrayOf(NOTIFICATION_SHAPE),
    markAllAsRead: func,
    triggerMessages: func,
    updateNotification: func,
};

const STATE = {
    hasMore: bool,
    isFetching: bool,
    notifications: arrayOf(NOTIFICATION_SHAPE),
    page: number,
    unreadCount: number,
};

const NOTIFICATIONS_PROP_TYPES = {
    notifications: shape({
        ...STATE,
        ...FUNCTIONS,
    }),
};

export default NOTIFICATIONS_PROP_TYPES;
export { NOTIFICATION_SHAPE };
