/* eslint-disable sort-keys */
import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import ContentContainer from '../../components/contentContainer';
import styles from './updateAccount.module.scss';
import MobileContainer from '../../components/mobileContainer';
import Translate, { useTranslation } from '../../components/translate';
import { useSetState } from '../../hooks';
import { useAuth, useNexus } from '../../providers';
import Button from '../../components/button';
import {
    makeCancellable, RequestType, SRAM_600, SRAM_RED,
} from '../../constants';
import Logger from '../../Logger';
import ConfirmationModal from './modals/ConfirmationModal';
import Spinner from '../../components/spinner';

const DEFAULT_STATE = {
    isOpenConfirmationModal: false,
    isOpenChangeEmailModal: false,
    isOpenChangePasswordModal: false,
    isOpenDeleteAccountEmailSentModal: false,
    isOpenDeleteAccountModal: false,
    isRequestingEmailChange: false,
    isRequestingPasswordChange: false,
    isRequestingDeleteAccount: false,
    newEmail: '',
    requestError: null,
    option1: {
        text: 'OK',
        onClick: () => {},
    },
};

const UpdateAccount = () => {
    const auth = useAuth();
    const nexus = useNexus();
    const location = useLocation();
    const translate = useTranslation();
    const [state, setState] = useSetState({
        ...DEFAULT_STATE,
        showEmailMissingModal: (
            location.hash === '#account_email_missing'
        || !nexus.nexusUserProfile.email
        ),
    });

    const {
        isOpenConfirmationModal,
        isOpenDeleteAccountModal,
        isRequestingDeleteAccount,
        isRequestingEmailChange,
        isRequestingPasswordChange,
        newEmail,
        option2,
        option1,
        message,
        subheader,
        header,
    } = state;
    const requestAccountDeleteRequest = useRef<RequestType | null>(null);
    const requestEmailChangeRequest = useRef<RequestType | null>(null);
    const requestPasswordChangeRequest = useRef<RequestType | null>(null);

    const resetConfirmationModalState = () => {
        setState({
            isOpenConfirmationModal: false,
            isOpenDeleteAccountModal: false,
            header: '',
            subheader: '',
            message: '',
            option1: {
                text: '',
                onClick: () => {},
            },
            option2: undefined,
            newEmail: '',
        });
    };

    async function requestEmailChange() {
        if (requestEmailChangeRequest.current) {
            try {
                await requestEmailChangeRequest.current.promise;
                return;
            } catch (error) {
                return;
            }
        }

        setState({ isRequestingEmailChange: true });

        requestEmailChangeRequest.current = makeCancellable(auth.requestEmailChange(newEmail));
        try {
            await requestEmailChangeRequest.current.promise;
            requestEmailChangeRequest.current = null;

            setState({
                isOpenConfirmationModal: true,
                isRequestingEmailChange: false,
                header: 'EMAIL_SENT',
                subheader: newEmail,
                message: 'EMAIL_SENT_VERIFY',
                option1: {
                    text: 'OK',
                    onClick: () => resetConfirmationModalState(),
                },
            });
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error Requesting Email Change', error);
                requestEmailChangeRequest.current = null;
                setState({ isRequestingEmailChange: false, requestError: error, errorHeader: 'MOBILE_ERROR_CHANGING_EMAIL' });
            }
        }
    }

    async function requestPasswordChange() {
        if (requestPasswordChangeRequest.current) {
            try {
                await requestPasswordChangeRequest.current.promise;
                return;
            } catch (error) {
                return;
            }
        }

        setState({ isRequestingPasswordChange: true });
        requestPasswordChangeRequest.current = makeCancellable(auth.requestPasswordChange());

        try {
            await requestPasswordChangeRequest.current.promise;
            requestPasswordChangeRequest.current = null;

            setState({
                isOpenConfirmationModal: true,
                isRequestingPasswordChange: false,
                header: 'EMAIL_SENT',
                subheader: nexus.nexusUserProfile.email,
                message: 'RIDER_PROFILE_PASSWORD_RESET_SUCCESS',
                option1: {
                    text: 'OK',
                    onClick: () => resetConfirmationModalState(),
                },
            });
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error Requesting Password Change', error);
                requestPasswordChangeRequest.current = null;

                setState({ isRequestingPasswordChange: false, requestError: error, errorHeader: 'ERROR_CHANGING_PASSWORD' });
            }
        }
    }

    async function requestAccountDelete() {
        if (requestAccountDeleteRequest.current) {
            try {
                await requestAccountDeleteRequest.current.promise;
                return;
            } catch (error) {
                return;
            }
        }

        setState({ isRequestingDeleteAccount: true });

        requestAccountDeleteRequest.current = makeCancellable(auth.requestAccountDelete());

        try {
            await requestAccountDeleteRequest.current.promise;
            requestAccountDeleteRequest.current = null;

            setState({
                isOpenDeleteAccountEmailSentModal: true,
                isOpenDeleteAccountModal: false,
                isRequestingDeleteAccount: false,
                isOpenConfirmationModal: true,
                header: 'EMAIL_SENT',
                subheader: nexus.nexusUserProfile.email,
                message: 'RIDER_PROFILE_ACCOUNT_DELETION_EMAIL_SENT',
                option1: {
                    text: 'OK',
                    onClick: () => resetConfirmationModalState(),
                },
                option2: undefined,
            });
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error Requesting Delete Account', error);
                requestAccountDeleteRequest.current = null;
                setState({ isRequestingDeleteAccount: false, requestError: error, errorHeader: 'MOBILE_ERROR_DELETING_ACCOUNT' });
            }
        }
    }

    const changeEmailSection = () => (
        <MobileContainer className={styles.section}>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    requestEmailChange();
                }}
            >
                <div className={styles.sectionTitle}>
                    <Translate>MOBILE_CHANGE_EMAIL_HEADER</Translate>
                </div>
                <div className={styles.rowDesc}>
                    <Translate>EMAIL_CHANGE_SUBTITLE</Translate>
                </div>
                <div className={styles.inputContainer}>
                    <input
                        className={styles.emailInput}
                        type="email"
                        required
                        placeholder={translate('ENTER_EMAIL')}
                        value={state.newEmail}
                        onChange={(e) => setState({ newEmail: e.target.value })}
                    />
                    <Button
                        type="submit"
                        className={styles.button}
                        color={SRAM_600}
                    >
                        <Translate>MOBILE_CHANGE_EMAIL</Translate>
                    </Button>
                </div>
            </form>
        </MobileContainer>
    );

    const changePasswordSection = () => (
        <MobileContainer className={styles.section}>
            <div className={styles.sectionTitle}>
                <Translate>MOBILE_CHANGE_PASSWORD_HEADER</Translate>
            </div>
            <div className={styles.rowDesc}>
                <Translate>CHANGE_PASSWORD_SUBTITLE</Translate>
            </div>
            <div className={styles.inputContainer}>
                <Button
                    className={styles.button}
                    color={SRAM_600}
                    onClick={() => requestPasswordChange()}
                >
                    <Translate>MOBILE_SEND_EMAIL</Translate>
                </Button>
            </div>
        </MobileContainer>
    );

    const deleteAccountSection = () => (
        <MobileContainer className={styles.section}>
            <div className={styles.sectionTitle}>
                <Translate>MOBILE_DELETE_ACCOUNT</Translate>
            </div>
            <div className={styles.rowDesc}>
                <Translate>MOBILE_DELETE_ACCOUNT_INFO</Translate>
            </div>
            <div className={styles.deleteBtnContainer}>
                <Button
                    className={styles.button}
                    color={SRAM_RED}
                    onClick={() => setState({
                        isOpenDeleteAccountModal: true,
                        header: 'DELETE_ACCOUNT',
                        subheader: 'ARE_YOU_SURE',
                        message: 'RIDER_PROFILE_ACCOUNT_DELETION_CONFIRM',
                        option1: {
                            text: 'DELETE',
                            onClick: () => requestAccountDelete(),
                        },
                        option2: {
                            text: 'CANCEL',
                            onClick: () => resetConfirmationModalState(),
                        },
                    })}
                    disabled={isRequestingDeleteAccount}
                    style={{
                        backgroundColor: 'transparant',
                    }}
                >
                    <Translate>DELETE_ACCOUNT</Translate>
                </Button>
            </div>
        </MobileContainer>
    );

    const renderErrorModal = () => {
        if (!state.requestError) {
            return null;
        }

        let errorMessage = 'PLEASE_TRY_AGAIN';
        if (state.requestError) {
            errorMessage = state.requestError.message;

            if (state.requestError.response && state.requestError.response.data) {
                errorMessage = state.requestError.response.data.msg;
            }
        }

        return (
            <ConfirmationModal
                open={!!state.requestError}
                header={state.errorHeader || 'ERROR'}
                msg={errorMessage}
                option1={{
                    onClick: () => {
                        setState({ requestError: null });
                    },
                    text: 'OK',
                }}
                subheader=""
            />
        );
    };

    const renderSuccessModal = () => (
        <ConfirmationModal
            open={isOpenConfirmationModal || isOpenDeleteAccountModal}
            header={header}
            subheader={subheader}
            msg={message}
            option1={option1}
            option2={option2 && option2}
        />
    );

    return (
        <>
            <Spinner loading={isRequestingEmailChange || isRequestingPasswordChange || isRequestingDeleteAccount} Component={FadeLoader} margin={-12} height={6} width={2} />
            <Helmet>
                <title>Update Account</title>
                <meta name="description" content="Update Account" />
            </Helmet>
            <ContentContainer className={styles.container}>
                <MobileContainer className={styles.header}>
                    <div className={styles.title}>
                        <Translate>MOBILE_UPDATE_ACCOUNT</Translate>
                    </div>
                </MobileContainer>
                {changeEmailSection()}
                {changePasswordSection()}
                {deleteAccountSection()}
            </ContentContainer>
            {renderSuccessModal()}
            {renderErrorModal()}
        </>
    );
};

export default UpdateAccount;
