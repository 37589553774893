import CadenceLineChart from './cadenceLineChart';
import HeartRateCurveChart from './heartRateCurveChart';
import HeartRateLineChart from './heartRateLineChart';
import HeartRatePlaceholder from './heartRatePlaceholder';
import HeartZoneDistribution from './heartZoneDistribution';
import PowerBalanceChart from './powerBalanceChart';
import PowerCurveChart from './powerCurveChart';
import PowerHistogram from './powerHistogram';
import PowerLineChart from './powerLineChart';
import PowerPlaceholder from './powerPlaceholder';
import PowerZoneDistribution from './powerZoneDistribution';

import Card from '../card';
import Row from '../row';
import Statistic from '../statistic';

import { logoQuarq } from '../../../assets';
import Carousel from '../../../components/carousel';
import { toLocaleString } from '../../../constants';
import { ComponentSummary, useUnits } from '../../../providers';

interface RiderPerformanceProps {
    disableEdit: boolean;
    disableOwnerFeatures: boolean;
    gpsComponent: ComponentSummary;
    heartComponent: ComponentSummary;
    heartZones: {min: number, max: number}[];
    isFetchingActivity: boolean;
    isFetchingComponentSummaries: boolean;
    powerComponent: ComponentSummary;
    powerZones: {min: number, max: number}[];
}

const RiderPerformance = ({
    disableEdit,
    disableOwnerFeatures,
    gpsComponent,
    heartComponent,
    heartZones,
    isFetchingActivity,
    isFetchingComponentSummaries,
    powerComponent,
    powerZones,
}: RiderPerformanceProps) => {
    const units = useUnits();
    const renderHeartRateStatistics = () => {
        if (!heartComponent) return null;

        const { shorthand } = units.getLabelEnergy();
        const energy = units.formatEnergy(units.convertEnergyFromSI(heartComponent.data.energy));

        return (
            <Row>
                <Statistic id="data-test-rider-performance-average-hr" label="AVERAGE_HEART_RATE" units="UNITS_BPM">
                    {toLocaleString(heartComponent.data.ave_hr)}
                </Statistic>
                <Statistic id="data-test-rider-performance-total-cal" label="TOTAL_CALORIES" units={shorthand}>
                    {disableOwnerFeatures ? null : toLocaleString(energy)}
                </Statistic>
            </Row>
        );
    };

    const renderPowerStatistics = () => {
        if (!powerComponent) return null;

        return (
            <>
                <Row>
                    <Statistic
                        id="data-test-rider-performance-normalized-power"
                        label="NORMALISED_POWER"
                        units="UNITS_WATTS_SHORT"
                    >
                        {powerComponent.data && Number.isFinite(powerComponent.data.normalized_power)
                            ? toLocaleString(powerComponent.data.normalized_power)
                            : null}
                    </Statistic>
                    <Statistic id="data-test-rider-performance-total-work" label="TOTAL_WORK" units="UNITS_KJ">
                        {toLocaleString(units.formatWork(powerComponent.data.energy))}
                    </Statistic>
                </Row>
                <Row>
                    <Statistic
                        id="data-test-rider-performance-avg-power"
                        label="AVERAGE_POWER"
                        units="UNITS_WATTS_SHORT"
                    >
                        {toLocaleString(powerComponent.data.ave_power)}
                    </Statistic>
                    <Statistic id="data-test-rider-performance-avg-cadence" label="AVERAGE_CADENCE" units="UNITS_RPM">
                        {toLocaleString(powerComponent.data.ave_cadence)}
                    </Statistic>
                </Row>
                <Row borderBottomNone={!heartComponent}>
                    <Statistic
                        id="data-test-rider-performance-20min-max-power"
                        label="NP_20_MIN"
                        units="UNITS_WATTS_SHORT"
                    >
                        {powerComponent.data ? toLocaleString(powerComponent.data['20_min_normalized_power']) : null}
                    </Statistic>
                    <Statistic id="data-test-rider-performance-max-power" label="POWER_MAX" units="UNITS_WATTS_SHORT">
                        {toLocaleString(powerComponent.data.max_power)}
                    </Statistic>
                </Row>
            </>
        );
    };

    const renderCharts = () => {
        const isFetchingData = isFetchingActivity || isFetchingComponentSummaries;

        return (
            <Carousel>
                <Carousel.Item show={!!powerComponent && !disableOwnerFeatures} title="POWER_ZONE">
                    <PowerZoneDistribution
                        disableEdit={disableEdit}
                        powerComponent={powerComponent}
                        powerZones={powerZones}
                    />
                </Carousel.Item>
                <Carousel.Item show={!!powerComponent} title="POWER_DISTRIBUTION">
                    <PowerHistogram
                        disableEdit={disableEdit}
                        disableOwnerFeatures={disableOwnerFeatures}
                        powerComponent={powerComponent}
                        powerZones={powerZones}
                    />
                </Carousel.Item>
                <Carousel.Item show={!!powerComponent} title="POWER_CURVE">
                    <PowerCurveChart
                        disableEdit={disableEdit}
                        disableOwnerFeatures={disableOwnerFeatures}
                        powerComponent={powerComponent}
                        powerZones={powerZones}
                    />
                </Carousel.Item>
                <Carousel.Item show={!!powerComponent} title="POWER_CHART">
                    <PowerLineChart
                        disableEdit={disableEdit}
                        disableOwnerFeatures={disableOwnerFeatures}
                        gpsComponent={gpsComponent}
                        powerComponent={powerComponent}
                        powerZones={powerZones}
                    />
                </Carousel.Item>
                <Carousel.Item
                    show={!!powerComponent && powerComponent.data.power_balance.length && !disableOwnerFeatures}
                    title="POWER_BALANCE"
                >
                    <PowerBalanceChart
                        disableEdit={disableEdit}
                        // disableOwnerFeatures={disableOwnerFeatures}
                        gpsComponent={gpsComponent}
                        powerComponent={powerComponent}
                        // powerZones={powerZones}
                    />
                </Carousel.Item>
                <Carousel.Item show={!!powerComponent} title="CADANCE_CHART">
                    <CadenceLineChart
                        disableEdit={disableEdit}
                        gpsComponent={gpsComponent}
                        powerComponent={powerComponent}
                    />
                </Carousel.Item>
                <Carousel.Item show={!!heartComponent && !disableOwnerFeatures} title="HEART_RATE_ZONE">
                    <HeartZoneDistribution
                        disableEdit={disableEdit}
                        heartComponent={heartComponent}
                        heartZones={heartZones}
                    />
                </Carousel.Item>
                <Carousel.Item show={!!heartComponent} title="HEART_RATE_CURVE">
                    <HeartRateCurveChart
                        disableEdit={disableEdit}
                        disableOwnerFeatures={disableOwnerFeatures}
                        heartComponent={heartComponent}
                        heartZones={heartZones}
                    />
                </Carousel.Item>
                <Carousel.Item show={!!heartComponent} title="HEART_RATE_CHART">
                    <HeartRateLineChart
                        disableEdit={disableEdit}
                        disableOwnerFeatures={disableOwnerFeatures}
                        gpsComponent={gpsComponent}
                        heartComponent={heartComponent}
                        heartZones={heartZones}
                    />
                </Carousel.Item>
                <Carousel.Item show={!powerComponent && !isFetchingData} title="POWER_ANALYSIS">
                    <PowerPlaceholder powerZones={powerZones} />
                </Carousel.Item>
                <Carousel.Item show={!heartComponent && !isFetchingData} title="HEART_RATE_ANALYSIS">
                    <HeartRatePlaceholder heartZones={heartZones} />
                </Carousel.Item>
            </Carousel>
        );
    };

    return (
        <Card label="RIDER_PERFORMANCE" logo={logoQuarq}>
            <div>
                {renderPowerStatistics()}
                {renderHeartRateStatistics()}
            </div>
            {renderCharts()}
        </Card>
    );
};

export default RiderPerformance;
