import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import EmailChangedModal from '../../views/emailChangedModal';
import ErrorModal from '../../views/errorModal';

const ChangedEmailWrapper = ({ children }: { children: any }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const emailChange = searchParams.get('emailchange');
    const msg: any = searchParams.get('msg');

    switch (emailChange) {
        case 'failed': return (
            <ErrorModal
                error={new Error(msg)}
                onClose={() => navigate('/riderportal/account')}
            />
        );
        case 'success': return (
            <EmailChangedModal
                onClose={() => navigate('/riderportal/account')}
                open
            />
        );
        default: return children;
    }
};

export default ChangedEmailWrapper;
