import loadImage from 'blueimp-load-image';
import React, { useEffect } from 'react';
import {
    Typeahead,
} from 'react-bootstrap-typeahead';

import styles from './BikeForm.module.scss';

import BikeFormRow from './bikeFormRow';
import CropImageModal from '../cropImageModal';

import Spinner from '../../components/spinner';
import Translate, { useTranslation } from '../../components/translate';
import { iconCamera } from '../../assets';
import {
    AXS_WEB_BG,
    makeCancellable,
    roundValueBy,
    WHITE,
} from '../../constants';
import { useSetState } from '../../hooks';
import { Bike, useUnits } from '../../providers';

interface BikeFormProps {
    bike?: Bike;
    children: React.ReactNode;
    id?: string;
    nameIsRequired?: boolean;
    onImageSelected: (image: any) => void;
    onSubmit: (changes: Bike) => void;
}

interface BikeFormState {
    activeRow: string | null;
    bikeImageCanvas: any;
    bikeModels: { [key: string]: string[] };
    isUploadingImage: boolean;
    showCropImageModal: boolean;
    updatedBike: Bike;
}

const MAX_WEIGHT = 75;
const BikeForm = ({
    id,
    bike = {} as Bike,
    children,
    nameIsRequired,
    onImageSelected,
    onSubmit,
}: BikeFormProps) => {
    const [state, setState] = useSetState({
        activeRow: null,
        bikeImageCanvas: null,
        bikeModels: {},
        isUploadingImage: false,
        showCropImageModal: false,
        updatedBike: bike,
    });
    const units = useUnits();
    const translate = useTranslation();
    const {
        activeRow,
        bikeImageCanvas,
        bikeModels,
        isUploadingImage,
        showCropImageModal,
        updatedBike,
    } = state as BikeFormState;
    let fetchBikeModelsRequest: any = null;

    const onChange = (key: string, value: Bike | string | File) => {
        // prevent rerender)
        if (!key || updatedBike[key] === value) return;

        const newUpdatedBike = { ...updatedBike };

        newUpdatedBike[key] = value;
        setState({ updatedBike: newUpdatedBike });
    };

    const onChangeBrandTypahead = (inputValue: string | any[]) => {
        if (!inputValue[0]) return;

        // New Selection or existing brand
        // If the model is set but not part of the brand, reset the model
        const newBrand = inputValue[0].label || inputValue[0];
        const newModel = bikeModels[newBrand]?.includes(updatedBike.model) ? updatedBike.model : null;

        setState({ updatedBike: { ...updatedBike, brand: newBrand, model: newModel } });
    };

    const onChangeModelTypahead = (inputValue: string | any[]) => {
        if (!inputValue[0]) return;

        // New Selection or existing model
        // If the brand is not set, try to find the brand based on the model
        const newModel = inputValue[0].label || inputValue[0];
        const newBrand = updatedBike.brand || Object.keys(bikeModels).find(
            (brand) => bikeModels[brand]?.includes(newModel),
        );

        setState({ updatedBike: { ...updatedBike, brand: newBrand, model: newModel } });
    };

    const onImageSelect = (newImage: string | Blob) => {
        if (!newImage) return;

        loadImage(
            newImage,
            (canvas) => setState({ bikeImageCanvas: canvas, isUploadingImage: false, showCropImageModal: true }),
            { canvas: true, orientation: true },
        );
    };

    const onImageCrop = (croppedCanvas: any) => {
        croppedCanvas.toBlob(
            (blob: Blob) => {
                const imageFile = new File([blob], 'croppedBikeImage.jpg');
                onChange('image', imageFile);
            },
            'image/jpeg',
        );

        onImageSelected(croppedCanvas.toDataURL('image/jpeg'));
        setState({ bikeImageCanvas: null, showCropImageModal: !showCropImageModal });
    };

    // Converts the weight to kg as its saved in the database
    const convertWeightToSI = (value: string | number) => {
        let newWeight = Number(value);
        newWeight = units.convertWeightToSI(newWeight);

        return newWeight;
    };

    const onFormSubmit = () => {
        const changes: any = {};

        Object.keys(updatedBike).forEach((key) => {
            if (key === 'data'
                && updatedBike.data !== null
                && typeof updatedBike.data !== 'undefined'
                && typeof updatedBike.data === 'object'
            ) {
                const dataChanges: { [key: string]: any } = {};

                Object.keys(updatedBike.data).forEach((dataKey) => {
                    if (updatedBike.data[dataKey] !== (bike.data && bike.data[dataKey])) {
                        dataChanges[dataKey] = updatedBike.data[dataKey];
                    }
                });

                if (Object.keys(dataChanges).length > 0) {
                    changes.data = dataChanges;
                }

                return;
            }

            if (key === 'weight') {
                const metricWeight = convertWeightToSI(updatedBike[key]);

                if (metricWeight !== bike[key]) {
                    changes[key] = metricWeight;
                }

                return;
            }

            if (updatedBike[key] !== bike[key]) {
                changes[key] = updatedBike[key];
            }
        });

        onSubmit(changes);
    };

    const getInputColor = (row: string) => {
        if (activeRow === row) return WHITE;

        return AXS_WEB_BG;
    };

    const getRowColor = (row: string) => {
        if (activeRow === row) return AXS_WEB_BG;

        return WHITE;
    };

    // Use this method to retrieve the staged change or the current value
    // from the nexusUserProfile
    const getValue = (key: string) => {
        if (updatedBike[key] === null || typeof updatedBike[key] === 'undefined') return '';

        return updatedBike[key];
    };

    const setActiveRow = (row: string) => {
        setState({ activeRow: row });
    };

    const fetchBikeModels = async () => {
        if (fetchBikeModelsRequest) {
            try {
                const data = fetchBikeModelsRequest.promise;

                return data;
            } catch {
                return null;
            }
        }

        fetchBikeModelsRequest = makeCancellable(import('../../assets/data/bikeModels.json'));

        try {
            const data = await fetchBikeModelsRequest.promise;

            setState({ bikeModels: data.default });

            fetchBikeModelsRequest = null;

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchBikeModelsRequest = null;
            }

            return null;
        }
    };

    // Converts the weight from kg as its saved in the database
    const convertWeightFromSI = (value: string | number) => {
        let newWeight: string | number = Number(value);
        newWeight = units.convertWeightFromSI(newWeight);
        newWeight = roundValueBy(newWeight, 2);

        if (!Number.isFinite(newWeight)) newWeight = '';

        return newWeight;
    };

    const renderCropImageModal = () => (
        <CropImageModal
            imageCanvas={bikeImageCanvas}
            onCancel={() => setState({ bikeImageCanvas: null, showCropImageModal: !showCropImageModal })}
            onCrop={(croppedCanvas) => onImageCrop(croppedCanvas)}
            open={showCropImageModal}
        />
    );

    const renderWeightInput = () => {
        const max = Math.floor(units.convertWeightFromSI(MAX_WEIGHT));

        const weight = getValue('weight');

        return (
            <BikeFormRow
                label="PROFILE_WEIGHT"
                onFocus={() => setActiveRow('weight')}
                style={{ background: getRowColor('weight') }}
                className="bike-weight-input"
            >
                <input
                    id="data-test-bike-weight"
                    className={styles.input}
                    max={max}
                    min={0}
                    onChange={(event) => onChange('weight', event.target.value)}
                    placeholder="---"
                    required
                    step={0.01}
                    style={{ background: getInputColor('weight'), width: '5rem' }}
                    type="number"
                    value={weight}
                />
                <div className={styles.units}>
                    <Translate>{units.getLabelWeight().shorthand}</Translate>
                </div>
            </BikeFormRow>
        );
    };

    useEffect(() => {
        fetchBikeModels();

        if (updatedBike) {
            setState({
                updatedBike: {
                    ...updatedBike,
                    weight: convertWeightFromSI(updatedBike.weight),
                },
            });
        }
    }, []);

    return (
        <form
            id={id}
            onSubmit={(event) => {
                event.preventDefault();
                onFormSubmit();
            }}
        >
            <Spinner loading={isUploadingImage} />
            <BikeFormRow label="PHOTO">
                <div className={styles.imagePickerContainer}>
                    <input
                        accept="image/*"
                        className={styles.imagePicker}
                        onChange={(event) => {
                            if (event.target.files) {
                                onImageSelect(event.target.files[0]);
                                // eslint-disable-next-line no-param-reassign
                                event.target.value = '';
                                setState({ isUploadingImage: true });
                            }
                        }}
                        type="file"
                    />
                    <img alt="" className={styles.imagePickerIcon} src={iconCamera} />
                    <Translate>PHOTO_SELECT</Translate>
                </div>
            </BikeFormRow>
            <BikeFormRow
                label="BIKE_NAME"
                onFocus={() => setActiveRow('name')}
                style={{ background: getRowColor('name') }}
            >
                <input
                    id="data-test-bike-name"
                    className={styles.input}
                    onChange={(event) => onChange('name', event.target.value.substr(0, 28))}
                    placeholder={translate('NEW_BIKE_NAME_PLACEHOLDER')}
                    required={nameIsRequired}
                    style={{ background: getInputColor('name') }}
                    type="text"
                    value={getValue('name')}
                />
            </BikeFormRow>
            <BikeFormRow
                label="BRAND"
                onFocus={() => setActiveRow('brand')}
                style={{ background: getRowColor('brand') }}
                className={styles.typeaheadInput}
                contentStyle={{ width: '100%' }}
            >
                <Typeahead
                    allowNew
                    id="bikeBrand"
                    onChange={(value) => onChangeBrandTypahead(value)}
                    onInputChange={(value) => setState({ updatedBike: { ...updatedBike, brand: value } })}
                    newSelectionPrefix={`${translate('NEW_SELECTION')}: `}
                    options={Object.keys(bikeModels)}
                    placeholder={translate('NEW_BIKE_BRAND_PLACEHOLDER')}
                    inputProps={{ style: { width: '100%' } }}
                    selected={updatedBike.brand ? [updatedBike.brand] : []}
                />
            </BikeFormRow>
            <BikeFormRow
                label="MODEL"
                onFocus={() => setActiveRow('model')}
                style={{ background: getRowColor('model') }}
                className={styles.typeaheadInput}
                contentStyle={{ width: '100%' }}
            >
                <Typeahead
                    allowNew
                    id="model"
                    selected={updatedBike.model ? [updatedBike.model] : []}
                    onChange={(value) => onChangeModelTypahead(value)}
                    onInputChange={(value) => setState({ updatedBike: { ...updatedBike, model: value } })}
                    newSelectionPrefix={`${translate('NEW_SELECTION')}: `}
                    options={(updatedBike.brand
                        ? bikeModels[updatedBike.brand] || []
                        : Object.values(bikeModels).flat().sort()
                    )}
                    placeholder={translate('NEW_BIKE_MODEL_PLACEHOLDER')}
                />
            </BikeFormRow>
            <BikeFormRow
                label="BIKE_MODEL_YEAR"
                onFocus={() => setActiveRow('year')}
                style={{ background: getRowColor('year') }}
            >
                <input
                    id="data-test-bike-model-year"
                    className={styles.input}
                    max={2500}
                    min={1900}
                    onChange={(event) => onChange('year', event.target.value)}
                    placeholder={translate('NEW_BIKE_MODEL_YEAR_PLACEHOLDER')}
                    style={{ background: getInputColor('year'), width: '100%' }}
                    type="number"
                    value={getValue('year')}
                />
            </BikeFormRow>
            {renderWeightInput()}
            {renderCropImageModal()}
            {children}
        </form>
    );
};

export default BikeForm;
