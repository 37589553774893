import Translate from '../../translate';

interface SupportLinkProps{
    className?: string,
    style?: Record<string, unknown>
}

const SupportLink = ({ className = '', style = {} }: SupportLinkProps) => (
    <a
        className={className}
        href="https://www.sram.com/en/company/campaigns/sram-rider-support"
        target="_blank"
        style={style} rel="noreferrer"
    >
        <Translate>HELP_AND_SUPPORT</Translate>
    </a>
);

export default SupportLink;
