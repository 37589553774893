import styles from './LoginError.module.scss';

import Button from '../button';
import Translate from '../translate';

interface LoginErrorProps{ error: { statusCode: number }, onClick?: (() => void) }

const LoginError = ({ error = { statusCode: 403 }, onClick = () => {} }: LoginErrorProps) => (
    <div className={styles.container}>
        <div className={styles.contentContainer}>
            <div className={styles.content}>
                <div className={styles.title}>
                    <Translate>LOGIN_ERROR_TITLE</Translate>
                </div>
                <div className={styles.subtitle}>
                    <Translate>LOGIN_ERROR_DESCRIPTION</Translate>
                </div>
                <div className={styles.notes}>
                    <Translate>LOGIN_ERROR_CODE</Translate>
                    &#58;
                    {error.statusCode}
                </div>
                <div className={`${styles.notes} ${styles.help}`}>
                    <Translate>LOGIN_ERROR_RETURN</Translate>
                </div>
                <Button
                    onClick={onClick}
                    type="button"
                >
                    <Translate>LOGIN</Translate>
                </Button>
            </div>
        </div>
    </div>
);

export default LoginError;
