import { ReactNode } from 'react';
import Spinner from '../spinner';
import { useUnits } from '../../providers/units/UnitsContext';

function UnitsBlock({ children = null }: { children: ReactNode }) {
    const { isFetching } = useUnits();

    if (isFetching) {
        return <Spinner />;
    }

    return children;
}

export default UnitsBlock;
