import { roundValueBy } from '../../constants';

type unitObjectProps = { [key: string]: string }

export const UNITS: unitObjectProps = {
    bar: 'bar',
    calories: 'calories',
    celcius: 'c',
    cm: 'cm',
    fahrenheit: 'f',
    feet: 'ft',
    hour_12: '12 hour',
    hour_24: '24 hour',
    inches: 'inches',
    kg: 'kg',
    kilojoules: 'kilojoules',
    kilopascal: 'kpa',
    km: 'km',
    kph: 'km/h',
    lbs: 'lbs',
    mbar: 'mbar',
    meters: 'm',
    miles: 'miles',
    mph: 'mph',
    mps: 'm/s',
    psi: 'psi',
};

export const UNIT_FORMATTERS = {
    [UNITS.bar]: (bar:number) => roundValueBy(bar, 2),
    [UNITS.celcius]: (celsius:number) => roundValueBy(celsius, 1),
    [UNITS.cm]: (cm:number) => Math.round(cm),
    [UNITS.fahrenheit]: (fahrenheit:number) => Math.round(fahrenheit),
    [UNITS.feet]: (feet:number) => Math.round(feet),
    [UNITS.inches]: (inch:number) => roundValueBy(inch, 1),
    [UNITS.calories]: (kCal:number) => Math.round(kCal),
    [UNITS.kg]: (kg:number) => roundValueBy(kg, 2),
    [UNITS.kilojoules]: (kJ:number) => Math.round(kJ),
    [UNITS.km]: (km:number) => roundValueBy(km, 1),
    [UNITS.kph]: (kmps:number) => roundValueBy(kmps, 1),
    [UNITS.kilopascal]: (kPa:number) => roundValueBy(kPa, 1),
    [UNITS.lbs]: (lbs:number) => Math.round(lbs),
    [UNITS.meters]: (meter:number) => Math.round(meter),
    [UNITS.mps]: (mps:number) => roundValueBy(mps, 1),
    [UNITS.miles]: (mile:number) => roundValueBy(mile, 1),
    [UNITS.mph]: (mph:number) => roundValueBy(mph, 1),
    [UNITS.mbar]: (mBar:number) => Math.round(mBar),
    [UNITS.psi]: (psi:number) => roundValueBy(psi, 1),
};

export const UNIT_LABELS = {
    [UNITS.bar]: { shorthand: 'UNITS_BAR' },
    [UNITS.celcius]: { shorthand: 'UNITS_CELSIUS' },
    [UNITS.calories]: { shorthand: 'UNITS_KILOCALORIE' },
    [UNITS.cm]: { shorthand: 'UNITS_CM_SHORT' },
    [UNITS.fahrenheit]: { shorthand: 'UNITS_FAHRENHEIT' },
    [UNITS.feet]: { shorthand: 'UNITS_FEET_SHORT' },
    [UNITS.hour_12]: { longhand: 'PROFILE_TIME_12' },
    [UNITS.hour_24]: { longhand: 'PROFILE_TIME_24' },
    [UNITS.inches]: { shorthand: 'UNITS_INCHES_SHORT' },
    [UNITS.kg]: { longhand: 'UNITS_KG_PLURAL', shorthand: 'UNITS_KG_SHORT' },
    [UNITS.kilojoules]: { shorthand: 'UNITS_KJ' },
    [UNITS.km]: { longhand: 'UNITS_KILOMETER', shorthand: 'UNITS_KILOMETER_SHORT' },
    [UNITS.kph]: { shorthand: 'UNITS_KILOMETER_PER_HOUR' },
    [UNITS.lbs]: { longhand: 'UNITS_POUNDS', shorthand: 'UNITS_POUNDS_SHORT' },
    // kilopascal: { shorthand: 'UNITS_KILOPASCAL_SHORT' },
    [UNITS.meters]: { shorthand: 'UNITS_METER_SHORT' },
    [UNITS.mbar]: { shorthand: 'UNITS_MILLIBAR' },
    [UNITS.mps]: { shorthand: 'UNITS_METER_PER_SECOND' },
    [UNITS.miles]: { longhand: 'UNITS_MILE', shorthand: 'UNITS_MILE_SHORT' },
    [UNITS.mph]: { shorthand: 'UNITS_MILE_PER_HOUR' },
    [UNITS.psi]: { shorthand: 'UNITS_PSI' },
};

export const UNITS_IMPERIAL: unitObjectProps = {
    altitude: UNITS.feet,
    distance: UNITS.miles,
    energy: UNITS.calories,
    height: UNITS.inches,
    mass: UNITS.lbs,
    pressure_suspension: UNITS.psi,
    pressure_tire: UNITS.psi,
    speed: UNITS.mph,
    temperature: UNITS.fahrenheit,
    work: UNITS.calories,
};

export const UNITS_METRIC: unitObjectProps = {
    altitude: UNITS.meters,
    category: '',
    distance: UNITS.km,
    energy: UNITS.kilojoules,
    height: UNITS.cm,
    mass: UNITS.kg,
    pressure_suspension: UNITS.bar,
    pressure_tire: UNITS.bar,
    speed: UNITS.kph,
    temperature: UNITS.celcius,
    work: UNITS.kilojoules,
};

export const UNIT_SYSTEMS = { ADVANCED: 'advanced', IMPERIAL: 'imperial', METRIC: 'metric' };

export function calculateUnitsPerDistance(distance: number, unitsValue: number) {
    const result = unitsValue / distance;
    if (!Number.isFinite(result)) {
        return 0;
    }

    return Math.round(result * 100) / 100;
}

export function convertInchesToMM(inches: number) {
    let weightInMM = Number(inches);

    weightInMM *= 25.4;
    weightInMM = Math.round(weightInMM);

    return weightInMM;
}
