import axios from 'axios';
import {
    ReactNode,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';

import DeviceModelsContext from './DeviceModelsContext';

import { useAuth } from '../auth';

import {
    makeCancellable,
    RequestType,
    URL_API,
    URL_FIRMWARE_API,
} from '../../constants';
import Logger from '../../Logger';

interface DeviceModelsProviderProps { children: ReactNode }

function cleanFirmwareVersions(rawFirmwareVersions: any) {
    return rawFirmwareVersions.filter(({ firmware_version, version }: any) => (
        firmware_version || version
    ));
}

const URL_MODELS = `${URL_API}models/`;

function DeviceModelsProvider({ children }: DeviceModelsProviderProps) {
    const [deviceTypes, setDeviceTypes] = useState([]);
    const [deviceModels, setDeviceModels] = useState([]);
    const [firmware, setFirmware] = useState<Record<string, any>>({});
    const deviceModelsFetchRequest = useRef<RequestType | null>(null);
    const deviceTypesFetchRequest = useRef<RequestType | null>(null);
    const auth = useAuth();
    const firmwareFetchRequests = useRef(new Map());
    const firmwareReleaseNotesRequests = useRef<RequestType | null>(null);

    const fetchDeviceModels = async () => {
        // Don't refetch if fetching
        if (deviceModelsFetchRequest.current) {
            try {
                const { data } = await deviceModelsFetchRequest.current.promise;
                return data;
            } catch (error) {
                return null;
            }
        }

        // Make the deviceModelsFetchRequest.current cancellable
        deviceModelsFetchRequest.current = makeCancellable(
            auth.isAuthenticated() ? axios.get(URL_MODELS) : axios.create().get(URL_MODELS),
        );

        try {
            const { data } = await deviceModelsFetchRequest.current.promise;
            deviceModelsFetchRequest.current = null;

            if (!data) return null;

            setDeviceModels(data);

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching device models', error);
                deviceModelsFetchRequest.current = null;
            }

            return null;
        }
    };

    const fetchDeviceTypes = async () => {
        // Don't refetch if fetching
        if (deviceTypesFetchRequest.current) {
            try {
                const { data } = await deviceTypesFetchRequest.current.promise;
                return data;
            } catch (error) {
                return null;
            }
        }

        deviceTypesFetchRequest.current = makeCancellable(
            auth.isAuthenticated()
                ? axios.get(`${URL_API}devicetypes/`)
                : axios.create().get(`${URL_API}devicetypes/`),
        );

        try {
            const { data } = await deviceTypesFetchRequest.current.promise;
            deviceTypesFetchRequest.current = null;

            if (!data) return null;

            setDeviceTypes(data);

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching device types', error);
                deviceTypesFetchRequest.current = null;
            }

            return null;
        }
    };

    const fetchFirmware = async (modelID: number) => {
        if (firmware[modelID]) {
            return firmware[modelID];
        }

        const currentRequest = firmwareFetchRequests.current.get(modelID);

        if (currentRequest) {
            currentRequest.cancel();
            firmwareFetchRequests.current.delete(modelID);
        }

        const firmwareFetchRequest = makeCancellable(
            auth.isAuthenticated()
                ? axios.get(`${URL_FIRMWARE_API}v2/firmware/${modelID}`)
                : axios.create().get(`${URL_FIRMWARE_API}v2/firmware/${modelID}`),
        );

        firmwareFetchRequests.current.set(modelID, firmwareFetchRequest);

        try {
            const { data } = await firmwareFetchRequest.promise;
            firmwareFetchRequests.current.delete(modelID);

            const newFirmware = cleanFirmwareVersions(data);

            if (!auth.isLoggingIn) {
                setFirmware({
                    ...firmware,
                    [modelID]: newFirmware,
                });
            }

            return newFirmware;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn(`Error fetching firmware ${modelID}`, error);
                firmwareFetchRequests.current.delete(modelID);
            }

            return null;
        }
    };

    const fetchReleaseNotes = async () => {
        if (firmwareReleaseNotesRequests.current) {
            try {
                const { data } = await firmwareReleaseNotesRequests.current.promise;
                return data;
            } catch (error) {
                return null;
            }
        }

        firmwareReleaseNotesRequests.current = makeCancellable(
            auth.isAuthenticated()
                ? axios.get(`${URL_FIRMWARE_API}v2/bundles/`)
                : axios.create().get(`${URL_FIRMWARE_API}v2/bundles/`),
        );

        try {
            const { data } = await firmwareReleaseNotesRequests.current.promise;
            firmwareReleaseNotesRequests.current = null;

            if (!data) return null;

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching release notes', error);
                firmwareReleaseNotesRequests.current = null;
            }

            return null;
        }
    };

    const getFirmware = (modelID: number) => firmware[modelID];

    useEffect(() => () => {
        if (deviceModelsFetchRequest.current) {
            deviceModelsFetchRequest.current.cancel();
        }

        if (deviceTypesFetchRequest.current) {
            deviceTypesFetchRequest.current.cancel();
            deviceTypesFetchRequest.current = null;
        }

        if (firmwareReleaseNotesRequests.current) {
            firmwareReleaseNotesRequests.current.cancel();
            firmwareReleaseNotesRequests.current = null;
        }

        firmwareFetchRequests.current.forEach((request) => request.cancel());
    }, []);

    return (
        <DeviceModelsContext.Provider
            value={{
                deviceTypes,
                fetch: () => fetchDeviceModels(),
                fetchDeviceTypes,
                fetchFirmware,
                fetchReleaseNotes,
                getFirmware,
                list: deviceModels,
            }}
        >
            {children}
        </DeviceModelsContext.Provider>
    );
}

export function useDeviceModels() {
    return useContext(DeviceModelsContext);
}

export default DeviceModelsProvider;
