import { binarySearch } from '../../../../constants';

export function gearMapStatistic(
    gearArray,
    statisticArray,
    gearTimeExtractor = (value, _index) => value,
    statisticTimeExtractor = (value, _index) => value,
    accumExtractor = (value, _index) => value,
) {
    if (!gearArray || !statisticArray) {
        return [];
    }

    // match sub component time stamp with gear time stamp and tally statistic values
    const tally = gearArray.reduce(
        (currentTally, gear, ...restOfParams) => {
            const currentTallyTemp = currentTally;

            const statisticIndex = binarySearch(
                statisticArray,
                gearTimeExtractor(gear, ...restOfParams),
                statisticTimeExtractor,
            );

            if (Number.isFinite(statisticArray[statisticIndex])) {
                if (!currentTallyTemp[gear]) {
                    currentTallyTemp[gear] = { entries: 0, tally: 0 };
                }

                currentTallyTemp[gear].entries += 1;
                currentTallyTemp[gear].tally += accumExtractor(
                    statisticArray[statisticIndex],
                    statisticIndex,
                    statisticArray,
                );
            }

            return currentTallyTemp;
        },
        [],
    );

    // handle array with empty elements due to skipping index in filters
    for (let index = 0; index < tally.length; index++) {
        if (!tally[index]) {
            tally[index] = { entries: 0, tally: 0 };
        }
    }

    return tally;
}

// filters cassette array by chain ring index
export function filterCassetteArrayByChainring(
    cassetteArray,
    chainringNumber,
    chainringArray,
    indexesOnly = false,
) {
    if (!cassetteArray || !chainringArray || !Number.isFinite(chainringNumber)) {
        return [];
    }

    const chainringIndexes = [];

    chainringArray.forEach((inGear, index) => {
        if (inGear === chainringNumber) {
            chainringIndexes.push(index);
        }
    });

    if (indexesOnly) {
        return chainringIndexes;
    }

    return chainringIndexes.map((chainringIndex) => cassetteArray[chainringIndex]);
}

// maps gear array into histogram array by tallying occurence
export function mapGearArrayToHistogram(
    gearArray,
    filteredIndexes,
    gearHistogram,
    speedArray,
) {
    if (!gearArray) {
        return [];
    }

    if (speedArray && speedArray.length) {
        return gearHistogram.map((_val, index) => filteredIndexes.reduce(
            (currentTally, gearIndex) => {
                let currentTallyTemp = currentTally;
                if ((gearArray[gearIndex] === index) && (speedArray[gearIndex] > 0)) {
                    currentTallyTemp += 1;
                }
                return currentTallyTemp;
            },
            0,
        ));
    }

    return gearHistogram.map((_val, index) => filteredIndexes.reduce(
        (currentTally, gearIndex) => {
            let currentTallyTemp = currentTally;
            if (gearArray[gearIndex] === index) {
                currentTallyTemp += 1;
            }
            return currentTallyTemp;
        },
        0,
    ));
}

export function tallyStatisticsByCassetteIndexes(
    cassetteIndexes,
    cassetteArray,
    fullHistogram,
    statisticArray,
) {
    const tallies = {};

    cassetteIndexes.forEach((chainringIndex) => {
        const rdGear = cassetteArray[chainringIndex];

        if (Number.isFinite(statisticArray[chainringIndex])) {
            if (!tallies[rdGear]) {
                tallies[rdGear] = { entries: 0, tally: 0 };
            }

            tallies[rdGear].entries += 1;
            tallies[rdGear].tally += statisticArray[chainringIndex];
        }
    });

    for (let index = 0; index < fullHistogram.length; index++) {
        if (!tallies[index]) {
            tallies[index] = { entries: 0, tally: 0 };
        }
    }

    return tallies;
}
