import { ReactNode } from 'react';

import styles from './ContentContainer.module.scss';

interface ContentContainerProps {
    children: ReactNode | Array<ReactNode>,
    className?: string,
    style?: Record<string, unknown>,
}

const ContentContainer = ({ children = null, className = '', style = {} }: ContentContainerProps) => (
    <div className={`${styles.container} ${className}`} style={style}>
        {children}
    </div>
);

export default ContentContainer;
