import { styled } from '@mui/material/styles';
import SliderValueLabel from '@mui/material/Slider/SliderValueLabel';
import { Slider as MaterialSlider } from '@mui/material';

import { SRAM_600, SRAM_RED } from '../../constants';

const StyledValueLabel = styled(SliderValueLabel)(({ theme }) => ({
    '& .MuiSlider-valueLabelLabel': {
        transform: 'initial',
    },
    '& .MuiSlider-valueLabelOffset': {
        top: '-12px',
    },
    borderRadius: 0,
    height: '18px',
    transform: 'initial',
    width: '45px',

}));

const DefaultSlider = styled(MaterialSlider)(({ theme }) => ({
    '& .MuiSlider-rail': {
        backgroundColor: SRAM_600,
        borderRadius: 4,
        height: 4,
    },
    '& .MuiSlider-root': {
        color: SRAM_RED,
    },
    '& .MuiSlider-thumb': {
        '&:hover': {
            boxShadow: '0px 0px 0px 8px rgba(229, 25, 55, 0.16)',
        },
        border: '2px solid currentColor',
        color: SRAM_RED,
        height: 24,
        width: 24,
    },
    '& .MuiSlider-track': {
        borderRadius: 4,
        color: SRAM_RED,
        height: 4,
    },
}));

const Slider = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <DefaultSlider {...props} valueLabelComponent={StyledValueLabel} />
);

export default Slider;
