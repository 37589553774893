import styles from './modals.module.scss';
import Modal from '../../../components/modal';
import Translate from '../../../components/translate';
import {
    imageSetupKey,
} from '../../../assets';

const SetupKeyInfo = ({
    open, onClose, image,
}:
    {open: boolean, onClose: () => void, image?: string}) => (
    <Modal
        className={styles.modalContainerInfo}
        contentClassName={styles.infoContent}
        headerStyle={{ color: 'white', fontSize: '1.5rem' }}
        contentLabel="InfoModal"
        isOpen={open}
        header="SETUP_KEY"
        hideImage
        onClose={() => onClose()}
        containerStyle={{ padding: 'none' }}
        onOverlayClick={() => onClose()}
        darkMode
        // hideCloseButton
    >

        <div className={styles.imageContainer}>
            <img
                alt="Setup Key"
                className={styles.image}
                src={image || imageSetupKey}
                style={{ width: '100%' }}
            />
        </div>
        <div className={styles.infoText}>
            <Translate>SETUP_KEY_POSITION_INFO</Translate>
        </div>
    </Modal>
);

export default SetupKeyInfo;
