import React, { useEffect, useState } from 'react';

import styles from './DistanceDurationSliders.module.scss';

import RangeSlider from '../../../../components/rangeSlider';
import Translate from '../../../../components/translate';

import { useUnits } from '../../../../providers/units/UnitsContext';

interface DistanceDurationSlidersProps {
    distance: Array<number>,
    duration: Array<number>,
    maxDistance: number,
    maxDuration: number,
    minDistance: number,
    minDuration: number,
    onChange: (value: {[key:string]: number[]}) => void,
}

const DistanceDurationSliders = ({
    distance = [0, 200],
    duration = [0, 24],
    maxDistance = 200,
    maxDuration = 24,
    minDistance = 0,
    minDuration = 0,
    onChange = () => {},
}:DistanceDurationSlidersProps) => {
    const [stagedDistance, setStagedDistance] = useState(distance);
    const [stagedDuration, setStagedDuration] = useState(duration);

    const { getLabelDistance } = useUnits();

    useEffect(() => {
        setStagedDistance(distance);
        setStagedDuration(duration);
    }, [distance, duration]);

    return (
        <>
            <div className={styles.distanceDurationDropdown}>
                <RangeSlider
                    label="DISTANCE"
                    max={maxDistance}
                    min={minDistance}
                    onChange={(values) => onChange({ distance: values })}
                    rangeValues={stagedDistance}
                    units={getLabelDistance().shorthand}
                />
                <RangeSlider
                    label="DURATION"
                    max={maxDuration}
                    min={minDuration}
                    onChange={(values) => onChange({ duration: values })}
                    rangeValues={stagedDuration}
                    units="HOURS"
                />
            </div>
            <div style={{ padding: '1rem', textAlign: 'right' }}>
                <button
                    className={`${styles.clearButton}`}
                    onClick={() => {
                        setStagedDuration([0, 24]);
                        setStagedDistance([0, 200]);
                        onChange({ distance: [0, 200], duration: [0, 24] });
                    }}
                    type="button"
                >
                    <Translate>CLEAR</Translate>
                </button>
            </div>
        </>
    );
};

export default DistanceDurationSliders;
