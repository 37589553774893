import { useEffect, useRef } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';

import styles from './BikeComponent.module.scss';
import BikeComponentCard from './bikeComponentCard';
import BikeComponentDownloads from './bikeComponentDownloads';
import BikeComponentNotification from './bikeComponentNotification';
import BikeComponentPanel from './bikeComponentPanel';
import BikeComponentStatistics from './BikeComponentStatistics';

import {
    iconAchievement,
    iconDelete,
    iconPlay,
} from '../../assets';
import Button from '../../components/button';
import ContentHeaderContainer from '../../components/contentHeaderContainer';
import Modal from '../../components/modal';
import Spinner from '../../components/spinner';
import Translate, { useTranslation } from '../../components/translate';
import { AXS_TEXT_GREY, makeCancellable, RequestType } from '../../constants';
import { useSetState } from '../../hooks';
import Logger from '../../Logger';
import { useAuth, useBikeComponents, useBikes } from '../../providers';
import NotFoundCard from '../../views/notFoundCard';

const MANUAL_TYPES = {
    COMPATIBILITY_MAP: 'compatibility-map',
    PUBLICATIONS: 'publications',
    SERVICE_MANUALS: 'service-manuals',
    USER_MANUALS: 'user-manuals',
};

function getManualTypeFromURL(url: string) {
    if (url.includes(MANUAL_TYPES.PUBLICATIONS)) {
        if (url.includes('UM')) {
            return MANUAL_TYPES.USER_MANUALS;
        }

        if (url.includes('SM')) {
            return MANUAL_TYPES.SERVICE_MANUALS;
        }
    }
    const matches = url
    /**
     * Remove url behind wanted directory. Substitute for positive lookbehind (.<=) not supported
     * by IE, Safari and Android FireFox
     */
        .replace(/.*(globalassets\/document-hierarchy\/)/, '')
    // Get first directory, excluding /
        .match(/.*?(?=\/)/);

    return matches ? matches[0] : '';
}

function getYoutubeVideoID(url: string) {
    const matches = url
        /**
        * Current valid YouTube video links have formats
        * youtube.com/watch?v={YOUTUBE_ID}
        * youtube.com/watch?vi={YOUTUBE_ID}
        * youtube.com/v/{YOUTUBE_ID}
        * youtube.com/vi/{YOUTUBE_ID}
        * youtu.be/{YOUTUBE_ID}
        * These links can be proceded by parameters
        *
        * Browser safe alternative for positive lookahead
        */
        .replace(/.*((v=)|(vi=)|(v\/)|(vi\/)|(youtu.be\/))/, '')
        /**
         * The YouTube ID format is not officially standard or documented. The format,
         * however, has remained unchanged and, since it has a space of 7.4e+19, it is
         * accepted it will not change. The current space is x^11, where
         * { x | {0,...,9} ^ {A,...,Z} ^ {a,...,z} ^ - ^ _ }
         *
         * Get first sub string to match current YouTube id format
         */
        .match(/[a-zA-Z0-9_-]{11}/);

    return matches ? matches[0] : null;
}

const DEFAULT_STATES = {
    bike: null,
    bikeComponent: null,
    componentBleServices: [],
    componentTags: null,
    displayDeleteModal: false,
    isDeleting: false,
    isFetching: true,
    isFetchingBleServices: false,
    isFetchingComponentTags: false,
    isFetchingFirmware: false,
    isFetchingNotifications: false,
    isFetchingProductDetails: false,
    notifications: [],
    productDetails: null,
    showTyrePressureGuide: false,
};

const BikeComponent = () => {
    const [state, setState] = useSetState(DEFAULT_STATES);
    const {
        bike,
        bikeComponent,
        componentBleServices,
        componentTags,
        displayDeleteModal,
        isDeleting,
        isFetching,
        isFetchingBleServices,
        isFetchingComponentTags,
        isFetchingFirmware,
        isFetchingNotifications,
        isFetchingProductDetails,
        notifications,
        productDetails,
        showTyrePressureGuide,
    } = state;
    const auth = useAuth();
    const bikeComponents = useBikeComponents();
    const bikes = useBikes();
    const navigate = useNavigate();
    const { id } = useParams();
    const translate = useTranslation();

    const deleteBikeComponentRequest = useRef<RequestType | null>(null);
    const fetchBikeComponentRequest = useRef<RequestType | null>(null);
    const fetchBikeRequest = useRef<RequestType | null>(null);
    const fetchProductDetailsRequest = useRef<RequestType | null>(null);
    const fetchComponentBleServicesRequest = useRef<RequestType | null>(null);
    const fetchComponentTagsRequest = useRef<RequestType | null>(null);
    const fetchNotificationRequest = useRef<RequestType | null>(null);

    function getManualsByType(documentType: string) {
        if (!productDetails.manuals) return null;

        return productDetails.manuals.filter((manual: any) => (
            getManualTypeFromURL(manual.url) === documentType
        ));
    }

    function getManualsRemainder() {
        if (!productDetails.manuals) return null;

        return productDetails.manuals.filter(({ url }: { url: string }) => (
            !Object.values(MANUAL_TYPES).includes(getManualTypeFromURL(url))
        ));
    }

    async function deleteBikeComponent() {
        if (!id) return;

        setState({ displayDeleteModal: false, isDeleting: true });

        deleteBikeComponentRequest.current = makeCancellable(bikeComponents.deleteBikeComponent(id));

        try {
            await deleteBikeComponentRequest.current.promise;

            deleteBikeComponentRequest.current = null;
            setState({ isDeleting: false });

            if (bikeComponent && bikeComponent.bike) {
                bikes.deleteComponentFromBike(bikeComponent.bike, id);
                navigate(`/bikerack/${bikeComponent.bike}`);

                return;
            }

            navigate('/components');
        } catch (error: any) {
            if (!error.isCancelled) {
                setState({ isDeleting: false });
                Logger.warn(error);
                deleteBikeComponentRequest.current = null;
            }
        }
    }

    async function fetchComponentTags() {
        if (!id) return;

        if (bikeComponent.bike === 'example') return;

        setState({ isFetchingComponentTags: true });
        fetchComponentTagsRequest.current = makeCancellable(bikeComponents.fetchComponentTags(id));

        try {
            const data = await fetchComponentTagsRequest.current.promise;
            fetchComponentTagsRequest.current = null;

            setState({ componentTags: data, isFetchingComponentTags: false });

            return data;
        } catch (err) {
            fetchComponentTagsRequest.current = null;
            setState({ isFetchingComponentTags: false });

            return null;
        }
    }

    async function fetchComponentNotifications() {
        if (!id) return;

        if (bikeComponent.bike === 'example') return;

        setState({ isFetchingNotifications: true });
        fetchNotificationRequest.current = makeCancellable(bikeComponents.fetchComponentNotifications(id));

        try {
            const data = await fetchNotificationRequest.current.promise;
            fetchNotificationRequest.current = null;

            setState({ isFetchingNotifications: false, notifications: data });

            return data;
        } catch (err) {
            fetchNotificationRequest.current = null;
            setState({ isFetchingNotifications: false });
            return null;
        }
    }

    async function fetchProductDetails() {
        const { model_code } = bikeComponent;

        if (bikeComponent.bike === 'example') return null;

        setState({ isFetchingProductDetails: true });
        fetchProductDetailsRequest.current = makeCancellable(bikeComponents.fetchProductDetails(model_code));

        try {
            const fetchedProductDetails = await fetchProductDetailsRequest.current.promise;

            fetchProductDetailsRequest.current = null;
            setState({ isFetchingProductDetails: false, productDetails: fetchedProductDetails });

            return fetchedProductDetails;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn(error);
                fetchProductDetailsRequest.current = null;
                setState({ isFetchingProductDetails: false });
            }

            return null;
        }
    }

    async function fetchBike() {
        if (!bikeComponent || !bikeComponent.bike) return null;

        fetchBikeRequest.current = makeCancellable(bikes.fetchBike(bikeComponent.bike, true));
        try {
            const fetchedBike = await fetchBikeRequest.current.promise;
            fetchBikeRequest.current = null;

            setState({ bike: fetchedBike });

            return fetchedBike;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn(error);
                fetchBikeRequest.current = null;
            }

            return null;
        }
    }

    async function fetchBikeComponent() {
        if (!id) return;

        if (fetchBikeComponentRequest.current) return;

        fetchBikeComponentRequest.current = makeCancellable(bikeComponents.fetch(id, true));

        try {
            const fetchedBikeComponent = await fetchBikeComponentRequest.current.promise;
            fetchBikeComponentRequest.current = null;

            if (!fetchedBikeComponent) {
                setState({ isFetching: false });
                return;
            }
            setState({ bikeComponent: fetchedBikeComponent, isFetching: false });
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn(error);
                fetchBikeComponentRequest.current = null;
                setState({ isFetching: false });
            }
        }
    }

    async function fetchComponentBleServices() {
        if (!id) return;

        if (bikeComponent.bike === 'example') return;

        setState({ isFetchingBleServices: true });
        fetchComponentBleServicesRequest.current = makeCancellable(bikeComponents.fetchComponentBleServices(id));

        try {
            const fetchedComponentBleServices = await fetchComponentBleServicesRequest.current.promise;

            fetchComponentBleServicesRequest.current = null;
            setState({ componentBleServices: fetchedComponentBleServices, isFetchingBleServices: false });
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn(error);
                fetchComponentBleServicesRequest.current = null;
                setState({ isFetchingBleServices: false });
            }
        }
    }

    function renderBackToBikeButton() {
        if (!bikeComponent.bike) return null;

        return (
            <Link
                className={`${styles.button} ${styles.link}`}
                to={`/bikerack/${bikeComponent.bike}`}
            >
                &lt;&nbsp;
                <Translate>BUTTON_BACK_TO_BIKE</Translate>
            </Link>
        );
    }

    function renderBikeComponent() {
        const position = componentBleServices.find(
            (bleService: any) => bleService.service_name === 'pressure_config',
        )?.position;

        return (
            <BikeComponentCard
                bike={bike}
                bikeComponent={bikeComponent}
                position={position}
                componentTags={componentTags}
                productDetails={productDetails}
            />
        );
    }

    function renderDeleteConfirmation() {
        return (
            <Modal
                contentClassName={styles.modalContainer}
                contentLabel="COMPONENT_DELETE_WARNING"
                dialog
                header="ARE_YOU_SURE"
                hideCloseButton
                isOpen={displayDeleteModal}
                imageStyle={{ height: '5rem', marginBottom: 0, marginTop: 'auto' }}
                imageSrc={iconDelete}
            >
                <Translate
                    params={{
                        name: bikeComponent.name
                            || bikeComponent.mobile_display_name_key
                            || (productDetails && productDetails.name),
                    }}
                >
                    COMPONENT_DELETE_WARNING
                </Translate>
                <div className={styles.modalButtonsContainer}>
                    <Button
                        color={AXS_TEXT_GREY}
                        inverse
                        onClick={() => setState({ displayDeleteModal: false })}
                    >
                        <Translate>CANCEL</Translate>
                    </Button>
                    <Button
                        type="button"
                        onClick={() => deleteBikeComponent()}
                    >
                        <Translate>DELETE</Translate>
                    </Button>
                </div>
            </Modal>
        );
    }

    function renderDocuments() {
        if (!productDetails || !productDetails.manuals || !productDetails.manuals.length) {
            return null;
        }

        return (
            <BikeComponentPanel
                label={(<Translate>DOCUMENTS_MANUALS</Translate>)}
                openOnMount
            >
                <BikeComponentDownloads
                    downloads={getManualsByType(MANUAL_TYPES.USER_MANUALS)}
                    title="USER_MANUALS"
                />
                <BikeComponentDownloads
                    downloads={getManualsByType(MANUAL_TYPES.SERVICE_MANUALS)}
                    title="SERVICE_MANUALS"
                />
                <BikeComponentDownloads
                    downloads={getManualsByType(MANUAL_TYPES.COMPATIBILITY_MAP)}
                    title="COMPATIBILITY_MAP"
                />
                <BikeComponentDownloads
                    downloads={getManualsRemainder()}
                    title="DOCUMENTS"
                />
            </BikeComponentPanel>
        );
    }

    function renderAlarms() {
        if (isFetchingNotifications) return null;

        const alarmNotifications = notifications
            ? notifications.filter(
                ({ notification_type }: { notification_type: string }) => (notification_type === 'alarm'),
            )
            : [];

        if (!alarmNotifications.length) {
            return (
                <BikeComponentPanel hideBorder label={(<Translate>ALERTS</Translate>)} openOnMount>
                    <BikeComponentNotification
                        icon={iconAchievement}
                        notification={{
                            body: translate('GO_ACCESS_THE_WORLD'),
                            title: translate('NO_ALERTS'),
                        }}
                    />
                </BikeComponentPanel>
            );
        }

        return (
            <BikeComponentPanel hideBorder label="ALERTS" openOnMount>
                {alarmNotifications.map((notification: any) => (
                    <BikeComponentNotification key={notification.id} notification={notification} />
                ))}
            </BikeComponentPanel>
        );
    }

    function renderStatisticsPanel() {
        return (
            <BikeComponentPanel
                label={(<Translate>COMPONENT_STATISTICS</Translate>)}
                openOnMount
            >
                <BikeComponentStatistics bikeComponent={bikeComponent} componentBleServices={componentBleServices} />
            </BikeComponentPanel>
        );
    }

    function renderVideos() {
        if (!productDetails || !productDetails.videos || !productDetails.videos.length) return null;

        return (
            <BikeComponentPanel
                label={(<Translate>VIDEOS</Translate>)}
                openOnMount
            >
                <div className={styles.videosContainer}>
                    {productDetails.videos.map((video: any) => (
                        <a
                            className={styles.videoContent}
                            href={video.url}
                            key={video.url}
                            rel="noreferrer"
                            target="_blank"
                        >
                            <div className={styles.videoImageContainer}>
                                <img
                                    alt=""
                                    className={styles.videoImage}
                                    /**
                                     * This thumbnail implementation is based on the assumption
                                     * that all urls are youtube videos
                                     */
                                    src={`https://img.youtube.com/vi/${getYoutubeVideoID(video.url)}/0.jpg`}
                                />
                                <img alt="" className={styles.videoPlay} src={iconPlay} />
                            </div>
                            <div className={styles.videoTitle}>
                                {video.name}
                            </div>
                        </a>
                    ))}
                </div>
            </BikeComponentPanel>
        );
    }

    function renderContent() {
        // eslint-disable-next-line max-len
        if (isFetching || isFetchingBleServices || isFetchingFirmware || isFetchingProductDetails || isFetchingComponentTags) return <Spinner />;

        if (!bikeComponent) return <NotFoundCard title={translate('BIKE_COMPONENT_NOT_FOUND')} />;

        return (
            <>
                <ContentHeaderContainer className={styles.ContentHeaderContainer}>
                    <Spinner loading={isDeleting} />
                    {renderBackToBikeButton()}
                    {(!auth.isLoggingIn && auth.isAuthenticated() && (bikeComponent.id !== 'example'))
                        ? (
                            <button
                                className={styles.deleteButton}
                                onClick={() => setState({ displayDeleteModal: true })}
                                type="button"
                            >
                                DELETE
                            </button>
                        )
                        : null}
                </ContentHeaderContainer>
                <div className={styles.content}>
                    {renderBikeComponent()}
                    {renderAlarms()}
                    {renderStatisticsPanel()}
                    {renderDocuments()}
                    {renderVideos()}
                    {renderDeleteConfirmation()}
                    <div className={styles.footer} />
                </div>
            </>
        );
    }

    useEffect(() => {
        fetchBikeComponent();

        return () => {
            if (deleteBikeComponentRequest.current) {
                deleteBikeComponentRequest.current.cancel();
            }

            if (fetchBikeComponentRequest.current) {
                fetchBikeComponentRequest.current.cancel();
            }

            if (fetchBikeRequest.current) {
                fetchBikeRequest.current.cancel();
            }

            if (fetchProductDetailsRequest.current) {
                fetchProductDetailsRequest.current.cancel();
            }

            if (fetchComponentTagsRequest.current) {
                fetchComponentTagsRequest.current.cancel();
            }

            if (fetchNotificationRequest.current) {
                fetchNotificationRequest.current.cancel();
            }
        };
    }, []);

    useEffect(() => {
        if (bikeComponent) {
            fetchComponentBleServices();
            fetchComponentTags();
            fetchComponentNotifications();
            fetchBike();
            fetchProductDetails();
        }
    }, [bikeComponent]);

    return (
        <div className={styles.container}>
            {renderContent()}
        </div>
    );
};

export default BikeComponent;

export { MANUAL_TYPES as MANUAL_TYPES_FOR_TESTING };
