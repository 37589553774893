import { useLocation } from 'react-router-dom';
import Translate from '../../components/translate';

import styles from './DeleteAccountConfirmWrapper.module.scss';

import { iconDelete } from '../../assets';

const DeleteAccountConfirmWrapper = () => {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const deleteconfirm = params.get('deleteconfirm');
    const message = params.get('msg');

    const renderTick = () => (
        <div className={styles.iconContainer}>
            {((deleteconfirm !== 'success')
                ? <img alt="" className={styles.errorIcon} src={iconDelete} />
                : <div className={styles.successIcon}>&#10003;</div>
            )}
        </div>
    );

    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <div className={styles.content}>
                    {renderTick()}
                    <div className={styles.description}>
                        <Translate>{message}</Translate>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteAccountConfirmWrapper;
