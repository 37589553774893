import React from 'react';

import styles from './Zone.module.scss';

import Translate from '../../../../../components/translate';

interface ZoneProps {
    color?: string;
    duration?: string;
    percentage?: number;
    percentageBarWidth?: number;
    range?: string;
    zone?: string;
}

const Zone = ({
    color = '',
    duration = '',
    percentage = 0,
    percentageBarWidth = 0,
    range = '',
    zone = '',
}: ZoneProps) => (
    <div className={styles.container}>
        <div className={styles.labelContainer}>
            <div className={styles.label}>
                <Translate>{zone}</Translate>
            </div>
            <div>{range}</div>
        </div>
        <div className={styles.percentageContainer}>
            <div
                className={styles.percentageBar}
                style={{
                    backgroundColor: color,
                    width: `${percentageBarWidth || percentage}%`,
                }}
            />
        </div>
        <div className={styles.percentageLabelContainer}>
            <div className={styles.label}>{duration}</div>
            <div>
                {percentage}
                %
            </div>
        </div>
    </div>
);

export default Zone;
