import React from 'react';
import Modal from '../../../components/modal';
import Translate from '../../../components/translate';
import styles from './modals.module.scss';
import { SRAM_RED } from '../../../constants';

interface ConfirmationModalProps {
    open: boolean;
    header: string;
    subheader: string;
    msg: string;
    option1: {
        text: string;
        onClick: () => void;
    };
    option2?: {
        text: string;
        onClick: () => void;
    };
}

const ConfirmationModal = ({
    open, header, subheader, msg, option1, option2,
}: ConfirmationModalProps) => (
    <Modal
        className={styles.confirmationModalContainer}
        contentClassName={styles.infoContent}
        headerStyle={{ color: 'white', fontSize: '1.4rem' }}
        contentLabel="ConfirmationModal"
        isOpen={open}
        header={header}
        hideImage
        containerStyle={{ padding: 'none' }}
        hideCloseButton
    >
        <div className={styles.infoText}>
            <Translate>{subheader}</Translate>
        </div>
        <div className={styles.messageText}>
            <Translate>{msg}</Translate>
        </div>
        <div className={styles.btnContainer}>
            <button
                className={`${styles.button}`}
                onClick={option1.onClick}
                style={{ color: option1.text === 'DELETE' ? SRAM_RED : 'white' }}
                type="button"
            >
                <Translate>{option1.text}</Translate>
            </button>
            {option2 && (
                <button
                    className={`${styles.button}`}
                    onClick={option2.onClick}
                    type="button"
                >
                    <Translate>{option2.text}</Translate>
                </button>
            )}
        </div>
    </Modal>
);

export default ConfirmationModal;
