import styles from './ToggleSelector.module.scss';

import Translate from '../translate';

import {
    BLACK,
    RED_LIGHT,
    SRAM_100,
    WHITE,
} from '../../constants';

const STYLES = {
    activeButton: (color: string) => ({
        backgroundColor: color,
        borderColor: color,
        color: color === SRAM_100 ? BLACK : WHITE,
    }),
};

interface buttonProps{
    key?: string,
    className?: string,
    label: string | number,
    style?: Record<string, unknown>,
    value: boolean | string | number | Record<string, unknown>,
    disabled?: boolean,
}

interface ToggleSelectorProps{
    activeButton: boolean | string | number | Record<string, unknown>,
    buttonClassName?: string,
    buttons: buttonProps[],
    buttonStyle?: Record<string, unknown>,
    className?: string,
    color?: string,
    onToggle?: (value:any) => void,
    id?: string,
}

const ToggleSelector = ({
    activeButton = '',
    buttonClassName = '',
    buttons = [],
    buttonStyle = {},
    className = '',
    color = RED_LIGHT,
    onToggle = () => {},
    id = '',
}: ToggleSelectorProps) => (
    <div className={`${styles.buttonGroup} ${className}`}>
        {buttons.map((button) => (
            <button
                id={id}
                className={`${styles.button} ${buttonClassName} ${button.className}`}
                disabled={button.disabled}
                key={button.label}
                onClick={() => onToggle(button.value)}
                style={{
                    ...(activeButton === button.value)
                        ? STYLES.activeButton(color)
                        : null,
                    ...buttonStyle,
                    ...button.style,
                }}
                type="button"
            >
                <Translate>{button.label}</Translate>
            </button>
        ))}
    </div>
);

export default ToggleSelector;
