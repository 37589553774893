import { FunctionComponent, ReactElement } from 'react';

export interface CarouselItemProps {
    children: ReactElement;
    show: boolean;
    title: string;
}

const CarouselItem: FunctionComponent<CarouselItemProps> = ({ children, show }) => (
    show ? children : null
);

export default CarouselItem;
