import styles from './ActivityStatistics.module.scss';

import Row from '../../row';
import Statistic from '../../statistic';

import DesktopContainer from '../../../../components/desktopContainer';
import MobileContainer from '../../../../components/mobileContainer';
import Translate, { useTranslation } from '../../../../components/translate';
import { toLocaleString } from '../../../../constants';
import { Activity, ComponentSummary, useUnits } from '../../../../providers';

const sanitizeValue = (value: number, round: boolean | string) => {
    if (!Number.isFinite(value)) return null;

    if (round) return toLocaleString(Math.round(value));

    return toLocaleString(value);
};

interface ActivityStatisticsProps {
    id?: string;
    activity: Activity;
    gpsComponent: ComponentSummary;
}

const ActivityStatistics = ({ activity, gpsComponent, id }: ActivityStatisticsProps) => {
    let averageHeartRate = null;
    let averageSpeed = null;
    let elevationGain = null;
    let maxTemperature = '--';
    let minTemperature = '--';
    let normalizedPower = null;

    const units = useUnits();
    const translate = useTranslation();
    const elevationSuffix = units.getLabelAltitude().shorthand;
    const speedSuffix = units.getLabelSpeed().shorthand;
    const temperatureSuffix = translate(units.getLabelTemperature().shorthand);

    if (activity.activitysummary_set.length) {
        const [activitySummarySet] = activity.activitysummary_set;

        averageHeartRate = sanitizeValue(activitySummarySet.data.average_heartrate, true);

        const aveSpeed = Number.isFinite(activitySummarySet.data.ave_speed)
            ? activitySummarySet.data.ave_speed
            : (activitySummarySet.data.distance / activitySummarySet.data.duration);

        averageSpeed = sanitizeValue(
            units.formatSpeed(
                units.convertSpeedFromSI(aveSpeed),
            ), '',
        );
        elevationGain = sanitizeValue(
            units.formatAltitude(
                units.convertAltitudeFromSI(activitySummarySet.data.ascent),
            ), '',
        );
        normalizedPower = sanitizeValue(activitySummarySet.data.normalized_power, true);
    }

    if (gpsComponent) {
        minTemperature = sanitizeValue(
            units.formatTemperature(
                units.convertTemperatureFromSI(gpsComponent.data.min_temp),
            ), '',
        );
        minTemperature = `${minTemperature}${temperatureSuffix}`;

        maxTemperature = sanitizeValue(
            units.formatTemperature(
                units.convertTemperatureFromSI(gpsComponent.data.max_temp),
            ), '',
        );
        maxTemperature = `${maxTemperature}${temperatureSuffix}`;
    }

    return (
        <>
            <MobileContainer id={id}>
                <Row>
                    <Statistic id="data-test-activity-summary-power" label="NORMALISED_POWER" units="UNITS_WATTS_SHORT">
                        {normalizedPower}
                    </Statistic>
                    <Statistic id="data-test-activity-summary-elevation" label="ELEVATION_GAIN" units={elevationSuffix}>
                        {elevationGain}
                    </Statistic>
                </Row>
                <Row>
                    <Statistic id="data-test-activity-summary-speed" label="AVG_SPEED" units={speedSuffix}>
                        {averageSpeed}
                    </Statistic>
                    <Statistic id="data-test-activity-summary-heart-rate" label="AVERAGE_HEART_RATE" units="UNITS_BPM">
                        {averageHeartRate}
                    </Statistic>
                </Row>
                <Row dividerComponent={null}>
                    <span className={styles.label}>
                        <Translate>TEMPERATURE</Translate>
                    </span>
                    <span className={styles.value}>
                        <span className={styles.lowTemp} id="data-test-activity-summary-min-temp">
                            {minTemperature}
                        </span>
                        <span>&nbsp;/&nbsp;</span>
                        <span className={styles.highTemp} id="data-test-activity-summary-max-temp">
                            {maxTemperature}
                        </span>
                    </span>
                </Row>
            </MobileContainer>
            <DesktopContainer className={styles.flexStyle} id={id}>
                <div className={styles.desktopContainer}>
                    <Statistic inline label="NORMALISED_POWER" units="UNITS_WATTS_SHORT">
                        {normalizedPower}
                    </Statistic>
                    <Statistic inline label="ELEVATION_GAIN" units={elevationSuffix}>
                        {elevationGain}
                    </Statistic>
                    <Statistic inline label="AVG_SPEED" units={speedSuffix}>
                        {averageSpeed}
                    </Statistic>
                    <Statistic inline label="AVERAGE_HEART_RATE" units="UNITS_BPM">
                        {averageHeartRate}
                    </Statistic>
                    <div className={styles.row}>
                        <div className={styles.label}>
                            <Translate>TEMPERATURE</Translate>
                        </div>
                        <div className={styles.value}>
                            <span className={styles.lowTemp}>
                                {minTemperature}
                            </span>
                            <span>&nbsp;/&nbsp;</span>
                            <span className={styles.highTemp}>
                                {maxTemperature}
                            </span>
                        </div>
                    </div>
                </div>
            </DesktopContainer>
        </>
    );
};

export default ActivityStatistics;
