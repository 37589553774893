import styles from './ImageContainer.module.scss';

interface ImageContainerProps {
    alt?: string,
    className?: string,
    onLoad?: () => void,
    src: string,
}

const ImageContainer = ({
    alt = '',
    className = '',
    onLoad = () => { /* do nothing */ },
    src = '',
    ...otherProps
}: ImageContainerProps) => (
    <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
        className={`${styles.container} ${className}`}
    >
        <img alt={alt} className={styles.image} onLoad={onLoad} src={src} />
    </div>
);

export default ImageContainer;
