import React from 'react';

import styles from './BikeDropdown.module.scss';

import { iconMenuDots } from '../../../assets';
import Translate from '../../../components/translate';
import Dropdown from '../../../views/navbar/dropdown';
import MenuItem from '../../../views/navbar/menuItem';

interface BikeComponentProps {
    onClick: () => void;
    onDeleteBike: () => void;
    open: boolean;
    toggleEdit: () => void;
}

const BikeDropdown = ({
    onClick,
    onDeleteBike,
    open,
    toggleEdit,
}: BikeComponentProps) => (
    <Dropdown
        buttonContent={(
            <img alt="More" className={styles.optionsButton} src={iconMenuDots} />
        )}
        dropdownClassName={styles.dropdown}
        onClick={onClick}
        open={open}
    >
        <MenuItem className="flex">
            <button
                className={styles.actionButton}
                onClick={() => {
                    onClick();
                    toggleEdit();
                }}
                type="button"
            >
                <Translate>BIKE_EDIT</Translate>
            </button>
        </MenuItem>
        <MenuItem className="flex">
            <button
                className={styles.actionButton}
                onClick={() => {
                    onClick();
                    onDeleteBike();
                }}
                type="button"
            >
                <Translate>BIKE_DELETE</Translate>
            </button>
        </MenuItem>
    </Dropdown>
);

export default BikeDropdown;
