export const AUTH_CONFIG = {
    audience: 'https://api-dev.quarqnet.com',
    clientID: 'WLaUePIY2uwbrsR15UM3I3H3Hqm4ce1f',
    domain: 'sramid-auth-dev.sram.com',
    redirectUri: 'https://axs-dev.sram.com/callback',
    responseType: 'token id_token',
    scope: 'openid email profile read:current_user update:current_user_identities',
};

export const AUTH0_LOGOUT_RETURN_TO_URL = 'https://axs-dev.sram.com';

export const COOKIE_DOMAIN = '.sram.com';

export const SERVER = 'dev';

export const SHARE_URL = 'https://axs-dev.sram.com/';

export const URI_QUARQNET_API = 'api-dev.quarqnet.com/api/v2/';

export const URL = 'https://nexus-dev.quarqnet.com/';
export const URL_API = `${URL}api/v2/`;
export const URL_BRODO = 'https://brodo-dev.quarqnet.com/';
export const URL_CLOUSEAU = 'https://clouseau-dev.quarqnet.com/';
export const URL_CLOUSEAU_API = 'https://clouseau-dev.quarqnet.com/api/';
// export const URL_FIRMWARE_API = 'https://sramfw-stage.quarqnet.com/';
export const URL_FIRMWARE_API = 'https://api.axs-dev.sram.com/firmware-service/';
export const URL_LOGGER = 'https://lincoln-dev.quarqnet.com/api/v1/axsweb';
export const URL_QUARQNET_API = `https://${URI_QUARQNET_API}`;
export const URL_QUARQTECH_API = 'https://nexus.dev.quarqtech.com/api/';
export const URL_STATIC_DATA = 'https://static.dev.quarqtech.com/';

export const DEEP_LINK_PRODUCT_REGISTRATION = 'https://register.dev.quarqtech.com/';
export const DEEP_LINK_SRAM_COM = 'https://local.sram.com/login/emailverified';
export const DEEP_LINK_TRAILHEAD = 'https://thx-client-stage.quarqnet.com';
