import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './EmailNotVerifiedModal.module.scss';

import Modal from '../../components/modal';
import Spinner from '../../components/spinner';
import Translate from '../../components/translate';

import { iconDelete, logoSramWhite } from '../../assets';
import { makeCancellable, RequestType } from '../../constants';
import Logger from '../../Logger';
import { useAuth } from '../../providers/auth/AuthContext';

interface StateProps {
    emailSentSuccessfully: boolean;
    isSendingEmail: boolean;
}

const EmailNotVerifiedModal = () => {
    const auth = useAuth();
    const [state, setState] = useState<StateProps>(
        { emailSentSuccessfully: false, isSendingEmail: false },
    );

    const resendVerificationEmailRequest = useRef<RequestType | null>(null);

    useEffect(() => () => {
        if (resendVerificationEmailRequest.current) {
            resendVerificationEmailRequest.current.cancel();
            resendVerificationEmailRequest.current = null;
        }
    }, []);

    const resendVerificationEmail = async () => {
        setState({ emailSentSuccessfully: false, isSendingEmail: true });

        resendVerificationEmailRequest.current = makeCancellable(
            auth.resendVerificationEmail(auth.userProfile.email),
        );
        try {
            const emailSentSuccessfully = await resendVerificationEmailRequest.current.promise;
            resendVerificationEmailRequest.current = null;

            setState({ emailSentSuccessfully, isSendingEmail: false });
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error resending email verification', error);
                resendVerificationEmailRequest.current = null;

                setState({ ...state, isSendingEmail: false });
            }
        }
    };

    const renderSentEmailTick = () => {
        switch (state.emailSentSuccessfully) {
            case null:
            case undefined:
                return null;
            case false:
                return (
                    <img alt="" className={styles.emailSentErrorIcon} src={iconDelete} />
                );
            default: return (
                <div className={styles.emailSentSuccessIcon}>
                    &#10003;
                </div>
            );
        }
    };

    return (
        <Modal
            contentClassName={styles.modalContent}
            className={styles.modalContent}
            contentLabel="Email Not Verified"
            dialog
            disableOverlayClick
            hideCloseButton
            hideImage
            isOpen
            containerStyle={{
                backgroundColor: '#00000000',
                display: 'flex',
                flexDirection: 'column',
                height: 600,
                maxHeight: 'calc(100vh - 2rem)',
                overflow: 'hidden',
                padding: 0,
            }}
        >
            <Spinner loading={state.isSendingEmail} />
            <div className={styles.headerContainer}>
                <div className={styles.imageContainer}>
                    <img
                        alt=""
                        className={styles.image}
                        src={logoSramWhite}
                    />
                </div>
                <div className={styles.title}>
                    <Translate>SRAM_AXS</Translate>
                </div>
            </div>
            <div className={styles.content}>
                <div style={{ padding: '2rem 0' }}>
                    <div className={styles.title}>
                        <Translate>EMAIL_SENT</Translate>
                    </div>
                    <div>
                        {auth.userProfile.email}
                    </div>
                </div>
                <div>
                    <Translate>EMAIL_VERIFICATION_CHECK</Translate>
                </div>
            </div>
            <div className={styles.resendContainer}>
                <div className={styles.emailSentContainer}>
                    {renderSentEmailTick()}
                </div>
                <button
                    className={styles.resendButton}
                    onClick={() => resendVerificationEmail()}
                    type="button"
                >
                    <Translate>EMAIL_VERIFICATION_RESEND</Translate>
                </button>
            </div>
            <Link
                className={styles.refreshButton}
                to="/logout"
            >
                <span className={styles.refreshButtonText}>
                    <Translate>EMAIL_VERIFICATION_CONTINUE</Translate>
                </span>
            </Link>
        </Modal>
    );
};

export default EmailNotVerifiedModal;
