import { array, func, shape } from 'prop-types';

const FUNCTIONS = {
    fetch: func,
    fetchFirmware: func,
    getFirmware: func,
};

const DEVICE_MODELS_PROP_TYPES = {
    deviceModels: shape({
        ...FUNCTIONS,
        list: array,
    }),
};

export default DEVICE_MODELS_PROP_TYPES;
