import React from 'react';

import styles from './UnitToggle.module.scss';

import ToggleSelector from '../../../components/toggleSelector';
import { SRAM_RED } from '../../../constants';
import { useUnits } from '../../../providers';

interface UnitToggleProps {
    onTogglePressure: (value: string) => void;
    selectedPressureUnits: string;
}

const UnitToggle = ({
    onTogglePressure,
    selectedPressureUnits,
}: UnitToggleProps) => {
    const units = useUnits();

    const renderPressureUnitsSelections = () => {
        const pressureUnits = units.units.find(({ name }) => name === 'pressure_tire');
        const pressureConversions = pressureUnits
            ? pressureUnits.conversions
            : [];
        return (
            <ToggleSelector
                activeButton={selectedPressureUnits}
                buttonClassName={styles.button}
                buttons={pressureConversions.map(({ label }) => ({
                    label: units.getLabelPressure(label).shorthand,
                    value: label,
                }))}
                className={styles.toggleSelector}
                color={SRAM_RED}
                // id="data-test-pressure-unit-toggle"
                onToggle={onTogglePressure}
            />
        );
    };

    return (
        <>
            {renderPressureUnitsSelections()}
        </>
    );
};

export default UnitToggle;
