import { useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import AXSAppTutorial from './axsAppTutorial';
import BikeFeedCard from './bikeFeedCard';
import DefaultBikeCard from './defaultBikeCard';

import ContentContainer from '../../components/contentContainer';
import Spinner from '../../components/spinner';
import { useNexus } from '../../providers/nexus/NexusContext';
import { useBikes } from '../../providers/bikes/BikesContext';
import { Bike } from '../../providers';

const Bikes = () => {
    const bikesContainerRef = useRef<HTMLDivElement>(null);
    const { nexusUserProfile } = useNexus();
    const bikes = useBikes();

    const { list, notificationSet } = bikes;

    const renderBikeFeed = () => list.map((bike: Bike) => (
        <BikeFeedCard key={bike.uuid} bike={bike} notificationSet={notificationSet && notificationSet[bike.uuid]} />
    ));

    const renderDefaultBikeCard = () => {
        if (nexusUserProfile && nexusUserProfile.user_journey && nexusUserProfile.user_journey.bikeCount) return null;

        return <DefaultBikeCard />;
    };

    const renderHelpCards = () => (
        <AXSAppTutorial
            showAxsAppContent={nexusUserProfile
                    && nexusUserProfile.user_journey
                    && !nexusUserProfile.user_journey.componentCount}
            showPairingContent={nexusUserProfile
                    && nexusUserProfile.user_journey
                    && !nexusUserProfile.user_journey.hasLinkedId}
        />
    );

    useEffect(() => {
        if (!bikes.list.length) return;

        bikes.list.forEach(({ uuid }) => bikes.fetchBikeNotifications(uuid));
    }, []);

    return (
        <ContentContainer>
            <Spinner loading={bikes.isFetching} />
            <div ref={bikesContainerRef}>
                {renderDefaultBikeCard()}
                {renderHelpCards()}
                <InfiniteScroll
                    dataLength={list.length}
                    hasMore={bikes.hasMore}
                    next={bikes.fetch}
                    loader
                >
                    {renderBikeFeed()}
                </InfiniteScroll>
            </div>
        </ContentContainer>
    );
};

export default Bikes;
