import styles from './GearRatioPlaceholder.module.scss';

import Button from '../../../../components/button/Button';
import { AXS_TEXT_DARK, SRAM_200 } from '../../../../constants';

import { EditDrivetrain } from '../gearHistogram';

import Translate from '../../../../components/translate';
import {
    Activity,
    Bike,
    ComponentSummary,
} from '../../../../providers';
import { BikeUpdates } from '../../../../providers/bikes/types';

const buttonComponent = ({ disabled, onClick }: {disabled: boolean, onClick: () => void}) => (
    <Button
        style={{
            border: `1px solid ${AXS_TEXT_DARK}`,
            color: AXS_TEXT_DARK,
        }}
        color={SRAM_200}
        disabled={disabled}
        inverse
        onClick={onClick}
    >
        <Translate>SELECT</Translate>
    </Button>
);

interface GearRatioPlaceholderProps {
    activity: Activity,
    bike: Bike,
    disableEdit?: boolean,
    gearComponent: ComponentSummary | undefined,
    updateActivity?: (activity: Activity) => void;
    updateBike: (bikeUpdates: BikeUpdates) => void;
}

const GearRatioPlaceholder = ({
    activity,
    bike,
    disableEdit = false,
    gearComponent,
    updateActivity,
    updateBike,
}:GearRatioPlaceholderProps) => (
    <div className={styles.container}>
        <div className={styles.subtitle}>
            <Translate>GEAR_RATIO_NO_DATA</Translate>
        </div>
        <div className={styles.description}>
            <div className={styles.descriptionTitle}>
                <Translate>GEAR_RATIO_NO_BIKE</Translate>
            </div>
            <div>
                <EditDrivetrain
                    activity={activity}
                    bike={bike}
                    ButtonComponent={buttonComponent}
                    disableEdit={disableEdit}
                    gearComponent={gearComponent}
                    updateActivity={updateActivity as (data: {bike_uuid: string}) => void}
                    updateBike={updateBike}
                />
            </div>
        </div>
    </div>
);

export default GearRatioPlaceholder;
