import axios from 'axios';
import { ReactNode, useEffect, useRef } from 'react';

import NexusContext from './NexusContext';

import { useAuth } from '../auth';

import { makeCancellable, RequestType, URL_API } from '../../constants';
import Logger from '../../Logger';
import { useSetState } from '../../hooks';

interface NexusProviderProps { children: ReactNode }

const DEFAULT_STATE = {
    nexusLinkedIds: [],
    nexusUserProfile: null,
};

function serialiseProfile(profile: any) {
    // Convert the data to a FormData object
    const profileFormData = new FormData();
    Object.entries(profile).forEach(([key, value]: any) => {
        // Check if the value is an object but not a file or blob
        if (!(value instanceof File || value instanceof Blob)
            && (typeof value === 'object' || Array.isArray(value))
        ) {
            profileFormData.append(key, JSON.stringify(value));
        } else {
            profileFormData.append(key, value);
        }
    });

    return profileFormData;
}

function NexusProvider({ children }: NexusProviderProps) {
    const [state, setState] = useSetState({
        ...DEFAULT_STATE,
        cachedProfileImage: localStorage.getItem('profilePicture'),
    });
    const auth = useAuth();
    const { nexusLinkedIds, nexusUserProfile } = state;

    const handleServiceLinkedRequest = useRef<RequestType | null>(null);
    const nexusLinkedIdsRequest = useRef<RequestType | null>(null);
    const nexusSyncLinkedIdsRequest = useRef<RequestType | null>(null);
    const nexusUserProfileRequest = useRef<RequestType | null>(null);
    const unlinkNexusServiceRequests = useRef(new Map<string, RequestType>());
    const updateNexusUserProfileRequest = useRef(new Set<RequestType>());

    const clear = () => {
        if (handleServiceLinkedRequest.current) {
            handleServiceLinkedRequest.current.cancel();
        }

        if (nexusLinkedIdsRequest.current) {
            nexusLinkedIdsRequest.current.cancel();
        }

        if (nexusSyncLinkedIdsRequest.current) {
            nexusSyncLinkedIdsRequest.current.cancel();
        }

        if (nexusUserProfileRequest.current) {
            nexusUserProfileRequest.current.cancel();
        }

        unlinkNexusServiceRequests.current.forEach((request) => request.cancel());
        unlinkNexusServiceRequests.current.clear();

        updateNexusUserProfileRequest.current.forEach((request) => request.cancel());
        updateNexusUserProfileRequest.current.clear();
    };

    const fetchNexusLinkedIds = async () => {
        // Check if nexusLinkedIds request is already active
        if (nexusLinkedIdsRequest.current) {
            try {
                const { data } = await nexusLinkedIdsRequest.current.promise;
                return data;
            } catch (error: any) {
                return null;
            }
        }

        // Actually request the nexusLinkedIds
        nexusLinkedIdsRequest.current = makeCancellable(axios.get(`${URL_API}linkedids/`));

        try {
            const { data } = await nexusLinkedIdsRequest.current.promise;
            nexusLinkedIdsRequest.current = null;

            setState({ nexusLinkedIds: data });

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching linked ids', error);

                nexusLinkedIdsRequest.current = null;
            }

            return null;
        }
    };

    const fetchNexusUserProfile = async () => {
        // Check if nexusUserProfile request is already active
        if (nexusUserProfileRequest.current) {
            try {
                const { data } = await nexusUserProfileRequest.current.promise;

                return data;
            } catch (error: any) {
                return null;
            }
        }

        // Actually request the nexusUserProfile
        nexusUserProfileRequest.current = makeCancellable(axios.get(`${URL_API}users/self/`));

        try {
            const { data } = await nexusUserProfileRequest.current.promise;
            const { id: nexusId } = data;

            nexusUserProfileRequest.current = null;
            localStorage.setItem('profilePicture', data.picture);
            setState({ cachedProfileImage: data.picture, nexusUserProfile: data });
            Logger.setNexusId(nexusId);

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching nexus user profile', error);
                nexusUserProfileRequest.current = null;
            }

            return null;
        }
    };

    const handleServiceLinked = async (service: string) => {
        const authResult = await auth.handleServiceLinked();
        const userProfile = await auth.fetchAuth0Profile();

        const subSplit = authResult.idTokenPayload.sub.split('|');
        if (subSplit[0] === 'auth0') {
            // check if service got linked to me or someone else
            if (authResult.idTokenPayload.sub === userProfile.sub) {
                // its me!
                Logger.warn('Service is already linked to account');
                return null;
            }
            // its not me, raise warning for user
            Logger.warn('Service is linked to another users account');
            sessionStorage.setItem(
                'linkedServiceAuth0Error',
                `${service} is linked to another users account`,
            );
            return null;
        }

        const sub = subSplit.slice(1).join('|');

        // Post the authenticated account to nexus to complete the linking process
        handleServiceLinkedRequest.current = makeCancellable(axios.post(
            `${URL_API}linkedids/`,
            { access_token: authResult.accessToken },
        ));

        try {
            const { data } = await handleServiceLinkedRequest.current.promise;
            handleServiceLinkedRequest.current = null;

            fetchNexusLinkedIds();

            localStorage.removeItem('accessToken');
            localStorage.removeItem('expiresAt');

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                handleServiceLinkedRequest.current = null;
                Logger.warn('Error linking authenticating linking account', error);
                Logger.warnSilent('Error authenticating linking account (auth0 response for debugging)', authResult);

                if (error.response) {
                    sessionStorage.setItem(
                        'linkedServiceAuth0Error',
                        error.response.data['service and linked_id'],
                    );
                }
            }

            return null;
        }
    };

    const triggerNexusIdsSync = async () => {
        // Check if nexusSyncLinkedIds request is already active
        if (nexusSyncLinkedIdsRequest.current) {
            try {
                const { data } = await nexusSyncLinkedIdsRequest.current.promise;
                return data;
            } catch (error: any) {
                return null;
            }
        }

        nexusSyncLinkedIdsRequest.current = makeCancellable(axios.get(`${URL_API}linkedids/?sync=true`));

        try {
            const { data } = await nexusSyncLinkedIdsRequest.current.promise;
            nexusSyncLinkedIdsRequest.current = null;

            fetchNexusLinkedIds();

            return data;
        } catch (error:any) {
            if (!error.isCancelled) {
                Logger.warn('Error syncing linked ids to nexus', error);
                nexusSyncLinkedIdsRequest.current = null;
            }
        }
        return null;
    };

    const unlinkNexusService = async (id: string) => {
        // Check if service is already being unliked
        const currentRequest = unlinkNexusServiceRequests.current.get(id);
        if (currentRequest) {
            try {
                await currentRequest.promise;
                return true;
            } catch (error: any) {
                return false;
            }
        }

        const fetchRequest = makeCancellable(axios.delete(`${URL_API}linkedids/${id}/`));
        unlinkNexusServiceRequests.current.set(id, fetchRequest);

        try {
            await fetchRequest.promise;
            // Remove the request from the list
            unlinkNexusServiceRequests.current.delete(id);

            setState({
                // eslint-disable-next-line eqeqeq
                nexusLinkedIds: nexusLinkedIds.filter((nexusLinkedId: any) => nexusLinkedId.id != id),
            });

            return true;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error unlinking nexus service', id, error);

                // Remove the request from the list
                unlinkNexusServiceRequests.current.delete(id);
            }

            return false;
        }
    };

    const updateNexusUserProfile = async (userProfileUpdate: any) => {
        const headers = { 'Content-Type': 'multipart/form-data' };
        // Convert the data to a FormData object
        const userUpdatesFormData = serialiseProfile(userProfileUpdate);

        const fetchRequest = makeCancellable(axios.patch(
            `${URL_API}users/${nexusUserProfile.id}/`,
            userUpdatesFormData,
            { headers },
        ));
        updateNexusUserProfileRequest.current.add(fetchRequest);

        try {
            const { data } = await fetchRequest.promise;

            updateNexusUserProfileRequest.current.delete(fetchRequest);

            setState({ cachedProfileImage: data.picture, nexusUserProfile: data });

            localStorage.setItem('profilePicture', data.picture);

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error Updating Nexus User Profile', userProfileUpdate, error);

                updateNexusUserProfileRequest.current.delete(fetchRequest);
            }

            return null;
        }
    };

    const fetchData = () => {
        fetchNexusLinkedIds();
        fetchNexusUserProfile();
    };

    useEffect(() => {
        if (!auth.accessToken) {
            clear();

            setState({ nexusLinkedIds: [], nexusUserProfile: null });

            return;
        }
        fetchData();
    }, [auth.accessToken]);

    useEffect(() => () => {
        clear();
    }, []);

    return (
        <NexusContext.Provider
            value={{
                nexus: {
                    ...state,
                    handleServiceLinked,
                    triggerNexusIdsSync,
                    unlinkNexusService,
                    updateNexusUserProfile,
                },
            }}
        >
            {children}
        </NexusContext.Provider>
    );
}

export default NexusProvider;
