import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import styles from './MobileTabBar.module.scss';

import MessageBadge from '../navbar/messagesBadge';

import Drawer from '../../components/drawer';
import MobileContainer from '../../components/mobileContainer';
import ProfileImage from '../../components/profileImage';
import Translate from '../../components/translate';
import WaffleBlock from '../../components/waffleBlock';
import { SRAM_RED } from '../../constants';

import { useAuth } from '../../providers/auth/AuthContext';
import { useBikes } from '../../providers/bikes/BikesContext';

const CATEGORIES = ['map', 'bike', 'messages', 'settings'];

const MobileTabBar = () => {
    const auth = useAuth();
    const bikes = useBikes();
    const [openDrawerCategory, setOpenDrawerCategory] = useState<string|null>(null);
    const [error, setError] = useState(false);
    const location = useLocation();

    const excludedPaths = ['/guides/mobile/fullmount/chain/calculator', '/mobile/riderportal'];
    const isExcluded = excludedPaths.some((path) => location.pathname && location.pathname.startsWith(path));

    const getPathCategory = () => {
        if (location.pathname.startsWith('/bikerack')) return 'bike';

        switch (location.pathname) {
            case '/activities':
            case '/activities/upload':
                return 'map';

            case '/components':
            case '/buildbike':
                return 'bike';

            case '/messages':
                return 'messages';

            case '/riderportal':
                return 'settings';

            default:
                return null;
        }
    };

    const toggleActiveCategory = (category: string) => {
        setOpenDrawerCategory((openDrawerCategory !== category) ? category : null);
    };

    const renderActiveBar = () => {
        const index = openDrawerCategory !== null ? CATEGORIES.indexOf(openDrawerCategory) : -1;

        if (index === -1) return null;

        return (
            <div
                className={styles.activeTabBar}
                style={{
                    left: `${(index / CATEGORIES.length) * 100}%`,
                    width: `${(1 / CATEGORIES.length) * 100}%`,
                }}
            />
        );
    };

    const renderAxsFeedDrawer = () => (
        <Drawer
            isOpen={(openDrawerCategory === 'map')}
            onClose={() => setOpenDrawerCategory(null)}
        >
            <Link
                className={styles.linkStyle}
                onClick={() => setOpenDrawerCategory(null)}
                style={(location.pathname === '/activities') ? { color: SRAM_RED } : {}}
                to="/activities"
            >
                <Translate>AXS_RIDE_REPORT</Translate>
            </Link>
            <WaffleBlock flag="test">
                <Link
                    className={styles.linkStyle}
                    onClick={() => setOpenDrawerCategory(null)}
                    style={(location.pathname === '/activities/log') ? { color: SRAM_RED } : {}}
                    to="/activities/log"
                >
                    <Translate>ACTIVITY_LOG</Translate>
                </Link>
            </WaffleBlock>
            <Link
                className={styles.linkStyle}
                onClick={() => setOpenDrawerCategory(null)}
                style={(location.pathname === '/activities/upload') ? { color: SRAM_RED } : {}}
                to="/activities/upload"
            >
                <Translate>ACTIVITY_UPLOAD</Translate>
                <div className={styles.plus}>
                        +
                </div>
            </Link>
            <Link
                className={styles.linkStyle}
                onClick={() => setOpenDrawerCategory(null)}
                style={(location.pathname === '/guides/tire/pressure') ? { color: SRAM_RED } : {}}
                to="/guides/tire/pressure"
            >
                <Translate>TIRE_PRESSURE_GUIDE</Translate>
            </Link>
            <Link
                className={styles.linkStyle}
                onClick={() => setOpenDrawerCategory(null)}
                style={
                    (location.pathname === '/guides/fullmount/chain/calculator') ? { color: SRAM_RED } : {}
                }
                to="/guides/fullmount/chain/calculator"
            >
                <Translate>FULL_MOUNT_CHAIN_CALCULATOR</Translate>
            </Link>
        </Drawer>
    );

    const renderBikesDrawer = () => (
        <Drawer
            isOpen={(openDrawerCategory === 'bike')}
            onClose={() => setOpenDrawerCategory(null)}
        >
            <Link
                className={styles.linkStyle}
                onClick={() => setOpenDrawerCategory(null)}
                style={(location.pathname === '/bikerack') ? { color: SRAM_RED } : {}}
                to="/bikerack"
            >
                <Translate>MY_BIKES</Translate>
            </Link>
            <Link
                className={styles.linkStyle}
                onClick={() => setOpenDrawerCategory(null)}
                style={(location.pathname === '/components') ? { color: SRAM_RED } : {}}
                to="/components"
            >
                <Translate>MY_COMPONENTS</Translate>
            </Link>
            {(bikes.list
                .filter((bike) => !bike.data || !bike.data.retired)
                .map((bike) => (
                    <Link
                        className={styles.linkStyle}
                        key={bike.id}
                        onClick={() => setOpenDrawerCategory(null)}
                        style={(location.pathname === `/bikerack/${bike.id}`) ? { color: SRAM_RED } : {}}
                        to={`/bikerack/${bike.id}`}
                    >
                        {bike.name}
                    </Link>
                ))
            )}
            <Link
                className={styles.linkStyle}
                onClick={() => setOpenDrawerCategory(null)}
                style={(location.pathname === '/buildbike') ? { color: SRAM_RED } : {}}
                to="/buildbike"
            >
                <Translate>BIKE_ADD_NEW</Translate>
                <div className={styles.plus}>
                        +
                </div>
            </Link>
        </Drawer>
    );

    const renderSettingsDrawer = () => (
        <Drawer
            isOpen={(openDrawerCategory === 'settings')}
            onClose={() => setOpenDrawerCategory(null)}
        >
            <Link
                className={styles.linkStyle}
                onClick={() => setOpenDrawerCategory(null)}
                style={(location.pathname === '/riderportal') ? { color: SRAM_RED } : {}}
                to="/riderportal"
            >
                <Translate>SETTINGS</Translate>
            </Link>
            <Link
                className={styles.linkStyle}
                onClick={() => setOpenDrawerCategory(null)}
                style={(location.pathname === '/logout') ? { color: SRAM_RED } : {}}
                to="/logout"
            >
                <Translate>LOG_OUT</Translate>
            </Link>
        </Drawer>
    );

    if (isExcluded) return null;

    return (
        <MobileContainer className={styles.container}>
            {((auth.isLoggingIn || auth.isAuthenticated())
                ? (
                    <>
                        <MenuOutlinedIcon
                            fontSize="large"
                            onClick={() => toggleActiveCategory('map')}
                            style={((
                                (openDrawerCategory === 'map')
                                || (!openDrawerCategory && (getPathCategory() === 'map'))
                            )
                                ? { color: 'black' }
                                : { color: '#898f93' }
                            )}
                        />
                        <DirectionsBikeIcon
                            fontSize="large"
                            onClick={() => toggleActiveCategory('bike')}
                            style={((
                                (openDrawerCategory === 'bike')
                                || (!openDrawerCategory && (getPathCategory() === 'bike'))
                            )
                                ? { color: 'black' }
                                : { color: '#898f93' }
                            )}
                        />
                        <Link
                            onClick={() => setOpenDrawerCategory(null)}
                            to="/messages"
                        >
                            <MessageBadge
                                fontSize="large"
                                style={(openDrawerCategory || (getPathCategory() !== 'messages')
                                    ? { color: '#898f93' }
                                    : null
                                )}
                            />
                        </Link>
                        {(!error
                            ? (
                                <button
                                    className={styles.profileImageButton}
                                    onClick={() => toggleActiveCategory('settings')}
                                    type="button"
                                >
                                    <ProfileImage
                                        className={((
                                            (openDrawerCategory === 'settings')
                                            || (!openDrawerCategory && (getPathCategory() === 'settings'))
                                        )
                                            ? styles.profileImageActive
                                            : ''
                                        )}
                                        id="data-test-cachedProfilePicture"
                                        onError={() => {
                                            localStorage.removeItem('profilePicture');
                                            setError(true);
                                        }}
                                    />
                                </button>
                            ) : (
                                <PermIdentityIcon
                                    className={styles.logo}
                                    fontSize="large"
                                    id="data-test-material-profilePicture"
                                    onClick={() => toggleActiveCategory('settings')}
                                    style={((
                                        (openDrawerCategory === 'settings')
                                        || (!openDrawerCategory && (getPathCategory() === 'settings'))
                                    )
                                        ? { color: 'black' }
                                        : { color: '#898f93' }
                                    )}
                                />
                            )
                        )}
                    </>
                )
                : (
                    <Link className={styles.loginLink} to="/login">
                        <Translate>LOGIN</Translate>
                    </Link>
                )
            )}
            {renderSettingsDrawer()}
            {renderAxsFeedDrawer()}
            {renderBikesDrawer()}
            {renderActiveBar()}
        </MobileContainer>
    );
};

export default MobileTabBar;
