import React, { lazy, Suspense, useState } from 'react';

import { iconNoTirePressureData } from '../../../../assets';
import Placeholder from '../../../../components/placeholder';
import Spinner from '../../../../components/spinner';
import Translate from '../../../../components/translate';

const TyreExample = lazy(() => import('./tyreExample'));

const TyrePlaceholder = () => {
    const [header, setHeader] = useState();

    return (
        <Placeholder
            header={header}
            imageSrc={iconNoTirePressureData}
            renderExample={() => (
                <Suspense fallback={<Spinner />}>
                    <TyreExample onCarouselItemChange={setHeader} />
                </Suspense>
            )}
            subtitle="TIRE_NO_DATA"
        >
            <Translate>TIRE_NO_GEAR_INFO</Translate>
        </Placeholder>
    );
};

export default TyrePlaceholder;
