import React, { useMemo, useState } from 'react';

import styles from './GearPower.module.scss';

import GearHistogram, {
    EditDrivetrain,
    filterCassetteArrayByChainring,
    gearMapStatistic,
    GearRow,
    tallyStatisticsByCassetteIndexes,
} from '../gearHistogram';

import BikeComponentLink from '../../bikeComponentLink';

import ToggleSelector from '../../../../components/toggleSelector';
import { PINK_BRIGHT, SRAM_300, SRAM_700 } from '../../../../constants';
import {
    Activity,
    Bike,
    ComponentSummary,
} from '../../../../providers';
import { BikeUpdates, ExtendedBike } from '../../../../providers/bikes/types';

interface GearPowerProps {
    activity: Activity
    bike: Bike
    disableEdit?: boolean;
    gearComponents: ComponentSummary[];
    powerComponent: ComponentSummary | null
    updateActivity?: (activity: Activity) => void;
    updateBike?: (bikeUpdates: BikeUpdates) => void;
}

function gearMapEnergy(value: { entries: number; tally: number; }) {
    return value.entries ? (value.tally / 1000) : 0;
}

function gearMapPowerAverage(value: { entries: number; tally: number; }) {
    return value.entries ? Math.round(value.tally / value.entries) : 0;
}

const GearPower = ({
    activity,
    bike,
    disableEdit = false,
    gearComponents = [],
    powerComponent = null,
    updateActivity = () => {},
    updateBike = () => {},
}: GearPowerProps) => {
    const [showAveragePower, setShowAveragePower] = useState(true);
    const [fdFilterIndex, setFdFilterIndex] = useState(null);

    const gearComponent = useMemo(
        () => gearComponents.find(({ data }) => data?.ant_component_id === 2),
        [gearComponents],
    );

    const { energyHistogram, powerAverageHistogram } = useMemo(
        () => {
            if (!gearComponent || !powerComponent) {
                return {};
            }

            const fdEnergy = gearMapStatistic(
                gearComponent.data.fd_gear,
                powerComponent.data.power,
                (_value: number, index: number) => gearComponent.data.adjustedTime[index],
                (_value: number, index: number) => powerComponent.data.adjustedTime[index],
            ).map(gearMapEnergy);

            const rdEnergy = gearMapStatistic(
                gearComponent.data.rd_gear,
                powerComponent.data.power,
                (_value: number, index: number) => gearComponent.data.adjustedTime[index],
                (_value: number, index: number) => powerComponent.data.adjustedTime[index],
            ).map(gearMapEnergy);

            const fdPowerAverage = gearMapStatistic(
                gearComponent.data.fd_gear,
                powerComponent.data.power,
                (_value: number, index: number) => gearComponent.data.adjustedTime[index],
                (_value: number, index: number) => powerComponent.data.adjustedTime[index],
            ).map(gearMapPowerAverage);

            const rdPowerAverage = gearMapStatistic(
                gearComponent.data.rd_gear,
                powerComponent.data.power,
                (_value: number, index: number) => gearComponent.data.adjustedTime[index],
                (_value: number, index: number) => powerComponent.data.adjustedTime[index],
            ).map(gearMapPowerAverage);

            return {
                energyHistogram: { fd: fdEnergy, rd: rdEnergy },
                powerAverageHistogram: { fd: fdPowerAverage, rd: rdPowerAverage },
            };
        },
        [gearComponent, powerComponent],
    );

    const activeRdHistogram = useMemo(() => {
        let newRdHistogram: number[] = [];

        if (!gearComponent) {
            return newRdHistogram;
        }

        if (showAveragePower) {
            newRdHistogram = powerAverageHistogram?.rd;

            if (fdFilterIndex !== null && powerComponent) {
                const filteredArrayIndexes = filterCassetteArrayByChainring(
                    gearComponent.data.rd_gear,
                    fdFilterIndex,
                    gearComponent.data.fd_gear,
                    true,
                );

                const tallies = tallyStatisticsByCassetteIndexes(
                    filteredArrayIndexes,
                    gearComponent.data.rd_gear,
                    newRdHistogram,
                    powerComponent.data.power,
                );

                newRdHistogram = Object.keys(tallies)
                    .map((key) => tallies[key as keyof typeof tallies])
                    .map(gearMapPowerAverage);
            }
        } else {
            newRdHistogram = energyHistogram?.rd;

            if (fdFilterIndex !== null && powerComponent) {
                const filteredArrayIndexes = filterCassetteArrayByChainring(
                    gearComponent.data.rd_gear,
                    fdFilterIndex,
                    gearComponent.data.fd_gear,
                    true,
                );

                const tallies = tallyStatisticsByCassetteIndexes(
                    filteredArrayIndexes,
                    gearComponent.data.rd_gear,
                    newRdHistogram,
                    powerComponent.data.power,
                );

                newRdHistogram = Object.keys(tallies)
                    .map((key) => tallies[key as keyof typeof tallies])
                    .map(gearMapEnergy);
            }
        }

        return newRdHistogram;
    }, [
        energyHistogram,
        fdFilterIndex,
        gearComponent,
        powerAverageHistogram,
        showAveragePower,
    ]);

    const filterIsActive = (fdFilterIndex !== null);

    return (
        <>
            <GearHistogram
                bike={bike as ExtendedBike}
                displayLabel={(showAveragePower ? 'GEAR_SHOW_POWER' : 'TOTAL_WORK_UNIT')}
                gearComponents={gearComponents}
                renderFDRow={({ fdCount, index, label }: any) => {
                    const current = showAveragePower ? powerAverageHistogram?.fd : energyHistogram?.fd;
                    let value = current[index];
                    let percentage = 0;

                    if (value) {
                        percentage = value / Math.max(...current);
                        percentage *= 100;
                    }

                    if (!showAveragePower) {
                        value = Math.round(value);
                    }

                    const isActiveIndex = (fdFilterIndex === index);
                    const isInactiveIndex = (filterIsActive && !isActiveIndex);
                    const textStyle = isInactiveIndex ? styles.inactive : '';
                    const color = isInactiveIndex ? SRAM_300 : PINK_BRIGHT;

                    return (
                        <button
                            className={styles.clickableRow}
                            disabled={(fdCount < 2)}
                            key={index}
                            onClick={() => setFdFilterIndex(isActiveIndex ? null : index)}
                            type="button"
                        >
                            <GearRow
                                color={color}
                                durationClassName={textStyle}
                                key={index}
                                label={label}
                                labelClassName={textStyle}
                                percentage={percentage}
                                value={value}
                            />
                        </button>
                    );
                }}
                renderRDRow={({ index, label }: any) => {
                    let displayValue = activeRdHistogram[index];
                    let displayPercentage = ((displayValue / Math.max(...activeRdHistogram)) * 100);

                    if (!showAveragePower) {
                        displayValue = Math.round(displayValue);
                    }

                    if (!Number.isFinite(displayPercentage)) {
                        displayPercentage = 0;
                    }

                    return (
                        <GearRow
                            color={PINK_BRIGHT}
                            key={index}
                            label={label}
                            percentage={displayPercentage}
                            value={displayValue}
                            durationClassName=""
                        />
                    );
                }}
            />
            <div className={styles.footer}>
                <div className={styles.toggle}>
                    <ToggleSelector
                        activeButton={showAveragePower}
                        buttonClassName={styles.button}
                        buttons={[
                            { key: 'Power', label: 'POWER_AVERAGE', value: true },
                            { key: 'Work', label: 'TOTAL_WORK', value: false },
                        ]}
                        color={SRAM_700}
                        id="data-test-gear-power-toggle"
                        onToggle={setShowAveragePower}
                    />
                </div>
                {!disableEdit && (
                    <div className={styles.editDrivetrain}>
                        <EditDrivetrain
                            activity={activity}
                            bike={bike}
                            disableEdit={disableEdit}
                            gearComponent={gearComponent}
                            updateActivity={updateActivity as (data: {bike_uuid: string}) => void}
                            updateBike={updateBike}
                        />
                    </div>
                )}
                {!disableEdit && <BikeComponentLink componentSummary={gearComponent} />}
            </div>
        </>
    );
};

export default GearPower;
