import React from 'react';
import Modal from '../../../components/modal';
import styles from './modals.module.scss';
import Translate from '../../../components/translate';

interface DataUnavailableModalProps {
    open: boolean,
    onClose: () => void,

}

const IdlerPullyNotice = ({
    open, onClose,
}: DataUnavailableModalProps) => (
    <Modal
        className={styles.unavailableModalContainer}
        contentClassName={styles.infoContent}
        headerStyle={{ color: 'white', fontSize: '1.5rem' }}
        contentLabel="InfoModal"
        isOpen={open}
        header="Notice"
        hideImage
        containerStyle={{ padding: 'none' }}
        onOverlayClick={() => onClose()}
        hideCloseButton
    >

        <div className={styles.infoText}>
            <Translate>IDLER_PULLEY_NOTICE</Translate>
        </div>
        <a
            // eslint-disable-next-line max-len
            href="https://docs.sram.com/en-US/publications/5jblJ4SRpeHwjcuWG1vPy4#hashItem=idler-pulley-frame-setup-information"
            rel="noreferrer"
            target="_blank"
        >
            <button
                className={`${styles.button}`}
                onClick={() => {}}
                type="button"
            >
                <Translate>VIEW_PRODUCT_MANUAL</Translate>
            </button>
        </a>
        <button
            className={`${styles.button}`}
            onClick={() => onClose()}
            type="button"
        >
            <Translate>MOBILE_DISMISS</Translate>
        </button>
    </Modal>
);

export default IdlerPullyNotice;
